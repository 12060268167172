import Immutable from 'seamless-immutable';
import { apiFetch } from '../../utils/index';
import _filter from 'lodash/get';

// ------------------------------------
// Constants
// ------------------------------------
const API = '/fni-lenderportal-navigation/navigation/';
export const MESSAGING_REQUEST = 'MESSAGING_REQUEST';
export const MESSAGING_RECEIVE = 'MESSAGING_RECEIVE';
export const MESSAGING_SET_READ = 'MESSAGING_SET_READ';
export const MESSAGING_POSTING = 'MESSAGING_POSTING';
export const MESSAGING_POSTED = 'MESSAGING_POSTED';
export const MESSAGING_USERS_RECEIVE = 'MESSAGING_USERS_RECEIVE';
export const MESSAGE_MODAL_ALERT_SET = 'MESSAGE_MODAL_ALERT_SET';
export const MESSAGE_MODAL_SET = 'MESSAGE_MODAL_SET';
export const MESSAGE_ALERT_SET = 'MESSAGE_ALERT_SET';
export const MESSAGE_COUNT_SET = 'MESSAGE_COUNT_SET';

const switchMessage = (data, messageId, refnum = false) => {
  data = Immutable.asMutable(data, { deep: true });
  let modData = refnum ? data.appMessages : data.userMessages;
  let read = false;
  modData.unreadMessages = modData.unreadMessages.filter(message => {
    if (message.messageId === messageId) {
      read = message;
      return false;
    } else {
      return true;
    }
  });
  modData.readMessages.push(read);
  return data;
};

// ------------------------------------
// Actions
// ------------------------------------
export const messagingRequest = () => {
  return {
    type: MESSAGING_REQUEST
  };
};

export const messagingReceive = (data) => {
  return {
    type: MESSAGING_RECEIVE,
    payload: data
  };
};

export const messagingSetRead = (messageId, refnum = false) => {
  return {
    type: MESSAGING_SET_READ,
    payload: {
      messageId,
      refnum
    }
  };
};

export const messagingPosting = () => {
  return {
    type: MESSAGING_POSTING
  };
};

export const messagingPosted = () => {
  return {
    type: MESSAGING_POSTED
  };
};

export const messagingUsersReceive = (data) => {
  return {
    type: MESSAGING_USERS_RECEIVE,
    payload: data
  };
};

export const messageModalAlertSet = (data) => {
  return {
    type: MESSAGE_MODAL_ALERT_SET,
    payload: {
      alert: data
    }
  };
};

export const messageModalSet = (data, type, message, msgType) => {
  return {
    type: MESSAGE_MODAL_SET,
    payload: {
      data,
      type,
      message,
      msgType
    }
  };
};

export const messageAlertSet = (data) => {
  return {
    type: MESSAGE_ALERT_SET,
    payload: {
      alert: data
    }
  };
};

export const messageCountSet = (type, count) => {
  return {
    type: MESSAGE_COUNT_SET,
    payload: { type, count }
  };
};

// ------------------------------------
// Middleware
// ------------------------------------
export const messagingFetch = (refnum = false, minutes = 5) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(messagingRequest());
      let url;
      if (refnum) {
        url = 'messages/' + refnum;
      } else {
        url = 'messages';
      }
      apiFetch(url, {}, API)
      .then(response => {
        if (!response.ok) {
          throw response;
        } else {
          return response.text();
        }
      }).then(text => {
        let messages = JSON.parse(text);
        dispatch(messagingReceive(messages));
        dispatch(applyMessageFetchTimeout(minutes, refnum));
        resolve(true);
      }).catch(() => {
        dispatch(messageAlertSet({
          show: true,
          type: 'danger',
          title: 'Error!',
          message: 'There was an error retrieving your messages.'
        }));
        reject();
      });
    });
  };
};

let messageFetchTimeout = null;

export const applyMessageFetchTimeout = (minutes, refnum) => {
  return (dispatch, getState) => {
    clearTimeout(messageFetchTimeout);
    messageFetchTimeout = setTimeout(() => {
      dispatch(messagingFetch(refnum, minutes));
    }, minutes * 60 * 1000);
  };
};

export const messagingMarkRead = (messageId, refnum = false, type, currentStatus) => {
  return (dispatch, getState) => {
    dispatch(messagingPosting());
    return new Promise((resolve, reject) => {
      let typeKey = type === 'APP' ? 'appMessages' : 'userMessages';
      let options = {
        method: 'PUT'
      };
      let end = refnum ? '/' + refnum : '';
      apiFetch('message/' + messageId + end, options, API)
        .then(response => {
          if (response.ok) {
            dispatch(messagingPosted());
            let count = getState().messaging[typeKey].unreadMessageCount;
            if (currentStatus === 'unread') {
              count--;
            } else {
              count++;
            }
            dispatch(messageCountSet(typeKey, count));
            resolve(true);
          } else {
            reject('error');
          }
        }).catch(error => { reject(error); });
    });
  };
};

export const messagingPost = (toUsers, text, refnum = false, msgType, system = false) => {
  return (dispatch, getState) => {
    dispatch(messagingPosting());
    return new Promise((resolve, reject) => {
      let body = {
        messageType: msgType,
        messageText: text,
        systemMessage: system
      };
      if (msgType === 'USER') {
        body.toUserIds = toUsers;
        body.refnum = refnum;
      } else {
        body.refnum = getState().coreLayout.refnum;
      }
      let options = {
        method: 'POST',
        body: JSON.stringify(body)
      };
      apiFetch('message', options, API)
        .then(response => {
          if (response.ok) {
            response.text()
              .then(text => {
                if (response.status === 200) {
                  dispatch(messagingPosted());
                  dispatch(messageModalSet(false, ''));
                  dispatch(messageModalAlertSet({
                    show: false,
                    type: '',
                    title: '!',
                    message: ''
                  }));
                  resolve(true);
                }
              });
          } else {
            let errorMsg;
            if (response.status === 400) {
              errorMsg = 'You have entered an invalid reference number';
            } else {
              errorMsg = 'There was an error sending your message';
            }
            reject(errorMsg);
          }
        })
        .catch(() => {
          dispatch(messageModalAlertSet({
            show: true,
            type: 'danger',
            title: 'Error!',
            message: 'There was an error sending your message.'
          }));
        });
    }).catch(error => {
      dispatch(messageModalAlertSet({
        show: true,
        type: 'danger',
        title: 'Error!',
        message: error
      }));
    });
  };
};

export const messagingUsersFetch = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(messagingRequest());
      apiFetch('users', {}, API)
        .then(response => !response.ok ? reject('Error') : response.text())
        .then(text => {
          let users = JSON.parse(text);
          dispatch(messagingUsersReceive(users));
          resolve(true);
        })
        .catch(error => {
          console.log(error);
        });
    }).catch(error => {
      console.log(error);
    });
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [MESSAGING_REQUEST]: (state, action) => {
    return Immutable({ ...state, fetching: true });
  },
  [MESSAGING_RECEIVE]: (state, action) => {
    return Immutable({ ...state, ...action.payload, fetching: false });
  },
  [MESSAGING_SET_READ]: (state, action) => {
    return Immutable({ ...switchMessage(state, action.payload.messageId, action.payload.refnum) });
  },
  [MESSAGING_POSTING]: (state, action) => {
    return Immutable({ ...state, posting: true });
  },
  [MESSAGING_POSTED]: (state, action) => {
    return Immutable({ ...state, posting: false });
  },
  [MESSAGING_USERS_RECEIVE]: (state, action) => {
    return Immutable({ ...state, users: action.payload });
  },
  [MESSAGE_MODAL_ALERT_SET]: (state, action) => {
    return Immutable({
      ...state,
      messageModal: {
        ...state.messageModal,
        alert: action.payload.alert
      }
    });
  },
  [MESSAGE_MODAL_SET]: (state, action) => {
    return Immutable({
      ...state,
      messageModal: {
        ...state.messageModal,
        modalOpen: action.payload.data,
        type: action.payload.type,
        message: action.payload.message,
        msgType: action.payload.msgType
      }
    });
  },
  [MESSAGE_ALERT_SET]: (state, action) => {
    return Immutable({
      ...state,
      alert: action.payload.alert
    });
  },
  [MESSAGE_COUNT_SET]: (state, action) => {
    return Immutable({
      ...state,
      [action.payload.type]: {
        ...state[action.payload.type],
        unreadMessageCount: action.payload.count
      }
    });
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  fetching: false,
  posting: false,
  alert: { show: false },
  messageModal: {
    modalOpen: false,
    type: '',
    message: {},
    msgType: '',
    alert: { show: false }
  }
};

export default function messagingReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SnackBar from '@mui/material/Snackbar'
import { conversationsErrorClear } from '../modules/conversations'
import InfoBarContent from './InfoBarContent'

const InfoBar = ({
  title = '',
  type = 'error',
  message = '',
  delay,
  conversationsErrorClear: dispatchConversationsErrorClear,
}) => {
  const [open, setOpen] = React.useState(false)
  React.useEffect(() => {
    if (message) {
      setOpen(message)
    }
  }, [message])
  return (
    <SnackBar
      open={Boolean(open)}
      onClose={(e, reason) => reason !== 'clickaway' && setOpen(false)}
      onExited={() => dispatchConversationsErrorClear()}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      autoHideDuration={delay}
    >
      <InfoBarContent
        variant={type}
        message={message}
        title={title}
        onClose={() => setOpen(false)}
      />
    </SnackBar>
  )
}

InfoBar.propTypes = {
  delay: PropTypes.number,
  message: PropTypes.string,
  conversationsErrorClear: PropTypes.func.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
}

const mapDispatchToProps = { conversationsErrorClear }

const mapStateToProps = (state) => state?.convo?.error ?? {}

export default connect(mapStateToProps, mapDispatchToProps)(InfoBar)

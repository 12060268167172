import { AppBar, Box, Toolbar } from '@mui/material';
import AvatarMenu from './AvatarMenu';
import Links from './Links';

export default function NavBar({ 
    currentAuthenticatedUser, 
    onSignOut, 
    code, 
    links,
}) {

    return (
        <AppBar position="static" >
            <Toolbar disableGutters>
                <Box 
                    padding='5px' 
                    marginTop='4px' 
                    alignItems='center' justifyContent='center'>
                    <img
                        height="32"
                        src={`/getLogo?code=${code}`}
                        alt="logo"
                        onError={event => {
                            event.target.src = '/assets/Circle-FNI.png';
                            event.onerror = null;
                        }}
                    />
                </Box>

                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    {currentAuthenticatedUser?.isValid && links.length ?
                        <Links links={links}  /> : null }
                </Box>

                <Box sx={{ flexGrow: 0 }}>
                    {currentAuthenticatedUser?.isFNIAdmin ?
                        <AvatarMenu onLogout={onSignOut} currentAuthenticatedUser={currentAuthenticatedUser} /> : null
                    }
                </Box>

            </Toolbar>
        </AppBar>
    );
}

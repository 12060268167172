import Immutable from 'seamless-immutable';

// eslint-disable-next-line import/no-cycle
import { makeApi } from 'fni-schema';

const api = makeApi({
  baseURL: 'fni-lenderportal-search/searching',
  withRequestInterceptor: false,
  withResponseInterceptor: false,
});

// ------------------------------------
// Constants
// ------------------------------------
const SET_DEALER_LANDING = 'SET_DEALER_LANDING';
const SET_SNACKBAR_MESSAGE = 'SET_SNACKBAR_MESSAGE';
const SET_DEALER_LANDING_FETCHING = 'SET_DEALER_LANDING_FETCHING';
const SET_ACTIONS = 'SET_ACTIONS';

// ------------------------------------
// Actions
// ------------------------------------
export const setDealerLanding = (data, path) => {
  return {
    type: SET_DEALER_LANDING,
    payload: { data, path },
  };
};

export const setSnackbarMessage = (payload) => {
  return {
    type: SET_SNACKBAR_MESSAGE,
    payload,
  };
};

export const setFetching = (path, data) => {
  return {
    type: SET_DEALER_LANDING_FETCHING,
    payload: { data, path },
  };
};

export const setActions = (payload) => {
  return {
    type: SET_ACTIONS,
    payload,
  };
};

export const setError = (message) =>
  setSnackbarMessage({ type: 'error', message });

export const setSuccess = (message) =>
  setSnackbarMessage({ type: 'success', message, delay: 2000 });
// ------------------------------------
// Middleware
// ------------------------------------

// GET fni-lenderportal-search/searching/search/{searchType}
export const getDealerSearchSchema = (searchType = 'DEALER') => async (
  dispatch,
  getState
) => {
  if (!getState()?.dealerLanding?.search) {
    try {
      
      const response = await api.get(`search/${searchType}`);
      if(response.status == 200){
        dispatch(setDealerLanding(response.data, ['search']));
      }
      
    } catch (error) {
      if (error.message) {
        dispatch(setError(error.message));
      } else dispatch(setError('Failed to fetch search fields'));
    }
  }
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_DEALER_LANDING]: (state, { payload: { data, path } }) => {
    return Immutable.setIn(state, path, data);
  },
  [SET_SNACKBAR_MESSAGE]: (state, { payload }) =>
    Immutable.setIn(state, ['snackbar'], payload),
  [SET_DEALER_LANDING_FETCHING]: (state, { payload: { data, path } }) => {
    return Immutable.setIn(state, path, data);
  },
  [SET_ACTIONS]: (state, { payload }) =>
    Immutable.setIn(state, ['actions'], payload),
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};
export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

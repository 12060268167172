/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import InputLabel from '@mui/material/InputLabel';

import ErrorControl from '../ErrorControl';

function CustomField(props) {
  const {
    Component,
    id,
    label,
    labelId,
    value,
    autoFocus = false,
    disabled = false,
    error = false,
    readOnly = false,
    required = false,
    errors,
    schemaValues,
    onChange,
    onBlur,
    onFocus,
    ErrorControlProps,
    LabelProps,
    ...rest
  } = props;

  return (
    <ErrorControl
      id={id}
      errors={errors}
      required={required}
      {...ErrorControlProps}
    >
      {Boolean(label) && (
        <InputLabel id={labelId} htmlFor={id} {...LabelProps}>
          {label}
        </InputLabel>
      )}

      <Component
        id={id}
        value={value}
        autoFocus={autoFocus}
        disabled={disabled}
        readOnly={readOnly}
        required={required}
        schemaValues={schemaValues}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        error={error}
        errors={errors}
        {...rest}
      />
    </ErrorControl>
  );
}

CustomField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  labelId: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.any),
  schemaValues: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  Component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  ErrorControlProps: PropTypes.objectOf(PropTypes.any),
  LabelProps: PropTypes.objectOf(PropTypes.any),
};

export default CustomField;

import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Paper } from '@mui/material'
import PageHeader from '../../components/PageHeader';
import ChangePasswordForm from "./ChangePasswordForm";
import { authActions } from '../modules/auth';

export default function ForceChangePasswordPage({ oldPassword }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [params] = useSearchParams();

    const handleChangePasswordSubmit = async (data) => {        
        dispatch(authActions.forceChangePassword({ oldPassword, newPassword: data.password }))
            .unwrap()
            .then(navigate(`/auth/logout${params.size ? `?${params.toString()}` : ''}`))
            .catch(error => authActions.setErrorMessage(error.message));
    };

    return (
        <Container maxWidth='sm'>
            <PageHeader text='For security, please change your password.' variant='h5' />
            <Paper elevation={0} sx={{ padding: 3 }}>
                <ChangePasswordForm
                    onSubmit={handleChangePasswordSubmit}
                    forceChange
                />
            </Paper>
        </Container>
    );
}

/* eslint-disable no-param-reassign */
import Immutable from 'seamless-immutable';
import moment from 'moment';
import _get from 'lodash/get';
import _pull from 'lodash/pull';
import _uniqBy from 'lodash/uniqBy';
import SchemaConverter from 'fni-components/FNISchemaForm/utils/SchemaConverter';
import { urlSet } from 'fni-components/CustomAbility/modules/customAbility';
import { batch } from 'react-redux';
import { makeApi } from 'fni-schema';

import {
  translateQueueDataFetch,
  translateQueueDataFetchToBackend,
  translateActionFetch,
  translateFilterDataFetch,
} from './translators';
import { informationSet } from '../../../store/coreLayout';
import apiFetch from '../../../utils/apiFetch';

const basePath = '/fni-lenderportal-search/';

export const SEARCH_DATA_STORE = 'SEARCH_DATA_STORE';
export const QUEUES_REQUEST = 'QUEUES_REQUEST';
export const QUEUES_RECEIVE = 'QUEUES_RECEIVE';
export const QUEUE_DATA_REQUEST = 'QUEUE_DATA_REQUEST';
export const QUEUE_DATA_RECEIVE = 'QUEUE_DATA_RECEIVE';
export const FILTERS_REQUEST = 'FILTERS_REQUEST';
export const FILTERS_RECEIVE = 'FILTERS_RECEIVE';
export const FILTER_CREATE_RECEIVE = 'FILTER_CREATE_RECEIVE';
export const ACTION_REQUEST = 'ACTION_REQUEST';
export const ACTION_RECEIVE = 'ACTION_RECEIVE';
export const ACTION_MODAL_SET = 'ACTION_MODAL_SET';
export const ACTION_SUBMIT_REQUEST = 'ACTION_SUBMIT_REQUEST';
export const ACTION_SUBMIT_RECEIVE = 'ACTION_SUBMIT_RECEIVE';
export const SHOW_ACTION_SUBMIT_ALERT = 'SHOW_ACTION_SUBMIT_ALERT';
export const HIDE_ACTION_SUBMIT_ALERT = 'HIDE_ACTION_SUBMIT_ALERT';
export const PAGINATION_SET = 'PAGINATION_SET';
export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_RECEIVE = 'SEARCH_RECEIVE';
export const SEARCH_MODAL_SET = 'SEARCH_MODAL_SET';
export const SHOW_QUEUE_ALERT = 'SHOW_QUEUE_ALERT';
export const HIDE_QUEUE_ALERT = 'HIDE_QUEUE_ALERT';
export const SHOW_QUEUE_DATA_ALERT = 'SHOW_QUEUE_DATA_ALERT';
export const HIDE_QUEUE_DATA_ALERT = 'HIDE_QUEUE_DATA_ALERT';
export const SHOW_FILTER_ALERT = 'SHOW_FILTER_ALERT';
export const HIDE_FILTER_ALERT = 'HIDE_FILTER_ALERT';
export const SEARCH_IS_NEW = 'SEARCH_IS_NEW';
export const SEARCH_IS_NOT_NEW = 'SEARCH_IS_NOT_NEW';
export const DATA_TABLE_CLEAR = 'DATA_TABLE_CLEAR';
export const DATA_TABLE_UPDATE_TITLE = 'DATA_TABLE_UPDATE_TITLE';
export const FILTER_DELETE_CONFIRM_MODAL_OPEN =
  'FILTER_DELETE_CONFIRM_MODAL_OPEN';
export const FILTER_DELETE_CONFIRM_MODAL_CLOSE =
  'FILTER_DELETE_CONFIRM_MODAL_CLOSE';
export const COLUMN_FILTER_SORT_CHANGE = 'COLUMN_FILTER_SORT_CHANGE';
export const CHANGE_SORT_PRIORITY = 'CHANGE_SORT_PRIORITY';
export const DRAWER_SET = 'DRAWER_SET';
export const DATA_COLLECT_SET = 'DATA_COLLECT_SET';
export const FORM_DATA_SET = 'FORM_DATA_SET';
export const UPDATE_FORM_SET = 'UPDATE_FORM_SET';
export const SEARCH_FETCHING_SET = 'CP_FETCHING_SET';
export const ABILITIES_SET = 'ABILITIES_SET';
export const ABILITIES_FETCHING_SET = 'ABILITIES_FETCHING_SET';
export const ABILITIES_CLEAR = 'ABILITIES_CLEAR';
export const ABILITY_ACTION_RESPONSE_SET = 'ABILITY_ACTION_RESPONSE_SET';
export const ACTION_FETCHING_SET = 'ACTION_FETCHING_SET';
export const HAS_CALC_SET = 'HAS_CALC_SET';
export const IS_DEALER_SET = 'IS_DEALER_SET';
export const UPDATE_DATA_TABLE_SET = 'UPDATE_DATA_TABLE_SET';
export const SET_STIP = 'SET_STIP';
export const UNSET_STIP = 'UNSET_STIP';

const fetchOptions = {
};

/* ACTIONS */


export const searchDataStore = (data) => {
  return {
    type: SEARCH_DATA_STORE,
    payload: data,
  };
};

export const updateFormSet = (data) => {
  return {
    type: UPDATE_FORM_SET,
    payload: data,
  };
};

export const drawerSet = (data) => {
  return {
    type: DRAWER_SET,
    payload: data,
  };
};

export const hasCalcSet = (data) => {
  return {
    type: HAS_CALC_SET,
    payload: data,
  };
};

export const isDealerSet = (data) => {
  return {
    type: IS_DEALER_SET,
    payload: data,
  };
};

// Queues
export const queuesRequest = () => {
  return {
    type: QUEUES_REQUEST,
  };
};

export const queuesReceive = (data) => {
  return {
    type: QUEUES_RECEIVE,
    payload: data,
  };
};

export const showQueueAlert = (msg, type, title) => {
  return {
    type: SHOW_QUEUE_ALERT,
    payload: {
      msg,
      type,
      title,
    },
  };
};

export const hideQueueAlert = () => {
  return {
    type: HIDE_QUEUE_ALERT,
  };
};

export const hideQueueDataAlert = () => {
  return {
    type: HIDE_QUEUE_DATA_ALERT,
  };
};

export const showQueueDataAlert = (msg, type, title) => {
  return {
    type: SHOW_QUEUE_DATA_ALERT,
    payload: {
      msg,
      type,
      title,
    },
  };
};

// Queue Data
export const queueDataRequest = (type) => {
  return {
    type: QUEUE_DATA_REQUEST,
    payload: {
      type,
    },
  };
};

export const queueDataReceive = (data) => {
  return {
    type: QUEUE_DATA_RECEIVE,
    payload: data,
  };
};

// Filters
export const filtersRequest = () => {
  return {
    type: FILTERS_REQUEST,
  };
};

export const filtersReceive = (data) => {
  return {
    type: FILTERS_RECEIVE,
    payload: data,
  };
};

export const filterCreateReceive = (data) => {
  return {
    type: FILTER_CREATE_RECEIVE,
    payload: data,
  };
};

export const showFilterAlert = (content) => {
  const { msg, type, delay = 60000 } = content;
  return {
    type: SHOW_FILTER_ALERT,
    payload: {
      msg,
      type,
      delay,
    },
  };
};

export const hideFilterAlert = () => {
  return {
    type: HIDE_FILTER_ALERT,
  };
};

export const filterDeleteConfirmModalOpen = (filter) => {
  return {
    type: FILTER_DELETE_CONFIRM_MODAL_OPEN,
    payload: {
      filterId: filter.filterId,
      filterName: filter.filterName,
    },
  };
};

export const filterDeleteConfirmModalClose = () => {
  return {
    type: FILTER_DELETE_CONFIRM_MODAL_CLOSE,
  };
};

// Actions
export const actionRequest = () => {
  return {
    type: ACTION_REQUEST,
  };
};

export const actionFetchingSet = (data) => {
  return {
    type: ACTION_FETCHING_SET,
    payload: data,
  };
};

export const actionReceive = (data, actionType, refnum) => {
  return {
    type: ACTION_RECEIVE,
    payload: { data, actionType, refnum },
  };
};

export const actionModalSet = (data) => {
  return {
    type: ACTION_MODAL_SET,
    payload: data,
  };
};

export const actionSubmitRequest = () => {
  return {
    type: ACTION_SUBMIT_REQUEST,
  };
};

export const actionSubmitReceive = () => {
  return {
    type: ACTION_SUBMIT_RECEIVE,
  };
};

export const showActionSubmitAlert = (type, msg, title) => {
  return {
    type: SHOW_ACTION_SUBMIT_ALERT,
    payload: {
      type,
      msg,
      title,
    },
  };
};

export const hideActionSubmitAlert = () => {
  return {
    type: HIDE_ACTION_SUBMIT_ALERT,
  };
};

// Pagination
export const paginationSet = (pageNumber = 1, pageSize = 0) => {
  return {
    type: PAGINATION_SET,
    payload: {
      pageNumber,
      pageSize,
    },
  };
};

// Search
export const searchRequest = () => {
  return {
    type: SEARCH_REQUEST,
  };
};

export const searchReceive = (data) => {
  return {
    type: SEARCH_RECEIVE,
    payload: data,
  };
};

export const searchModalSet = (data) => {
  return {
    type: SEARCH_MODAL_SET,
    payload: data,
  };
};



export const searchIsNew = () => {
  return {
    type: SEARCH_IS_NEW,
  };
};

export const searchIsNotNew = () => {
  return {
    type: SEARCH_IS_NOT_NEW,
  };
};

export const dataTableClear = () => {
  return {
    type: DATA_TABLE_CLEAR,
  };
};

export const dataTableUpdateTitle = (title) => {
  return {
    type: DATA_TABLE_UPDATE_TITLE,
    payload: {
      title,
    },
  };
};

export const columnFilterSortChange = (data) => {
  return {
    type: COLUMN_FILTER_SORT_CHANGE,
    payload: data,
  };
};

export const fetchingSet = (data) => {
  return {
    type: SEARCH_FETCHING_SET,
    payload: data,
  };
};

// noinspection JSUnusedGlobalSymbols
export const changeSortPriority = (data) => {
  return {
    type: CHANGE_SORT_PRIORITY,
    payload: data,
  };
};

export const dataCollectSet = (data) => {
  return {
    type: DATA_COLLECT_SET,
    payload: data,
  };
};

export const formDataSet = (data, stipId) => {
  return {
    type: FORM_DATA_SET,
    payload: { data, stipId },
  };
};

export const abilitiesSet = (data) => {
  return {
    type: ABILITIES_SET,
    payload: data,
  };
};

export const abilitiesFetchingSet = (data) => {
  return {
    type: ABILITIES_FETCHING_SET,
    payload: data,
  };
};

export const abilitiesClear = () => {
  return {
    type: ABILITIES_CLEAR,
  };
};

export const abilityActionResponseSet = (data) => {
  return {
    type: ABILITY_ACTION_RESPONSE_SET,
    payload: data,
  };
};

export const updateDataTableSet = (data) => {
  return {
    type: UPDATE_DATA_TABLE_SET,
    payload: data,
  };
};

export const setStip = (data) => {
  return {
    type: SET_STIP,
    payload: data,
  };
};

export const unsetStip = () => {
  return {
    type: UNSET_STIP,
  };
};
// ------------------------------------
// Middleware
// ------------------------------------

// Queues
export const queuesFetch = () => {
  return async (dispatch) => {

    try {
      dispatch(hideQueueAlert());
      dispatch(queuesRequest());
      const response = await api.get("searching/queues");

      if (response.status == 401) {
        window.location.reload(true);
      }
      if (response.status == 200) {
        dispatch(queuesReceive(response.data));
      }

    } catch (e) {
      return dispatch(
        showQueueAlert(
          'There was an error receiving the queues.',
          'danger',
          'Error!'
        )
      );
    }
  };
};

export const queueAlertHide = () => {
  return (dispatch) => {
    dispatch(hideQueueAlert());
  };
};

export const dataTableAlertHide = () => {
  return (dispatch) => {
    dispatch(hideQueueDataAlert());
  };
};

// Queue Data
export const queueDataFetch = (queueGroupId, queueId, func) => {
  return async (dispatch, getState) => {
    let { pagination } = getState().search.dataTable;
    if (pagination.pageNumber === 0) {
      pagination = {
        pageNumber: 1,
        pageSize: getState().search.dataTable.pagination.pageSize,
      };
    }
    const data = {
      queueId,
      queueGroupId,
      pagination,
      sortAndFilter: getState().search?.dataTable.sortAndFilter,
    };


    dispatch(hideQueueDataAlert());

    dispatch(searchDataUpdate([]));
    
    dispatch(searchIsNotNew());
    dispatch(queueDataRequest('queue'));
    dispatch(searchModalSet(false));
    if (func) {
      func()
    }
    const response = await api.put('searching/queues', translateQueueDataFetchToBackend(data))
    if (response.status === 401) {
      window.location.reload(true);
    }
    try {
      dispatch(
        queueDataReceive(
          translateQueueDataFetch(response.data, queueGroupId, queueId, 'queue')
        )
      );
      dispatch(updateDataTableSet(true));
      
    } catch (e) {
      return dispatch(
        showQueueDataAlert(
          'There was an error receiving the queue data.',
          'danger',
          'Error!'
        )
      );
    }
  }
};

export const hasLoanCalcPermission = () => async (dispatch) => {
  const response = await api.get(`searching/hasLoanCalcPermission`);
  if (response.status == 200) {
    dispatch(hasCalcSet(response.data));
  }

};

export const getIsDealer = () => async (dispatch) => {

  const response = await api.get("searching/isDealer")
  if (response.status == 200) {
    dispatch(isDealerSet(response.data));
  }
};

export const filterAlertHide = () => {
  return (dispatch) => {
    dispatch(hideFilterAlert());
  };
};

// Filters
export const filtersFetch = () => {
  return async (dispatch) => {
    dispatch(hideFilterAlert());
    dispatch(filtersRequest());
    const response = await api.get('searching/filters')
    if (response.status === 401) {
      window.location.reload(true);
    }
    if (response.status === 200) {
      try {
        dispatch(filtersReceive(response.data));
      } catch (e) {
        dispatch(
          showFilterAlert({
            msg: 'There was an error receiving the filters.',
            type: 'error',
          })
        );
      }
    }
  };
};

export const filterCreate = (filterName, filterData) => {
  return (dispatch) => {
    dispatch(hideFilterAlert());
    dispatch(filtersRequest());
    const filterCriteria = filterData.map((field) => {
      const criteria = {
        fieldId: field.fieldId,
      };
      if (field.searchValue) {
        criteria.searchValue = field.searchValue;
      } else if (field.searchFrom) {
        criteria.searchFrom = moment(field.searchFrom * 1000).format(
          'YYYYMMDD'
        );
        criteria.searchTo = moment(field.searchTo * 1000).format('YYYYMMDD');
      }
      return criteria;
    });
    const data = {
      filterName,
      filterCriteria,
    };
    const init = {
      ...fetchOptions,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    return fetch(`${basePath}searching/filters`, init)
      .then((response) => {
        if (response.status === 401) {
          window.location.reload(true);
        }
        if (response.ok) {
          dispatch(searchIsNotNew());
          dispatch(filtersFetch());
          return dispatch(dataTableUpdateTitle(filterName));
        }
        return dispatch(
          showFilterAlert({
            msg: 'There was an error creating a filter.',
            type: 'error',
          })
        );
      })
      .catch(() => {
        return dispatch(
          showFilterAlert({
            msg: 'There was an error creating a filter.',
            type: 'error',
          })
        );
      });
  };
};

export const filterDataFetch = (filterId, func) => {
  return async (dispatch, getState) => {
    let { pagination } = getState().search.dataTable;
    // for performance reasons, pageNumber can never be 0
    if (pagination.pageNumber === 0) {
      pagination = {
        pageNumber: 1,
        pageSize: pagination.pageSize,
      };
    }
    const data = {
      filterId,
      pagination,
      sortAndFilter: getState().search?.dataTable.sortAndFilter,
    };

    dispatch(hideQueueDataAlert());
    dispatch(searchIsNotNew());
    dispatch(queueDataRequest('filter'));
    dispatch(searchModalSet(false));
    if(func){
      func("DATA")
    }
    const response = await api.put("searching/filters", translateQueueDataFetchToBackend(data))
    if (response.status === 401) {
      window.location.reload(true);
    }
    try {
      const fields = response.data.filterCriteria;
      const searchData = [];
      fields.forEach((field) => {
        if (field.searchValue) {
          searchData.push(field);
        }
      });

      dispatch(
        queueDataReceive(translateFilterDataFetch(response.data, filterId))
      );
      dispatch(updateDataTableSet(true));
    } catch (e) {
      return dispatch(
        showQueueDataAlert(
          'There was an error receiving the filter data.',
          'danger',
          'Error!'
        )
      );
    }
  };
};

export const openFilterDeleteConfirmModal = (filter) => {
  return (dispatch) => {
    dispatch(filterDeleteConfirmModalOpen(filter));
  };
};

export const closeFilterDeleteConfirmModal = () => {
  return (dispatch) => {
    dispatch(filterDeleteConfirmModalClose());
  };
};

export const clearSearch = () => {
  return (dispatch) => {
    dispatch(searchDataStore([]));
  };
};


export const filterDelete = (filterId) => {
  return (dispatch, getState) => {
    const state = getState().search?.dataTable.resultsId;
    const init = {
      ...fetchOptions,
      method: 'DELETE',
    };
    dispatch(hideQueueDataAlert());
    dispatch(searchDataStore([]));
    dispatch(searchIsNotNew());
    dispatch(searchModalSet(false));
    return fetch(`${basePath}searching/filters/${filterId}`, init)
      .then((response) => {
        if (response.status === 401) {
          window.location.reload(true);
        }
        if (response.ok) {
          return response.text();
        }
        dispatch(filterDeleteConfirmModalClose());
        return dispatch(
          showFilterAlert({
            msg: 'There was an error deleting the filter',
            type: 'error',
          })
        );
      })
      .then(() => {
        try {
          if (filterId === state) {
            dispatch(dataTableClear());
          }
          dispatch(filterDeleteConfirmModalClose());
          dispatch(filtersFetch());
          dispatch(
            showFilterAlert({
              msg: 'Filter has been successfully deleted.',
              type: 'success',
            })
          );
          window.scrollTo(0, 0);
          setTimeout(() => {
            return dispatch(hideFilterAlert());
          }, 5000);
        } catch (e) {
          dispatch(filterDeleteConfirmModalClose());
          return dispatch(
            showFilterAlert({
              msg: 'There was an error deleting the filter.',
              type: 'error',
            })
          );
        }
        return undefined;
      })
      .catch(() => {
        dispatch(filterDeleteConfirmModalClose());
        return dispatch(
          showFilterAlert({
            msg: 'There was an error deleting the filter.',
            type: 'error',
          })
        );
      });
  };
};

export const searchSubmit = (clearSF = false) => {
  return async (dispatch, getState) => {
    dispatch(queueDataRequest('search'));
    dispatch(searchIsNew());
    dispatch(searchModalSet(false));
    const state = getState().search;
    const data = state.searchData;
    const searchData = data.map((field) => {
      const criteria = {
        fieldId: field.fieldId,
      };
      if (field.searchValue) {
        criteria.searchValue = field.searchValue;
      } else if (field.searchFrom) {
        criteria.searchFrom = moment(field.searchFrom * 1000).format(
          'YYYYMMDD'
        );
        criteria.searchTo = moment(field.searchTo * 1000).format('YYYYMMDD');
      }
      return criteria;
    });
    const info = {
      searchCriteria: searchData,
      pagination: state?.dataTable.pagination,
    };
    if (clearSF) {
      batch(() => {
        dispatch(columnFilterSortChange([]));
        dispatch(changeSortPriority(1));
      });
    } else if (state?.dataTable.sortAndFilter) {
      info.sortAndFilter = state?.dataTable.sortAndFilter;
    }

    try {
      const response = await api.post("searching/search", info)
      if (response.status === 401) {
        window.location.reload(true);
      }
      if (response.status == 200) {
        dispatch(
          queueDataReceive(
            translateQueueDataFetch(
              response.data,
              null,
              null,
              'search',
              data
            )
          )
        );
        dispatch(updateDataTableSet(true));
      }
    } catch (err) {
      dispatch(
        showQueueDataAlert(
          'There was an error receiving the search data.',
          'danger',
          'Error!'
        )
      );
    }

  }
};

// Paging
export const paginationChange = (pageNumber = 1, pageSize = 0) => {
  return (dispatch, getState) => {
    // for performance reasons, pageNumber can never be 0
    dispatch(paginationSet(pageNumber > 1 ? pageNumber : 1, pageSize));
    const state = getState().search;
    if (state?.dataTable.filterId) {
      return dispatch(filterDataFetch(state?.dataTable.filterId));
    }
    if (state?.dataTable.queueGroupId && state?.dataTable.queueId) {
      dispatch(
        queueDataFetch(state?.dataTable.queueGroupId, state?.dataTable.queueId)
      );
    } else if (state.searchData) {
      dispatch(searchSubmit());
    }
    return undefined;
  };
};

export const columnFilterAndSort = (data) => {
  let newData = data;
  return async (dispatch, getState) => {
    // Format Data for filtering
    if (newData.length) {
      newData.forEach((item) => {
        if (!item.column) {
          const stateColumns = getState().search?.dataTable?.columns;
          let dataPropertyName;
          stateColumns.forEach((col) => {
            const colInfo = newData[col.columnName];
            if (colInfo) {
              dataPropertyName = col.columnName;
            }
          });
          if (dataPropertyName) {
            newData = data[dataPropertyName].value;
          }
        }
      });
    }
    let columns = [];
    let colIndex = -1;
    // Get the current sort priority counter level
    let sortPriority = getState().search?.dataTable.sortPriority;

    if (newData.length) {
      // Data was passed, get the current sort and filter settings
      columns = Immutable.asMutable(
        getState().search?.dataTable.sortAndFilter,
        { deep: true }
      );
      newData.forEach((item) => {
        if (item.clearSort && item.clearFilter) {
          columns = [];
          sortPriority = 1;
        } else if (item.clearSort) {
          // If data intent is to clear sort, loop through columns
          columns = columns.map((column) => {
            if (!column?.filterTerm && !column?.dateFilterRange) {
              // If filters are null, return the entire object as null
              return null;
            }
            return column;
          });
          // Set the sort priority back to 1
        } else if (item.clearFilter) {
          // If data intent is to clear sort, loop through columns
          columns = columns.map((column) => {
            if (!column.sortDirection) {
              // if not sort detected, then return null for the whole column
              return null;
            }
            item.filterTerm = null;
            item.dateFilterRange = null;
            return column;
          });
        } else {
          columns = columns.map((column) => {
            if (!column?.sortDirection) {
              return null;
            }
            return column;
          });
        }
      });

      // Look up the column in the existing settings
      let col = columns?.find((obj) => {
        return obj?.columnName === data?.column?.info?.columnName;
      });
      // Set the col object and it's index number
      if (col) {
        // Update the current column object
        colIndex = columns.findIndex((e) => {
          return e.columnName === col.columnName;
        });
      }
      if (!col && data.length) {
        // If col didn't already exist, add it and set sort priority
        // If no column currently exists...
        data.forEach((column) => {
          col = column?.column?.info;
          if (col?.sortDirection) {
            col.sortPriority = sortPriority;
            // increase the global sort priority level
            sortPriority += 1;
          }
          // add the new column to the sortAndFilter object
          columns.push(col);
        });
      } else if (col && data.column.clearFilter) {
        // if there is a column, and we need to clear the filter
        if (!col.sortDirection) {
          // If no existing sort set, return the object as null
          columns.splice(colIndex);
        } else {
          // else only clear the filters
          col.filterTerm = null;
          col.dateFilterRange = null;
          columns[colIndex] = col;
        }
      } else if (col && newData.column.clearSort) {
        // if there is a column and we need to clear the sort...
        if (col.sortPriority) {
          // loop through all the columns and set a new priority
          columns = columns.map((c) => {
            // if the current sort priority is greater than the column sort priority to be removed...
            if (c.sortPriority > col.sortPriority) {
              return { ...c, sortPriority: c.sortPriority - 1 };
            }
            return c;
          });
          // remove a level from the original sort priority
          sortPriority -= 1;

          // unset the current columns sort priority
          delete col.sortPriority;
          delete col.sortDirection;
        }
        // Update the column object
        columns[colIndex] = col;
      } else {
        if (col && !col?.sortPriority) {
          col.sortPriority = sortPriority + 1;
        }
        // Find the column index
        const columnIndex = columns.findIndex((e) => {
          return e?.columnName === col?.columnName;
        });
        // Update the column object
        columns[columnIndex] = col;
      }
      dispatch(changeSortPriority(sortPriority));
      // Pull null values from columns array
      _pull(columns, null, undefined);
      const uniqueColumns = _uniqBy(columns.reverse(), (column) => {
        return column.columnName;
      }).reverse();
      dispatch(columnFilterSortChange(uniqueColumns));
      const state = getState().search;
      if (state?.dataTable.filterId) {
        return dispatch(filterDataFetch(state?.dataTable.filterId));
      }

      if (state?.dataTable.queueGroupId && state?.dataTable.queueId) {
        dispatch(
          queueDataFetch(
            state?.dataTable.queueGroupId,
            state?.dataTable.queueId
          )
        );
      } else {
        dispatch(searchSubmit());
      }
    }
    return undefined;
  };
};

// GET fni-lenderportal-search/searching/abilities/{refnum}
export const abilitiesApi = makeApi({
  baseURL: `fni-lenderportal-search/searching`,
  schema: 'abilitySchema',
});

export const api = makeApi({
  baseURL: `fni-lenderportal-search`,
  headers: {
    'Content-Type': 'application/json',
  },
  withRequestInterceptor: false,
  withResponseInterceptor: false,
});
export const abilitiesFetch = (refnum) => async (dispatch, getState) => {
  const fetching = getState?.search?.fetching?.abilities;

  if (fetching !== refnum)
    try {
      dispatch(abilitiesFetchingSet(refnum));
      const response = await abilitiesApi.get(`abilities/${refnum}`);
      dispatch(abilitiesSet(response));
    } catch (error) {
      dispatch(
        informationSet({
          type: 'error',
          title: 'Error! ',
          message: error.message
            ? error.message
            : 'Failed to fetch abilities data',
        })
      );
    } finally {
      dispatch(abilitiesFetchingSet(false));
    }
};

export const abilityFetch = (type, abilityId, refnum) => async (dispatch) => {
  dispatch(actionFetchingSet(true));
  return fetch(`${basePath}searching/abilities/${refnum}/${abilityId}`)
    .then((response) => response.text())
    .then((response) => {
      let data = JSON.parse(response);
      data.schema = data.abilitySchema;
      delete data.abilitySchema;
      data = SchemaConverter.translateFromBackend(data);
      switch (type) {
        case 'ACTION':
          dispatch(abilityActionResponseSet(data));
          break;
        case 'CUSTOM': {
          dispatch(urlSet(data.formData.CUSTOM_PORTAL_URL));
          return data.formData.CUSTOM_PORTAL_URL;
        }
        case 'PRESENCE':
          dispatch(urlSet(data.formData.PRESENCE_URL));
          break;
        default:
          throw new Error(`Unhandled ability type: ${type}`);
      }
      return undefined;
    })
    .finally(() => {
      dispatch(actionFetchingSet(false));
    });
};

export const dataCollectionFetch = (refnum, stipId) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      apiFetch(`searching/stipData/${refnum}/${stipId}`)
        .then((response) => {
          if (!response.ok) {
            throw response;
          } else {
            return response.text();
          }
        })
        .then((text) => {
          let stipDesc = '';
          const formData = _get(
            getState(),
            ['search', 'actionModal', 'formData'],
            null
          );

          if (formData !== null) {
            const stipTypes = Object.keys(formData);
            stipTypes.forEach((stipType) => {
              formData[stipType].forEach((stip) => {
                if (stip.STIP_ID === stipId) {
                  stipDesc = stip.STIP_DESC;
                }
              });
            });
          }

          const parsedText = JSON.parse(text);
          const translatedText =
            SchemaConverter.translateFromBackend(parsedText);
          const newText = {
            ...translatedText,
            refnum,
            stipId,
            origFormData: translatedText.formData,
            stipDesc,
          };
          dispatch(dataCollectSet(newText));
          resolve(newText);
          return newText;
        })
        .catch(() => {
          dispatch(
            informationSet({
              type: 'error',
              title: 'Error! ',
              message:
                'There were was an error retrieving your task information.',
              id: stipId,
            })
          );
          reject();
        });
    });
  };
};

// Actions
export const actionFetch = (actionId, refnum) => {
  return (dispatch) => {
    let data = {
      actionId,
      refnum,
    };
    const init = {
      ...fetchOptions,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(translateQueueDataFetchToBackend(data)),
    };
    dispatch(actionRequest());
    return fetch(`${basePath}searching/actions`, init)
      .then((response) => response.text())
      .then((response) => {
        if (response.status === 401) {
          window.location.reload(true);
        }
        data = JSON.parse(response);
        if (actionId === 'ASSIGN') {
          dispatch(actionReceive(translateActionFetch(data), actionId, refnum));
          dispatch(actionModalSet(true));
          return undefined;
        }
        data.schema = data.actionSchema;
        delete data.actionSchema;
        data = SchemaConverter.translateFromBackend(data);
        if (actionId === 'STIPS') {
          const stipTypes = Object.keys(data.formData);
          stipTypes.forEach((stipType) => {
            data.formData[stipType].forEach((stip) => {
              if (stip.STIP_PURPOSE === 'DATA_COLLECT') {
                dispatch(dataCollectionFetch(refnum, stip.STIP_ID));
              }
            });
          });
        }

        dispatch(actionReceive(data, actionId, refnum));
        return actionId;
      });
  };
};

export const actionSubmit = (data, url) => {
  return (dispatch, getState) => {
    dispatch(actionSubmitRequest());
    const newUrl = Object.entries(data).reduce((acc, [key, value]) => {
      const path = basePath + acc.split(basePath)[1];
      return `${path}&${key}=${value}`;
    }, url);
    const init = {
      ...fetchOptions,
      method: 'GET',
    };
    const actionName =
      getState().search?.actionModal[
        getState().search?.actionModal.actionType
      ][0]?.actionLabel;
    return fetch(newUrl, init)
      .then((response) => {
        if (response.status === 401) {
          window.location.reload(true);
        }
        if (response.ok) {
          return response.text();
        }
        return dispatch(
          showActionSubmitAlert(
            'danger',
            `There was an error submitting ${actionName}.`,
            'Error!'
          )
        );
      })
      .then((response) => {
        const responseObj = JSON.parse(response);
        if (responseObj.status === 'SUCCESS') {
          dispatch(
            showActionSubmitAlert(
              'success',
              `The action ${actionName} has been submitted successfully.`,
              'Success!'
            )
          );
          setTimeout(() => {
            dispatch(actionSubmitReceive());
            return dispatch(hideActionSubmitAlert());
          }, 5000);
        } else {
          return dispatch(
            showActionSubmitAlert(
              'danger',
              `There was an error submitting ${actionName}.`,
              'Error!'
            )
          );
        }
        return undefined;
      })
      .catch(() => {
        return dispatch(
          showActionSubmitAlert(
            'danger',
            `There was an error submitting ${actionName}.`,
            'Error!'
          )
        );
      });
  };
};

export const actionAlertHide = () => {
  return (dispatch) => {
    dispatch(hideActionSubmitAlert());
  };
};

// Search
export const searchFetch = () => {
  return async (dispatch) => {
    dispatch(searchRequest());
    try {
      const respone = await api.get("searching/search")

      if (respone.status == 200) {

        dispatch(searchReceive(respone.data))
      }
    } catch (err) {

      dispatch(
        showFilterAlert({
          msg: 'There was an error receiving search data.',
          type: 'error',
        })
      );

    }
  };
}


export const setStipOpen = (stipId, stipStatus) => {
  return (dispatch) => {
    dispatch(setStip({ stipId, stipStatus }));
  };
};

export const unsetStipOpen = () => {
  return (dispatch) => {
    dispatch(unsetStip());
  };
};

export const uploadDoc = (formData, type, refnum) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(actionRequest());
      const options = {
        method: 'POST',
        body: formData,
        headers: {},
      };
      apiFetch('searching/uploadDoc', options)
        .then((response) => {
          if (!response.ok) {
            throw response;
          } else {
            return response.text();
          }
        })
        .then(() => {
          dispatch(actionFetch('STIPS', refnum));
          dispatch(
            showActionSubmitAlert(
              'success',
              'Your document has uploaded successfully.'
              // 'Success!'
            )
          );
          setTimeout(() => {
            dispatch(
              actionReceive(getState().search.actionModal.STIPS, type, refnum)
            );
            return dispatch(hideActionSubmitAlert());
          }, 5000);
          resolve();
          return undefined;
        })
        .catch(() => {
          dispatch(actionReceive(getState().search.actionModal, type, refnum));
          dispatch(
            showActionSubmitAlert(
              'danger',
              'There was an error uploading your document.'
              // 'Error!'
            )
          );
          reject();
        });
    });
  };
};



export const searchCancel = () => {
  return (dispatch) => {
    dispatch(searchModalSet(false));
  };
};

export const submitDataCollection = (formData, stipId, refnum) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchingSet(true));
      const data = {
        stipId,
        refnum,
        formData: SchemaConverter.translateToBackend(formData),
      };
      const options = {
        method: 'PUT',
        body: JSON.stringify(data),
      };
      apiFetch('searching/stipData', options)
        .then((response) => {
          if (!response.ok) {
            throw response;
          } else {
            return response.text();
          }
        })
        .then(() => {
          dispatch(actionFetch('STIPS', refnum));
          dispatch(
            showActionSubmitAlert(
              'success',
              'Your data was submitted successfully.',
              'Success!'
            )
          );

          setTimeout(() => {
            return dispatch(hideActionSubmitAlert());
          }, 5000);
          dispatch(fetchingSet(false));
          resolve();
          return undefined;
        })
        .catch(() => {
          dispatch(
            showActionSubmitAlert(
              'danger',
              'There was an error submitting your data.',
              'Error!'
            )
          );
          dispatch(fetchingSet(false));
          reject();
        });
    });
  };
};

export const submitDataCollectionSet = (stipIds, refnum) => {
  return (dispatch, getState) => {
    const processArray = (arr, fn) => {
      return arr.reduce(
        // eslint-disable-next-line
        (p, v) => p.then((a) => fn(v).then((r) => a.concat([r]))),
        Promise.resolve([])
      );
    };
    const saveStip = (stipId) => {
      const state = getState();
      const index = state.search.dataCollect
        .map((e) => {
          return e.stipId;
        })
        .indexOf(stipId);
      const { formData } = state?.search?.dataCollect?.[index];
      return dispatch(submitDataCollection(formData, stipId, refnum));
    };
    return processArray(stipIds, saveStip);
  };
};


export const searchDataUpdate = (data) => {
  return async (dispatch, getState) => {

    dispatch(searchDataStore(data));
  };
};

export const refreshResultList = () => {
  return (dispatch, getState) => {
    const state = getState().search;
    const dataTable = state?.dataTable;
    const type = state.dataSetType;
    switch (type) {
      case 'filter':
        return dispatch(filterDataFetch(dataTable.filterId));
      case 'queue':
        dispatch(queueDataFetch(dataTable.queueGroupId, dataTable.queueId));
        break;
      case 'search':
        dispatch(searchSubmit());
        break;
      default:
        return undefined;
    }
    return undefined;
  };
};

// ------------------------------------
// Reducer
// ------------------------------------

const SEARCH_ACTION_HANDLERS = {
  [QUEUES_REQUEST]: (state) => {
    return Immutable({ ...state, queues: { ...state.queues, fetching: true } });
  },
  [QUEUES_RECEIVE]: (state, action) => {
    return Immutable({
      ...state,
      queues: { queueList: action.payload, fetching: false },
    });
  },
  [QUEUE_DATA_REQUEST]: (state, action) => {
    return Immutable({
      ...state,
      dataSetType: action.payload.type,
      dataTable: { ...state?.dataTable, fetching: true },
    });
  },
  [QUEUE_DATA_RECEIVE]: (state, action) => {
    return Immutable({
      ...state,
      dataTable: { ...state?.dataTable, ...action.payload, fetching: false },
    });
  },
  [DATA_TABLE_UPDATE_TITLE]: (state, action) => {
    return Immutable({
      ...state,
      dataTable: { ...state?.dataTable, resultsTitle: action.payload.title },
    });
  },
  [FILTERS_REQUEST]: (state) => {
    return Immutable({
      ...state,
      filters: { ...state.filters, fetching: true },
    });
  },
  [FILTERS_RECEIVE]: (state, action) => {
    return Immutable({
      ...state,
      filters: { filterList: action.payload, fetching: false },
    });
  },
  [FILTER_CREATE_RECEIVE]: (state) => {
    return Immutable({
      ...state,
      filters: { ...state.filterss, fetching: true },
    });
  },
  [SEARCH_DATA_STORE]: (state, action) => {
    return Immutable({ ...state, searchData: action.payload });
  },
  [FILTER_DELETE_CONFIRM_MODAL_OPEN]: (state, action) => {
    return Immutable({
      ...state,
      filterDeleteConfirmModal: {
        ...state.filterDeleteConfirmModal,
        modalOpen: true,
        filterId: action.payload.filterId,
        filterName: action.payload.filterName,
      },
    });
  },
  [FILTER_DELETE_CONFIRM_MODAL_CLOSE]: (state) => {
    return Immutable({
      ...state,
      filterDeleteConfirmModal: {
        ...state.filterDeleteConfirmModal,
        modalOpen: false,
        filterId: '',
        filterName: '',
      },
    });
  },
  [ACTION_REQUEST]: (state) => {
    return Immutable({
      ...state,
      actionModal: { ...state.actionModal, fetching: true },
    });
  },
  [ACTION_FETCHING_SET]: (state, action) => {
    return Immutable({
      ...state,
      actionModal: { ...state.actionModal, fetching: action.payload },
    });
  },
  [ACTION_SUBMIT_REQUEST]: (state) => {
    return Immutable({
      ...state,
      actionModal: {
        ...state.actionModal,
        actionSubmit: { fetching: true },
      },
    });
  },
  [ACTION_SUBMIT_RECEIVE]: (state) => {
    return Immutable({
      ...state,
      actionModal: {
        ...state.actionModal,
        modalOpen: false,
        actionSubmit: { fetching: false },
      },
    });
  },
  [SHOW_ACTION_SUBMIT_ALERT]: (state, action) => {
    return Immutable({
      ...state,
      actionModal: {
        ...state.actionModal,
        fetching: false,
        actionSubmit: { fetching: false },
        alert: {
          showAlert: true,
          type: action.payload.type,
          msg: action.payload.msg,
          title: action.payload.title,
        },
      },
    });
  },
  [HIDE_ACTION_SUBMIT_ALERT]: (state) => {
    return Immutable({
      ...state,
      actionModal: {
        ...state.actionModal,
        alert: {
          showAlert: false,
          type: '',
          msg: '',
          title: '',
        },
      },
    });
  },
  [ACTION_RECEIVE]: (state, action) => {
    const { actionType, data, refnum } = action.payload;
    return Immutable({
      ...state,
      actionModal: {
        ...state.actionModal,
        [actionType]: { ...data },
        actionType,
        fetching: false,
        refnum,
      },
    });
  },
  [ACTION_MODAL_SET]: (state, action) => {
    const data = action.payload
      ? { ...state.actionModal }
      : {
        fetching: false,
        modalOpen: false,
        actionSubmit: {
          fetching: false,
        },
        alert: {
          type: '',
          title: '',
          msg: '',
          showAlert: false,
        },
      };
    return Immutable({
      ...state,
      actionModal: { ...data, modalOpen: action.payload },
    });
  },
  [PAGINATION_SET]: (state, action) => {
    return Immutable({
      ...state,
      dataTable: {
        ...state?.dataTable,
        pagination: {
          pageNumber: action.payload.pageNumber,
          pageSize: action.payload.pageSize,
        },
      },
    });
  },
  [SEARCH_REQUEST]: (state) => {
    return Immutable({
      ...state,
      searchModal: { ...state.searchModal, fetching: true },
    });
  },
  [SEARCH_RECEIVE]: (state, action) => {
    return Immutable({
      ...state,
      searchModal: {
        ...state.searchModal,
        fields: action.payload,
        fetching: false,
      },
    });
  },
  [SEARCH_MODAL_SET]: (state, action) => {
    return Immutable({
      ...state,
      searchModal: { ...state.searchModal, modalOpen: action.payload },
    });
  },
  [SHOW_QUEUE_ALERT]: (state, action) => {
    return Immutable({
      ...state,
      queues: { ...state.queues, fetching: false },
      alerts: {
        ...state.alerts,
        queues: {
          ...state.alerts.queues,
          msg: action.payload.msg,
          type: action.payload.type,
          title: action.payload.title,
          showError: true,
        },
      },
    });
  },
  [HIDE_QUEUE_ALERT]: (state) => {
    return Immutable({
      ...state,
      queues: { ...state.queues, fetching: false },
      alerts: {
        ...state.alerts,
        queues: {
          ...state.alerts.queues,
          msg: '',
          type: '',
          title: '',
          showError: false,
        },
      },
    });
  },
  [SHOW_QUEUE_DATA_ALERT]: (state, action) => {
    return Immutable({
      ...state,
      dataTable: { ...state?.dataTable, ...action.payload, fetching: false },
      alerts: {
        ...state.alerts,
        dataTable: {
          ...state.alerts?.dataTable,
          msg: action.payload.msg,
          type: action.payload.type,
          title: action.payload.title,
          showError: true,
        },
      },
    });
  },
  [HIDE_QUEUE_DATA_ALERT]: (state, action) => {
    return Immutable({
      ...state,
      dataTable: { ...state?.dataTable, ...action.payload, fetching: false },
      alerts: {
        ...state.alerts,
        dataTable: {
          ...state.alerts?.dataTable,
          msg: '',
          type: '',
          title: '',
          showError: false,
        },
      },
    });
  },
  [SHOW_FILTER_ALERT]: (state, action) => {
    return Immutable({
      ...state,
      filters: { ...state.filters, fetching: false },
      alerts: {
        ...state.alerts,
        filters: {
          ...state.alerts.filters,
          msg: action.payload.msg,
          type: action.payload.type,
          delay: action.payload.delay,
          showError: true,
        },
      },
    });
  },
  [HIDE_FILTER_ALERT]: (state) => {
    return Immutable({
      ...state,
      filters: { ...state.filters, fetching: false },
      alerts: {
        ...state.alerts,
        filters: {
          ...state.alerts.filters,
          msg: '',
          type: '',
          title: '',
          showError: false,
        },
      },
    });
  },
  [SEARCH_IS_NEW]: (state) => {
    return Immutable({ ...state, newSearch: true });
  },
  [SEARCH_IS_NOT_NEW]: (state) => {
    return Immutable({ ...state, newSearch: false });
  },
  [DATA_TABLE_CLEAR]: (state) => {
    return Immutable({
      ...state,
      dataTable: {
        sortAndFilter: [],
        queueGroupId: 0,
        queueId: 0,
        pagination: {
          pageNumber: 1,
          pageSize: 100,
        },
      },
    });
  },
  [COLUMN_FILTER_SORT_CHANGE]: (state, action) => {
    return Immutable({
      ...state,
      dataTable: { ...state?.dataTable, sortAndFilter: action.payload },
    });
  },
  [CHANGE_SORT_PRIORITY]: (state, action) => {
    return Immutable({
      ...state,
      dataTable: { ...state?.dataTable, sortPriority: action.payload },
    });
  },
  [DATA_COLLECT_SET]: (state, action) => {
    let index = state.dataCollect.findIndex((s) => {
      return s.stipId === action.payload.stipId;
    });
    if (index === -1) {
      index =
        state.dataCollect.length === 0
          ? state.dataCollect.length
          : state.dataCollect.length;
    }
    return Immutable.setIn(state, ['dataCollect', index], action.payload);
  },
  [FORM_DATA_SET]: (state, action) => {
    let index = state.dataCollect.findIndex((s) => {
      return s.stipId === action.payload.stipId;
    });
    if (index === -1) {
      index =
        state.dataCollect.length === 0
          ? state.dataCollect.length
          : state.dataCollect.length;
    }
    return Immutable.setIn(
      state,
      ['dataCollect', index, 'formData'],
      action.payload.data
    );
  },
  [UPDATE_FORM_SET]: (state, action) => {
    return Immutable.setIn(state, ['updateForm'], action.payload);
  },
  [SEARCH_FETCHING_SET]: (state, action) => {
    return Immutable.setIn(state, ['actionModal', 'fetching'], action.payload);
  },
  [ABILITIES_SET]: (state, action) => {
    return Immutable.setIn(
      state,
      ['abilities', 'abilitiesList'],
      action.payload
    );
  },
  [ABILITIES_FETCHING_SET]: (state, action) => {
    return Immutable.setIn(state, ['fetching', 'abilities'], action.payload);
  },
  [ABILITIES_CLEAR]: (state) => {
    return Immutable.setIn(state, ['abilities'], {});
  },
  [ABILITY_ACTION_RESPONSE_SET]: (state, action) => {
    let data = Immutable.setIn(
      state,
      ['actionModal', 'ABILITY', 'aActionResponse'],
      { ...action.payload }
    );
    data = Immutable.setIn(data, ['actionModal', 'ABILITY', 'response'], true);
    return data;
  },
  [HAS_CALC_SET]: (state, { payload }) => {
    return Immutable.setIn(state, ['hasCalc'], payload);
  },
  [IS_DEALER_SET]: (state, { payload }) => {
    return Immutable.setIn(state, ['isDealer'], payload);
  },
  [UPDATE_DATA_TABLE_SET]: (state, action) => {
    return Immutable.setIn(state, ['updateDataTable'], action.payload);
  },
  [SET_STIP]: (state, action) => {
    return Immutable.setIn(state, ['stipOpen'], action.payload);
  },
  [UNSET_STIP]: (state) => {
    return Immutable.setIn(state, ['stipOpen'], {
      stipStatus: '',
      stipId: '',
    });
  },
};

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = Immutable({
  dataCollect: [],
  newSearch: false,
  queues: {
    queueList: [],
    fetching: false,
  },
  filters: {
    filterList: [],
    fetching: false,
  },
  dataTable: {
    queueGroupId: 0,
    queueId: 0,
    pagination: {
      pageNumber: 1,
      pageSize: 100,
    },
    sortAndFilter: [],
    sortPriority: 1,
    fetching: true
  },
  abilities: {},
  actionModal: {
    fetching: false,
    modalOpen: false,
    actionSubmit: {
      fetching: false,
    },
    alert: {
      type: '',
      title: '',
      msg: '',
      showAlert: false,
    },
  },
  searchModal: {
    fields: [],
    fetching: false,
    modalOpen: false,
  },
  filterDeleteConfirmModal: {
    filterId: '',
    modalOpen: false,
  },
  alerts: {
    queues: {
      msg: '',
      type: '',
      title: '',
      showError: false,
    },
    filters: {
      msg: '',
      type: '',
      title: '',
      showError: false,
    },
    dataTable: {
      msg: '',
      type: '',
      title: '',
      showError: false,
    },
  },
  searchData: [],
  stipOpen: {
    stipStatus: '',
    stipId: '',
  },
});

export default function searchReducer(state = initialState, action) {
  const handler = SEARCH_ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

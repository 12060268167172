import { useState } from 'react';
import { useNavigate } from 'react-router';
import {
    Button,
    TextField,
    Grid,
    InputAdornment,
    IconButton,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useFormik } from 'formik';
import * as yup from 'yup';


export default function ChangePasswordForm({ onSubmit, forceChange }) {
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const { 
        handleSubmit, 
        handleChange, 
        handleBlur, 
        values, 
        errors, 
        touched,
    } = useFormik({
        initialValues: { password: '', passwordConfirmation: '' },
        validationSchema: yup.object({
            password: yup.string()
                .required('Required')
                .min(6, 'Must be at least 6 characters'),
            passwordConfirmation: yup.string()
                .oneOf([yup.ref('password'), null], 'Passwords must match'),
        }),
        onSubmit: (values) => {
            onSubmit(values);
        },
    });

    return (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Grid container spacing={4}>                        
                <Grid item xs={12}>
                    <TextField
                        sx={{ width: '100%' }}
                        name='password'
                        type={showPassword ? 'text' : 'password'}
                        label='New password'
                        value={values.password || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={ Boolean(errors.password) && touched.password }
                        helperText={ touched.password ? errors.password : ''}
                        InputProps={{
                            endAdornment:(
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            )
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        sx={{ width: '100%' }}
                        name='passwordConfirmation'
                        type={showConfirmPassword ? 'text' : 'password'}
                        label='Confirm password'
                        value={values.passwordConfirmation || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={ Boolean(errors.passwordConfirmation) && touched.passwordConfirmation }
                        helperText={ touched.passwordConfirmation ? errors.passwordConfirmation : ''}
                        InputProps={{
                            endAdornment:(
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            )
                        }}
                    />
                </Grid>

                <Grid item
                    xs={12}
                    display='flex'
                    flexDirection={!forceChange? { xs: 'column', sm: 'row' } : { xs: 'row' }}
                    justifyContent={!forceChange? { xs: 'center', sm: 'space-between' } : { xs: 'center', sm: 'flex-end'}}
                >
                    {!forceChange &&
                        <Button onClick={() => navigate(-1)}>Cancel</Button>
                    }
                    <Button type='submit'>Change</Button>
                </Grid>
            </Grid>
        </form>
    );
}

import { createTheme, adaptV4Theme } from "@mui/material/styles"
import { workbenchTheme as theme } from './utils/themeValues';

const getCardHeaderPadding = (cardType) => {
  if (cardType === 'solid') return '4px 16px';
  if (cardType === 'border') return '0px 16px';
  return '8px 64px 8px 16px';
};

const getCardTitleColor = (cardType) => {
  if (cardType === 'solid') return '#ffffff';
  if (cardType === 'border') return 'rgba(0,0,0,0.87)';
  return theme.palette.primary.main;
};

const WorkbenchTheme = createTheme(
  adaptV4Theme({
    palette: theme.palette,
    typography: {
      fontFamily: [
        'Roboto',
        'Helvetica Neue',
        'Arial',
        'Noto Sans',
        'sans-serif',
      ].join(','),
      body1: {
        fontSize: '0.875rem',
      },
      h3: {
        fontSize: '1.75rem',
      },
      h4: {
        fontSize: '1.625rem',
      },
    },
    shape: {
      borderRadius: theme.borderRadius,
    },
    overrides: {
      MuiFormControl: {
        root: {
          marginTop: '5px',
          minWidth: '60px',
          width: '100%',
          marginRight: '8px',
        },
      },
      MuiRadio: {
        colorSecondary: {
          '&.Mui-checked': {
            color: theme.palette.secondary.light,
          },
          color: theme.palette.secondary.light,
        },
      },
      MuiPaper: {
        elevation0: { backgroundColor: 'rgba(255,255,255,0)' },
      },
      MuiInput: {
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.inputColor,
          },
          '&:after': {
            borderBottomColor: theme.palette.primary.main,
          },
          '&:hover:not(Mui-disabled):before': {
            borderBottomColor: theme.palette.primary.main,
          },
        },
      },
      MuiInputBase: {
        root: {},
      },
      MuiSelect: {
        select: {
          minWidth: '160px',
        },
      },
      MuiSvgIcon: {
        colorSecondary: {
          color: theme.palette.secondary.light,
        },
      },
      MuiTableCell: {
        head: {
          fontSize: '0.9rem',
          fontWeight: 600,
        },
      },
      MuiTableRow: {
        hover: {
          cursor: 'pointer',
          '&.Mui-hover:hover': {
            backgroundColor: theme.palette.primary.xxlight,
          },
        },
      },
      MuiTextField: {
        root: {
          marginTop: '5px',
          '& label': {
            color: theme.palette.inputColor,
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: theme.borderRadius,
          backgroundColor: '#ffffff',
        },
        notchedOutline: {
          borderColor: theme.body ? theme.palette.inputColor : 'rgba(0,0,0,0)',
        },
      },
      MuiInputLabel: {
        outlined: {
          backgroundColor: theme.body ? 'rgba(0,0,0,0)' : '#ffffff',
          padding: '4px',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        },
      },
      MuiCardHeader: {
        root: {
          backgroundColor:
            theme.cardType === 'solid' ? theme.palette.primary.main : null,
          padding: getCardHeaderPadding(theme.cardType),
          borderTop:
            theme.cardType === 'border'
              ? `8px solid ${theme.palette.primary.main}`
              : null,
        },
        title: {
          color: getCardTitleColor(theme.cardType),
          fontSize: theme.cardType === 'border' ? '1rem' : '1.25rem',
          fontWeight: theme.cardType !== 'solid' ? 'bold' : null,
          borderBottom:
            theme.cardType === 'underline'
              ? `3px solid ${theme.palette.primary.main}`
              : null,
          marginTop: theme.cardType === 'border' ? '4px' : null,
          width: theme.cardType === 'underline' ? '50%' : null,
        },
        action: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
      MuiTab: {
        root: {
          minWidth: 0,
          '@media (min-width: 0px)': {
            minWidth: 0,
          },
        },
        textColorPrimary: {
          color: theme.palette.text.disabled,
          '&.Mui-selected': {
            color: theme.palette.primary.dark,
          },
          indicatorColor: 'primary',
        },
        textColorSecondary: {
          color: theme.palette.text.white,
          '&.Mui-selected': {
            color: theme.palette.secondary.main,
          },
        },
      },
    },
    props: {
      MuiBadge: {
        color: 'error',
      },
      MuiButtonBase: {
        disableRipple: true,
      },
      MuiFormControl: {
        variant: theme.inputType,
      },
      MuiLink: {
        underline: 'hover',
      },
      MuiSelect: {
        variant: theme.inputType,
      },
      MuiOutlinedInput: {
        color: 'primary',
      },
      MuiPaper: {
        elevation: !theme.body ? 0 : 1,
      },
      MuiSkeleton: {
        animation: 'wave',
      },
      MuiTextField: {
        margin: 'normal',
        variant: theme.inputType,
      },
      MuiTabs: {
        variant: 'scrollable',
        TabIndicatorProps: {
          style: { background: theme.palette.pageHeader.contrastText },
        },
        textColor: 'primary',
      },
    },
  })
);

export default WorkbenchTheme;

import Immutable from 'seamless-immutable';
import { makeApi } from 'fni-schema'

export const HEADER_NAV_SET = 'HEADER_NAV_SET';
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const READ_ONLY = "READ_ONLY"

export const api = makeApi({
  baseURL: `fni-lenderportal-navigation/navigation`,
  headers: {
    'Content-Type': 'application/json',
  },
  withRequestInterceptor: false,
  withResponseInterceptor: false,
});
/** ACTIONS **/

// Header
export const headerNavSet = (links) => {
  return {
    type: HEADER_NAV_SET,
    payload: {
      links: links
    }
  };
};


export const linksFetch = () => async (dispatch, getState) => {
  const links = await api.get("links")
  if (links) {
    dispatch(headerNavSet(links.data));
  } else {
  }
}

export const toggleDrawer = () => async (dispatch, getState) => {
  dispatch({ type: TOGGLE_DRAWER, value: !getState()?.header?.drawerToggled });
}


const readonlyApi = makeApi({
  baseURL: 'fni-lenderportal-workbench/workbench',
  withResponseInterceptor: false,
});


export const internalGetReadonly = (tabRoute) => async (dispatch, getState) => {
  try {
    const response = await readonlyApi.get(`tab/read-only/${tabRoute}`);
    if (response.status == 200) {
      dispatch({ type: READ_ONLY, tabValue: response.data, tabRoute: tabRoute })
    }
    return response.data;
  } catch (e) {
    return false;
  }
};

const ACTION_HANDLERS = {
  [HEADER_NAV_SET]: (state, action) => {
    return Immutable({ ...state, headerLinks: action.payload.links });
  },
  [READ_ONLY]: (state, action) => {
    return Immutable({ ...state, readOnly: { [action.tabRoute]: action.tabValue } });
  },
  [TOGGLE_DRAWER]: (state, action) => {
    return {
      ...state,
      drawerToggled: action.value
    }
  }
};

/** INITIAL STATE **/
const initialState = Immutable({
  headerLinks: [],
  drawerToggled: true
});

export default function searchReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

import { createTheme } from '@mui/material';
import { blue, indigo, green } from '@mui/material/colors';


const theme = createTheme({
    palette: {
        primary: {
            main: blue[700],
        },
        secondary: {
            main: green[500],
        },
    },
    typography: {
        h3: {
            fontSize: '2.25rem',
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    height: 62,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        background: indigo[50],
                    },
                    fontWeight: 600,
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    minHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 20,
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                    cursor: 'pointer',
                    fontSize: 14,
                    display: 'flex',
                    alignItems: 'center',
                },
            },
        },
    },
});

export default theme;

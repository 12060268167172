import Immutable from 'seamless-immutable';
import { makeApi } from 'fni-schema';


export const api = makeApi({
    baseURL: `fni-lenderportal-reporting/report`,
    headers: {
        'Content-Type': 'application/json',
    },
    withRequestInterceptor: false,
    withResponseInterceptor: false,
});

// ------------------------------------
// Constants
// ------------------------------------
export const SET_DESIGN = 'SET_DESIGN';
export const SET_DESIGN_GRID = 'SET_DESIGN_GRID';

const SET_INFO = 'SET_INFO';

export const getGrid = (obj) => async (dispatch) => {
    try {
        const response = await api.put('/generate/grid', obj);
        if (response?.status == 200) {
            dispatch(setDesignGrid(response.data));
        }
    } catch (e) {
    }
};

export const getDesign = (obj) => async (dispatch) => {
    try {
        const response = await api.put('/designs', obj);
        if (response?.status == 200) {
            dispatch(setDesign(response.data));
        }
    } catch (e) {
    }
};

export const setDesign = (data) => {
    return {
        type: SET_DESIGN,
        payload: data,
    };
};

export const setDesignGrid = (payload) => {
    return {
        type: SET_DESIGN_GRID,
        payload,
    };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [SET_DESIGN_GRID]: (state, action) => {
        return Immutable({
            ...state,
            columns: action?.payload?.columnDefs?.map((s,i) => {
                return {
                    "field": s.name?.replace(/\s/g, ''),
                    "headerName": s.displayName,
                    "sortable": true,
                    "filterable": true,
                    "id": i
                }
            }) || [],
            rows: action?.payload?.data.map(d => {
                Object.keys(d).forEach((key) => {
                    var replacedKey = key.replace(/\s/g, '');
                    if (key !== replacedKey) {
                       d[replacedKey] = d[key];
                       delete d[key];
                    }
                 });
                return d
            }) || []
        });
    },
    [SET_DESIGN]: (state, action) => {
        return Immutable({
            ...state,
            design: action.payload,
        });
    }
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    columns: [
        { "field": "TimeReceived", "displayName": "Time Received", "width": 200  },
        { "field": "Count", "displayName": "Count", "width": 200}
    ],
    rows: [
        { "id": 1, "Count": 2, "TimeReceived": "2022/03/30 16:54:59" },
        { "id": 2, "Count": 8, "TimeReceived": "2022/03/29 11:45:08" },
        { "id": 3, "Count": 2, "TimeReceived": "2022/03/28 06:48:33" },
        { "id": 4, "Count": 1, "TimeReceived": "2022/03/18 06:55:32" },
        { "id": 5, "Count": 8, "TimeReceived": "2022/03/17 10:18:41" },
        { "id": 6, "Count": 2, "TimeReceived": "2022/03/16 07:38:48" }],
    design: [{ "id": 515, "displayName": "Volume: Count of Application by Day" }]
};

export default function reportingReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}

import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Clear from 'fni-components/MaterialIcons/Clear';
import { useNavigate } from 'react-router-dom';

import styles from './FilterItem.module.scss';

const FilterItem = ({
  dataTableFilterId,
  filter,
  filterDataFetch,
  openFilterDeleteConfirmModal,
}) => {
  const history = useNavigate();
  const [deleteHover, setDeleteHover] = React.useState(false);

  const handleMouseOver = () => {
    setDeleteHover(true);
  };

  const handleMouseOut = () => {
    setDeleteHover(false);
  };

  return (
    <li
      className={classNames(styles.container, {
        [styles.deletableContainer]: deleteHover,
      })}
    >
      <div
        role="button"
        tabIndex={0}
        className={classNames(styles.item, styles.itemName, {
          [styles.selected]: dataTableFilterId === filter.filterId,
        })}
        onClick={async () => {
           filterDataFetch(filter.filterId, (view = "DATA")=> history(`/fni-lenderportal-search/${view}/SEARCH`));
          
        }}
      >
        {filter.filterName}
      </div>
      <div className={classNames(styles.item, styles.iconItem)}>
        {Boolean(filter.deleteFlag) && (
          // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
          <Clear
            className="text-danger"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={() => {
              openFilterDeleteConfirmModal(filter);
            }}
          />
        )}
      </div>
    </li>
  );
};

FilterItem.propTypes = {
  dataTableFilterId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  filterDataFetch: PropTypes.func.isRequired,
  openFilterDeleteConfirmModal: PropTypes.func.isRequired,
};

export default FilterItem;

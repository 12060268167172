/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { styled } from '@mui/styles';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, color }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette[color].light,
  },
}));

function getNumberFormat({ scale, format }) {
  if (scale) return true;
  if (format) {
    switch (format) {
      case 'phone':
      case 'currency':
      case 'percentage':
        return true;

      default:
        return false;
    }
  }

  return false;
}

function getNumberFormatProps({ format, scale }) {
  const defaultProps = {
    customInput: TextField,
    type: 'text',
    isNumericString: true,
  };

  switch (format) {
    case 'phone':
      return {
        ...defaultProps,
        type: 'tel',
        format: '(###) ###-####',
        mask: '_',
      };
    case 'currency':
      return {
        ...defaultProps,
        thousandSeparator: true,
        decimalScale: 2,
        InputProps: {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        },
      };
    case 'percentage':
      return {
        ...defaultProps,
        thousandSeparator: true,
        decimalScale: scale,
        InputProps: {
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        },
      };

    default:
      return { ...defaultProps, decimalScale: scale };
  }
}

function DefaultField({
  autoFocus,
  clearable,
  ComponentProps,
  disabled,
  ErrorControlProps,
  errors,
  format,
  IconProps,
  id,
  label,
  labelId,
  LabelProps,
  onBlur,
  onChange,
  onFocus,
  options,
  readOnly,
  required,
  scale,
  TooltipProps,
  type,
  value,
  warning,
  ...rest
}) {
  const filteredErrors = React.useMemo(
    () => errors?.filter(Boolean) ?? [],
    [errors]
  );
  const error = Boolean(filteredErrors?.length || warning);
  const select = options?.length && !disabled && !readOnly;
  const numberFormat = getNumberFormat({ scale, format });

  const selectDisplayVal = React.useMemo(() => {
    if (select || !options?.length) return value;
    return options?.find((o) => o.value === value)?.label;
  }, [options, select, value]);

  const selectEndAdornment = React.useMemo(() => {
    return clearable ? (
      <InputAdornment
        position="end"
        sx={{
          position: 'absolute',
          right: (theme) => theme.spacing(3),
        }}
      >
        <IconButton
          aria-label="clear dropdown"
          size="small"
          onClick={() => onChange('')}
        >
          <ClearIcon />
        </IconButton>
      </InputAdornment>
    ) : null;
  }, [clearable, onChange]);

  const helperText = React.useMemo(() => {
    if (filteredErrors?.length)
      return (
        <StyledTooltip
          id={`${id}_TOOLTIP`}
          data-testid="error-control-tooltip"
          title={filteredErrors?.[0]}
          placement="right"
          color="error"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...TooltipProps}
        >
          <ErrorIcon
            data-testid="error-icon"
            color="error"
            sx={{
              '& + svg': {
                marginLeft: (theme) => theme.spacing(0.5),
              },
              width: (theme) => theme.spacing(2),
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...IconProps}
          />
        </StyledTooltip>
      );
    if (warning)
      return (
        <StyledTooltip
          id={`${id}_WARNING_TOOLTIP`}
          data-testid="warning-control-tooltip"
          title={warning}
          placement="right"
          color="warning"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...TooltipProps}
        >
          <WarningIcon
            data-testid="warning-icon"
            color="warning"
            sx={{
              width: (theme) => theme.spacing(2),
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...IconProps}
          />
        </StyledTooltip>
      );
    return undefined;
  }, [IconProps, TooltipProps, filteredErrors, id, warning]);

  const defaultProps = {
    autoFocus,
    'data-testid': 'error-form-control',
    disabled,
    error,
    sx: {
      '& .MuiFormHelperText-root': error
        ? { marginTop: 0, marginLeft: 0 }
        : undefined,
    },
    helperText,
    id,
    label,
    onBlur,
    onFocus,
    required,
    type,
    multiline: format === 'multiline',
    value: selectDisplayVal || value,
    InputLabelProps: LabelProps,
    ...ErrorControlProps,
    ...rest,
  };

  if (select) {
    const SelectProps = {
      endAdornment: selectEndAdornment,
      labelId,
      readOnly,
      ...ComponentProps,
    };

    // clear value if it's not in the enum, ignores multiselects
    if (
      format !== 'waterfall' &&
      !ComponentProps?.multiple &&
      value &&
      // REA-6490 - allow abstract equality
      // eslint-disable-next-line eqeqeq
      !options.some(({ value: optionValue }) => optionValue == value)
    ) {
      onChange('');
      return <TextField {...defaultProps} />; // prevent select from rendering with invalid data
    }
    return (
      <TextField
        {...defaultProps}
        select
        onChange={onChange}
        SelectProps={SelectProps}
        sx={{
          ...defaultProps.sx,
          '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: (theme) =>
              clearable ? theme.spacing(7) : theme.spacing(3),
          },
        }}
      >
        {options?.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  }

  if (numberFormat) {
    const NumberFormatProps = getNumberFormatProps({ format, scale });
    return (
      <NumberFormat
        {...defaultProps}
        {...NumberFormatProps}
        onValueChange={(values) => onChange(values.value)}
      />
    );
  }

  return (
    <TextField
      {...defaultProps}
      InputProps={{ readOnly, ...ComponentProps }}
      onChange={onChange}
    />
  );
}

DefaultField.propTypes = {
  ComponentProps: PropTypes.objectOf(PropTypes.any),
  ErrorControlProps: PropTypes.objectOf(PropTypes.any),
  IconProps: PropTypes.objectOf(PropTypes.any),
  LabelProps: PropTypes.objectOf(PropTypes.any),
  TooltipProps: PropTypes.objectOf(PropTypes.any),
  autoFocus: PropTypes.bool,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  format: PropTypes.oneOf(['date', 'phone', 'currency', 'percentage']),
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  labelId: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  scale: PropTypes.number,
  type: PropTypes.oneOf(['number', 'text']),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]).isRequired,
  warning: PropTypes.string,
};

export default DefaultField;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';

import CustomField from './CustomField';
import DateField from './DateField';
import DefaultField from './DefaultField';
import DoubleEntry from './DoubleEntry';
import { validateField as internalValidateField } from '../../utils/validators/validators';
import YearsMonths from './YearsMonths';

function defineType(schemaType, options = {}) {
  if (options.precision) return 'number';

  switch (schemaType) {
    case 'string':
      return 'text';
    case 'integer':
    case 'number':
      return 'number';
    default:
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(`Invalid schema type: "${schemaType}".`);
      }
      return 'text';
  }
}

function Field({
  name,
  id: externalId,
  label: externalLabel,
  error: externalError,
  warning = '',
  readOnly: externalReadOnly,
  disabled: externalDisabled,
  autoFocus = false,
  required: externalRequired,
  clearable = false,
  format: externalFormat,
  options,
  component,
  schemaValues,
  fieldProps,
  ComponentProps,
  LabelProps,
  ErrorControlProps,
  onChange: externalOnChange,
  onBlur: externalOnBlur,
  onFocus: externalOnFocus,
  validateField: externalValidateField,
  value: externalValue, // specifically ignored
  ...rest
}) {

  const validateField = React.useMemo(() => {
    if (typeof externalValidateField === 'function')
      return externalValidateField(schemaValues);
    return internalValidateField(schemaValues);
  }, [externalValidateField, schemaValues]);

  const {
    input: {
      value,
      onBlur: internalOnBlur,
      onChange: internalOnChange,
      onFocus: internalOnFocus,
    },
    meta: { error, touched },
  } = useField(`"${name}"`, {
    validate: validateField,
    validateFields: [],
    // Override the default behavior of converting '' to undefined
    // so that clearing a field sends an empty string to the backend
    parse: (v) => v,
    ...fieldProps,
  });
  let errors = [];
  if (externalError) errors = [externalError];
  else if (error && touched) errors = [error];

  const onChange = React.useMemo(() => {
    if (typeof externalOnChange === 'function') {
      return (event) => externalOnChange(event, internalOnChange);
    }
    return internalOnChange;
  }, [externalOnChange, internalOnChange]);

  const onBlur = React.useMemo(() => {
    if (typeof externalOnBlur === 'function') {
      return (event) => externalOnBlur(event, internalOnBlur);
    }
    return internalOnBlur;
  }, [externalOnBlur, internalOnBlur]);

  const onFocus = React.useMemo(() => {
    if (typeof externalOnFocus === 'function') {
      return (event) => externalOnFocus(event, internalOnFocus);
    }
    return internalOnFocus;
  }, [externalOnFocus, internalOnFocus]);

  const inputId = externalId ?? `${name}-input`;
  const labelId = `${inputId}-label`;

  const label = externalLabel ?? schemaValues?.label;
  const internalReadonly = false; // get valuie from redux store!
  const readOnly =
    externalReadOnly ?? (internalReadonly || schemaValues?.readOnly);
  const disabled = externalDisabled ?? schemaValues?.disabled;
  const required = externalRequired ?? schemaValues?.required;
  const type = defineType(schemaValues?.type, schemaValues?.fieldOptions);
  const scale = schemaValues?.fieldOptions?.precision;
  const format = externalFormat ?? schemaValues?.format;
  const defaultProps = {
    autoFocus,
    disabled,
    error: Boolean(errors.length),
    errors,
    warning,
    id: inputId,
    label,
    labelId,
    name,
    onBlur,
    onChange,
    onFocus,
    readOnly,
    required,
    value,
    scale,
  };

  if (component) {
    return (
      <CustomField
        {...defaultProps}
        schemaValues={schemaValues}
        Component={component}
        ErrorControlProps={ErrorControlProps}
        LabelProps={LabelProps}
        {...ComponentProps}
        {...rest}
      />
    );
  }

  if (format === 'date')
    return <DateField {...defaultProps} {...ComponentProps} {...rest} />;

  if (format === 'mm-yy')
    return (
      <YearsMonths
        {...defaultProps}
        type={type}
        ComponentProps={ComponentProps}
        ErrorControlProps={ErrorControlProps}
        LabelProps={LabelProps}
        {...rest}
      />
    );

  if (schemaValues?.fieldOptions?.doubleEntry)
    return (
      <DoubleEntry
        {...defaultProps}
        type={type}
        ComponentProps={ComponentProps}
        ErrorControlProps={ErrorControlProps}
        LabelProps={LabelProps}
        {...rest}
      />
    );

  return (
    <DefaultField
      {...defaultProps}
      type={type}
      format={format}
      options={options ?? schemaValues?.options ?? []}
      clearable={clearable}
      ComponentProps={ComponentProps}
      ErrorControlProps={ErrorControlProps}
      LabelProps={LabelProps}
      {...rest}
    />
  );
}

Field.propTypes = {
  format: PropTypes.string,
  ComponentProps: PropTypes.objectOf(PropTypes.any),
  ErrorControlProps: PropTypes.objectOf(PropTypes.any),
  LabelProps: PropTypes.objectOf(PropTypes.any),
  autoFocus: PropTypes.bool,
  clearable: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fieldProps: PropTypes.objectOf(PropTypes.any),
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.string,
    })
  ),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  schemaValues: PropTypes.shape({
    disabled: PropTypes.bool,
    fieldOptions: PropTypes.shape({
      doubleEntry: PropTypes.bool,
      precision: PropTypes.number,
    }),
    format: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.node,
        value: PropTypes.string,
      })
    ),
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    type: PropTypes.string,
  }),
  validateField: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
  ]),
  warning: PropTypes.string,
};

export default Field;

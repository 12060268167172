import FingerpringJS from '@fingerprintjs/fingerprintjs';

const getBrowserFingerprint = () => {
  const fpPromise = FingerpringJS.load();
  return fpPromise
    .then(fp => fp.get())
    .then(result => result.visitorId)
}

export default getBrowserFingerprint;

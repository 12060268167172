import Immutable from 'seamless-immutable';
import _get from 'lodash/get';

export default class SchemaConverter {
  constructor(data, uiSchema = {}, knownFields = []) {
    this.formData = data.formData;
    this.schema = data.tabSchema || data.schema;
    if (data.readOnly) {
      uiSchema['ui:readonly'] = true;
    }
    this.readOnly = data.readOnly;
    this.uiSchema = uiSchema;
    this.overflowFields = Object.keys(this.schema.properties).filter(
      (i) => knownFields.indexOf(i) < 0
    );
    if (this.overflowFields.length) {
      this.createOverflow(this.overflowFields);
    }
  }

  static flattenArray(obj) {
    if (obj[0] && typeof obj[0] === 'object') {
      let arr = obj.map((row, i) => {
        let rowKeys = Object.keys(row);
        return rowKeys.map((r) => {
          if (Array.isArray(row[r])) {
            row[r] = SchemaConverter.flattenArray(row[r]);
            return {
              fieldId: r,
              fieldValues: row[r],
            };
          } else {
            return {
              fieldId: r,
              fieldValue: row[r] || '',
            };
          }
        });
      });
      return arr;
    } else {
      return obj;
    }
  }

  static flattenObject(obj) {
    let keys = Object.keys(obj);
    keys.forEach((k) => {
      if (Array.isArray(obj[k])) {
        obj[k] = SchemaConverter.flattenArray(obj[k]);
      } else if (typeof obj[k] === 'object') {
        obj = { ...obj, ...SchemaConverter.flattenObject(obj[k]) };
        delete obj[k];
      }
    });
    return obj;
  }

  static translateToBackend(data, dates = []) {
    data = SchemaConverter.flattenObject(
      Immutable.asMutable(data, { deep: true })
    );
    let keys = Object.keys(data);
    let values = [];
    keys.forEach((k) => {
      let val = data[k] || '';
      if (dates.includes(k)) {
        let regex = /(\d{2})(\d{2})(\d{4})/;
        let arr = regex.exec(val);
        if (arr) {
          val = arr[3] + '-' + arr[1] + '-' + arr[2];
        }
      }
      if (Array.isArray(val)) {
        values.push({
          fieldId: k,
          fieldValues: val,
        });
      } else {
        values.push({
          fieldId: k,
          fieldValue: val,
        });
      }
    });
    return values;
  }

  static translateData(field) {
    if (field.type === 'array') {
      let items = {};
      if (field.items.properties) {
        field.items.properties.forEach((item) => {
          items[item.key] = SchemaConverter.translateData(item);
          // delete items[item.key].key;
        });
        field.items.properties = items;
      }
    } else if (field.type === 'object' || !field.type) {
      let properties = {};
      let fieldProps = field.properties || field.groupProperties;
      fieldProps.forEach((f) => {
        properties[f.key] = SchemaConverter.translateData(f);
        // delete properties[f.key].key;
      });
      field.properties = properties;
    } else if (field.type === 'button') {
      field.type = 'null';
    }
    if (Array.isArray(field.enum) && field.enum.length === 0) {
      field.enum = [''];
    }
    if (Array.isArray(field.enumNames) && field.enumNames.length === 0) {
      field.enumNames = [''];
    }
    if (field.pattern) {
      field.pattern = '^' + field.pattern + '$|^$';
    }
    return field;
  }

  static groupFormData(schema, formData) {
    if (schema.type === 'object') {
      let newFormData = {};
      let schemaKeys = Object.keys(schema.properties);
      schemaKeys.forEach((key) => {
        if (schema.properties[key].type === 'object') {
          newFormData[key] = SchemaConverter.groupFormData(
            schema.properties[key],
            formData
          );
        } else {
          newFormData[key] = formData[key];
        }
      });
      return newFormData;
    } else {
      return formData;
    }
  }

  static formDataTranslateRec = (data) => {
    return data.reduce((acc, field) => {
      if (field.fieldValues && Array.isArray(field.fieldValues[0])) {
        acc[field.fieldId] = field.fieldValues.map((item) => {
          return SchemaConverter.formDataTranslateRec(item);
        });
      } else if (field.fieldValue && _get(field.fieldValue, [0, 'fieldId'])) {
        acc[field.fieldId] = SchemaConverter.formDataTranslateRec(
          field.fieldValue
        );
      } else {
        acc[field.fieldId] = field.fieldValue || field.fieldValues;
      }
      return acc;
    }, {});
  };

  static translateFromBackend = (data) => {
    const { formData, schema } = data;
    try {
      let dates = [];
      if (schema) {
        if (schema.properties) {
          let properties = {};
          schema.properties.forEach((field) => {
            if (field.format && field.format === 'date') {
              dates.push(field.key);
              // field.format = undefined;
            }
            properties[field.key] = SchemaConverter.translateData(field);
            // delete properties[field.key].key;
          });
          schema.properties = properties;
        } else {
          data.schema = SchemaConverter.translateData(schema);
        }
      }
      data.formData = SchemaConverter.formDataTranslateRec(formData || []);
      data.dates = dates;
      data.formData = SchemaConverter.groupFormData(data.schema, data.formData);
      return data;
    } catch (e) {
      if (process.env.NODE_ENV) console.error(e);
      return data;
    }
  };

  createOverflow(fields) {
    let properties = {};
    fields.forEach((field) => {
      properties[field] = this.schema.properties[field] || {};
    });
    let schema = {
      type: 'object',
      title: '',
      properties: properties,
    };
    let uiSchema = {
      'ui:field': 'overflow',
    };
    Object.keys(this.uiSchema).forEach((s) => {
      if (fields.includes(s)) {
        uiSchema[s] = this.uiSchema[s];
      }
    });
    this.modifySchema('overflow', schema, uiSchema);
  }

  createBlock(fields, name, uiSchemaSent = {}, title = '') {
    let properties = {};
    fields.forEach((field) => {
      properties[field] = this.schema.properties[field] || {};
    });
    let schema = {
      type: 'object',
      title: '',
      properties: properties,
    };
    let uiSchema = {
      'ui:field': name,
    };
    uiSchema = { ...uiSchema, ...uiSchemaSent };
    Object.keys(this.uiSchema).forEach((s) => {
      if (fields.includes(s)) {
        uiSchema[s] = this.uiSchema[s];
      }
    });
    this.modifySchema(name, schema, uiSchema);
  }

  createAddress(fields, name = 'address', title = '') {
    let innerFields = ['ADDR1', 'ADDR2', 'CITY', 'STATE', 'ZIP'];
    if (fields.length === innerFields.length) {
      let inSchema;
      fields.map((f) => {
        if (this.schema.properties[f]) {
          inSchema = true;
        }
      });
      if (!inSchema) {
        return;
      }
      let con = {};
      let properties = {};
      fields.forEach((f, i) => {
        con[innerFields[i]] = f;
        properties[f] = this.schema.properties[f] || {};
      });
      let schema = {
        type: 'object',
        title: title,
        properties: properties,
      };
      let uiSchema = {
        'ui:field': 'address',
        [con.STATE]: {
          'ui:widget': 'SmallDropdown',
        },
        'ui:options': {
          conversion: con,
        },
      };
      this.modifySchema(name, schema, uiSchema);
    }
  }

  createPersonal(fields, name = 'personal', title = '') {
    let innerFields = [
      'FIRST',
      'MI',
      'LAST',
      'DOB',
      'SOC',
      'GEN',
      'PHNUM',
      'PRIM_SMS_FLAG',
      'CELL_PHONE',
      'ALT_SMS_FLAG',
      'E_MAIL',
      'MMAID',
    ];
    if (fields.length === innerFields.length) {
      let con = {};
      let properties = {};
      fields.forEach((f, i) => {
        con[innerFields[i]] = f;
        properties[f] = this.schema.properties[f] || {};
      });
      let schema = {
        type: 'object',
        title: title,
        properties: properties,
      };
      let uiSchema = {
        'ui:field': 'personal',
        [con.SOC]: {
          'ui:widget': 'masked',
          'ui:options': {
            mask: '111-11-1111',
          },
        },
        [con.GEN]: {
          'ui:widget': 'SmallDropdown',
        },
        [con.PHNUM]: {
          'ui:widget': 'masked',
          'ui:options': {
            mask: '(111) 111-1111',
          },
        },
        [con.CELL_PHONE]: {
          'ui:widget': 'masked',
          'ui:options': {
            mask: '(111) 111-1111',
          },
        },
        'ui:options': {
          conversion: con,
        },
      };
      this.modifySchema(name, schema, uiSchema);
    }
  }

  createEmployment(fields, name = 'employment', title = '') {
    let innerFields = [
      'ENAME',
      'EPHONE',
      'EMP_STATUS',
      'PR_OCC_CD',
      'PAY',
      'PAYBAS',
      'LEMP',
    ];
    if (fields.length === innerFields.length) {
      let con = {};
      let properties = {};
      fields.forEach((f, i) => {
        con[innerFields[i]] = f;
        properties[f] = this.schema.properties[f] || {};
      });
      let schema = {
        type: 'object',
        title: title,
        properties: properties,
      };
      let uiSchema = {
        'ui:field': 'employment',
        [con.EPHONE]: {
          'ui:widget': 'masked',
          'ui:options': {
            mask: '(111) 111-1111',
          },
        },
        [con.LEMP]: {
          'ui:field': 'numberMonthsField',
        },
        [con.CELL_PHONE]: {
          'ui:widget': 'masked',
          'ui:options': {
            mask: '(111) 111-1111',
          },
        },
        'ui:options': {
          conversion: con,
        },
      };
      this.modifySchema(name, schema, uiSchema);
    }
  }

  createBalanceTransfer(fields, name = 'balanceTransfer', title = '') {
    let innerFields = [
      'LENDER',
      'ADDR',
      'ATTN',
      'ADDR3',
      'ADDR4',
      'CITY',
      'STATE',
      'ZIP',
      'CNTRY_CD',
      'BALANCE',
      'ACCT_NUM',
    ];
    if (fields.length === innerFields.length) {
      let con = {};
      let properties = {};
      fields.forEach((f, i) => {
        con[innerFields[i]] = f;
        properties[f] = this.schema.properties[f] || {};
      });
      let schema = {
        type: 'object',
        title: title,
        properties: properties,
      };
      let uiSchema = {
        'ui:field': 'balancetransfer',
        'ui:options': {
          conversion: con,
        },
      };

      this.modifySchema(name, schema, uiSchema);
    }
  }

  _fieldGrab(schema) {
    let ret = {};
    let keys = Object.keys(schema.properties);
    keys.forEach((f) => {
      if (schema.properties[f] && schema.properties[f].type === 'object') {
        ret[f] = this._fieldGrab(schema.properties[f]);
      } else {
        ret[f] = this.formData[f];
        delete this.formData[f];
        delete this.schema.properties[f];
        if (this.schema.required && this.schema.required.includes(f)) {
          this.schema.required.splice(this.schema.required.indexOf(f), 1);
          if (!schema.required) {
            schema.required = [];
          }
          schema.required.push(f);
        }
      }
    });
    return ret;
  }

  modifySchema(name, schema, uiSchema) {
    this.formData[name] = this._fieldGrab(schema);
    this.schema.properties[name] = schema;
    this.uiSchema[name] = uiSchema;
  }

  getSchemas() {
    return {
      schema: this.schema,
      uiSchema: this.uiSchema,
      formData: this.formData,
      readOnly: this.readOnly,
    };
  }
}

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { styled } from '@mui/styles';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';
import TextField from '@mui/material/TextField';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.light,
  },
}));

function DateField(props) {
  const {
    error,
    errors,
    format = 'MM/DD/YYYY',
    id,
    label,
    labelId,
    name,
    onChange,
    type = 'DATE',
    value,
    ...rest
  } = props;

  const backendFormat = type === 'DATE' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss';
  const frontendFormat = type === 'DATE' ? format : 'MM/DD/YYYY hh:mm a';

  const isDateValid = value !== 'Invalid date';
  const showError = error || !isDateValid;
  const displayErrors = isDateValid ? errors : ['Invalid date'];

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <DatePicker
        name={name}
        label={label}
        value={value || null}
        onChange={(date) => {
          onChange(
            moment(date, frontendFormat).startOf('date').format(backendFormat)
          );
        }}
        inputFormat={frontendFormat}
        renderInput={(renderProps) => (
          <TextField
            id={id}
            {...renderProps}
            error={showError}
            inputProps={{
              ...renderProps.inputProps,
              'aria-label': label,
              'data-testid': 'keyboard-date-picker',
            }}
            sx={{
              '& .MuiFormHelperText-root': showError
                ? { marginTop: 0, marginLeft: 0 }
                : undefined,
            }}
            helperText={
              showError && (
                <StyledTooltip
                  id={`${name}_TOOLTIP`}
                  data-testid="error-control-tooltip"
                  title={displayErrors?.[0]}
                  placement="right"
                  color="error"
                  sx={{
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: (theme) => theme.palette.error.light,
                    },
                  }}
                >
                  <ErrorIcon
                    data-testid="error-icon"
                    sx={{
                      width: (theme) => theme.spacing(2),
                    }}
                  />
                </StyledTooltip>
              )
            }
          />
        )}
        {...rest}
      />
    </LocalizationProvider>
  );
}

DateField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  labelId: PropTypes.string,
  format: PropTypes.string,
  error: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['DATE', 'TIMESTAMP']),
};

export default DateField;

import Immutable from 'seamless-immutable';
import SchemaConverter from 'fni-components/FNISchemaForm/utils/SchemaConverter';
import { batch } from 'react-redux';
import _get from 'lodash/get';
import { makeApi } from 'fni-schema';

import { fetchingSet } from '../../../../../components/store/shared';
import { setInfo } from '../../../store/coreLayout';
import apiFetch from '../../../utils/apiFetch';

// ------------------------------------
// Constants
// ------------------------------------
export const STRATEGY_SET = 'STRATEGY_SET';
export const STRATEGY_SET_ID = 'STRATEGY_SET_ID';

// ------------------------------------
// Actions
// ------------------------------------

export const strategySet = (data, path) => ({
  type: STRATEGY_SET,
  payload: { data, path },
});

export const strategySetRegion = (region) => ({
  type: STRATEGY_SET_ID,
  payload: region,
});

// ------------------------------------
// Middleware
// ------------------------------------
const setError = (message) => setInfo({ type: 'error', message });

const baseURL = 'fni-lenderportal-admin/admin/strategy/';
export const api = makeApi({ baseURL, schemaKey: 'adminSchema' });

// Standard fetch for all actions
export const strategyFetch = (url, options) => (dispatch, getState) => {
  const {
    fetchPath = ['strategy', 'main'],
    dataPath = ['data'],
    urlOptions = null,
    errorOptions = {},
    callBack = (data) => data,
    noText = false,
  } = options;
  const innerErrorOptions = {
    type: 'error',
    message: 'Failed to load page. Please try again later.',
    ...errorOptions,
  };
  dispatch(fetchingSet(fetchPath, true));
  return new Promise((resolve, reject) => {
    return apiFetch(url, urlOptions ? urlOptions(dispatch, getState) : null)
      .then((response) => {
        if (response.ok) {
          return response.text();
        }
        throw innerErrorOptions;
      })
      .then((initialText) => {
        let text = initialText;
        if (noText) {
          text = false;
        } else {
          const {
            adminSchema: schema,
            formData,
            status,
            errorMsgs,
          } = JSON.parse(text);
          if (status === 'ERROR') {
            const error = {
              ...innerErrorOptions,
              message: errorMsgs.join(' '),
            };
            throw error;
          }
          text = SchemaConverter.translateFromBackend({
            schema,
            formData,
          });
        }
        batch(() => {
          text = callBack(text, dispatch, getState);
          if (!noText) {
            dispatch(strategySet(text, dataPath));
          }
          dispatch(fetchingSet(fetchPath, false));
        });
        resolve(text);
        return true;
      })
      .catch((e) => {
        if (e.type) {
          dispatch(setInfo({ message: e }));
        } else {
          dispatch(setInfo({ message: innerErrorOptions }));
        }
        reject(e);
      });
  });
};

// GET fni-lenderportal-admin/admin/strategy/actions/{region}
export const getActions = (region) => async (dispatch, getState) => {
  const dataPath = ['actions', 'data', region];
  const fetchPath = ['strategy', 'actionModal'];

  const fetching = _get(getState()?.shared?.fetching, fetchPath);

  if (!fetching) {
    try {
      dispatch(fetchingSet(fetchPath, true));
      const response = await api.get(`actions/${region}`);
      dispatch(strategySet(response, dataPath));
    } catch (error) {
      if (error.message) {
        dispatch(setError(error.message));
      } else dispatch(setError('Failed to load strategy actions.'));
    } finally {
      dispatch(fetchingSet(fetchPath, false));
    }
  }
};

// GET fni-lenderportal-admin/admin/strategy/manage/{region}
export const getManagementData = (region) => async (dispatch, getState) => {
  const dataPath = ['manage', 'data', region];
  const fetchPath = ['strategy', 'manage', region];

  const fetching = _get(getState()?.shared?.fetching, fetchPath);

  if (!fetching) {
    try {
      dispatch(fetchingSet(fetchPath, true));
      const response = await api.get(`manage/${region}`);
      dispatch(strategySet(response, dataPath));
    } catch (error) {
      if (error.message) {
        dispatch(setError(error.message));
      } else dispatch(setError('Failed to load strategy data.'));
    } finally {
      dispatch(fetchingSet(fetchPath, false));
    }
  }
};

// get landing info
export const landingFetch = () =>
  strategyFetch('admin/strategy/landing', {
    dataPath: ['landing', 'data'],
  });

// fni-lenderportal-admin/strategy/actions/{region}
export const postActions = (region, data) => async (dispatch, getState) => {
  const fetchPath = ['strategy', 'actionModal'];
  const fetching = _get(getState()?.shared?.fetching, fetchPath);

  if (!fetching) {
    try {
      dispatch(fetchingSet(fetchPath, true));
      await api.post(`actions/${region}`, { ACTIONS: data });
      dispatch(
        setInfo({
          type: 'success',
          delay: 3000,
          message: 'Saved successfully!',
        }),
      );
    } catch (error) {
      if (error.message) {
        dispatch(setError(error.message));
      } else dispatch(setError('Error encountered while saving action.'));
    } finally {
      batch(() => {
        dispatch(fetchingSet(fetchPath, false));
        dispatch(getManagementData(region));
      });
    }
  }
};

// save name
export const nameSave = (name) => (dispatch, getState) => {
  const id = getState().strategy.region;
  return strategyFetch(`admin/strategy/version/${id}`, {
    fetchPath: ['strategy', 'nameModal'],
    urlOptions: () => {
      return {
        method: 'PUT',
        body: JSON.stringify([{ fieldId: 'VERSION_NAME', fieldValue: name }]),
      };
    },
    errorOptions: {
      id: 'nameSave',
      title: 'Saving Name',
      message: 'Error encountered while saving version name.',
    },
  })(dispatch, getState)
    .then(() => dispatch(getManagementData(id)))
    .then(() => {
      dispatch(
        setInfo({
          type: 'success',
          delay: 3000,
          message: 'Saved successfully!',
        }),
      );
      return undefined;
    });
};

// delete table
export const tableDelete = (tableName) => (dispatch, getState) => {
  const { region } = getState().strategy;
  return strategyFetch(`admin/strategy/table/${region}/${tableName}`, {
    fetchPath: ['strategy', 'tableDelete'],
    urlOptions: () => {
      return {
        method: 'DELETE',
      };
    },
    errorOptions: {
      message: `Failed to delete ${tableName}.`,
    },
    noText: true,
  })(dispatch, getState)
    .then(() => dispatch(getManagementData(region)))
    .then(() => {
      dispatch(
        setInfo({
          type: 'success',
          delay: 3000,
          message: 'Table deleted successfully!',
        }),
      );
      return undefined;
    });
};

export const actions = {
  landingFetch,
  getManagementData,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [STRATEGY_SET]: (state, { payload }) => {
    return Immutable.setIn(state, payload.path, payload.data);
  },
  [STRATEGY_SET_ID]: (state, { payload }) => {
    return payload === state.region
      ? state
      : Immutable.setIn(state, ['region'], payload);
  },
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  landing: {
    data: {},
  },
  manage: {
    data: {},
  },
};

export default function strategyReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

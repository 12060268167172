import Immutable from 'seamless-immutable';
import _get from 'lodash/get';

import { makeApi } from 'fni-schema';

export const api = makeApi({
  baseURL: 'fni-lenderportal-search/searching/',
});

export const hashmapApi = makeApi({
  baseURL: 'fni-lenderportal-search/searching/',
  withResponseInterceptor: false,
  withRequestInterceptor: false,
});

// ------------------------------------
// Constants
// ------------------------------------
const SET_LOAN_VIEW = 'SET_LOAN_VIEW';
const SET_SNACKBAR_MESSAGE = 'SET_SNACKBAR_MESSAGE';
const SET_LOAN_VIEW_FETCHING = 'SET_LOAN_VIEW_FETCHING';

// ------------------------------------
// Actions
// ------------------------------------
export const setLoanView = (data, path) => {
  return {
    type: SET_LOAN_VIEW,
    payload: { data, path },
  };
};

export const setSnackbarMessage = (payload) => {
  return {
    type: SET_SNACKBAR_MESSAGE,
    payload,
  };
};

export const setFetching = (path, data) => {
  return {
    type: SET_LOAN_VIEW_FETCHING,
    payload: { data, path },
  };
};

export const setError = (message) =>
  setSnackbarMessage({ type: 'error', message });

export const setSuccess = (message) =>
  setSnackbarMessage({ type: 'success', message, delay: 2000 });

// ------------------------------------
// Middleware
// ------------------------------------

// GET fni-lenderportal-search/searching/dealerPortal/tabs
export const getDealerTabs = () => async (dispatch) => {
  try {
    const response = await hashmapApi.get('dealerPortal/tabs');
    dispatch(setLoanView(response.data, ['dealerTabs']));
  } catch (error) {
    if (error.message) {
      dispatch(setError(error.message));
    } else dispatch(setError('Failed to fetch dealerportal tabs'));
  }
};

// GET fni-lenderportal-search/searching/loanViewLayout/loanView
export const getLoanView = () => async (dispatch, getState) => {
  const fetchPath = ['fetching', 'loanView'];
  const fetching = _get(getState()?.loanView, fetchPath);

  if (!fetching) {
    try {
      dispatch(setFetching(fetchPath, true));

      const response = await api.get('loanViewLayout/loanView');
      dispatch(setLoanView(response, ['loanView']));
    } catch (error) {
      if (error.message) {
        dispatch(setError(error.message));
      } else dispatch(setError('Failed to fetch loan view data'));
    } finally {
      dispatch(setFetching(fetchPath, false));
    }
  }
};

// GET fni-lenderportlal-search/searching/loanViewLayout/loanViewGroup
export const getLoanViewGroup = () => async (dispatch, getState) => {
  const fetchPath = ['fetching', 'loanViewGroup'];
  const fetching = _get(getState()?.loanView, fetchPath);

  if (!fetching) {
    try {
      dispatch(setFetching(fetchPath, true));

      const response = await api.get('loanViewLayout/loanViewGroup');
      dispatch(setLoanView(response, ['loanViewGroup']));
    } catch (error) {
      if (error.message) {
        dispatch(setError(error.message));
      } else dispatch(setError('Failed to fetch loan view group data'));
    } finally {
      dispatch(setFetching(fetchPath, false));
    }
  }
};

// GET fni-lenderportlal-search/searching/loanViewLayout/appData/{refnum}
export const getLoanViewAppData = (refnum) => async (dispatch, getState) => {
  const fetchPath = ['fetching', 'loanViewAppData'];
  const fetching = _get(getState()?.loanView, fetchPath);

  if (!fetching) {
    try {
      dispatch(setFetching(fetchPath, true));

      const response = await api.get(`loanViewLayout/appData/${refnum}`);
      dispatch(setLoanView(response, ['appData']));
    } catch (error) {
      if (error.message) {
        dispatch(setError(error.message));
      } else dispatch(setError('Failed to fetch app data'));
    } finally {
      dispatch(setFetching(fetchPath, false));
    }
  }
};

export const stipsApi = makeApi({
  baseURL: 'fni-lenderportal-search/searching/',
  withRequestInterceptor: false,
  schema: 'actionSchema',
});

// GET fni-lenderportal-admin/searching/conditions/stipStatus/{refnum}/
export const getStips = (refnum) => async (dispatch, getState) => {
  const fetchPath = ['fetching', 'stips'];
  const fetching = _get(getState()?.loanView, fetchPath);

  if (!fetching) {
    try {
      dispatch(setFetching(fetchPath, true));
      const response = await stipsApi.get(`conditions/stipStatus/${refnum}`);
      dispatch(setLoanView(response, ['stips']));
    } catch (error) {
      if (error.message) {
        dispatch(setError(error.message));
      } else dispatch(setError('Failed to fetch stip details'));
    } finally {
      dispatch(setFetching(fetchPath, false));
    }
  }
};

// GET fni-lenderporatal-search/searching/loanViewStatus/loanStatus/{refnum}
export const getLoanStatus = (refnum) => async (dispatch, getState) => {
  const fetchPath = ['fetching', 'loanStatus'];
  const fetching = _get(getState()?.loanView, fetchPath);

  if (!fetching) {
    try {
      dispatch(setFetching(fetchPath, true));
      const response = await api.get(`loanViewStatus/loanStatus/${refnum}`);
      dispatch(setLoanView(response, ['loanStatus']));
    } catch (error) {
      if (error.message) {
        dispatch(setError(error.message));
      } else dispatch(setError('Failed to fetch loan status'));
    } finally {
      dispatch(setFetching(fetchPath, false));
    }
  }
};

// GET fni-lenderporatal-search/searching/loanViewStatus/appData/{refnum}
export const getLoanStatusAppData = (refnum) => async (dispatch, getState) => {
  const fetchPath = ['fetching', 'loanStatusAppData'];
  const fetching = _get(getState()?.loanView, fetchPath);

  if (!fetching) {
    try {
      dispatch(setFetching(fetchPath, true));
      const response = await api.get(`loanViewStatus/appData/${refnum}`);
      dispatch(setLoanView(response, ['loanStatusAppData']));
    } catch (error) {
      if (error.message) {
        dispatch(setError(error.message));
      } else dispatch(setError('Failed to fetch loan status app data'));
    } finally {
      dispatch(setFetching(fetchPath, false));
    }
  }
};

// GET fni-lenderportal-search/searching/loanViewMessageCount/{REFNUM}
export const messageCountApi = makeApi({
  baseURL: 'fni-lenderportal-search/searching/',
  withResponseInterceptor: false,
});
export const getMessageCount = (refnum) => async (dispatch, getState) => {
  const fetchPath = ['fetching', 'messageCount'];
  const fetching = _get(getState()?.loanView, fetchPath);

  if (!fetching) {
    try {
      dispatch(setFetching(fetchPath, true));
      const response = await messageCountApi.get(
        `loanViewMessageCount/${refnum}`
      );
      dispatch(setLoanView(response.data, ['messageCount']));
    } catch (error) {
      if (error.message) {
        dispatch(setError(error.message));
      } else dispatch(setError('Failed to fetch loan message count'));
    } finally {
      dispatch(setFetching(fetchPath, false));
    }
  }
};

// PUT fni-lenderportal-search/searching/conditions/esignDocument/{refnum}/{stipId}
export const uploadDoc =
  (refnum, stipId, uploadFile) => async (dispatch, getState) => {
    const fetchPath = ['fetching', 'uploadDoc'];
    const fetching = _get(getState()?.shared?.fetching, fetchPath);

    const form = new FormData();
    form.append('document', uploadFile);

    if (!fetching) {
      try {
        dispatch(setFetching(fetchPath, true));

        await makeApi({ withRequestInterceptor: false }).put(
          `fni-lenderportal-search/conditions/esignDocument/${refnum}/${stipId}`,
          form,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      } catch (error) {
        if (error.message) {
          dispatch(setError(error.message));
        } else {
          dispatch(setError('Failed to upload document.'));
        }

        throw error;
      } finally {
        dispatch(setFetching(fetchPath, false));
      }
    }
  };

// PUT fni-lenderportal-search/searching/conditions/esignDocument/{refnum}/{stipId}/status/updated
export const putEsignUpdated = (refnum, stipId) => async (dispatch) => {
  try {
    return await api.put(
      `conditions/esignDocument/${refnum}/${stipId}/status/updated`
    );
  } catch (error) {
    if (error.message) {
      dispatch(setError(error.message));
    } else dispatch(setError('Failed to update'));
    throw error;
  }
};

export const getHasConvosPerms = async (refnum) => {
  try {
    await api.get(`convo/${refnum}/conversations/permissions`);

    return true;
  } catch (e) {
    if (e?.response?.status === 401) {
      return false;
    }
    return e;
  }
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_LOAN_VIEW]: (state, { payload: { data, path } }) => {
    return Immutable.setIn(state, path, data);
  },
  [SET_SNACKBAR_MESSAGE]: (state, { payload }) =>
    Immutable.setIn(state, ['snackbar'], payload),
  [SET_LOAN_VIEW_FETCHING]: (state, { payload: { data, path } }) => {
    return Immutable.setIn(state, path, data);
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};
export default function (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

export const SchemaContext = createContext();

export const SchemaProvider = ({
  schema,
  children,
  optionalContext: OptionalContext = SchemaContext,
}) => (
  <OptionalContext.Provider value={schema}>{children}</OptionalContext.Provider>
);

SchemaProvider.propTypes = {
  children: PropTypes.node,
  schema: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  optionalContext: PropTypes.objectOf(PropTypes.any),
};

const createSchemaObject = (levels, schema) => {
  return levels.reduce((acc, level) => {
    if (acc?.type === 'array') return acc?.items;
    if (acc?.type === 'object' || acc?.type === undefined) {
      const returnObject = {
        ...acc?.properties?.[level],
        readOnly: acc?.readOnly || acc?.properties?.[level]?.readOnly,
      };
      if (acc?.required?.includes(level)) {
        returnObject.required = true;
      }
      return returnObject;
    }

    return undefined;
  }, schema);
};

export const parseName = (name, schema) => {
  const levels = name.match(/[^\][.]+/g);

  const schemaValue = createSchemaObject(levels, schema);

  if (schemaValue) {
    let options;
    if (schemaValue.enum) {
      options = schemaValue.enum.map((value, index) => ({
        value,
        label: schemaValue.enumNames[index],
      }));
    }

    return {
      key: levels[levels.length - 1],
      label: schemaValue?.title ?? '',
      readOnly: Boolean(schemaValue?.readOnly),
      options,
      minLength: schemaValue?.minLength,
      maxLength: schemaValue?.maxLength,
      pattern: schemaValue?.pattern,
      validationMessages: schemaValue?.validationMessages,
      required: schemaValue?.required ?? false,
      type: schemaValue?.type,
      format: schemaValue?.format,
      fieldOptions: schemaValue?.options,
    };
  }
  return {};
};

export const makeName = (name, addition) => `${name}.${addition}`;

export const makeFields = (name, arr) =>
  arr.reduce((acc, value) => ({ ...acc, [value]: `${name}.${value}` }), {});

export const makeNameArray = (name, arr) =>
  arr.reduce((acc, value) => [...acc, `${name}.${value}`], []);

export const useSchema = (name = '', schemaContext = SchemaContext) => {
  const schema = useContext(schemaContext);
  return useMemo(() => {
    if (Array.isArray(name))
      return name.reduce(
        (ret, n) => ({ ...ret, [n]: parseName(n, schema) }),
        {}
      );
    return parseName(name, schema);
  }, [name, schema]);
};


export const useSchemaManual = (name = '',schema) => {
  return parseName(name, schema);
};

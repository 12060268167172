import _get from 'lodash/get';

const flattenArray = (obj) => {
  if (obj[0] && typeof obj[0] === 'object') {
    return obj.map((row) => {
      return Object.entries(row).map(([key, value]) => {
        if (Array.isArray(value)) {
          return {
            fieldId: key,
            fieldValues: flattenArray(value),
          };
        }
        return {
          fieldId: key,
          fieldValue: value ?? '',
        };
      });
    });
  }
  return obj;
};

const flattenObject = (obj) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      acc[key] = flattenArray(value);
      return acc;
    }
    if (typeof value === 'object') {
      return { ...acc, ...flattenObject(value) };
    }
    acc[key] = value;
    return acc;
  }, {});
};

export const translateToBackend = (data = {}) => {
  const newData = flattenObject(data);
  return Object.entries(newData).map(([key, value]) => {
    if (Array.isArray(value)) {
      return {
        fieldId: key,
        fieldValues: value,
      };
    }
    return {
      fieldId: key,
      fieldValue: value,
    };
  });
};

export const translateData = (field) => {
  const newField = { ...field };
  if (!newField.type) {
    if (Array.isArray(newField.properties)) {
      newField.type = 'object';
    } else throw new Error(`${newField.key} is invalid`);
  }
  if (newField.type === 'array') {
    const items = {};
    if (newField.items.properties) {
      newField.items.properties.forEach((item) => {
        items[item.key] = translateData(item);
      });
      newField.items.properties = items;
    }
  } else if (newField.type === 'object') {
    const properties = {};
    const fieldProps = newField.properties ?? newField.groupProperties;
    fieldProps.forEach((f) => {
      properties[f.key] = translateData(f);
    });
    newField.properties = properties;
  } else if (newField.type === 'button') {
    newField.type = 'null';
  }
  if (Array.isArray(newField.enum) && newField.enum.length === 0) {
    newField.enum = [''];
  }
  if (Array.isArray(newField.enumNames) && newField.enumNames.length === 0) {
    newField.enumNames = [''];
  }
  if (newField.pattern) {
    newField.pattern = `^${newField.pattern}$|^$`;
  }
  return newField;
};

const groupFormData = (schema, formData) => {
  if (schema?.type === 'object' || schema?.properties?.length) {
    const newFormData = {};
    Object.keys(schema.properties).forEach((key) => {
      if (schema.properties[key].type === 'object') {
        newFormData[key] = groupFormData(
          schema.properties[key],
          formData?.[key] ?? formData
        );
      } else {
        newFormData[key] = formData[key];
      }
    });
    return newFormData;
  }
  return formData;
};

const formDataTranslateRec = (data) => {
  return data.reduce((acc, field) => {
    if (field.fieldValues && Array.isArray(field.fieldValues[0])) {
      acc[field.fieldId] = field.fieldValues.map((item) => {
        return formDataTranslateRec(item);
      });
    } else if (field.fieldValue && _get(field.fieldValue, [0, 'fieldId'])) {
      acc[field.fieldId] = formDataTranslateRec(field.fieldValue);
    } else {
      acc[field.fieldId] = field.fieldValue || field.fieldValues;
    }
    return acc;
  }, {});
};

const translateSchemaFromBackend = (schema) => {
  if (schema.properties) {
    const properties = {};
    schema.properties.forEach((field) => {
      properties[field.key] = translateData(field);
    });
    return { ...schema, properties };
  }
  return translateData(schema);
};

export const translateFromBackend = (data) => {
  const { formData, schema } = data;
  try {
    const newData = {};
    if (schema) {
      newData.schema = translateSchemaFromBackend(schema);
    }
    newData.formData = formDataTranslateRec(formData ?? []);
    newData.formData = groupFormData(newData.schema, newData.formData);
    return newData;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return data;
  }
};

import Immutable from 'seamless-immutable';
import SchemaConverter from 'fni-components/FNISchemaForm/utils/SchemaConverter';
import { setInfo } from '../../../store/coreLayout';
import apiFetch from '../../../utils/apiFetch';

// ------------------------------------
// Constants
// ------------------------------------
export const SMS_TEMPLATES_SET = 'SMS_TEMPLATES_SET';
export const SMS_FIELDS_SET = 'SMS_FIELDS_SET';
export const SMS_TEMPLATE_SET = 'SMS_TEMPLATE_SET';
export const SMS_RESPONSE_SET = 'SMS_RESPONSE_SET';
export const SMS_FORMDATA_UPDATE = 'SMS_FORMDATA_UPDATE';
export const SMS_FETCHING_SET = 'SMS_FETCHING_SET';

// ------------------------------------
// Actions
// ------------------------------------
export const templatesSet = (data) => {
  return {
    type: SMS_TEMPLATES_SET,
    payload: data,
  };
};

export const fieldsSet = (data) => {
  return {
    type: SMS_FIELDS_SET,
    payload: data,
  };
};

export const templateSet = (data) => {
  return {
    type: SMS_TEMPLATE_SET,
    payload: data,
  };
};

export const smsResponseSet = (data) => {
  return {
    type: SMS_RESPONSE_SET,
    payload: data,
  };
};

export const templateFormDataUpdate = (location, data) => {
  return {
    type: SMS_FORMDATA_UPDATE,
    payload: {
      location,
      data,
    },
  };
};

export const smsFetchingSet = (data) => {
  return {
    type: SMS_FETCHING_SET,
    payload: data,
  };
};

// ------------------------------------
// Middleware
// ------------------------------------
export const templatesFetch = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(smsFetchingSet(true));
      return apiFetch('admin/sms/templates')
        .then((response) => {
          if (response.ok) {
            return response.text();
          }
          return undefined;
        })
        .then((text) => {
          let parsed = JSON.parse(text);
          parsed = SchemaConverter.translateFromBackend({
            schema: parsed.adminSchema,
            formData: parsed.formData,
          });
          dispatch(templatesSet(parsed));
          dispatch(smsFetchingSet(false));
          resolve(true);
          return undefined;
        })
        .catch((response) => {
          // eslint-disable-next-line no-console
          if (process.env.NODE_ENV === 'development') console.error(response);
          reject();
        });
    });
  };
};

export const templateFetch = (templateId) => (dispatch) => {
  const url = templateId
    ? `admin/sms/template/${templateId}`
    : 'admin/sms/template';
  return new Promise(() => {
    dispatch(smsFetchingSet(true));
    return apiFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.text();
        }
        return undefined;
      })
      .then((text) => {
        let parsed = JSON.parse(text);
        parsed = SchemaConverter.translateFromBackend({
          schema: parsed.adminSchema,
          formData: parsed.formData,
        });
        dispatch(templateSet(parsed));
        dispatch(smsFetchingSet(false));
        return undefined;
      })
      .catch((response) => {
        // eslint-disable-next-line no-console
        if (process.env.NODE_ENV === 'development') console.error(response);
      });
  });
};

export const fieldsFetch = () => {
  return (dispatch) => {
    dispatch(smsFetchingSet(true));
    return apiFetch('admin/sms/fields')
      .then((response) => {
        if (response.ok) {
          return response.text();
        }
        return undefined;
      })
      .then((text) => {
        let parsed = JSON.parse(text);
        parsed = SchemaConverter.translateFromBackend({
          schema: parsed.adminSchema,
          formData: parsed.formData,
        });
        dispatch(fieldsSet(parsed));
        dispatch(smsFetchingSet(false));
        return undefined;
      })
      .catch((response) => {
        // eslint-disable-next-line no-console
        if (process.env.NODE_ENV === 'development') console.error(response);
      });
  };
};

export const templateSend = (templateId) => {
  const url = templateId
    ? `admin/sms/template/${templateId}`
    : 'admin/sms/template';
  const method = templateId ? 'PUT' : 'POST';
  return (dispatch, getState) => {
    dispatch(smsFetchingSet(true));
    return new Promise((resolve, reject) => {
      let data = getState().sms.template.formData;
      data = SchemaConverter.translateToBackend(data);
      const options = {
        method,
        body: JSON.stringify(data),
      };
      return apiFetch(url, options)
        .then((response) => {
          if (!response.ok) {
            dispatch(smsFetchingSet(false));
            dispatch(
              setInfo({
                type: 'error',
                message: 'Failed to Save!',
              })
            );
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('Failure');
            return undefined;
          }
          return response.text();
        })
        .then((text) => {
          const parsed = JSON.parse(text);
          if (parsed.status === 'SUCCESS') {
            dispatch(smsFetchingSet(false));
            dispatch(
              setInfo({
                type: 'success',
                message: 'Template Saved Successfully!',
                delay: 3000,
              })
            );
            dispatch(templatesFetch());
            resolve(true);
            return undefined;
          }
          dispatch(smsFetchingSet(false));
          const message = parsed.errorMsgs.join('\n');
          dispatch(
            setInfo({
              type: 'error',
              message,
            })
          );
          // eslint-disable-next-line prefer-promise-reject-errors
          reject('Failure');
          return undefined;
        });
    });
  };
};

export const templateDelete = (templateId) => {
  const url = templateId
    ? `admin/sms/template/${templateId}`
    : 'admin/sms/template';
  return (dispatch) => {
    dispatch(smsFetchingSet(true));
    return new Promise((resolve, reject) => {
      const options = {
        method: 'DELETE',
      };
      return apiFetch(url, options)
        .then((response) => {
          if (!response.ok) {
            dispatch(smsFetchingSet(false));
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('Failure');
            return undefined;
          }
          return response.text();
        })
        .then((text) => {
          const parsed = JSON.parse(text);
          if (parsed.status === 'SUCCESS') {
            dispatch(smsFetchingSet(false));
            dispatch(
              setInfo({
                type: 'success',
                message: `Template ${templateId} Deleted Successfully!`,
                delay: 3000,
              })
            );
            dispatch(templatesFetch());
            resolve(true);
          } else {
            dispatch(smsFetchingSet(false));
            const message = parsed.errorMsgs.join('\n');
            dispatch(
              setInfo({
                type: 'error',
                message,
              })
            );
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('Failure');
          }
          return undefined;
        });
    });
  };
};

export const actions = {
  templatesFetch,
  templateFetch,
  fieldsFetch,
  templateFormDataUpdate,
  templateSend,
  templateDelete,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SMS_TEMPLATES_SET]: (state, action) => {
    return Immutable.merge(state, { templates: action.payload });
  },
  [SMS_FIELDS_SET]: (state, action) => {
    return Immutable.merge(state, { fields: action.payload });
  },
  [SMS_TEMPLATE_SET]: (state, action) => {
    return Immutable.merge(state, { template: action.payload });
  },
  [SMS_RESPONSE_SET]: (state, action) => {
    return Immutable.merge(state, { response: action.payload });
  },
  [SMS_FORMDATA_UPDATE]: (state, action) => {
    return Immutable.setIn(
      state,
      [action.payload.location, 'formData'],
      action.payload.data
    );
  },
  [SMS_FETCHING_SET]: (state, action) => {
    return Immutable({ ...state, fetching: action.payload });
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { view: 'list' };
export default function smsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

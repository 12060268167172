import PropTypes from 'prop-types';
import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const ConfirmModal = ({
  filterDeleteConfirmModal,
  closeFilterDeleteConfirmModal,
  filterDelete,
}) => {
  return (
    <Dialog
      open={filterDeleteConfirmModal?.modalOpen}
      onClose={(event,reason)=>{
        if (reason === 'backdropClick' || reason === 'onEscapeKeyDown') {
          closeFilterDeleteConfirmModal(event, reason)
        }

      }}
    >
      <DialogTitle>
        {`Are you you sure you wish to delete 
        ${filterDeleteConfirmModal?.filterName}?`}
      </DialogTitle>
      <DialogActions>
        <Button
          onClick={() => {
            closeFilterDeleteConfirmModal();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            filterDelete(filterDeleteConfirmModal.filterId);
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.propTypes = {
  filterDeleteConfirmModal: PropTypes.objectOf(PropTypes.any).isRequired,
  filterDelete: PropTypes.func.isRequired,
  closeFilterDeleteConfirmModal: PropTypes.func.isRequired,
};

export default ConfirmModal;

import Immutable from 'seamless-immutable';
import { makeApi } from 'fni-schema';

import { informationSet } from '../../../../../components/store/shared';
import { incrementFetchCount, decrementFetchCount } from './touchpoint';

const setInfo = (error) => {
  if (typeof error === 'string') {
    return informationSet({ type: 'error', message: error });
  }
  return informationSet(error);
};

const baseURL = `fni-lenderportal-touchpoint/touchpoint/idology`;
export const api = makeApi({
  baseURL,
  withRequestInterceptor: false,
  withResponseInterceptor: false,
  responseType: 'text',
});

// ------------------------------------
// Constants
// ------------------------------------
const SET_IDOLOGY = 'SET_IDOLOGY';

// ------------------------------------
// Actions
// ------------------------------------
export const setIdology = (data, path) => {
  return {
    type: SET_IDOLOGY,
    payload: { data, path },
  };
};

// ------------------------------------
// Middleware
// ------------------------------------

const handleError =
  (error, defaultMessage = '') =>
  (dispatch) => {
    /* eslint-disable no-console */
    if (process.env.NODE_ENV === 'development') {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('response: ', error.response);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error('request: ', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('message: ', error.message);
      }
    }
    /* eslint-enable no-console */

    if (error?.response?.data) dispatch(setInfo(error.response.data));
    else if (error.message) dispatch(setInfo(error.message));
    else {
      dispatch(setInfo(defaultMessage));
    }
  };

const handleSuccess = (message) => (dispatch) => {
  dispatch(
    setInfo({
      type: 'success',
      message,
      delay: 3000,
    })
  );
};

export const getQuestions =
  ({ chnum = 1, test }) =>
  async (dispatch) => {
    dispatch(incrementFetchCount());

    const params = new URLSearchParams();
    params.append('chnum', chnum);
    if (test) params.append('test', test);

    try {
      const response = await api.get('questions', {
        params,
      });
      dispatch(setIdology(response?.data, ['questions']));
    } catch (e) {
      dispatch(handleError(e, 'Failed to fetch questions'));
    } finally {
      dispatch(decrementFetchCount());
    }
  };

export const submitStandardAnswers =
  ({ idNumber, chnum = 1, test }, data) =>
  async (dispatch) => {
    const params = new URLSearchParams();
    params.append('chnum', chnum);
    if (test) params.append('test', test);

    try {
      const response = await api.post(`answer/${idNumber}`, data, { params });
      dispatch(handleSuccess('Answers submitted succesfully'));
      if (response?.data?.challenge) {
        dispatch(setIdology(response?.data, ['questions']));
        return false;
      }
      return true;
    } catch (e) {
      dispatch(handleError(e, 'Failed to submit answers'));
      throw e;
    }
  };

export const submitChallengeAnswers =
  ({ idNumber, chnum = 1, test }, data) =>
  async (dispatch) => {
    const params = new URLSearchParams();
    params.append('chnum', chnum);
    if (test) params.append('test', test);

    try {
      await api.post(`challenge/${idNumber}`, data, { params });
      dispatch(handleSuccess('Additional answers submitted succesfully'));
      return true;
    } catch (e) {
      dispatch(handleError(e, 'Failed to submit additional answers'));
      throw e;
    }
  };

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_IDOLOGY]: (state, { payload: { data, path } }) => {
    return Immutable.setIn(state, path, data);
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};
export default function idologyReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

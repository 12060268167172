
   
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider as StoreProvider } from 'react-redux';
import store from './redux/store';
import { initializeApp, getApp } from 'firebase/app';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getAuth, connectAuthEmulator } from 'firebase/auth';

fetch('/__/firebase/init.json').then(async response => {
    initializeApp(await response.json());
    if (process.env.NODE_ENV === 'development') {
        const functions = getFunctions(getApp());
        const auth = getAuth(getApp());
        connectFunctionsEmulator(functions, 'localhost', '5001');
        connectAuthEmulator(auth, 'http://localhost:9099');
    }

    ReactDOM.render(
        <React.Fragment>
            <StoreProvider store={store}>
                    <App />
            </StoreProvider>
        </React.Fragment>,
        document.getElementById('root'),
    );
});

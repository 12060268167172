import Immutable from 'seamless-immutable';
import { makeApi } from 'fni-schema';

// ------------------------------------
// Constants
// ------------------------------------
const getBaseUrl = () => {
  if (window.location.pathname.includes('fni-lenderportal-workbench'))
    return 'fni-lenderportal-workbench/workbench/auto-collateral';

  if (window.location.pathname.includes('fni-lenderportal-search'))
    return 'fni-lenderportal-search/searching/auto-collateral';

  return '';
};

const getRefnum = () => {
  if (window.refnum) return window.refnum;
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('REFNUM');
};

export const api = makeApi({
  baseURL: getBaseUrl(),
});
export const hashmapApi = makeApi({
  baseURL: getBaseUrl(),
  withResponseInterceptor: false,
  withRequestInterceptor: false,
});

const SET_AUTO_COLLATERAL = 'SET_AUTO_COLLATERAL';
const SET_INFO = 'SET_INFO';

// ------------------------------------
// Actions
// ------------------------------------
export const setAutoCollateral = (data, path) => {
  return {
    type: SET_AUTO_COLLATERAL,
    payload: { data, path },
  };
};

export const setInfo = (message) => {
  return {
    type: SET_INFO,
    payload: message,
  };
};

export const clearFieldValuations = (type) => {
  return {
    type: SET_AUTO_COLLATERAL,
    payload: {
      data: {},
      path: [type, 'fields'],
    },
  };
};

export const setVehicleSource = (type, source) => {
  return {
    type: SET_AUTO_COLLATERAL,
    payload: {
      data: source,
      path: [type, 'source'],
    },
  };
};

export const setWaterfallVehicle = (type, key, value) => {
  return {
    type: SET_AUTO_COLLATERAL,
    payload: {
      data: value,
      path: ['waterfall', 'vehicle', type, key],
    },
  };
};

export const clearWaterfalls = (type) => (dispatch) => {
  dispatch(setAutoCollateral([], ['waterfall', type, 'years']));
  dispatch(setAutoCollateral([], ['waterfall', type, 'makes']));
  dispatch(setAutoCollateral([], ['waterfall', type, 'models']));
  dispatch(setAutoCollateral([], ['waterfall', type, 'trims']));
  dispatch(setAutoCollateral([], ['waterfall', type, 'equipment']));
  dispatch(setAutoCollateral([], ['waterfall', type, 'region']));
};

export const clearVehicleByVin = (type) => (dispatch) => {
  dispatch(setWaterfallVehicle(type, 'year', ''));
  dispatch(setWaterfallVehicle(type, 'make', ''));
  dispatch(setWaterfallVehicle(type, 'model', ''));
}

export const clearWaterfallByFieldType =
  (vehicleType, fieldType) => (dispatch) => {
    dispatch(setAutoCollateral([], ['waterfall', vehicleType, fieldType]));
  };

// ------------------------------------
// Middleware
// ------------------------------------
const handleError =
  (e, defaultMessage = '') =>
  (dispatch) => {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    if (e.message) dispatch(setInfo(e.message));
    else {
      dispatch(setInfo(defaultMessage));
    }
  };

const handleSuccess = (message) => (dispatch) => {
  dispatch(setInfo({ type: 'success', message, delay: 2000 }));
};

// Sale Tab
// --------------------

// GET /vehicles/sale/dropdown
export const getSaleVehicles = () => async (dispatch) => {
  try {
    const response = await api.get('/vehicles/sale/dropdown', {
      headers: {
        REFNUM: getRefnum(),
      },
    });
    dispatch(setAutoCollateral(response, ['sale', 'all']));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch sale vehicles'));
  }
};

// GET /vehicles/sale
export const getSaleAssetTypes = () => async (dispatch) => {
  try {
    const response = await api.get('/vehicles/sale', {
      headers: {
        REFNUM: getRefnum(),
      },
    });
    dispatch(setAutoCollateral(response, ['sale', 'assetTypes']));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch sale vehicle asset types'));
  }
};

// GET /vehicles/sale/{vehicleId}
export const getSaleAssetTypesById = (id) => async (dispatch) => {
  try {
    const response = await api.get(`/vehicles/sale/${id}`, {
      headers: {
        REFNUM: getRefnum(),
      },
    });

    const assetType = response?.formData?.VEHICLE_ASSET_TYPE;
    dispatch(setAutoCollateral(assetType, ['sale', 'assetType']));
    dispatch(setAutoCollateral(response, ['sale', 'assetTypes']));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch vehicle data'));
  }
};

// GET /vehicles/sale/asset/{assetType}
export const getSaleFieldsByAssetType = (assetType) => async (dispatch) => {
  try {
    const response = await api.get(`/vehicles/sale/asset/${assetType}`, {
      headers: {
        REFNUM: getRefnum(),
      },
    });

    dispatch(setAutoCollateral(assetType, ['sale', 'assetType']));
    dispatch(setAutoCollateral(response, ['sale', 'fields']));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch sale vehicle valuation fields'));
  }
};

// GET /vehicles/sale/{vehicleId}/asset
export const getSaleValuationsById = (id) => async (dispatch) => {
  try {
    const response = await api.get(`/vehicles/sale/${id}/asset`, {
      headers: {
        REFNUM: getRefnum(),
      },
    });
    dispatch(setAutoCollateral(response, ['sale', 'fields']));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch vehicle data'));
  }
};

// POST /vehicles/sale
export const postSaleVehicle = (data) => async (dispatch) => {
  try {
    const response = await api.post('/vehicles/sale', data, {
      headers: {
        REFNUM: getRefnum(),
      },
    });

    const { VEHICLE_MAKE, VEHICLE_MODEL } = data;
    dispatch(
      handleSuccess(`Successfully saved ${VEHICLE_MAKE} ${VEHICLE_MODEL}`)
    );
    const { VEHICLE_ID } = response.formData;
    return VEHICLE_ID;
  } catch (e) {
    dispatch(handleError(e, 'Failed to save vehicle'));
    throw e;
  }
};

// PUT /vehicles/sale/{vehicleId}
export const putSaleVehicle = (id, data) => async (dispatch) => {
  try {
    await api.put(`/vehicles/sale/${id}`, data, {
      headers: {
        REFNUM: getRefnum(),
      },
    });

    const { VEHICLE_MAKE, VEHICLE_MODEL } = data;
    dispatch(
      handleSuccess(`Successfully saved ${VEHICLE_MAKE} ${VEHICLE_MODEL}`)
    );
  } catch (e) {
    dispatch(handleError(e, 'Failed to save vehicle'));
    throw e;
  }
};

// Trade Tab
// --------------------

// GET /vehicles/trade/dropdown
export const getTradeVehicles = () => async (dispatch) => {
  try {
    const response = await api.get('/vehicles/trade/dropdown', {
      headers: {
        REFNUM: getRefnum(),
      },
    });
    dispatch(setAutoCollateral(response, ['trade', 'all']));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch trade vehicles'));
  }
};

// GET /vehicles/trade
export const getTradeAssetTypes = () => async (dispatch) => {
  try {
    const response = await api.get('/vehicles/trade', {
      headers: {
        REFNUM: getRefnum(),
      },
    });
    dispatch(setAutoCollateral(response, ['trade', 'assetTypes']));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch trade vehicle asset types'));
  }
};

// GET /vehicles/trade/{vehicleId}
export const getTradeAssetTypesById = (id) => async (dispatch) => {
  try {
    const response = await api.get(`/vehicles/trade/${id}`, {
      headers: {
        REFNUM: getRefnum(),
      },
    });
    const assetType = response?.formData?.VEHICLE_ASSET_TYPE;
    dispatch(setAutoCollateral(assetType, ['trade', 'assetType']));
    dispatch(setAutoCollateral(response, ['trade', 'assetTypes']));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch vehicle data'));
  }
};

// GET /vehicles/trade/asset/{assetType}
export const getTradeFieldsByAssetType = (assetType) => async (dispatch) => {
  try {
    const response = await api.get(`/vehicles/trade/asset/${assetType}`, {
      headers: {
        REFNUM: getRefnum(),
      },
    });
    dispatch(setAutoCollateral(assetType, ['trade', 'assetType']));
    dispatch(setAutoCollateral(response, ['trade', 'fields']));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch trade vehicle valuation fields'));
  }
};

// GET /vehicles/trade/{vehicleId}/asset
export const getTradeValuationsById = (id) => async (dispatch) => {
  try {
    const response = await api.get(`/vehicles/trade/${id}/asset`, {
      headers: {
        REFNUM: getRefnum(),
      },
    });
    dispatch(setAutoCollateral(response, ['trade', 'fields']));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch vehicle data'));
  }
};

// POST /vehicles/trade
export const postTradeVehicle = (data) => async (dispatch) => {
  try {
    const response = await api.post('/vehicles/trade', data, {
      headers: {
        REFNUM: getRefnum(),
      },
    });

    const { VEHICLE_MAKE, VEHICLE_MODEL } = data;
    dispatch(
      handleSuccess(`Successfully saved ${VEHICLE_MAKE} ${VEHICLE_MODEL}`)
    );
    const { VEHICLE_ID } = response.formData;
    return VEHICLE_ID;
  } catch (e) {
    dispatch(handleError(e, 'Failed to save vehicle'));
    throw e;
  }
};

// PUT /vehicles/trade/{vehicleId}
export const putTradeVehicle = (id, data) => async (dispatch) => {
  try {
    await api.put(`/vehicles/trade/${id}`, data, {
      headers: {
        REFNUM: getRefnum(),
      },
    });

    const { VEHICLE_MAKE, VEHICLE_MODEL } = data;
    dispatch(
      handleSuccess(`Successfully saved ${VEHICLE_MAKE} ${VEHICLE_MODEL}`)
    );
  } catch (e) {
    dispatch(handleError(e, 'Failed to save vehicle'));
    throw e;
  }
};

// Valuation Tab
// --------------------

// GET /vehicles/valuation
export const getValuations = () => async (dispatch) => {
  try {
    const response = await api.get('/vehicles/valuation', {
      headers: {
        REFNUM: getRefnum(),
      },
    });
    dispatch(setAutoCollateral(response, ['valuations']));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch valuations'));
  }
};

// Valuation Service APIs
// --------------------------

// GET /vehicles/valuation/services
export const getValuationServices = () => async (dispatch) => {
  try {
    const response = await hashmapApi.get('/vehicles/valuation/services', {
      headers: {
        REFNUM: getRefnum(),
      },
    });
    dispatch(setAutoCollateral(response?.data, ['valuation', 'services']));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch valuation services'));
  }
};

// GET /vehicles/valuation/preferred-service
export const getPreferredService = () => async (dispatch) => {
  try {
    const response = await hashmapApi.get(
      '/vehicles/valuation/preferred-service',
      {
        responseType: 'text',
        headers: {
          REFNUM: getRefnum(),
        },
      }
    );
    dispatch(
      setAutoCollateral(response?.data, ['valuation', 'preferredService'])
    );
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch preferred valuation service'));
  }
};

// GET /vehicles/valuation/{service}
export const getValuationSchemaByService =
  (service = '') =>
  async (dispatch) => {
    try {
      const response = await api.get(`/vehicles/valuation/${service}`, {
        headers: {
          REFNUM: getRefnum(),
        },
      });
      dispatch(setAutoCollateral(response, ['valuation', service]));
    } catch (e) {
      dispatch(handleError(e, `Failed to fetch valuation data for ${service}`));
    }
  };

// POST /vehicles/waterfall/{service}/lookUp/{vehicleId}
export const postVehicleLookup =
  (service, vehicleId, data) => async (dispatch) => {
    const [regionId] = data.VEHICLE_REGION.split('|');
    try {
      await api.post(
        `/vehicles/waterfall/${service}/lookUp/${vehicleId}`,
        {
          data,
          VEHICLE_REGION: regionId,
        },
        {
          headers: {
            REFNUM: getRefnum(),
          },
        }
      );

      dispatch(handleSuccess('Vehicle lookup succeeded'));
    } catch (e) {
      dispatch(handleError(e, 'Failed to lookup vehicle'));
      throw e;
    }
  };

// Waterfall APIs
// --------------------

function formatData(data = {}) {
  return Object.entries(data).map(([value, label]) => ({ value, label }));
}

function sortStrings(data = []) {
  return data.sort((a, b) => {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }

    return 0;
  });
}

const setWaterfallFetching = (type) => (dispatch) => {
  dispatch(setAutoCollateral(type, ['waterfall', 'fetching']));
};

// GET /vehicles/waterfall/{service}/regions
export const getWaterfallRegions =
  (type, service = 'jdpower') =>
  async (dispatch) => {
    dispatch(setWaterfallFetching(`${type}-region`));
    dispatch(setWaterfallVehicle(type, 'service', service));

    try {
      const response = await hashmapApi.get(
        `/vehicles/waterfall/${service}/regions`,
        {
          headers: {
            REFNUM: getRefnum(),
          },
        }
      );
      const data = formatData(response.data);

      dispatch(
        setAutoCollateral(sortStrings(data), ['waterfall', type, 'regions'])
      );
    } catch (e) {
      dispatch(handleError(e, 'Failed to fetch available vehicle regions'));
    } finally {
      dispatch(setWaterfallFetching(''));
    }
  };

// GET /vehicles/waterfall/{service}/years
export const getWaterfallYears =
  (type, service = 'jdpower') =>
  async (dispatch) => {
    dispatch(setWaterfallFetching(`${type}-year`));
    dispatch(setWaterfallVehicle(type, 'service', service));

    try {
      const response = await hashmapApi.get(
        `/vehicles/waterfall/${service}/years`,
        {
          headers: {
            REFNUM: getRefnum(),
          },
        }
      );
      const data = formatData(response?.data);
      // number descending
      const sorted = data.sort((a, b) => b.label - a.label);

      dispatch(setAutoCollateral(sorted, ['waterfall', type, 'years']));
    } catch (e) {
      dispatch(handleError(e, 'Failed to fetch available vehicle years'));
    } finally {
      dispatch(setWaterfallFetching(''));
    }
  };

// POST /vehicles/waterfall/{service}/make
export const getWaterfallMakes = (type, year) => async (dispatch, getState) => {
  dispatch(setWaterfallFetching(`${type}-make`));
  dispatch(setWaterfallVehicle(type, 'year', Number(year)));

  const { service } =
    getState()?.autoCollateral?.waterfall?.vehicle?.[type] ?? {};

  try {
    const response = await hashmapApi.post(
      `/vehicles/waterfall/${service}/make`,
      { year: Number(year) },
      {
        headers: {
          REFNUM: getRefnum(),
        },
      }
    );
    const data = formatData(response?.data);

    dispatch(
      setAutoCollateral(sortStrings(data), ['waterfall', type, 'makes'])
    );
  } catch (e) {
    dispatch(handleError(e, `Failed to fetch vehicle makes for ${year}`));
    dispatch(clearWaterfallByFieldType(type, 'makes'));
  } finally {
    dispatch(setWaterfallFetching(''));
  }
};

// POST /vehicles/waterfall/{service}/model
export const getWaterfallModels =
  (type, make = '') =>
  async (dispatch, getState) => {
    dispatch(setWaterfallFetching(`${type}-model`));
    dispatch(setWaterfallVehicle(type, 'make', make));

    const { service, year } =
      getState()?.autoCollateral?.waterfall?.vehicle?.[type] ?? {};

    try {
      // make can sometimes have a forward slash, which isn't encoded automatically
      const response = await hashmapApi.post(
        `/vehicles/waterfall/${service}/model`,
        { year: Number(year), make },
        {
          headers: {
            REFNUM: getRefnum(),
          },
        }
      );
      const data = formatData(response?.data);

      dispatch(
        setAutoCollateral(sortStrings(data), ['waterfall', type, 'models'])
      );
    } catch (e) {
      dispatch(
        handleError(e, `Failed to fetch vehicle models for ${year} ${make}`)
      );
      dispatch(clearWaterfallByFieldType(type, 'models'));
    } finally {
      dispatch(setWaterfallFetching(''));
    }
  };

// POST /vehicles/waterfall/{service}/trim
export const getWaterfallTrimsByModel =
  (type, model = '') =>
  async (dispatch, getState) => {
    dispatch(setWaterfallFetching(`${type}-trim`));
    dispatch(setWaterfallVehicle(type, 'model', model));

    const { service, year, make } =
      getState()?.autoCollateral?.waterfall?.vehicle?.[type] ?? {};

    try {
      // make can sometimes have a forward slash, which isn't encoded automatically
      const response = await hashmapApi.post(
        `/vehicles/waterfall/${service}/trim`,
        { year: Number(year), make, model },
        {
          headers: {
            REFNUM: getRefnum(),
          },
        }
      );

      const data = formatData(response?.data);

      dispatch(
        setAutoCollateral(data, ['waterfall', type, 'trims'])
      );
    } catch (e) {
      dispatch(
        handleError(
          e,
          `Failed to fetch vehicle trims for ${year} ${make} ${model}`
        )
      );
      dispatch(clearWaterfallByFieldType(type, 'trims'));
    } finally {
      dispatch(setWaterfallFetching(''));
    }
  };

// GET /vehicles/waterfall/{service}/vin/{vin}
export const getWaterfallTrimsByVin =
  (type, vin = '') =>
  async (dispatch, getState) => {
    dispatch(setWaterfallFetching(`${type}-trim`));
    dispatch(setWaterfallVehicle(type, 'vin', vin));

    const { service } =
      getState()?.autoCollateral?.waterfall?.vehicle?.[type] ?? {};

    try {
      // make can sometimes have a forward slash, which isn't encoded automatically
      const response = await hashmapApi.get(
        `/vehicles/waterfall/${service}/vin/${vin}`,
        {
          headers: {
            REFNUM: getRefnum(),
          },
        }
      );


      const data = formatData(response?.data?.trimList);

      dispatch(
        setAutoCollateral(sortStrings(data), ['waterfall', type, 'trims'])
      );
    } catch (e) {
      dispatch(handleError(e, `Failed to fetch vehicle trims for VIN: ${vin}`));
      dispatch(clearWaterfallByFieldType(type, 'trims'));
    } finally {
      dispatch(setWaterfallFetching(''));
    }
  };

export const getVehicleByVin =
  (type, vin = '') =>
  async (dispatch, getState) => {
    dispatch(setWaterfallVehicle(type, 'vin', vin));

    const { preferredService } =
      getState()?.autoCollateral?.valuation ?? {};

    try {
      // make can sometimes have a forward slash, which isn't encoded automatically
      const response = await hashmapApi.get(
        `/vehicles/waterfall/${preferredService ?? 'jdpower'}/vin/${vin}`,
        {
          headers: {
            REFNUM: getRefnum(),
          },
        }
      );
      
      const trimList = formatData(response?.data?.trimList);
      const { year, make, model } = response?.data;

      dispatch(
        setAutoCollateral(sortStrings(trimList), ['waterfall', type, 'trims'])
      );

      dispatch(setWaterfallVehicle(type, 'year', Number(year)));
      dispatch(setWaterfallVehicle(type, 'make', make));
      dispatch(setWaterfallVehicle(type, 'model', model));
    } catch (e) {
      dispatch(handleError(e, `Failed to fetch vehicle trims for VIN: ${vin}`));
      dispatch(clearWaterfallByFieldType(type, 'trims'));
    } finally {
      dispatch(setWaterfallFetching(''));
    }
  }

// POST /vehicles/waterfall/{service}/equipment
export const getWaterfallEquipment =
  (type, serviceVehicleId = '', region = '') =>
  async (dispatch, getState) => {
    dispatch(setWaterfallFetching(`${type}-equipment`));
    dispatch(setWaterfallVehicle(type, 'serviceVehicleId', serviceVehicleId));

    const { service } =
      getState()?.autoCollateral?.waterfall?.vehicle?.[type] ?? {};

    const [regionId] = region.split('|');

    try {
      const response = await hashmapApi.post(
        `/vehicles/waterfall/${service}/equipment`,
        { serviceVehicleId, region: regionId },
        {
          headers: {
            REFNUM: getRefnum(),
          },
        }
      );
      const data = formatData(response?.data);
      dispatch(
        setAutoCollateral(sortStrings(data), ['waterfall', type, 'equipment'])
      );
    } catch (e) {
      dispatch(handleError(e, 'Failed to fetch vehicle equipment for vehicle'));
      dispatch(clearWaterfallByFieldType(type, 'equipment'));
    } finally {
      dispatch(setWaterfallFetching(''));
    }
  };

// Permissions APIs
// --------------------

// GET /vehicles/permissions
export const getPermissions = () => async (dispatch) => {
  try {
    const response = await hashmapApi.get('/vehicles/permissions', {
      headers: {
        REFNUM: getRefnum(),
      },
    });
    dispatch(setAutoCollateral(response?.data, ['permissions']));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch valuations'));
  }
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_AUTO_COLLATERAL]: (state, { payload: { data, path } }) => {
    return Immutable.setIn(state, path, data);
  },
  [SET_INFO]: (state, { payload }) => {
    if (typeof payload === 'string')
      return Immutable.setIn(state, ['info'], {
        type: 'error',
        message: payload,
        delay: null,
      });
    return Immutable.setIn(state, ['info'], payload);
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};
export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

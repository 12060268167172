import Immutable from 'seamless-immutable';
import _get from 'lodash/get';
import { batch } from 'react-redux';
import { makeApi } from 'fni-schema';

import { setInfo } from '../../../store/coreLayout';

export const api = makeApi({
  baseURL: 'fni-lenderportal-admin/admin/adverse-actions/',
});

export const postApi = makeApi({
  baseURL: 'fni-lenderportal-admin/admin/adverse-actions/',
  withRequestInterceptor: false,
});

// ------------------------------------
// Constants
// ------------------------------------
const SET_ADV_ACTION = 'SET_ADV_ACTION';
const SET_ADV_ACTION_FETCHING = 'SET_ADV_ACTION_FETCHING';

// ------------------------------------
// Actions
// ------------------------------------
export const setAdvAction = (data, path) => {
  return {
    type: SET_ADV_ACTION,
    payload: { data, path },
  };
};

export const setFetching = (path, data) => {
  return {
    type: SET_ADV_ACTION_FETCHING,
    payload: { data, path },
  };
};

export const setError = (message) => setInfo({ type: 'error', message });

export const setSuccess = (message) =>
  setInfo({ type: 'success', message, delay: 2000 });
// ------------------------------------
// Middleware
// ------------------------------------
// GET /admin/adverse-actions/actions
export const getActions = (fetch = 'allActions') => async (
  dispatch,
  getState
) => {
  const dataPath = ['actions', 'allActions'];
  const fetchPath = ['actions', 'fetching', fetch];
  const fetching = _get(getState()?.advaction, fetchPath);

  if (!fetching) {
    try {
      dispatch(setFetching(fetchPath, true));
      const response = await api.get('actions');

      batch(() => {
        dispatch(setAdvAction(response, dataPath));
        dispatch(setFetching(fetchPath, false));
      });
    } catch (error) {
      if (error.message) {
        dispatch(setError(error.message));
      } else dispatch(setError('Failed to load actions.'));
      dispatch(setFetching(fetchPath, false));
    }
  }
};

// PUT /admin/adverse-actions/actions/{aaCode}/{appStatus}
export const saveAction = (data, initData) => async (dispatch, getState) => {
  const fetchPath = ['actions', 'fetching', 'save'];
  const fetching = _get(getState()?.advaction, fetchPath);
  if (!fetching) {
    try {
      dispatch(setFetching(fetchPath, true));
      const response = await api.put(
        `actions/${initData.AA_CODE}/${initData.APP_STATUS}`,
        data
      );
      batch(() => {
        dispatch(setSuccess('Save Successful'));
        const index = getState()?.advaction?.actions?.allActions?.formData?.ADVERSE_ACTIONS.findIndex(
          ({ AA_CODE, APP_STATUS }) =>
            AA_CODE === initData.AA_CODE && APP_STATUS === initData.APP_STATUS
        );

        const path = [
          'actions',
          'allActions',
          'formData',
          'ADVERSE_ACTIONS',
          index,
        ];
        dispatch(setAdvAction(response.formData, path));
        dispatch(setFetching(fetchPath, false));
      });
    } catch (error) {
      if (error.message) {
        dispatch(setError(error.message));
      } else {
        dispatch(setError('Failed to update Adverse Action reason.'));
      }
      dispatch(setFetching(fetchPath, false));
      throw error;
    }
  }
};

const formatCreateData = ({ AA_CODE, AA_DESC, AA_ACTIVE, APP_STATUS }) => {
  return [
    {
      fieldId: 'AA_CODE',
      fieldValue: AA_CODE,
    },
    {
      fieldId: 'AA_DESC',
      fieldValue: AA_DESC,
    },
    {
      fieldId: 'AA_ACTIVE',
      fieldValue: AA_ACTIVE,
    },
    {
      fieldId: 'APP_STATUS',
      fieldValue: APP_STATUS,
    },
  ];
};

// POST /admin/adverse-actions/actions
export const createAction = (data) => async (dispatch, getState) => {
  const fetchPath = ['actions', 'fetching', 'create'];
  const fetching = _get(getState()?.advaction, fetchPath);
  if (!fetching) {
    try {
      dispatch(setFetching(fetchPath, true));
      // This is necessary as requestInterceptor doesn't like true boolean
      await postApi.post('actions', formatCreateData(data));
      batch(() => {
        dispatch(setSuccess('Save Successful'));
      });
    } catch (error) {
      if (error.message) {
        dispatch(setError(error.message));
      } else {
        dispatch(setError('Failed to create Adverse Action.'));
      }
      throw error;
    } finally {
      dispatch(setFetching(fetchPath, false));
    }
  }
};

// PUT /admin/adverse-actions/actions/{aaCode}/{appStatus}/activate
// PUT /admin/adverse-actions/actions/{aaCode}/{appStatus}/deactivate
export const toggleActive = (isActive, initData) => async (
  dispatch,
  getState
) => {
  const action = isActive ? 'activate' : 'deactivate';
  try {
    const response = await api.put(
      `actions/${initData.AA_CODE}/${initData.APP_STATUS}/${action}`
    );

    const index = getState()?.advaction?.actions?.allActions?.formData?.ADVERSE_ACTIONS.findIndex(
      ({ AA_CODE, APP_STATUS }) =>
        AA_CODE === initData.AA_CODE && APP_STATUS === initData.APP_STATUS
    );

    const path = [
      'actions',
      'allActions',
      'formData',
      'ADVERSE_ACTIONS',
      index,
    ];
    dispatch(setAdvAction(response.formData, path));
  } catch (error) {
    if (error.message) {
      dispatch(setError(error.message));
    } else {
      dispatch(setError(`Failed to ${action} Adverse Action.`));
    }
    throw error;
  }
};

// GET /admin/adverse-actions/actions/{aaCode)/desc
export const fetchDescriptionInfo = (aaCode) => async (dispatch, getState) => {
  const fetchPath = ['actions', 'fetching', 'description'];
  const fetching = _get(getState()?.advaction, fetchPath);
  if (!fetching) {
    try {
      dispatch(setFetching(fetchPath, true));
      const response = await api.get(`actions/${aaCode}/desc`);
      return response?.formData;
    } catch (error) {
      if (error.message) {
        dispatch(setError(error.message));
      } else {
        dispatch(setError(`Failed to fetch description information.`));
      }
    } finally {
      dispatch(setFetching(fetchPath, false));
    }
  }
  return undefined;
};

// GET /admin/adverse-actions/actions/{aaCode}/{appStatus}/isUnique
export const checkUniqueness = (aaCode, appStatus) => async () => {
  try {
    await api.get(`actions/${aaCode}/${appStatus}/isUnique`);
  } catch (error) {
    if (error.message) {
      return error.message;
    }
    return 'Adverse Action is not unique.';
  }
  return false;
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_ADV_ACTION]: (state, { payload: { data, path } }) => {
    return Immutable.setIn(state, path, data);
  },
  [SET_ADV_ACTION_FETCHING]: (state, { payload: { data, path } }) => {
    return Immutable.setIn(state, path, data);
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};
export default function advActionReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

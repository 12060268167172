import Immutable from 'seamless-immutable';

export const TIMEOUT_SET = 'TIMEOUT_SET';
export const TIMEOUT_MODAL_SET = 'TIMEOUT_MODAL_SET';

/** ACTIONS **/

export const timeoutSet = (minutes) => {
  return {
    type: TIMEOUT_SET,
    payload: minutes,
  };
};

export const timeoutModalSet = (open) => {
  return {
    type: TIMEOUT_MODAL_SET,
    payload: open,
  };
};

/** MIDDLEWARE **/

export const redirect = (url) => {
  setTimeout(() => {
    window.location.replace(url);
  }, 1500);
};

let appTimeoutWarn = null;
let appTimeoutKick = null;

export const applyTimeout = (refresh = true, keepAlive, deleteSession, minutes = null, reducerName = 'timeout') => {
  return (dispatch, getState) => {
    if (minutes) {
      dispatch(timeoutSet(minutes));
    } else {
      minutes = getState().timeout.minutes;
    }
    clearTimeout(appTimeoutWarn);
    clearTimeout(appTimeoutKick);
    appTimeoutWarn = setTimeout(() => {
      dispatch(timeoutModalSet(true));
    }, (minutes - 1) * 60 * 1000);
    appTimeoutKick = setTimeout(() => {
      dispatch(deleteSession());
    }, (minutes) * 60 * 1000);
    if (refresh) {
      keepAlive();
    }
  };
};

const ACTION_HANDLERS = {
  [TIMEOUT_SET]: (state, action) => {
    return Immutable({ ...state, minutes: action.payload, modal: false });
  },
  [TIMEOUT_MODAL_SET]: (state, action) => {
    return Immutable({ ...state, modal: action.payload });
  },
};

/** INITIAL STATE **/
const initialState = Immutable({
  minutes: 10,
  modal: false,
});

export default function fniTimeoutReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

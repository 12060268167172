import { createSelector, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { utils } from '../utils';
import { getAuth, signOut } from 'firebase/auth';
import Tenant from './tenantModel';

// SELECTORS-------------------------------------------

const selectedTenantId = state => state.tenants.selectedTenantId;

const allTenants = createSelector(
    state => state.tenants.list,
    tenants => tenants?.map(tenant => new Tenant(tenant)), 
);

const allUsers = createSelector(
    state => state.tenants.users,
    users => users,
);

const allInvites = createSelector(
    state => state.tenants.invites,
    invites => invites,
);

const usersByTenant = createSelector(
    state => state.tenants.selectedTenantUsers,
    (users) => users?.length ? users : [],
);

const invitesByTenant = createSelector(
    state => state.tenants.selectedTenantInvites,
    (invites) => invites?.length ? invites : [],
);

const usersAndInvitesByTenant = createSelector(
    usersByTenant,
    invitesByTenant,
    (users, invites) => {
        const mappedUsers = users.map(user => ({ ...user, status: 'Confirmed' }));
        const mappedInvites = invites.map(invite => ({ ...invite, status: 'Invited' }));
        return [...mappedUsers, ...mappedInvites];
    },
);

const tenantsLoading = createSelector(
    state => state.tenants.isLoading,
    isLoading => Object.values(isLoading).some(val => val),
);

const selectedTenant = createSelector(
    allTenants,
    state => state.tenants.selectedTenantId,
    (tenants, tenantId) => tenants.find(tenant => tenant.id === tenantId),
);

const selectedUser = createSelector(
    state => state.tenants.selectedUserDetail,
    (user) => user, 
);

const selectedInviteId = createSelector(
    state => state.tenants.selectedInviteId,
    inviteId => inviteId,
);

const isTenantStatusDialogOpen = createSelector(
    state => state.tenants.isTenantStatusDialogOpen,
    isOpen => isOpen,
);

const isUserStatusDialogOpen = createSelector(
    state => state.tenants.isUserStatusDialogOpen,
    isOpen => isOpen,
);

const selectedTenantRoles = createSelector(
    state => state.tenants.rolesByTenantId,
    selectedTenantId,
    (roles, tenantId) => roles && tenantId ? roles[tenantId] : [],
);

const selectedTenantDealers = createSelector(
    state => state.tenants.dealersByTenantId,
    selectedTenantId,
    (dealers, tenantId) => dealers && tenantId ? dealers[tenantId] : [],
);

const selectedTenantFacilities = createSelector(
    state => state.tenants.facilitiesByTenantId,
    selectedTenantId,
    (facilities, tenantId) => facilities && tenantId ? facilities[tenantId] : [],
);

const rolesByTenantId = createSelector(
    state => state.tenants.rolesByTenantId,
    roles => roles,
);

const dealersByTenantId = createSelector(
    state => state.tenants.dealersByTenantId,
    dealers => dealers,
);

const facilitiesByTenantId = createSelector(
    state => state.tenants.facilitiesByTenantId,
    facilities => facilities,
);

const tenantAlert = createSelector(
    state => state.tenants.alert,
    alert => alert,
);

const selectedTenantUser = createSelector(
    state => state.tenants.selectedUserDetail,
    user => user,
);

export const tenantSelectors = {
    allTenants,
    allUsers,
    allInvites,
    usersByTenant,
    invitesByTenant,
    tenantsLoading,
    selectedTenant,
    selectedUser,
    selectedInviteId,
    rolesByTenantId,
    dealersByTenantId,
    facilitiesByTenantId,
    isTenantStatusDialogOpen,
    isUserStatusDialogOpen,
    tenantAlert,
    selectedTenantRoles, 
    selectedTenantDealers, 
    selectedTenantFacilities,
    selectedTenantUser,
    usersAndInvitesByTenant,
};

// ACTIONS -----------------------------------

const addTenant = createAsyncThunk(
    'tenants/addTenant',
    async (newTenant) => {
        const addTenantFunction = httpsCallable(getFunctions(), 'tenants-addTenant');
        return await addTenantFunction(newTenant);
    },
);

const getTenants = createAsyncThunk(
    'tenants/getTenants',
    async () => {
        const getTenantsFunction = httpsCallable(getFunctions(), 'tenants-getTenants');
        return await getTenantsFunction();
    },
);

const updateTenant = createAsyncThunk(
    'tenants/updateTenant',
    async (updatedTenant) => {
        const updateTenantFunction = httpsCallable(getFunctions(), 'tenants-updateTenant');
        return await updateTenantFunction(updatedTenant);
    },
);

const disableTenant = createAsyncThunk(
    'tenants/disableTenant',
    async (tenantId) => {
        const disableTenantFunction = httpsCallable(getFunctions(), 'tenants-disableTenant');
        return await disableTenantFunction(tenantId);
    },
);

const enableTenant = createAsyncThunk(
    'tenants/enableTenant',
    async (tenantId) => {
        const enableTenantFunction = httpsCallable(getFunctions(), 'tenants-enableTenant');
        return await enableTenantFunction(tenantId);
    },
);

const updateUser = createAsyncThunk(
    'tenants/updateUser',
    async (updatedUser) => {
        const updateUserFunction = httpsCallable(getFunctions(), 'users-updateUserMultiTenant');
        return await updateUserFunction(updatedUser);
    },
);

const disableUser = createAsyncThunk(
    'tenants/disableUser',
    async (userId) => {
        const functions = getFunctions();
        const disableUserFunction = httpsCallable(functions, 'users-disableUser');
        return await disableUserFunction(userId);
    },
);

const enableUser = createAsyncThunk(
    'tenants/enableUser',
    async (userId) => {
        const functions = getFunctions();
        const enableUserFunction = httpsCallable(functions, 'users-enableUser');
        return await enableUserFunction(userId);
    },
);

const getTenantRoleData = createAsyncThunk(
    'tenants/getTenantRoleData',
    async (tenantId) => {
        const getTenantRoleDataFunction = httpsCallable(getFunctions(), 'tenants-getTenantRoleData');
        return await getTenantRoleDataFunction(tenantId);
    },
);

const getUsers = createAsyncThunk(
    'tenants/getUsers',
    async () => {
        const getUsersFunction = httpsCallable(getFunctions(), 'users-getUsers');
        return await getUsersFunction();
    },
);

const getInvites = createAsyncThunk(
    'tenants/getInvites',
    async () => {
        const getInvitesFunction = httpsCallable(getFunctions(), 'users-getInvites');
        return await getInvitesFunction();
    },
);

const getUsersByTenantId = createAsyncThunk(
    'tenants/getUsersByTenantId',
    async (tenantId) => {
        const getUsersByTenantIdFunction = httpsCallable(getFunctions(), 'users-getUsersByTenantId');
        return await getUsersByTenantIdFunction(tenantId);
    },
);

const getInvitesByTenantId = createAsyncThunk(
    'tenants/getInvitesByTenantId',
    async (tenantId) => {
        const getInvitesByTenantIdFunction = httpsCallable(getFunctions(), 'users-getInvitesByTenantId');
        return await getInvitesByTenantIdFunction(tenantId);
    },
);

const inviteUser = createAsyncThunk(
    'tenants/inviteUser',
    async (newUser) => {
        const functions = getFunctions();
        const inviteUserFunction = httpsCallable(functions, 'users-inviteUser');
        return await inviteUserFunction(newUser);
    },
);

const getUserDetail = createAsyncThunk(
    'tenants/getUserDetail',
    async (userId) => {
        const getUserDetail = httpsCallable(getFunctions(), 'users-getUserDetailById');
        return await getUserDetail(userId);
    },
);

const resetMFAForUser = createAsyncThunk(
    'tenants/resetMFAEnrollment',
    async (userId) => {
        const functions = getFunctions();
        const resetMFAForUserFunction = httpsCallable(functions, 'auth-resetMFAEnrollment');
        return await resetMFAForUserFunction({ userId });
    },
);

const signOutUser = createAsyncThunk(
    'tenants/signOutUser',
    async (_, { dispatch, getState }) => {
        const code = getState().core?.tenantCode;
        await signOut(getAuth());
        dispatch(tenantActions.logout());
        localStorage.removeItem('code');
        return code;
    },
);

const removeInvite = createAsyncThunk(
    'tenants/removeInvite',
    async (inviteId) => {
        const functions = getFunctions();
        const removeInviteByIdFunction = httpsCallable(functions, 'users-removeInviteById');
        return await removeInviteByIdFunction(inviteId);
    },
);

const removeInviteByTenant = createAsyncThunk(
    'tenants/removeInviteByTenant',
    async (request) => {
        const functions = getFunctions();
        const removeInviteByIdFunction = httpsCallable(functions, 'users-removeInviteForTenantById');
        return await removeInviteByIdFunction(request);
    },
);

// REDUCER SLICE --------------------------------

const { 
    getUpdatedList,
    trimActionType, 
    setAlertError,
    setAlertSuccess,
    alertInitialState,
    removeItemFromListById,
} = utils;

const initialState = {
    list: [],
    users: [],
    invites: [],
    selectedTenantId: null,
    selectedTenantUsers: [],
    selectedTenantInvites: [],
    selectedUserId: null,
    selectedUserDetail: null,
    selectedInviteId: null,
    rolesByTenantId: {},
    dealersByTenantId: {},
    facilitiesByTenantId: {},
    isLoading: {},
    isTenantStatusDialogOpen: false,
    isUserStatusDialogOpen: false,
    alert: alertInitialState,
};

export const TenantSlice = createSlice({
    name: 'tenants',
    initialState,
    reducers: {
        selectTenant(state, action) {
            state.selectedTenantId = action.payload;
        },
        selectUser(state, action) {
            state.selectedUserId = action.payload;
        },
        selectInvite(state, action) {
            state.selectedInviteId = action.payload;
        },
        toggleTenantStatusDialogOpen(state) {
            state.isTenantStatusDialogOpen = !state.isTenantStatusDialogOpen;
        },
        toggleUserStatusDialogOpen(state) {
            state.isUserStatusDialogOpen = !state.isUserStatusDialogOpen;
        },
        hideAlert(state) {
            state.alert.open = false;
        },
        resetSlice() {
            return initialState;
        },
        logout() {
            //check in store.js to see state reset for logout
        },
    },
    extraReducers: {
        [inviteUser.fulfilled]: (state, action) => {
            state.selectedTenantInvites = [...state.selectedTenantInvites, action.payload.data];
            state.isLoading[trimActionType(action.type)] = false;
            setAlertSuccess(state, 'User invited!');
        },
        [inviteUser.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },        
        [inviteUser.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
            setAlertError(state, action.error);
        },
        [addTenant.fulfilled]: (state, action) => {
            state.list = [...state.list, action.payload.data];
            state.isLoading[trimActionType(action.type)] = false;
            setAlertSuccess(state, 'New tenant created!');
        },
        [addTenant.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },        
        [addTenant.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
            setAlertError(state, action.error);
        },
        [disableTenant.fulfilled]: (state, action) => {
            state.list = getUpdatedList(state.list, action.payload.data);
            state.isLoading[trimActionType(action.type)] = false;
            setAlertSuccess(state, 'The tenant has been disabled!');
        },
        [disableTenant.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },        
        [disableTenant.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [enableTenant.fulfilled]: (state, action) => {
            state.list = getUpdatedList(state.list, action.payload.data);
            state.isLoading[trimActionType(action.type)] = false;
            setAlertSuccess(state, 'The tenant has been enabled!');
        },
        [enableTenant.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },        
        [enableTenant.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [disableUser.fulfilled]: (state, action) => {
            state.selectedTenantUsers = getUpdatedList(state.selectedTenantUsers, action.payload.data);
            state.users = getUpdatedList(state.users, action.payload.data);
            state.selectedUserDetail = action.payload.data;
            state.isLoading[trimActionType(action.type)] = false;
            setAlertSuccess(state, 'The user has been disabled!');
        },
        [disableUser.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },        
        [disableUser.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [enableUser.fulfilled]: (state, action) => {
            state.selectedTenantUsers = getUpdatedList(state.selectedTenantUsers, action.payload.data);
            state.users = getUpdatedList(state.users, action.payload.data);
            state.selectedUserDetail = action.payload.data;
            state.isLoading[trimActionType(action.type)] = false;
            setAlertSuccess(state, 'The user has been enabled!');
        },
        [enableUser.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },        
        [enableUser.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getTenantRoleData.fulfilled]: (state, action) => {
            const { tenantId, roles, dealerGroups, dealerLocations, facilityGroups, facilityLocations } = action.payload.data;

            state.rolesByTenantId[tenantId] = roles;

            state.dealersByTenantId[tenantId] = {
                dealerGroups,
                dealerLocations,
            };

            if(facilityGroups && facilityLocations) {
                state.facilitiesByTenantId[tenantId] = {
                    facilityGroups,
                    facilityLocations,
                };
            }

            state.isLoading[trimActionType(action.type)] = false;
        },
        [getTenantRoleData.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [getTenantRoleData.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getTenants.fulfilled]: (state, action) => {
            state.list = action.payload.data;
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getTenants.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [getTenants.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getInvites.fulfilled]: (state, action) => {
            state.invites = action.payload.data;
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getInvites.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [getInvites.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getInvitesByTenantId.fulfilled]: (state, action) => {
            state.selectedTenantInvites = action.payload.data;
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getInvitesByTenantId.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [getInvitesByTenantId.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getUserDetail.fulfilled]: (state, action) => {
            state.selectedUserDetail = action.payload.data;
            state.selectedTenantUsers = getUpdatedList(state.selectedTenantUsers, action.payload.data);
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getUserDetail.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [getUserDetail.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getUsers.fulfilled]: (state, action) => {
            state.users = action.payload.data;
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getUsers.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [getUsers.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getUsersByTenantId.fulfilled]: (state, action) => {
            state.selectedTenantUsers = action.payload.data;
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getUsersByTenantId.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [getUsersByTenantId.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [updateTenant.fulfilled]: (state, action) => {
            state.list = getUpdatedList(state.list, action.payload.data);
            state.isLoading[trimActionType(action.type)] = false;
            setAlertSuccess(state, 'The tenant has been updated!');
        },
        [updateTenant.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },        
        [updateTenant.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
            setAlertError(state, action.error);
        },
        [updateUser.fulfilled]: (state, action) => {
            state.users = getUpdatedList(state.users, action.payload.data);
            state.isLoading[trimActionType(action.type)] = false;
            setAlertSuccess(state, 'The user has been updated!');
        },
        [updateUser.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },        
        [updateUser.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [removeInvite.fulfilled]: (state, action) => {
            state.invites = removeItemFromListById(state.invites, action.payload.data.inviteId);
            state.isLoading[trimActionType(action.type)] = false;
            setAlertSuccess(state, 'Invite removed!');
        },
        [removeInvite.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },        
        [removeInvite.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
            setAlertError(state, action.error);
        },
        [removeInviteByTenant.fulfilled]: (state, action) => {
            state.selectedTenantInvites = removeItemFromListById(state.selectedTenantInvites, action.payload.data.inviteId);
            state.isLoading[trimActionType(action.type)] = false;
            setAlertSuccess(state, 'Invite removed!');
        },
        [removeInviteByTenant.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },        
        [removeInviteByTenant.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
            setAlertError(state, action.error);
        },
        [resetMFAForUser.fulfilled]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
            setAlertSuccess(state, 'MFA reset success!');
        },
        [resetMFAForUser.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },        
        [resetMFAForUser.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
            setAlertError(state, action.error);
        },
    },
});

export const tenantActions = {
    ...TenantSlice.actions,
    addTenant,
    getTenants,
    updateTenant,
    disableTenant,
    enableTenant,
    disableUser,
    enableUser,
    getUsers,
    getUserDetail,
    getUsersByTenantId,
    getInvites,
    getInvitesByTenantId,
    getTenantRoleData,
    inviteUser,
    signOutUser,
    updateUser,
    removeInvite,
    removeInviteByTenant,
    resetMFAForUser
};

export default TenantSlice.reducer;

/* eslint-disable babel/camelcase */
/* eslint-disable import/no-cycle */
import React from 'react';
import moment from 'moment';
import Cookies from 'js-cookie';
import { formDataSubmit } from '../store/formData';
import { oldAppSet } from '../../store/shared';

export const SECTION_HEADER_STYLE = {
  marginLeft: '-22px',
  marginRight: '-22px',
};

const BTErrors = (formData, errors) => {
  return errors;
};

export const ROUTE_IMPORTS = [
  { name: 'Applicant', import: 'Applicant', path: 'applicant', submit: true },
  {
    name: 'Co-Applicant',
    import: 'CoApplicant',
    path: 'coapplicant',
    submit: true,
  },
  {
    name: 'Subject Property',
    import: 'SubjectProperty',
    path: 'subjectproperty',
    submit: true,
  },
  { name: 'Bureau', import: 'Bureau', path: 'bureau', submit: true },
  {
    name: 'Balance Transfer',
    import: 'BalanceTransfer',
    path: 'balancetransfer',
    submit: true,
    customError: BTErrors,
  },
  {
    name: 'Deal Structure',
    import: 'DealStructureAuto',
    path: 'dealstructureauto',
    submit: true,
  },
  { name: 'Notes', import: 'Notes', path: 'notes' },
  {
    name: 'App History',
    import: 'AppHistory',
    path: 'app_hist',
    autoRefresh: true,
  },
  {
    name: 'Strat History',
    import: 'StratHistory',
    path: 'strat_hist',
    autoRefresh: true,
  },
  { name: 'Decision History', import: 'DecisionHistory', path: 'dec_hist' },
  {
    name: 'Document Generation',
    import: 'DocumentGeneration',
    path: 'gen_doc',
  },
  {
    name: 'Debt Profile',
    import: 'DebtProfile',
    path: 'debt_pad',
    submit: true,
  },
  {
    name: 'Application Info',
    import: 'SchoolInfo',
    path: 'school',
    submit: true,
  },
  {
    name: 'Document Upload',
    import: 'DocUpload',
    path: 'doc_upload',
    submit: true,
  },
  {
    name: 'Auto Collateral',
    import: 'AutoCollateral',
    path: 'auto_collateral',
    submit: true,
  },
  {
    name: 'Small Business',
    import: 'SmallBusiness',
    path: 'sm_bus_info',
    submit: true,
  },
  { name: 'Reference', import: 'Reference', path: 'reference', submit: true },
  {
    name: 'Conversations',
    import: 'Conversations',
    path: 'convo',
    submit: false,
  },
  {
    name: 'Product',
    import: 'Product',
    path: 'product',
    submit: true,
  },
];

export const CUSTOM_ROUTE_IMPORTS = [
  {
    name: 'FS Product',
    import: 'ProductFS',
    path: 'product_fs',
    submit: true,
  },
  {
    name: 'TF Product',
    import: 'ProductTF',
    path: 'product_tf',
    submit: true,
  },
  {
    name: 'FS Funding',
    import: 'fundingFS',
    path: 'funding_fs',
    submit: true,
  },
  {
    name: 'TF Funding',
    import: 'fundingTF',
    path: 'funding_tf',
    submit: true,
  },
  {
    name: 'Product Select',
    import: 'productSelect',
    path: 'productselection',
    submit: true,
  },
  {
    name: 'Funding',
    import: 'funding',
    path: 'funding',
    submit: true,
  },
];

export const getRouteImport = (name) => {
  const route = ROUTE_IMPORTS.find((obj) => obj.name === name);
  if (route) {
    return route.import || false;
  }
  return false;
};

export const vehicleTypes = ['SALE', 'TRADE'];

export const submitAllData = (code, cb) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(formDataSubmit(code))
        .then(() => {
          if (typeof cb === 'function') {
            // eslint-disable-next-line promise/no-callback-in-promise
            cb();
          }
          resolve();
          return undefined;
        })
        .catch((e) => {
          throw e;
        });
    });
  };
};

export const getRouteImportByTab = (tab) => {
  switch (tab.tabType) {
    case 'STIPS':
      return 'Stips';
    case 'SERVICE':
      return 'ThirdParty';
    case 'COMMENT':
      return 'DtCmts';
    default: {
      const route = ROUTE_IMPORTS.find((obj) => obj.path === tab.tabRoute);
      if (route) {
        return route.import || false;
      }
      return false;
    }
  }
};

export const getRoute = (tab) => {
  const path = tab.tabRoute;
  switch (tab.tabType) {
    case 'STIPS':
      return { import: 'Stips' };
    case 'SERVICE':
      return { import: 'ThirdParty' };
    case 'COMMENT':
      return { import: 'DtCmts' };
    default:
  }
  return ROUTE_IMPORTS.find((t) => path === t.path);
};

export const BASE_PATH = '/fni-lenderportal-workbench/';
export const API_PATH = `${BASE_PATH}workbench/`;

export const formatStip = (stip) => {
  const ret = {};
  ret.STIP_ID = stip.STIP_ID;
  ret.STIP_STATUS = stip.STIP_STATUS;
  ret.STIP_DESC = stip.STIP_DESC;
  const files = stip.DOC_FILES.map((f) => {
    return {
      DOC_SEQ_NO: f.DOC_SEQ_NO,
      FILE_VERIFIED: f.FILE_VERIFIED,
    };
  });
  ret.DOC_FILES = files;
  return ret;
};
export const translateSubmitFormData = (data, path, tabType) => {
  const _data = { ...data };
  if (path === 'bureau') {
    _data.formData = {
      app_bureau_block: {
        APP_DCSN_BUREAU: _data?.formData?.app_bureau_block?.APP_DCSN_BUREAU,
        APP_DCSN_BUR_NUM: _data?.formData?.app_bureau_block?.APP_DCSN_BUR_NUM,
      },
      co_bureau_block: {
        CO_DCSN_BUREAU: _data?.formData?.co_bureau_block?.CO_DCSN_BUREAU,
        CO_DCSN_BUR_NUM: _data?.formData?.co_bureau_block?.CO_DCSN_BUR_NUM,
      },
      overflow: _data?.formData?.overflow,
    };
  } else if (tabType === 'STIPS') {
    const newData = {};
    newData.STIPS = _data.formData.STIPS.map((stip) => {
      return formatStip(stip);
    });
    _data.formData = newData;
  } else if (path === 'dealstructureauto') {
    const keys = Object.keys(_data.formData || {});
    keys.forEach((val) => {
      if (_data.schema.properties[val].readOnly) {
        delete _data.formData[val];
      } else if (
        _data.formData[val] !== undefined &&
        !Number.isNaN(parseFloat(_data.formData[val]))
      ) {
        _data.formData[val] = `${_data.formData[val]}`;
      }
    });
  } else if (path === 'auto_collateral') {
    const history = _data.formData.VALUATION_HIST.map((v) => {
      return {
        SEQ_NO: v.SEQ_NO,
        STATUS: v.STATUS,
        VEHICLE_TYPE: v.VEHICLE_TYPE,
      };
    });
    _data.formData = { VALUATION_HIST: history };
    _data.schema.properties = {
      VALUATION_HIST: _data.schema.properties.VALUATION_HIST,
    };
  }
  return _data;
};

export const apiFetch = (url, options = {}, api = API_PATH) => {
  if (!window.XSRF) {
    window.XSRF = Cookies.get('XSRF-TOKEN');
  }
  const { XSRF } = window;
  Cookies.get('XSRF-TOKEN');
  if (window.store && XSRF !== Cookies.get('XSRF-TOKEN')) {
    window.store.dispatch(oldAppSet(true));
  }
  const defaultOptions = {
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': XSRF,
    },
  };
  if (window.refnum) {
    defaultOptions.headers.REFNUM = window.refnum;
  }
  return fetch(api + url, { ...defaultOptions, ...options });
};

export const translateToBackend = (data, path, dates) => {
  const keys = Object.keys(data);
  const values = [];
  keys.forEach((k) => {
    let val = data[k] || '';
    if (dates.includes(k)) {
      const regex = /(\d{2})(\d{2})(\d{4})/;
      const arr = regex.exec(val);
      if (arr) {
        val = `${arr[3]}-${arr[1]}-${arr[2]}`;
      }
    }
    values.push({
      fieldId: k,
      fieldValue: val,
    });
  });
  return {
    tabRoute: path,
    formData: values,
  };
};

export const translateData = (field) => {
  const _field = { ...field };
  if (_field.type === 'array') {
    const items = {};
    _field.items.properties.forEach((item) => {
      items[item.key] = translateData(item);
      delete items[item.key].key;
    });
    _field.items.properties = items;
  } else if (_field.type === 'object') {
    const properties = {};
    _field.properties.forEach((f) => {
      properties[f.key] = f;
      delete properties[f.key].key;
    });
    _field.properties = properties;
  }
  return _field;
};

export const translateFromBackend = (data) => {
  const _data = { ...data };
  try {
    if (_data.tabSchema) {
      const properties = {};
      _data.tabSchema.properties.forEach((field) => {
        properties[field.key] = translateData(field);
        delete properties[field.key].key;
      });
      _data.tabSchema.properties = properties;
    }
    const formData = {};
    _data.formData.forEach((field) => {
      const _field = { ...field };
      if (_field.fieldValues) {
        _field.fieldValues = _field.fieldValues.map((items) => {
          const fv = {};
          items.forEach((item) => {
            if (item.fieldValue || item.fieldValue === '') {
              fv[item.fieldId] = item.fieldValue || undefined;
            } else if (item.fieldValues) {
              fv[item.fieldId] = item.fieldValues.map((fld) => {
                const obj = {};
                fld.forEach((f) => {
                  obj[f.fieldId] = f.fieldValue || undefined;
                });
                return obj;
              });
            }
          });
          return fv;
        });
      }
      let val = _field.fieldValue || _field.fieldValues;
      val = val === '' ? undefined : val;
      formData[_field.fieldId] = val;
    });
    _data.formData = formData;
    return _data;
  } catch (e) {
    return _data;
  }
};

export const ORIGIN = `${window.location.origin}/`;

export const getSuccessMessage = (actionCode) => {
  let message;
  switch (actionCode) {
    case 'SAVE':
      message = 'The application has been successfully saved.';
      break;
    case 'REACTIVATE':
      message = 'The application has been successfully reactivated.';
      break;
    case 'UNASSIGN':
      message = 'The application has been successfully unassigned.';
      break;
    case 'APP_EX':
      message = 'Your bureau report has been successfully pulled.';
      break;
    case 'APP_TU':
      message = 'Your bureau report has been successfully pulled.';
      break;
    case 'APP_EQ':
      message = 'Your bureau report has been successfully pulled.';
      break;
    case 'CO_EX':
      message = 'Your bureau report has been successfully pulled.';
      break;
    case 'CO_TU':
      message = 'Your bureau report has been successfully pulled.';
      break;
    case 'CO_EQ':
      message = 'Your bureau report has been successfully pulled.';
      break;
    case 'CLOSE':
      message = 'This app has been successfully closed.';
      break;
    default:
      message = 'Your decision has been successfully submitted.';
  }

  return message;
};

export const getErrorMessage = (actionCode) => {
  let message;
  switch (actionCode) {
    case 'SAVE':
      message = 'There was an error saving the application.';
      break;
    case 'REACTIVATE':
      message = 'There was an error reactivating the application.';
      break;
    case 'UNASSIGN':
      message = 'There was an error unassigning the application.';
      break;
    case 'APP_EX':
      message = 'There was an error pulling the Experian bureau report.';
      break;
    case 'APP_TU':
      message = 'There was an error pulling the Trans Union bureau report.';
      break;
    case 'APP_EQ':
      message = 'There was an error pulling the Equifax bureau report.';
      break;
    case 'CO_EX':
      message = 'There was an error pulling the Experian bureau report';
      break;
    case 'CO_TU':
      message = 'There was an error pulling the Trans Union bureau report.';
      break;
    case 'CO_EQ':
      message = 'There was an error pulling the Equifax bureau report.';
      break;
    case 'CLOSE':
      message = 'There was an error closing the application.';
      break;
    default:
      message = 'There was an error submitting your application.';
  }

  return message;
};

export const DragItemTypes = {
  DOCUMENT: 'document',
};

export const getVirusScanPrepend = (status) => {
  if (status === 'VPS') {
    return (
      <span>
        <em className="text-warning">Pending virus scan</em> -
      </span>
    );
  }
  if (status === 'FVS') {
    return (
      <span>
        <em className="text-danger">Failed virus scan</em> -
      </span>
    );
  }

  return null;
};

export const formatDate = (date, showTime = true) => {
  const str = `M/D/YYYY${showTime ? ' h:mm a' : ''}`;
  if (date) {
    return moment(date).format(str);
  }
  return null;
};

export const filterHistory = (
  history,
  typeFilter,
  filter,
  dateFilter,
  histTypeKey
) => {
  if (!typeFilter && !filter && !dateFilter) {
    return history;
  }
  let typeFilteredHistory = [];
  if (typeFilter) {
    history.forEach((h) => {
      if (h[histTypeKey] === typeFilter) {
        typeFilteredHistory.push(h);
      }
    });
  } else {
    typeFilteredHistory = history;
  }
  let dateFilteredHistory = [];
  if (dateFilter) {
    typeFilteredHistory.forEach((h) => {
      if (h.APP_HIST_TIMESTAMP.includes(dateFilter)) {
        dateFilteredHistory.push(h);
      }
    });
  } else {
    dateFilteredHistory = typeFilteredHistory;
  }
  let filteredHistory;
  if (filter) {
    filteredHistory = dateFilteredHistory.filter((row) => {
      const keys = Object.keys(row);
      let keep = false;
      keys.forEach((key) => {
        if (row[key] && row[key].toLowerCase().includes(filter.toLowerCase())) {
          keep = true;
        }
      });
      return keep;
    });
  } else {
    filteredHistory = dateFilteredHistory;
  }
  return filteredHistory;
};

export const stratFilterHistory = (
  history,
  typeFilter,
  filter,
  dateFilter,
  histTypeKey
) => {
  if (!typeFilter && !filter && !dateFilter) {
    return history;
  }
  let typeFilteredHistory = [];
  if (typeFilter) {
    history.forEach((h) => {
      if (h[histTypeKey] === typeFilter) {
        typeFilteredHistory.push(h);
      }
    });
  } else {
    typeFilteredHistory = history;
  }
  let dateFilteredHistory = [];
  if (dateFilter) {
    typeFilteredHistory.forEach((h) => {
      if (h.STRAT_HIST_TIMESTAMP.includes(dateFilter)) {
        dateFilteredHistory.push(h);
      }
    });
  } else {
    dateFilteredHistory = typeFilteredHistory;
  }
  let filteredHistory;
  if (filter) {
    filteredHistory = dateFilteredHistory.filter((row) => {
      const keys = Object.keys(row);
      let keep = false;
      keys.forEach((key) => {
        if (row[key] && row[key].toLowerCase().includes(filter.toLowerCase())) {
          keep = true;
        }
      });
      return keep;
    });
  } else {
    filteredHistory = dateFilteredHistory;
  }
  return filteredHistory;
};

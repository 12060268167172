import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import styles from './QueueItem.module.scss';

const QueueItem = ({ dataTableQueueId, queue, groupID, queueDataFetch }) => {
  const history = useNavigate();
  const selected = dataTableQueueId === queue.queueId;
  return (
    <li
      className={classNames(styles.queueListItem, {
        [styles.selected]: selected,
      })}
    >
      <Grid container justifyContent="space-between">
        <Grid item xs="auto" className={styles.nameCol}>
          <div
            style={{ fontSize: 14 }}
            role="button"
            tabIndex={0}
            className={classNames(styles.queueName, {
              [styles.selectable]: queue.browseFlag,
            })}
            onClick={() => {
              if (queue.browseFlag) {
                queueDataFetch(groupID, queue.queueId,(view = "DATA")=>{
                  history(`/fni-lenderportal-search/${view}/SEARCH`)
                });
              }
            }}
          >
            {queue.queueName} ({queue.unassignedInQueue}/{queue.recordsInQueue})
          </div>
        </Grid>
        <Grid item xs="auto">
          <Button
            style={{ fontSize: 10 }}
            href={queue.getNextUrl}
            variant="contained"
            disabled={queue.recordsInQueue <= 0 || !queue.getNextUrl}
          >
            Get Next
          </Button>
        </Grid>
      </Grid>
    </li>
  );
};

QueueItem.propTypes = {
  groupID: PropTypes.number.isRequired,
  dataTableQueueId: PropTypes.string,
  queue: PropTypes.objectOf(PropTypes.any).isRequired,
  queueDataFetch: PropTypes.func.isRequired,
};

export default React.memo(QueueItem);

// FormData/Schema
export const getDocTypesData = (state) =>
  state?.documentType?.data?.formData?.DOCUMENT_TYPES;

export const getDocTypesSchema = (state) =>
  state?.documentType?.data?.schema?.properties?.DOCUMENT_TYPES?.items;

// Fetching
export const getDocTypesFetching = (state) =>
  state?.documentType?.fetching?.docTypes ?? false;

export const getSaveActionFetching = (state) =>
  state?.documentType?.fetching?.saveDocType ?? false;

// Misc
export const getDocTypeIdx = (state, type) =>
  state?.documentType?.data?.formData?.DOCUMENT_TYPES.findIndex(({ TYPE }) => {
    return TYPE === type;
  });

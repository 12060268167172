import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import QueueGroup from './components/QueueGroup/QueueGroup';
import { queueDataFetch } from '../../modules/search';
import { getQueues } from '../../utils/selectors';
import styles from './QueueList.module.scss';

const QueueList = ({
  listType,
  queues,
  queueDataFetch: dispatchQueueDataFetch,
}) => {
  return (
    <>
      <h4 className={styles.listHeader}>{listType}</h4>
      {queues?.map((queueGroup) => {
        return (
          <QueueGroup
            queueDataFetch={dispatchQueueDataFetch}
            key={queueGroup.queueGroupId}
            queues={queueGroup.queues}
            groupName={queueGroup.queueGroupName}
            groupID={queueGroup.queueGroupId}
            recordsInGroup={queueGroup.recordsInGroup}
          />
        );
      })}
    </>
  );
};

QueueList.propTypes = {
  queueDataFetch: PropTypes.func.isRequired,
  listType: PropTypes.string.isRequired,
  queues: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state) => ({
  queues: getQueues(state),
});

const mapDispatchToProps = {
  queueDataFetch,
};

export default connect(mapStateToProps, mapDispatchToProps)(QueueList);

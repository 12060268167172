import Immutable from 'seamless-immutable';
import _get from 'lodash/get';
import { batch } from 'react-redux';
import { makeApi } from 'fni-schema';


import { getDocTypeIdx } from '../utils/selectors';
import { setInfo } from '../../../store/coreLayout';

const baseURL = 'fni-lenderportal-admin/admin/document-type/';
export const api = makeApi({ baseURL, withRequestInterceptor: false });

// ------------------------------------
// Constants
// ------------------------------------

const SET_DOC_TYPE = 'SET_DOC_TYPE';
const SET_DOC_TYPE_FETCHING = 'SET_DOC_TYPE_FETCHING';

// ------------------------------------
// Actions
// ------------------------------------

export const setDocType = (data, path) => {
  return {
    type: SET_DOC_TYPE,
    payload: { data, path },
  };
};

export const setFetching = (path, data) => {
  return {
    type: SET_DOC_TYPE_FETCHING,
    payload: { data, path },
  };
};

export const setError = (message) => setInfo({ type: 'error', message });

export const setSuccess = (message) =>
  setInfo({ type: 'success', message, delay: 2000 });

// ------------------------------------
// Middleware
// ------------------------------------

// GET /admin/document-type/types
export const getDocTypes = () => async (dispatch, getState) => {
  const dataPath = ['data'];
  const fetchPath = ['fetching', 'docTypes'];
  const fetching = _get(getState()?.documentType, fetchPath);

  if (!fetching) {
    try {
      dispatch(setFetching(fetchPath, true));
      const response = await api.get('types');
      const productTypes =
        response?.schema?.properties?.DOCUMENT_TYPES?.items?.properties
          ?.PRODUCT_TYPES?.enum;
      const formattedDocTypes = response?.formData?.DOCUMENT_TYPES?.map(
        (type) => {
          if (type.PRODUCT_TYPES.includes('ALL')) {
            return { ...type, PRODUCT_TYPES: productTypes };
          }
          return type;
        },
      );
      const data = Immutable.setIn(
        response,
        ['formData', 'DOCUMENT_TYPES'],
        formattedDocTypes,
      );
      dispatch(setDocType(data, dataPath));
    } catch (error) {
      if (error.message) {
        dispatch(setError(error.message));
      } else {
        dispatch(setError('Failed update document type.'));
      }
    } finally {
      dispatch(setFetching(fetchPath, false));
    }
  }
};

const formatUpdateData = ({ TYPE_DESCRIPTION, PRODUCT_TYPES }) => {
  return [
    {
      fieldId: 'TYPE_DESCRIPTION',
      fieldValue: TYPE_DESCRIPTION,
    },
    {
      // Default is fieldValues ( emphasis on plural )
      // BE doesn't like that one bit as of 5.24.2020
      fieldId: 'PRODUCT_TYPES',
      fieldValue: PRODUCT_TYPES.includes('ALL') ? ['ALL'] : PRODUCT_TYPES,
    },
  ];
};

// PUT /admin/document-type/types/{type}
export const updateDocType = (requestData) => async (dispatch, getState) => {
  const fetchPath = ['fetching', 'saveDocType'];
  const fetching = _get(getState()?.documentType, fetchPath);

  if (!fetching) {
    try {
      dispatch(setFetching(fetchPath, true));

      const response = await api.put(
        `types/${requestData.TYPE}`,
        formatUpdateData(requestData),
      );
      const { formData } = response;
      const productTypes = response?.schema?.properties?.PRODUCT_TYPES?.enum;
      if (formData?.PRODUCT_TYPES?.includes('ALL')) {
        formData.PRODUCT_TYPES = productTypes;
      }

      batch(() => {
        dispatch(setSuccess('Save Successful'));
        const index = getDocTypeIdx(getState(), requestData?.TYPE);
        dispatch(
          setDocType(formData, ['data', 'formData', 'DOCUMENT_TYPES', index]),
        );
      });
    } catch (error) {
      if (error.message) {
        dispatch(setError(error.message));
      } else {
        dispatch(setError('Failed update document type.'));
      }
    } finally {
      dispatch(setFetching(fetchPath, false));
    }
  }
};

const formatCreateData = ({
  TYPE,
  TYPE_DESCRIPTION,
  PRODUCT_TYPES,
  ACTIVE,
}) => {
  return [
    {
      fieldId: 'TYPE',
      fieldValue: TYPE,
    },
    {
      fieldId: 'TYPE_DESCRIPTION',
      fieldValue: TYPE_DESCRIPTION,
    },
    {
      fieldId: 'PRODUCT_TYPES',
      fieldValue: PRODUCT_TYPES?.includes('ALL') ? ['ALL'] : PRODUCT_TYPES,
    },
    {
      fieldId: 'ACTIVE',
      fieldValue: ACTIVE,
    },
  ];
};

// POST /admin/document-type/types/
export const createDocType = (data) => async (dispatch, getState) => {
  const fetchPath = ['fetching', 'saveDocType'];
  const fetching = _get(getState()?.documentType, fetchPath);

  if (!fetching) {
    try {
      dispatch(setFetching(fetchPath, true));

      await api.post('types', formatCreateData(data));
      dispatch(setSuccess('Save Successful'));
    } catch (error) {
      if (error.message) {
        dispatch(setError(error.message));
      } else {
        dispatch(setError('Failed create document type.'));
      }
      throw error;
    } finally {
      dispatch(setFetching(fetchPath, false));
    }
  }
};

// PUT admin/document-type/types/{type}/activate
// PUT admin/document-type/types/{type}/deactivate
export const toggleActive = (isActive, type) => async (dispatch, getState) => {
  const action = isActive ? 'activate' : 'deactivate';
  try {
    const response = await api.put(`types/${type}/${action}`);
    const index = getDocTypeIdx(getState(), type);
    dispatch(
      setDocType(response.formData, [
        'data',
        'formData',
        'DOCUMENT_TYPES',
        index,
      ]),
    );
  } catch (error) {
    if (error.message) {
      dispatch(setError(error.message));
    } else {
      dispatch(setError('Failed update document type.'));
    }
  }
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_DOC_TYPE]: (state, { payload: { data, path } }) =>
    Immutable.setIn(state, path, data),
  [SET_DOC_TYPE_FETCHING]: (state, { payload: { data, path } }) =>
    Immutable.setIn(state, path, data),
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};
export default function docTypeReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

import PropTypes from 'prop-types';
import React from 'react';
import { useField } from 'react-final-form';

import Hidden from '@mui/material/Hidden';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const VALUES = 0;
const RESULT = 1;

const CalculatorDisplay = ({ children }) => {
  const childArray = React.Children.toArray(children);
  const [tab, setTab] = React.useState(0);
  const {
    input: { value: calculated },
  } = useField('CALCULATED', { subscription: { value: true } });
  React.useEffect(() => {
    if (calculated) {
      setTab(RESULT);
    }
  }, [calculated]);
  return (
    <>
      <Hidden smDown>{children}</Hidden>
      <Hidden mdUp>
        <div>
          <Tabs
            value={tab}
            onChange={(e, value) => {
              setTab(value);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab key={VALUES} label="Values" value={VALUES} />
            <Tab
              key={RESULT}
              label="Results"
              value={RESULT}
              disabled={!calculated}
            />
          </Tabs>
          {childArray[tab]}
        </div>
      </Hidden>
    </>
  );
};

CalculatorDisplay.propTypes = {
  children: PropTypes.node,
};

export default CalculatorDisplay;

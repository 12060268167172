import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Paper } from '@mui/material'
import PageHeader from '../../components/PageHeader';
import ChangePasswordForm from "./ChangePasswordForm";
import MfaModal from './MfaModal';
import { getAuth, signInWithCustomToken, updatePassword } from 'firebase/auth';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { authActions } from '../modules/auth';

export default function ChangePasswordPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = getAuth();

    const [mfaModalOpen, setMfaModalOpen] = useState(false);

    const { 
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        values,
        errors,
        touched,
        dirty,
    } = useFormik({
        initialValues: { verificationCode: '', password: '' },
        validationSchema: yup.object({
            verificationCode: yup.string().trim()
                .matches(/^[0-9]{6}$/, 'Must be 6 digits'),
        }),
        onSubmit: (values) => {
            dispatch(authActions.verifyMFA(values.verificationCode))
                .then(async response => {
                    const success = response.payload?.data?.success;
                    const token = response.payload?.data?.token;
                    if (success && token) {
                        const signInResponse = await signInWithCustomToken(auth, token);

                        if (!signInResponse.error) {
                            try {
                                await updatePassword(auth.currentUser, values.password);                                
                                dispatch(authActions.setSuccessMessage('Password updated!'));

                                const timer = setTimeout(() => {
                                    navigate(-1);
                                }, 1000);
                                return () => clearTimeout(timer);

                            } catch (error) {
                                console.log(error)
                                dispatch(authActions.setErrorMessage('Unable to update password'));
                            }
                        }                        
                    };

                    setFieldValue('verificationCode', '', false);
                })
                .catch(error => {
                    setFieldValue('verificationCode', '', false);
                    console.error(error);
                })
        },
    });

    const handleChangePasswordSubmit = (data) => {
        setFieldValue('password', data.password);

        // MFA code verification
        setMfaModalOpen(true);
    };

    const showMfaError = Boolean(errors.verificationCode && touched.verificationCode && dirty);

    return (
        <Container maxWidth='sm'>
            <PageHeader text='Change Password' variant='h4' />
            <Paper elevation={0} sx={{ padding: 3 }}>
                <ChangePasswordForm
                    onSubmit={handleChangePasswordSubmit}
                />
            </Paper>

            <MfaModal 
                open={mfaModalOpen}
                onClose={() => setMfaModalOpen(false)}
                hideRemember
                onChange={handleChange}
                onBlur={handleBlur}
                values={values}
                handleSubmit={handleSubmit}
                error={showMfaError}
                helperText={showMfaError ? errors.verificationCode : null}
            />  
        </Container>
    );
}

import Immutable from 'seamless-immutable';
import SchemaConverter from 'fni-components/FNISchemaForm/utils/SchemaConverter';
import { batch } from 'react-redux';
import { makeApi } from 'fni-schema';

import { setInfo } from '../../../store/coreLayout';

const baseURL = 'fni-lenderportal-admin/';

export const api = makeApi({ baseURL, schemaKey: 'adminSchema' });
export const pageApi = makeApi({
  baseURL,
  withRequestInterceptor: false,
  withResponseInterceptor: false,
});
export const assetApi = makeApi({
  baseURL,
  withRequestInterceptor: false,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});
export const touchpointApi = makeApi({
  baseURL: '',
  withResponseInterceptor: false,
  responseType: 'text',
});

// ------------------------------------
// Constants
// ------------------------------------
export const PAGES_FETCHING_SET = 'PAGES_FETCHING_SET';
export const PAGES_SET = 'PAGES_SET';
export const PAGE_SET = 'PAGE_SET';
export const PAGE_FIELDS_SET = 'PAGE_FIELDS_SET';
export const PAGE_ELEMENTS_SET = 'PAGE_ELEMENTS_SET';
export const PAGE_ASSETS_SET = 'PAGE_ASSETS_SET';
export const PAGE_TEMPLATE_SET = 'PAGE_TEMPLATE_SET';
export const PAGE_TEMPLATE_FIELDS_SET = 'PAGE_TEMPLATE_FIELDS_SET';
export const PRESENCES_USING_SET = 'PRESENCES_USING_SET';
export const BOOKS_USING_SET = 'BOOKS_USING_SET';
export const IN_USE_FETCHING_SET = 'IN_USE_FETCHING_SET';
export const PAGE_SET_ID = 'PAGE_SET_ID';
export const SET_PLACEHOLDER_SIZING = 'SET_PLACEHOLDER_SIZING';

// ------------------------------------
// Actions
// ------------------------------------

export const pagesFetchingSet = (data) => {
  return {
    type: PAGES_FETCHING_SET,
    payload: data,
  };
};

export const pagesSet = (data) => {
  return {
    type: PAGES_SET,
    payload: data,
  };
};

export const pageSet = (data) => {
  return {
    type: PAGE_SET,
    payload: data,
  };
};

export const pageFieldsSet = (data) => {
  return {
    type: PAGE_FIELDS_SET,
    payload: data,
  };
};

export const pageElementsSet = (data) => {
  return {
    type: PAGE_ELEMENTS_SET,
    payload: data,
  };
};

export const pageAssetsSet = (data) => {
  return {
    type: PAGE_ASSETS_SET,
    payload: data,
  };
};

export const pageTemplate = (template) => {
  return {
    type: PAGE_TEMPLATE_SET,
    payload: template,
  };
};

export const pageTemplateFieldsSet = (data) => {
  return {
    type: PAGE_TEMPLATE_FIELDS_SET,
    payload: data,
  };
};

export const presencesUsingSet = (data) => {
  return {
    type: PRESENCES_USING_SET,
    payload: data,
  };
};

export const inUseFetchingSet = (data) => {
  return {
    type: IN_USE_FETCHING_SET,
    payload: data,
  };
};

export const booksUsingSet = (data) => {
  return {
    type: BOOKS_USING_SET,
    payload: data,
  };
};

export const pageSetId = (id) => {
  return {
    type: PAGE_SET_ID,
    payload: id,
  };
};

export const setPlaceholderSizing = (payload) => ({
  type: SET_PLACEHOLDER_SIZING,
  payload,
});

export const setError = (message) => setInfo({ type: 'error', message });

// ------------------------------------
// Middleware
// ------------------------------------

// Pages

const setInTemplate = (
  template,
  id = 0,
  path = false,
  isTemplatePiece = false
) => {
  const templatePiece = isTemplatePiece || template;
  let _template = template;
  let _id = id;
  if (templatePiece.id === undefined) {
    const innerPath = path ? path.split('.') : [];
    innerPath.push('id');
    _template = Immutable.setIn(template, innerPath, (_id += 1));
  }
  if (templatePiece.children) {
    Object.keys(templatePiece.children).forEach((i) => {
      const innerPath = path ? `${path}.children.${i}` : `children.${i}`;
      const values = setInTemplate(
        _template,
        _id,
        innerPath,
        templatePiece.children[i]
      );
      _template = values.template;
      _id = values.id;
    });
  }
  return { id: _id, template: _template };
};

export const pageTemplateSet = (template) => (dispatch, getState) => {
  const { id } = getState().pages;
  const values = setInTemplate(template, id);
  dispatch(pageTemplate(values.template));
};

export const pagesFetch = () => async (dispatch) => {
  try {
    dispatch(pagesFetchingSet(true));
    const response = await api.get('admin/pages');
    batch(() => {
      dispatch(pagesSet(response));
      dispatch(pagesFetchingSet(false));
    });
  } catch (error) {
    if (error.message) {
      dispatch(setError(error.message));
    } else {
      dispatch(setError('Failed to fetch pages'));
    }
    dispatch(pagesFetchingSet(false));
    throw error;
  }
};

export const pageDelete = (id) => async (dispatch) => {
  try {
    dispatch(pagesFetchingSet(true));
    await api.delete(`admin/page/${id}`);
    dispatch(pagesFetch());
    dispatch(pagesFetchingSet(false));
    dispatch(
      setInfo({
        type: 'success',
        message: 'Deleted Successfully!',
        delay: 3000,
      })
    );
  } catch (error) {
    dispatch(pagesFetchingSet(false));
    dispatch(
      setInfo({
        message: 'Failed to delete page.',
      })
    );
    throw error;
  }
};

export const presencesUsingFetch = (id) => async (dispatch) => {
  try {
    dispatch(inUseFetchingSet(true));
    const response = await api.get(`admin/presencesUsingPage/${id}`);
    dispatch(presencesUsingSet(response));
    dispatch(inUseFetchingSet(false));
  } catch (error) {
    if (error.message) {
      dispatch(setError(error.message));
    } else {
      dispatch(setError('Failed to fetch presences'));
    }
    dispatch(inUseFetchingSet(false));
    throw error;
  }
};

export const booksUsingFetch = (id) => async (dispatch) => {
  try {
    dispatch(inUseFetchingSet(true));
    const response = await api.get(`admin/booksUsingPage/${id}`);
    dispatch(booksUsingSet(response));
    dispatch(inUseFetchingSet(false));
  } catch (error) {
    if (error.message) {
      dispatch(setError(error.message));
    } else {
      dispatch(setError('Failed to fetch collections'));
    }
    dispatch(inUseFetchingSet(false));
    throw error;
  }
};

export const convertTemplate = (template) => {
  const newTemplate = { ...template };
  if (newTemplate?.children) {
    const newChildren = Object.values(newTemplate?.children);
    newTemplate.children = newChildren.map((child) => convertTemplate(child));
  }
  return newTemplate;
};

export const revertTemplate = (template) => {
  const newTemplate = { ...template };
  if (newTemplate?.children) {
    const newChildren = newTemplate?.children?.reduce((acc, child, index) => {
      return { ...acc, [index + 1]: revertTemplate(child) };
    }, {});
    newTemplate.children = newChildren;
  }
  return newTemplate;
};

export const getPage =
  (id = '', dupe) =>
  async (dispatch) => {
    dispatch(pagesFetchingSet(true));
    const response = await pageApi.get(`admin/page/${id}`);
    if (response?.data) {
      const data = response?.data;
      let convertedData;
      try {
        convertedData = JSON.parse(
          atob(data?.templateData?.replace?.(' ', ''))
        );
      } catch (e) {
        // eslint-disable-next-line no-use-before-define
        convertedData = initialState?.page?.template;
      }
      const template = convertTemplate(convertedData);

      const text = SchemaConverter.translateFromBackend({
        schema: data?.adminSchema,
        formData: data?.formData ?? [],
      });
      if (Array.isArray(text?.formData?.FIELD_LIST)) {
        text.formData.FIELD_LIST = text?.formData?.FIELD_LIST.reduce(
          (acc, val) => {
            acc[val.FIELD_ID] = val;
            return acc;
          },
          {}
        );
      }
      if (dupe) text.formData.PAGE_ID = '';
      batch(() => {
        dispatch(pageTemplateSet(template));
        dispatch(pageSet(text));
        dispatch(pagesFetchingSet(false));
      });
      return text;
    }
    throw new Error('500');
  };

export const getPageAsset = async (url) =>
  touchpointApi.get(url, {
    headers: {
      'Cache-Control': 'no-cache',
    },
  });

export const postPageAsset =
  ({ assetDesc, uploadAsset }) =>
  async (dispatch) => {
    dispatch(pagesFetchingSet(true));

    const form = new FormData();
    form.append('assetDesc', assetDesc);
    form.append('uploadAsset', uploadAsset);
    try {
      await assetApi.post('admin/asset', form);
    } catch (e) {
      // eslint-disable-next-line no-console
      if (process.env.NODE_ENV === 'development') console.error(e);
      throw e;
    } finally {
      dispatch(pagesFetchingSet(false));
    }
  };

export const updatePageAsset =
  ({ assetId, assetDesc, uploadAsset }) =>
  async (dispatch) => {
    dispatch(pagesFetchingSet(true));

    const form = new FormData();
    form.append('assetDesc', assetDesc);
    form.append('uploadAsset', uploadAsset);
    try {
      await assetApi.put(`admin/asset/${assetId}`, form, {
        params: {
          assetDesc,
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      if (process.env.NODE_ENV === 'development') console.error(e);
      throw e;
    } finally {
      dispatch(pagesFetchingSet(false));
    }
  };

export const postPageElement = async (data) => {
  try {
    await api.post('admin/element', data);
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    throw e;
  }
};

export const putPageElement = async (data, id) => {
  try {
    await api.put(`admin/element/${id}`, data);
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);

    throw e;
  }
};

export const getPageFields = () => async (dispatch) => {
  const text = await api.get('admin/pageFields');
  dispatch(pageFieldsSet(text));
};

export const getPageElements = () => async (dispatch) => {
  const text = await api.get('admin/pageElements');
  dispatch(pageElementsSet(text));
};

export const getPageAssets = () => async (dispatch) => {
  const text = await api.get('admin/pageAssets');
  dispatch(pageAssetsSet(text));
};

export const deletePageAsset = (id) => async (dispatch) => {
  try {
    dispatch(pagesFetchingSet(true));
    await api.delete(`admin/asset/${id}`);
    dispatch(pagesFetchingSet(false));
    dispatch(
      setInfo({
        type: 'success',
        message: 'Deleted Successfully!',
        delay: 3000,
      })
    );
  } catch (error) {
    if (error.message) {
      dispatch(setError(error.message));
    } else {
      dispatch(setError('Failed to delete'));
    }
    dispatch(pagesFetchingSet(false));
    throw error;
  }
};

export const deletePageElement = (id) => async (dispatch) => {
  try {
    dispatch(pagesFetchingSet(true));
    await api.delete(`admin/element/${id}`);
    dispatch(pagesFetchingSet(false));
    dispatch(
      setInfo({
        type: 'success',
        message: 'Deleted Successfully!',
        delay: 3000,
      })
    );
  } catch (error) {
    if (error.message) {
      dispatch(setError(error.message));
    } else {
      dispatch(setError('Failed to delete'));
    }
    dispatch(pagesFetchingSet(false));
    throw error;
  }
};

export const putPage = (id, data) => async (dispatch) => {
  const { template, ...formData } = data;
  const translatedFieldList = Object.entries(formData?.FIELD_LIST ?? {}).map(
    ([, val]) => val
  );

  try {
    await pageApi.put(`admin/page/${id}`, {
      formData: SchemaConverter.translateToBackend({
        ...formData,
        FIELD_LIST: translatedFieldList,
      }),
      templateData: btoa(JSON.stringify(template)),
    });
    dispatch(
      setInfo({
        type: 'success',
        message: 'Saved Successfully!',
        delay: 3000,
      })
    );
  } catch (error) {
    if (error.message) {
      dispatch(setError(error.message));
    } else {
      dispatch(setError('Failed to save page'));
    }
    throw error;
  }
};

export const postPage = (data) => async (dispatch) => {
  const { template, ...formData } = data;
  const translatedFieldList = Object.entries(formData?.FIELD_LIST ?? {}).map(
    ([, val]) => val
  );

  try {
    const {
      data: { pageId },
    } = await pageApi.post(`admin/page`, {
      formData: SchemaConverter.translateToBackend({
        ...formData,
        FIELD_LIST: translatedFieldList,
      }),
      templateData: btoa(JSON.stringify(template)),
    });

    dispatch(
      setInfo({
        type: 'success',
        message: 'Saved Successfully!',
        delay: 3000,
      })
    );

    return pageId;
  } catch (error) {
    if (error.message) {
      dispatch(setError(error.message));
    } else {
      dispatch(setError('Failed to save page'));
    }
    throw error;
  }
};

export const getTemplateFields = (id) => async (dispatch) => {
  const { data } = await pageApi.get(`admin/templateFields/${id}`);
  if (!data) throw new Error('500');
  const templateSchema = SchemaConverter.translateFromBackend({
    schema: data.templateSchema,
    formData: [],
  });
  const text = SchemaConverter.translateFromBackend({
    schema: data.adminSchema,
    formData: data.formData || [],
  });
  dispatch(pageTemplateFieldsSet({ ...text, templateSchema }));
};

export const actions = {
  pagesFetchingSet,
  pagesSet,
  pagesFetch,
  pageDelete,
  pageTemplateSet,
  pageAssetsSet,
  deletePageAsset,
  presencesUsingFetch,
  booksUsingFetch,
  booksUsingSet,
  presencesUsingSet,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PAGES_FETCHING_SET]: (state, action) => {
    return Immutable({ ...state, fetching: action.payload });
  },
  [PAGES_SET]: (state, action) => {
    return Immutable({
      ...state,
      pageList: { ...state.pageList, ...action.payload },
    });
  },
  [PAGE_SET]: (state, action) => {
    return Immutable({
      ...state,
      page: { ...state.page, ...action.payload },
    });
  },
  [PAGE_FIELDS_SET]: (state, action) => {
    return Immutable.setIn(state, ['page', 'availableFields'], action.payload);
  },
  [PAGE_ELEMENTS_SET]: (state, action) => {
    return Immutable({
      ...state,
      page: { ...state.page, availableElements: action.payload },
    });
  },
  [PAGE_ASSETS_SET]: (state, action) => {
    return Immutable({
      ...state,
      page: { ...state.page, availableAssets: action.payload },
    });
  },
  [PAGE_TEMPLATE_SET]: (state, action) => {
    return Immutable({
      ...state,
      page: { ...state.page, template: action.payload },
    });
  },
  [PAGE_TEMPLATE_FIELDS_SET]: (state, action) => {
    return Immutable({
      ...state,
      page: { ...state.page, templateFields: action.payload },
    });
  },
  [PRESENCES_USING_SET]: (state, action) => {
    return Immutable({
      ...state,
      pageList: { ...state.pageList, presencesUsing: action.payload },
    });
  },
  [BOOKS_USING_SET]: (state, action) => {
    return Immutable({
      ...state,
      pageList: { ...state.pageList, booksUsing: action.payload },
    });
  },
  [IN_USE_FETCHING_SET]: (state, action) => {
    return Immutable({
      ...state,
      pageList: { ...state.pageList, inUseFetching: action.payload },
    });
  },
  [PAGE_SET_ID]: (state, action) => {
    return Immutable.setIn(state, ['id'], action.payload);
  },
  [SET_PLACEHOLDER_SIZING]: (state, { payload }) => {
    return Immutable({
      ...state,
      placeholderSizing: { ...payload },
    });
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  page: {
    template: { type: 'layout', children: [] },
    availableComponents: {
      formData: [
        {
          componentName: 'TouchpointSectionHeader',
          title: 'Section Header',
          props: [
            {
              name: 'title',
              description: 'Title for the section',
              displayName: 'Title',
              type: 'string',
              value: '',
            },
          ],
        },
        {
          componentName: 'ConditionalShowComponent',
          title: 'Conditional Show Component',
          props: [{}],
        },
      ],
    },
  },
  pageDnd: {},
};
export default function pagesReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import { authActions, authSelectors } from '../modules/auth';
import { LocalAlert, LoadingSpinner } from 'fni-components-next';
import { coreActions } from '../../redux/CoreSlice/core';
import { useEffect } from 'react';

export default function Authentication() {

    const dispatch = useDispatch();
    const { code } = useParams();
    const isLoading =  useSelector(authSelectors.authLoading);
    const authAlert = useSelector(authSelectors.authAlert);

    useEffect(() => {
        if(code) dispatch(coreActions.setCode(code));
    }, []);

    return (
        <>
            <LocalAlert alert={authAlert} onHideAlert={() => dispatch(authActions.hideAlert())} />
            <Outlet />
            <LoadingSpinner open={isLoading} />
        </>
    );
}

import {
  bluePalette,
  greenPalette,
  blueGreyPalette,
  lightTextPalette,
} from './themePalettes';

export const dealerTheme = {
  palette: {
    primary: bluePalette,
    secondary: greenPalette,
    tertiary: blueGreyPalette,
    inputColor: 'rgba(0, 0, 0, 0.4);',
    mainHeader: greenPalette.dark,
    pageHeader: {
      main: '#ffffff',
      contrastText: bluePalette.main,
    },
    text: lightTextPalette,
  },
  // TODO allow custom fonts when custom themes are implemented
  fontFamily: '',
  borderRadius: 8,
  buttonBorderRadius: 25,
  inputType: 'standard',
  body: true,
  cardType: 'underline',
};

export const workbenchTheme = {
  palette: {
    primary: bluePalette,
    secondary: greenPalette,
    tertiary: blueGreyPalette,
    inputColor: 'rgba(0, 0, 0, 0.4);',
    mainHeader: blueGreyPalette.xdark,
    pageHeader: {
      main: bluePalette.xxlight,
      contrastText: bluePalette.dark,
    },
    text: lightTextPalette,
  },
  // TODO allow custom fonts when custom themes are implemented
  fontFamily: '',
  borderRadius: 4,
  buttonBorderRadius: null,
  inputType: 'standard',
  body: true,
  cardType: 'border',
};

export const adminTheme = {
  palette: {
    primary: bluePalette,
    secondary: greenPalette,
    tertiary: blueGreyPalette,
    inputColor: 'rgba(0, 0, 0, 0.6);',
    mainHeader: blueGreyPalette.xdark,
    pageHeader: {
      main: bluePalette.dark,
      contrastText: '#fff',
    },
    text: lightTextPalette,
  },
  // TODO allow custom fonts when custom themes are implemented
  fontFamily: '',
  borderRadius: 4,
  buttonBorderRadius: null,
  inputType: 'standard',
  body: true,
  cardType: 'solid',
};


const getItemByItemId = (items, itemId) => {
    if(items?.length && itemId) {
        return items.find(item => item.id === itemId);
    } 
    return null;
};

const removeItemFromListById = (list, id) => {
    if(list?.length && id) {
        return list.filter(item => {
            return item.id !== id;
        });
    } 
    return list;
};

const getUpdatedList = (list, updatedItem) => {
    if (list?.length && updatedItem) {
        return list.map(item => {
            return item.id === updatedItem.id ? updatedItem : item;
        });
    } 
    return list;
};


export const reducerUtils = {
    getItemByItemId, 
    removeItemFromListById,
    getUpdatedList,
};

import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import ReplyIcon from '@mui/icons-material/Reply';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import ApiContext from './ApiContext';

const checkDisabled = (predef, id, text) => {
  return (predef && !id) || (!predef && !text);
};

const ReplyBlock = ({
  predefMessages,
  CONVO_ID,
  type,
  closed,
  CAN_SEND,
  isLocked,
}) => {
  const [predef, setPredef] = useState(type === 'LENDER');
  const [defIndex, setDefIndex] = useState(false);
  const [reply, setReply] = useState('');
  const [replying, setReplying] = useState(false);
  const {
    apis: { postMessage },
  } = useContext(ApiContext);

  const maxLength =
    predefMessages?.schema?.properties?.PREDEF_MESSAGES?.items?.properties
      ?.CONVO_MSG_TEXT?.maxLength ?? 250;
  return (
    <>
      {closed || CAN_SEND === false || isLocked ? (
        <Box mt={2} display="flex" justifyContent="center">
          <Typography>
            {closed || isLocked ? (
              <i>Replies are locked on this conversation.</i>
            ) : (
              <i>Current user lacks permission to reply.</i>
            )}
          </Typography>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" mt={2}>
          {type === 'LENDER' && (
            <Box display="flex" alignItems="flex-end">
              <Box flexGrow={0} mr={2}>
                <TextField
                  select
                  value={defIndex}
                  label="Select Message"
                  autoWidth
                  disabled={!predef}
                  onChange={(e) => {
                    setDefIndex(e.target.value);
                    setReply(
                      predefMessages?.formData?.PREDEF_MESSAGES?.[
                        e.target.value
                      ]?.CONVO_MSG_TEXT ?? ''
                    );
                  }}
                >
                  {predefMessages?.formData?.PREDEF_MESSAGES.map(
                    ({ CONVO_DEF_MSG_ID, CONVO_DISPLAY_TEXT }, index) => {
                      return (
                        <MenuItem key={CONVO_DEF_MSG_ID} value={index}>
                          {CONVO_DISPLAY_TEXT}
                        </MenuItem>
                      );
                    }
                  )}
                </TextField>
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={predef}
                      onChange={() => setPredef(!predef)}
                      name="switch"
                      color="primary"
                    />
                  }
                  label="Predefined Reply"
                />
              </Box>
            </Box>
          )}
          <Box display="flex" alignItems="flex-end">
            <Box flexGrow={2}>
              <TextField
                value={reply}
                label="Reply"
                multiline
                disabled={predef}
                onChange={(e) => {
                  if (!maxLength || e.target.value?.length <= maxLength) {
                    setReply(e.target.value);
                  }
                }}
                InputProps={{
                  endAdornment: Boolean(maxLength) && (
                    <InputAdornment position="end">{`${reply.length}/${maxLength}`}</InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box mb={1} ml={2}>
              <Hidden xsDown>
                <Button
                  disabled={
                    replying ||
                    checkDisabled(
                      predef,
                      predefMessages?.formData?.PREDEF_MESSAGES?.[defIndex]
                        ?.CONVO_DEF_MSG_ID,
                      reply
                    )
                  }
                  onClick={async () => {
                    setReplying(true);
                    const sentData = predef
                      ? {
                          CONVO_DEF_MSG_ID:
                            predefMessages?.formData?.PREDEF_MESSAGES?.[
                              defIndex
                            ]?.CONVO_DEF_MSG_ID,
                        }
                      : {
                          CONVO_MSG_TEXT: reply,
                        };
                    const isSuccessful = await postMessage(CONVO_ID, sentData);
                    if (isSuccessful) {
                      if (!predef) setReply('');
                    }
                    setReplying(false);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Reply
                </Button>
              </Hidden>
              <Hidden smUp>
                <IconButton
                  disabled={checkDisabled(
                    predef,
                    predefMessages?.formData?.PREDEF_MESSAGES?.[defIndex]
                      ?.CONVO_DEF_MSG_ID,
                    reply
                  )}
                  onClick={async () => {
                    const sentData = predef
                      ? {
                          CONVO_DEF_MSG_ID:
                            predefMessages?.formData?.PREDEF_MESSAGES?.[
                              defIndex
                            ]?.CONVO_DEF_MSG_ID,
                        }
                      : {
                          CONVO_MSG_TEXT: reply,
                        };
                    const isSuccessful = await postMessage(CONVO_ID, sentData);
                    if (isSuccessful) {
                      if (!predef) setReply('');
                    }
                  }}
                  color="primary"
                >
                  <ReplyIcon />
                </IconButton>
              </Hidden>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

ReplyBlock.propTypes = {
  CONVO_ID: PropTypes.number,
  closed: PropTypes.bool,
  predefMessages: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
  isLocked: PropTypes.bool,
  CAN_SEND: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  predefMessages: state?.convo?.predef,
  type: state?.convo?.type,
  CAN_SEND: state?.convo?.permissions?.CAN_SEND,
  isLocked: state?.coreLayout?.lockIndicator,
});

export default connect(mapStateToProps)(ReplyBlock);

import Immutable from 'seamless-immutable';
import { makeApi } from 'fni-schema';

import { setInfo } from '../../../store/coreLayout';

export const setError = (message) => setInfo({ type: 'error', message });
export const setSuccess = (message) =>
  setInfo({ type: 'success', message, delay: 2000 });

export const api = makeApi({ baseURL: 'fni-lenderportal-admin/admin/convo/' });

// ------------------------------------
// Constants
// ------------------------------------
export const CONVERSATIONS_SET = 'CONVERSATIONS_SET';

// ------------------------------------
// Actions
// ------------------------------------

export const conversationsSet = (data, path) => {
  return {
    type: CONVERSATIONS_SET,
    payload: { data, path },
  };
};

// ------------------------------------
// Middleware
// ------------------------------------

export const fetchMessages = () => async (dispatch) => {
  try {
    const data = await api.get('predef-messages');
    dispatch(conversationsSet(data, ['data']));
    return true;
  } catch (e) {
    dispatch(setError('Failed to create new function.'));
    return false;
  }
};

export const fetchMessage = (id, index) => async (dispatch) => {
  try {
    const data = await api.get(`predef-messages/${id}`);
    dispatch(
      conversationsSet(data?.formData, [
        'data',
        'formData',
        'PREDEF_MESSAGES',
        index,
      ]),
    );
    return true;
  } catch (e) {
    dispatch(setError('Failed to create new function.'));
    return false;
  }
};

export const updateMessage = (id, data) => async (dispatch) => {
  await api.put(`predef-messages/${id}`, data);
  dispatch(
    setSuccess(`Successfully updated ${data?.CONVO_DISPLAY_TEXT ?? 'message'}.`),
  );
};

export const createMessage = (data, index) => async (dispatch) => {
  try {
    const { formData: newData } = await api.post('predef-messages', data);
    dispatch(
      setSuccess(
        `Successfully created ${data?.CONVO_DISPLAY_TEXT ?? 'message'}.`,
      ),
    );
    dispatch(
      conversationsSet(newData, ['data', 'formData', 'PREDEF_MESSAGES', index]),
    );
  } catch (e) {
    dispatch(setError(e?.message || 'Failed to create new message.'));
    throw e;
  }
};

export const deleteMessage = (id, index, isCreating) => async (
  dispatch,
  getState,
) => {
  if (!isCreating) {
    await api.delete(`predef-messages/${id}`);
  }
  const newArr = [
    ...(getState()?.convo?.data?.formData?.PREDEF_MESSAGES ?? []),
  ];
  const { CONVO_DISPLAY_TEXT } = newArr.splice(index, 1)[0] || {};
  dispatch(conversationsSet(newArr, ['data', 'formData', 'PREDEF_MESSAGES']));
  dispatch(
    setSuccess(
      `${CONVO_DISPLAY_TEXT || 'The New Message'} was successfully deleted.`,
    ),
  );
  return true;
};

export const actions = {};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CONVERSATIONS_SET]: (state, { payload: { data, path } }) => {
    return Immutable.setIn(state, path, data);
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};
export default function conversationReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

import React from 'react';
import PropTypes from 'prop-types';

import Skeleton from '@mui/material/Skeleton';

import Field from './Field';
import { useSchemaManual } from '../useSchema';

const SchemaField = ({
  name,
  schemaName,
  schemaProps,
  searchSchema,
  noSchema = false, // can be used to override skeleton behavior
  ...props
}) => {
  console.log("schema", searchSchema)

  const schemaValues = useSchemaManual(schemaName ?? name, searchSchema);
  if (!schemaValues?.type && !noSchema)
    return (
      <div
        sx={{ minWidth: 200 }}
        id={`${name}-loading-skeleton`}
        data-testid={`${name}-loading-skeleton`}
      >
        <Skeleton height={16} width="25%" />
        <Skeleton height={32} width="100%" />
      </div>
    );

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Field name={name} schemaValues={schemaValues} {...props} />;
};

SchemaField.propTypes = {
  name: PropTypes.string.isRequired,
  schemaName: PropTypes.string,
  schemaProps: PropTypes.objectOf(PropTypes.any),
  noSchema: PropTypes.bool,
};

export default SchemaField;

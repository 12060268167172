import { useState } from 'react';
import { Avatar, IconButton, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function getAvatarLinks(currentAuthenticatedUser, navigate) {
    const avatarLinks = [];

    if (currentAuthenticatedUser?.isFNIAdmin) {
        avatarLinks.push([
            (<MenuItem key="tenants" onClick={() => navigate('/tenants/list/tenants')}>Manage Tenants</MenuItem>),
            (<MenuItem key="users" onClick={() => navigate('/tenants/list/users')}>Manage Users</MenuItem>),
        ]);
    } else {
        avatarLinks.push([
            (<MenuItem key="users" onClick={() => navigate('/users')}>Manage Users</MenuItem>),
        ]);
    }

    avatarLinks.push((<MenuItem key="password" onClick={() => navigate('/auth/change-password')}>Change Password</MenuItem>));

    return avatarLinks;
}

export default function AvatarMenu({ onLogout, currentAuthenticatedUser }) {

    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const handleClick = (e) => setAnchorEl(e.currentTarget);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        onLogout();
        handleClose();
    };

    if(!currentAuthenticatedUser) return null;
    return (
        <>
            <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <Avatar />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {currentAuthenticatedUser?.isValid ? getAvatarLinks(currentAuthenticatedUser, navigate) : null}
                <MenuItem onClick={handleLogout}>
                    Logout
                </MenuItem>
            </Menu>
        </>
    );
}

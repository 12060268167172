import { makeApi } from 'fni-schema';
import { handleError } from '../../../utils/infoBarHandlers'; // handleSuccess
import { groupEnums } from '../../../utils/useSchema'



export const api = makeApi({
    baseURL: `fni-lenderportal-admin/admin`,
    headers: {
        'Content-Type': 'application/json',
    },
    withRequestInterceptor: false,
    withResponseInterceptor: false,
});

// ------------------------------------
// Actions
// ------------------------------------
export const setDealerMembershipGroup = (data, path) => {

    return {
        type: "SET_DEALER_MEMBERSHIP_GROUPS",
        payload: { data, path },
    };
};

export const setDealerMembershipLocations = (data, path) => {
    return {
        type: "SET_DEALER_MEMBERSHIP_LOCATIONS",
        payload: { data, path }
    };
};

export const setDealerMemberships = (data, path) => {
    return {
        type: "SET_DEALER_MEMBERSHIP",
        payload: { data, path }
    };
};




// ------------------------------------
// Middleware
// ------------------------------------

// GET /dealer-membership/groups
export const getDealerMembershipGroups = () => async (dispatch) => {
    try {
        const response = await api.get('/dealer-membership/groups');
        if (response) {
            dispatch(setDealerMembershipGroup(response.data, 'dealerMembershipGroups'));
        }
    } catch (e) {
        dispatch(handleError(e, 'Failed to fetch dealer groups'));
    }
};

// GET /dealer-membership/locations/${id}
export const getDealerMembershipLocations = (id) => async (dispatch) => {
    try {
        const response = await api.get(`/dealer-membership/locations/${id}`);
        if (response && response.data) {
            dispatch(setDealerMembershipLocations({ fieldValues: groupEnums(response.data.schema?.properties) }, 'dealerMembershipLocations'));
        }
    } catch (e) {
        dispatch(handleError(e, 'Failed to fetch dealer groups'));
    }
};

// GET /dealer-membership/memberships/${id}
export const getDealerMemberships = (id) => async (dispatch) => {
    try {
        const response = await api.get(`/dealer-membership/memberships/${id}`);
        if (response.data) {
            dispatch(setDealerMemberships(response.data, 'dealerMemberships'));
        }
    } catch (e) {
        dispatch(handleError(e, 'Failed to fetch dealer groups'));
    }
};



// PUT /dealer-membership/membership/${groupId}/${locationId}
export const putDealerMemberships = (groupId, locationId, data, callback) => async (dispatch) => {
    try {

        await api.put(`/dealer-membership/membership/${groupId}/${locationId}`, [
            {
                "fieldId": "MEMBER_PERMS",
                "fieldValue": data
            }
        ]);

        if (callback) {
            callback()
        }
        // dispatch(setDealerMemberships(response.data, 'dealerMemberships'));
    } catch (e) {
        dispatch(handleError(e, 'Failed to fetch dealer groups'));
    }
};



// POST /dealer-membership/membership
export const postDealerMemberships = (groupId, locationId, data, callback) => async (dispatch) => {
    try {
        await api.post(`/dealer-membership/membership`, [
            {
                "fieldId": "GROUP_ID",
                "fieldValue": groupId
            },
            {
                "fieldId": "LOCATION_ID",
                "fieldValue": locationId
            },
            {
                "fieldId": "MEMBER_PERMS",
                "fieldValue": data
            }
        ]);
        
        const response = await api.get(`/dealer-membership/memberships/${groupId}`);
        if (response.data) {
            if (callback) {
                callback()
            }
            dispatch(setDealerMemberships(response.data, 'dealerMemberships'));
        }
    } catch (e) {
        dispatch(handleError(e, 'Failed to fetch dealer groups'));
    }
};


// PUT /dealer-membership/membership/${groupId}/${locationId}
export const putDealerMembershipsActivate = (groupId, locationId, action = "activate") => async (dispatch) => {
    try {
        await api.put(`/dealer-membership/${groupId}/${locationId}/${action}`);
        const response = await api.get(`/dealer-membership/memberships/${groupId}`);
        if (response.data) {
            dispatch(setDealerMemberships(response.data, 'dealerMemberships'));
        }
    } catch (e) {
        dispatch(handleError(e, 'Failed to fetch dealer groups'));
    }
};


// DELETE /fni-lenderportal-admin/admin/dealer-membership/membership/<group_id>/<member_location_id>
export const deleteDealerMembership = (groupId, locationId) => async (dispatch) => {
    try {
        await api.delete(`/dealer-membership/membership/${groupId}/${locationId}`);
        const response = await api.get(`/dealer-membership/membership/${groupId}`);
        if (response.data) {
            dispatch(setDealerMemberships(response.data, 'dealerMemberships'));
        }
    } catch (e) {
        dispatch(handleError(e, 'Failed to fetch dealer groups'));
    }
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLER = (state, { payload: { data, path } }) => {

    return {
        ...state,
        [path]: {
            ...state[path],
            ...data
        },
    };
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};
export default function dealerMembershipAdminReducer(state = initialState, action) {

    const handler = ACTION_HANDLER;
    return handler && action.payload?.data ? { ...handler(state, action) } : { ...state };
}

import Immutable from 'seamless-immutable';

import { makeApi } from 'fni-schema';
import { handleError, handleSuccess } from '../../../utils/infoBarHandlers';

export const api = makeApi({
  baseURL: 'fni-lenderportal-admin/admin',
});

// ------------------------------------
// Constants
// ------------------------------------
const SET_EMAIL_SCHEDULE_LIST = 'SET EMAIL SCHEDULE LIST';
// ------------------------------------
// Actions
// ------------------------------------
export const setEmailScheduleList = (data, path) => {
  return {
    type: SET_EMAIL_SCHEDULE_LIST,
    payload: { data, path },
  };
};
// ------------------------------------
// Middleware
// ------------------------------------

// GET fni-lenderportal-admin/admin/emailSched/jobs
export const getEmailSchedulesList = () => async (dispatch) => {
  try {
    const response = await api.get(`/emailSched/jobs`);
    dispatch(setEmailScheduleList(response, ['list']));
  } catch (e) {
    dispatch(handleError(e, 'Unable to fetch schedules.'));
  }
};

// POST fni-lenderportal-admin/admin/emailSched/jobs
export const postEmailSchedule = (data) => async (dispatch) => {
  try {
    await api.post('/emailSched/jobs', data);
    dispatch(handleSuccess('Save Successful'));
  } catch (e) {
    dispatch(handleError(e, 'Email schedule failed to send.'));
    throw e;
  }
};

export const putEmailSchedule = (data) => async (dispatch) => {
  try {
    const { SCHEDULED_JOB_ID: jobId } = data;
    await api.put(`/emailSched/jobs/${jobId}`, data);
    dispatch(handleSuccess('Job Successfully Updated'));
  } catch (e) {
    dispatch(handleError(e, 'Email schedule failed to update.'));
    throw e;
  }
};

export const deleteEmailSchedule = (jobId) => async (dispatch) => {
  try {
    await api.delete(`/emailSched/jobs/${jobId}`);
    dispatch(handleSuccess('Job Successfully Deleted'));
  } catch (e) {
    dispatch(handleError(e, 'Email schedule failed to delete.'));
    throw new Error(e);
  }
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_EMAIL_SCHEDULE_LIST]: (state, { payload: { data, path } }) => {
    return Immutable.setIn(state, path, data);
  },
};
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  create: {},
  fetching: false,
};

export default function emailSchedulerReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

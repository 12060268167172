import axios from 'axios';
import Cookie from 'js-cookie';
import {
  translateToBackend,
  translateFromBackend,
} from '../schemaConverter/schemaConverter';

const XSRF = Cookie.get('XSRF-TOKEN');

const formatErrorMessages = (errors) => {
  return errors
    ?.map((error) => {
      if (typeof error === 'string') return error;
      return error?.message;
    })
    .join('\n');
};

const makeApi = (config) => {
  const {
    baseURL,
    withRequestInterceptor = true,
    withResponseInterceptor = true,
    schemaKey,
    responseType = 'json',
    headers = {},
  } = config;

  if(process.env.NODE_ENV === "development"){
    headers['X-FORWARDED-FOR'] =  process.env.REACT_APP_IP_ADDRESS || '10.1.82.125'
    headers.authorization =  `Basic ${btoa(`${process.env.REACT_APP_USER}:${process.env.REACT_APP_PASSWORD}`)}`
  }
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}${baseURL}`,
    headers: {
      'X-CSRF-TOKEN': XSRF,
      ...headers,
    },
    responseType,
  });

  if (withRequestInterceptor) {
    instance.interceptors.request.use(
      (request) => {
        if (request.data) {
          return {
            ...request,
            data: translateToBackend(request.data),
          };
        }
        return request;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  if (withResponseInterceptor) {
    instance.interceptors.response.use(
      (response) => {
        const { formData, status, errorMsgs } = response?.data ?? {};
        const schema = response?.data?.[schemaKey] ?? response?.data?.schema;

        if (status === 'ERROR') {
          throw new Error(formatErrorMessages(errorMsgs));
        }

        return translateFromBackend({
          schema,
          formData,
        });
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  return instance;
};

export default makeApi;

/* eslint-disable no-undef */
import Cookies from 'js-cookie';

export const BASE_PATH = `${process.env.REACT_APP_BASE_URL}fni-lenderportal-admin/`;
/* eslint-enable no-undef */

export const API_PATH = BASE_PATH;

export default (url, options = {}, api = API_PATH) => {
  if (!window.XSRF) {
    window.XSRF = Cookies.get('XSRF-TOKEN');
  }
  const { XSRF } = window;

  Cookies.get('XSRF-TOKEN');

  const defaultOptions = {
    headers: {
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': XSRF,
    },
  };
  
  if(process.env.NODE_ENV === "development"){
    defaultOptions.headers.authorization =  `Basic ${btoa(`${process.env.REACT_APP_USER}:${process.env.REACT_APP_PASSWORD}`)}`
  }
  if (window.refnum) {
    defaultOptions.headers.REFNUM = window.refnum;
  }
  const newOptions = { ...defaultOptions, ...options };
  return fetch(api + url, newOptions).catch(err => console.log(err));
};
import { makeApi, translateToBackend } from 'fni-schema';
import Immutable from 'seamless-immutable';
import { setInfo } from '../../../store/coreLayout';

const SET_SNACKBAR_MESSAGE = 'ACL_ERROR_MESSAGE';
const ACL_SET = 'ACL_SET';

export const api = makeApi({
    baseURL: 'fni-lenderportal-admin/admin/acl',
    schemaKey: 'adminSchema',
});

export const rawApi = makeApi({
    baseURL: 'fni-lenderportal-admin/admin/acl',
    schemaKey: 'adminSchema',
    withRequestInterceptor: false,
});

export const aclSet = (path, data) => ({
    type: ACL_SET,
    payload: { path, data },
});

export const getAcls = () => async (dispatch) => {
    try {
        const data = await api.get('all');
        dispatch(aclSet(['all'], data));
    } catch (e) {
        dispatch(setInfo(e, 'Failed to retrieve ACLs'));
    }
};

export const getAcl = (id) => async (dispatch) => {
    try {
        const data = await api.get(`ip/${id}`);
        dispatch(aclSet(['ips', id], data));
    } catch (e) {
        dispatch(setInfo(e, 'Failed to retrieve ACL'));
    }
};

export const saveAcl = (data) => async (dispatch) => {
    try {
        await rawApi.post('update', translateToBackend({ UPDATE_ACL: [data] })[0]);
    } catch (e) {
        dispatch(setInfo(e, 'Failed to update ACL'));
    }
};

export const newAcl = (data) => async (dispatch) => {
    try {
        await rawApi.post('create', translateToBackend({ CREATE_ACL: [data] })[0]);
    } catch (e) {
        dispatch(setInfo(e, 'Failed to create new ACL'));
    }
};

export const deleteAcl = (id) => async (dispatch) => {
    try {
        await rawApi.post('remove', {
            fieldId: 'REMOVE_ACL',
            fieldValues: [
                [
                    {
                        fieldId: 'ACL_ID',
                        fieldValue: id,
                    },
                ],
            ],
        });
        return true;
    } catch (e) {
        dispatch(setInfo(e, 'Failed to delete ACL'));
        throw e;
    }
};

export const deleteIp = (id) => async (dispatch) => {
    try {
        rawApi.post('ip/remove', {
            fieldId: 'REMOVE_IP',
            fieldValues: [
                [
                    {
                        fieldId: 'IP_ID',
                        fieldValue: id,
                    },
                ],
            ],
        });
    } catch (e) {
        dispatch(setInfo(e, 'Failed to delete IP'));
        throw e;
    }
};

export const createIp =
  ({ IP_MIN, IP_MAX, ACL_ID }) =>
      async (dispatch) => {
          try {
              await rawApi.post(
                  'ip/add',
                  translateToBackend({
                      APP_IP: [{ IP_MIN, IP_MAX, ACL_ID }],
                  })[0],
              );
              return true;
          } catch (e) {
              dispatch(setInfo(e, 'Failed to create new IP'));
              return false;
          }
      };

export const updateIp =
  ({ IP_ID, IP_MIN, IP_MAX }) =>
      async (dispatch) => {
          try {
              await rawApi.post(
                  'ip/update',
                  translateToBackend({
                      UPDATE_ACL: [
                          {
                              IP_ID,
                              IP_MIN,
                              IP_MAX,
                          },
                      ],
                  })[0],
              );
              return true;
          } catch (e) {
              dispatch(setInfo(e, 'Failed to update IP'));
              return false;
          }
      };

export const actions = {
    aclSet,
    getAcls,
    getAcl,
    saveAcl,
    deleteAcl,
    newAcl,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [SET_SNACKBAR_MESSAGE]: (state, { payload }) =>
        Immutable.setIn(state, ['snackbar'], payload),
    [ACL_SET]: (state, { payload: { path, data } }) =>
        Immutable.setIn(state, path, data),
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    create: {},
    ips: {},
    selected: {},
    snackbar: {},
};
export default function aclReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}

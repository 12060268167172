import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { linksFetch,toggleDrawer } from './store/headerStore';
import { Helmet } from 'react-helmet';
import SideBar from './components/Sidebar/Sidebar';
import NavBar from '../components/fni-components-next/NavBar/NavBar';


const drawerWidth = 320;


const classes = {
    header: 'DrawerContext-header',
    appBar: 'DrawerContext-appBar',
    appBarShift: 'DrawerContext-appBarShift',
    content: 'DrawerContext-content',
    contentShift: 'DrawerContext-contentShift',
};



class FNIContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            adminType: 'GENERAL_ADMIN',
            currentItem: '',
        };
    }

    componentDidMount() {
        const { headerLinks, currentAuthenticatedUser } = this.props;
        const [, admin] = window.location.pathname.split('/');
        if (
            admin === 'pages' ||
            admin === 'collections' ||
            admin === 'editpresencelist'
        ) {
            this.setState({ adminType: 'TOUCHPOINT_ADMIN' });

        }
        else {
            this.setState({ adminType: 'GENERAL_ADMIN' });
        }

        if (headerLinks?.length) {
            const group = headerLinks.find((link) => link.id === this.state.adminType);
            if (group) {
                const type = group.items.find(
                    (page) => page.path.split('/')[1] === admin,
                );
                if (type) {
                    this.setState({ currentItem: type.id });
                }
            }
        }
        if(currentAuthenticatedUser) this.props.linksFetch();
    }

    drawerOpen = () => this.props.drawerToggled && this.props.currentAuthenticatedUser && this.props.isSearch;

    render() {

        const { headerLinks, children, title } = this.props;
        return (<>
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <div style={{ marginLeft: this.drawerOpen() ? '320px' : '0px' }}>
                <NavBar
                    links={headerLinks}
                />
            </div>

            <SideBar isSidebarOpen={this.drawerOpen()} toggleSidebar={() => this.props.toggleDrawer()} />

            <div style={{ marginLeft: this.drawerOpen() ? '320px' : '0px' }}>
                {children}
            </div>
        </>
        );
    }
}


FNIContainer.propTypes = {
    headerLinks: PropTypes.arrayOf(PropTypes.object),
    linksFetch: PropTypes.func.isRequired,
    isSearch: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return ({
        headerLinks: state?.header?.headerLinks,
        modalSchema: state?.search?.searchModal.fields,
        drawerToggled: state?.header?.drawerToggled,
    });
};

const mapDispatchToProps = {
    linksFetch,
    toggleDrawer,
};


export default connect(mapStateToProps, mapDispatchToProps)(FNIContainer);

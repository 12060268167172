import Immutable from 'seamless-immutable';

import { makeApi } from 'fni-schema';

import { handleError, handleSuccess } from '../../../utils/infoBarHandlers';

export const api = makeApi({
  baseURL: 'fni-lenderportal-admin',
  schemaKey: 'adminSchema',
});

// ------------------------------------
// Constants
// ------------------------------------
export const PRESENCE_LIST_FETCHING_SET = 'PRESENCE_LIST_FETCHING_SET';
export const PRESENCE_LIST_SET = 'PRESENCE_LIST_SET';
export const PRESENCE_SET = 'PRESENCE_SET';
export const AVAILABLE_BOOKS_SET = 'AVAILABLE_BOOKS_SET';
export const NEW_PRESENCE_SET = 'NEW_PRESENCE_SET';
export const METADATA_SET = 'METADATA_SET';

// ------------------------------------
// Actions
// ------------------------------------

export const presenceListFetchingSet = (data) => {
  return {
    type: PRESENCE_LIST_FETCHING_SET,
    payload: data,
  };
};

export const presenceListSet = (data) => {
  return {
    type: PRESENCE_LIST_SET,
    payload: data,
  };
};

export const presenceSet = (data) => {
  return {
    type: PRESENCE_SET,
    payload: data,
  };
};

export const availableBooksSet = (data) => {
  return {
    type: AVAILABLE_BOOKS_SET,
    payload: data,
  };
};

export const newPresenceSet = (data) => {
  return {
    type: NEW_PRESENCE_SET,
    payload: data,
  };
};

export const metadataSet = (data) => {
  return {
    type: METADATA_SET,
    payload: data,
  };
};

// ------------------------------------
// Middleware
// ------------------------------------

export const getPresences = () => async (dispatch) => {
  dispatch(presenceListFetchingSet(true));
  try {
    const response = await api.get('admin/presences');
    dispatch(presenceListSet(response));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch presences'));
  } finally {
    dispatch(presenceListFetchingSet(false));
  }
};

export const presenceDisableEnable = (id) => async (dispatch) => {
  dispatch(presenceListFetchingSet(true));
  try {
    await api.patch(`admin/presence/${id}`);
  } catch (e) {
    dispatch(handleError(e, 'Failed to modify presence status'));
  }
};

export const deletePresence = (id) => async (dispatch) => {
  try {
    await api.delete(`admin/presence/${id}`);
    dispatch(handleSuccess('Presence Deleted Successfully'));
    dispatch(getPresences());
  } catch (e) {
    dispatch(handleError(e, 'Failed to delete presence'));
  }
};

export const getPresence = (id) => async (dispatch) => {
  try {
    dispatch(presenceListFetchingSet(true));
    const data = await api.get(`admin/presence/${id || ''}`);
    dispatch(presenceSet(data));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch presence'));
  } finally {
    dispatch(presenceListFetchingSet(false));
  }
};

export const getAvailableBooks = () => async (dispatch) => {
  dispatch(presenceListFetchingSet(true));
  try {
    const data = await api.get('admin/books');
    dispatch(availableBooksSet(data));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch presences'));
  } finally {
    dispatch(presenceListFetchingSet(false));
  }
};

export const presenceSave = (id, formData) => async (dispatch) => {
  dispatch(presenceListFetchingSet(true));

  const dataToSend = {
    PRESENCE_TITLE: formData.PRESENCE_TITLE,
    PRESENCE_URL: formData.PRESENCE_URL,
    DESCRIPTION: formData.DESCRIPTION,
    INITIAL_BOOK: formData.INITIAL_BOOK,
    POST_DECISION_BOOK_SEQUENCES: formData.POST_DECISION_BOOK_SEQUENCES,
    PRESENCE_SECURED: formData.PRESENCE_SECURED,
    DEALER_TYPES: formData.DEALER_TYPES,
    DEALER_GROUPS: formData.DEALER_GROUPS,
    DEALER_LOCATIONS: formData.DEALER_LOCATIONS,
    METADATA: formData.METADATA,
  };

  const useApi = id ? api.put : api.post;

  try {
    await useApi(`admin/presence/${id || ''}`, dataToSend);
    dispatch(newPresenceSet(false));
    dispatch(handleSuccess('Presence Saved Successfully!'));
  } catch (e) {
    dispatch(handleError(e, 'Error saving presence.'));
    throw e;
  } finally {
    dispatch(presenceListFetchingSet(false));
  }
  return undefined;
};

export const getMetadata = () => async (dispatch) => {
  try {
    const response = await api.get('admin/pageFields');
    dispatch(metadataSet(response));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch metadata'));
  }
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PRESENCE_LIST_FETCHING_SET]: (state, action) => {
    return Immutable({ ...state, fetching: action.payload });
  },
  [PRESENCE_LIST_SET]: (state, action) => {
    return Immutable({ ...state, formData: action.payload });
  },
  [PRESENCE_SET]: (state, action) => {
    return Immutable({
      ...state,
      presence: { ...state.presence, ...action.payload },
    });
  },
  [METADATA_SET]: (state, action) => {
    return Immutable({ ...state, metadata: action.payload });
  },
  [AVAILABLE_BOOKS_SET]: (state, action) => {
    return Immutable({
      ...state,
      presence: { ...state.presence, availableBooks: action.payload },
    });
  },
  [NEW_PRESENCE_SET]: (state, action) => {
    return Immutable({
      ...state,
      presence: { ...state.presence, newPresence: action.payload },
    });
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  mainView: 'list',
  deleteModal: { open: false, msg: '' },
};
export default function presenceListReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

import moment from 'moment';

const csvBase = '/fni-lenderportal-search/searching/generate/csv/';

export const translateQueuesFetch = (data) => JSON.parse(data);

export const translateFiltersFetch = (data) => JSON.parse(data);

export const createFilterDownloadUrl = (filters) => {
  return filters
    .map((filter, i) => {
      let str = `${encodeURIComponent(`searchCriteria[${i}].fieldId`)}=${filter.fieldId
        }&`;

      if (filter.searchValue) {
        str += `${encodeURIComponent(`searchCriteria[${i}].searchValue`)}=${filter.searchValue
          }`;
      } else if (filter.searchFrom) {
        str += `${encodeURIComponent(
          `searchCriteria[${i}].searchFrom`
        )}=${moment(filter.searchFrom * 1000).format(
          'YYYYMMDD'
        )}&${encodeURIComponent(`searchCriteria[${i}].searchTo`)}=${moment(
          filter.searchTo * 1000
        ).format('YYYYMMDD')}`;
      }
      return str;
    })
    .join('&');
};

export const translateDataRows = (data) => {
  return data.map((val, index) => {
    const newRow = {};
    newRow.tableId = index;
    val.forEach((v) => {
      if (v.dataColumn === 'ACTION') {
        newRow[v.dataColumn] = v.actions;
      } else if (v.dataColumn === 'REFNUM') {
        newRow[v.dataColumn] = v.dataValue;
        newRow.refnumLink = v.dataLink;
      } else if (
        v.dataColumn === 'TMRCVD' ||
        v.dataColumn === 'TMRCDE' ||
        v.dataColumn === 'TIME_LAST_UPDATE'
      ) {
        newRow[v.dataColumn] = v.dataValue
          ? moment(v.dataValue).format('M/DD/YYYY h:mm a')
          : '';
      } else {
        newRow[v.dataColumn] = v.dataValue;
      }
    });
    return newRow;
  });
};

export const translateQueueDataFetch = (
  data,
  queueGroupId,
  queueId,
  type,
  filters = []
) => {
  let parsedData;
  try {
    parsedData = JSON.parse(string);
  } catch (e) {
    parsedData = data
  }
  let link;
  if (type === 'queue') {
    link = `${csvBase + type}/${queueId}`;
  } else if (type === 'search') {
    link = `${csvBase + type}?${createFilterDownloadUrl(filters)}`;
  }

  // Do we have data?
  const newData = translateDataRows(parsedData?.dataRows ?? []);
  return {
    columns: parsedData?.columnDefs,
    rows: newData,
    resultsTitle: parsedData?.resultsTitle,
    totalRecords: parsedData?.totalRecordCount,
    resultsId: parsedData?.resultsId,
    queueGroupId,
    queueId,
    filterId: null,
    downloadLink: link,
  };
};

export const translateFilterDataFetch = (data, filterId) => {
  const parsedData = JSON.parse(data);
  const newData = translateDataRows(parsedData?.filterData?.dataRows ?? []);
  return {
    columns: parsedData?.filterData?.columnDefs,
    rows: newData,
    resultsTitle: parsedData?.filterData?.resultsTitle,
    totalRecords: parsedData?.filterData?.totalRecordCount,
    resultsId: parsedData?.filterData?.resultsId,
    queueGroupId: null,
    queueId: null,
    filterId,
  };
};

export const translateQueueDataFetchToBackend = (data) => data;

// Translator for action Fetch
export const translateActionFetch = (data) => {
  return data.actionInputs.map((input) => {
    if (input.fieldType === 'DROPDOWN') {
      input.ddOptions.map((option) => ({
        ...option,
        value: option.ddValue,
        label: option.ddLabel,
      }));
    }
    return data;
  });
};

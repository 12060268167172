import Immutable from 'seamless-immutable';

import deepEqual from 'deep-equal';
import { apiErrorSet } from './error';
import { makeApi } from 'fni-schema'
import { formDataSubmit } from 'fni-workbench-standard/store/formData';

// ------------------------------------
// Constants
// ------------------------------------
export const UNSAVED_CHANGES_MODAL_SET = 'UNSAVED_CHANGES_MODAL_SET';
export const APPLICATION_TIMEOUT_SET = 'APPLICATION_TIMEOUT_SET';
export const APPLICATION_TIMEOUT_MODAL_SET = 'APPLICATION_TIMEOUT_MODAL_SET';
export const DECISION_BLOCK_SET = 'DECISION_BLOCK_SET';
export const ERROR_BOX_SET = 'ERROR_BOX_SET';
export const FOCUS_ON_SET = 'FOCUS_ON_SET';
export const ERROR_BOX_CLEAR = 'ERROR_BOX_CLEAR';
export const NEW_NOTE_MODAL_OPEN = 'NEW_NOTE_MODAL_OPEN';
export const NEW_NOTE_MODAL_CLOSE = 'NEW_NOTE_MODAL_CLOSE';
export const ADVERSE_ACTION_RECEIVE = 'ADVERSE_ACTION_RECEIVE';
export const ADVERSE_ACTION_MODAL_SET = 'ADVERSE_ACTION_MODAL_SET';
export const ADVERSE_ACTION_SET = 'ADVERSE_ACTION_SET';
export const MESSAGE_MODAL_SET = 'MESSAGE_MODAL_SET';
export const MESSAGE_MODAL_ALERT_SET = 'MESSAGE_MODAL_ALERT_SET';
export const REFNUM_SET = 'WB_REFNUM_SET';
export const SCORE_MODAL_SET = 'SCORE_MODAL_SET';
export const FICO_SCORE_RECEIVE = 'FICO_SCORE_RECEIVE';
export const SEARCH_URL_SET = 'SEARCH_URL_SET';
export const DECISION_DD_SET = 'DECISION_DD_SET';
export const OLD_APP_SET = 'OLD_APP_SET';
export const HEADER_HEIGHTS_SET = 'HEADER_HEIGHTS_SET';
export const SET_MULTI_APP_INDICATOR = 'SET_MULTI_APP_INDICATOR';
export const FNI_FETCHING_SET = 'FNI_FETCHING_SET';
export const LOADING_INCREMENT = 'LOADING_INCREMENT';
export const LOADING_DECREMENT = 'LOADING_DECREMENT';
export const INFORMATION_SET = 'INFORMATION_SET'

export const BASE_PATH = '/fni-lenderportal-workbench/';
export const API_PATH = `${BASE_PATH}workbench/`;


export const api = makeApi({
  baseURL: API_PATH,
  headers: {
    'Content-Type': 'application/json',
  },
  withRequestInterceptor: false,
  withResponseInterceptor: false,
});
export const fetchingSet = (path, flag) => {
  return {
    type: FNI_FETCHING_SET,
    payload: { path, flag },
  };
};


export const incrementLoading = () => ({
  type: LOADING_INCREMENT,
});

export const decrementLoading = () => ({
  type: LOADING_DECREMENT,
});

export const informationSet = (data) => {
  return {
    type: INFORMATION_SET,
    payload: data,
  };
};

export const informationClear = (data) => {
  return {
    type: INFORMATION_CLEAR,
    payload: data,
  };
};

export const setInfo = (payload) => {
  return {
    type: SET_INFO,
    payload,
  };
};


export const showInfoBase = (
  id = '',
  error = '',
  { title = '', type = 'error', delay = false }
) => {
  return informationSet({
    id,
    type,
    title,
    message: error,
    delay,
  });
};

export const showInfo = (...args) => (dispatch) => {
  dispatch(showInfoBase(...args));
};

export const createDefaultShowInfo = (id, options = {}) => {
  return (error = options.error, newOptions = {}) => (dispatch) => {
    dispatch(
      showInfoBase(newOptions.id || id, error || options.defaultError, {
        ...options,
        ...newOptions,
      })
    );
  };
};





const translateActionFetch = (data) => {
  const _data = { ...data };
  try {
    const properties = {};
    _data.actionSchema.properties.forEach((field) => {
      properties[field.key] = field;
      delete properties[field.key].key;
    });
    _data.actionSchema.properties = properties;
    const formData = {};
    _data.formData.forEach((field) => {
      formData[field.fieldId] = field.fieldValue;
    });
    _data.formData = formData;
    return _data;
  } catch (e) {
    return _data;
  }
};

export const translateAdverseActionData = (data) => {
  const _data = { ...data };
  try {
    const dates = [];
    const properties = {};
    _data.adverseActionSchema.properties.forEach((field) => {
      const _field = { ...field };
      if (_field.format && _field.format === 'date') {
        dates.push(_field.key);
        _field.format = undefined;
      }
      if (_field.type === 'array') {
        const items = {};
        _field.items.properties.forEach((item) => {
          items[item.key] = item;
          delete items[item.key].key;
        });
        _field.items.properties = items;
      }
      properties[_field.key] = _field;
      delete properties[_field.key].key;
    });
    _data.adverseActionSchema.properties = properties;
    const formData = {};
    _data.formData.forEach((field) => {
      const _field = { ...field };
      if (_field.fieldValues) {
        _field.fieldValues = _field.fieldValues.map((items) => {
          const fv = {};
          items.forEach((item) => {
            if (item.fieldValue || item.fieldValue === '') {
              fv[item.fieldId] = item.fieldValue;
            } else if (item.fieldValues) {
              fv[item.fieldId] = item.fieldValues.map((fld) => {
                const obj = {};
                fld.forEach((f) => {
                  obj[f.fieldId] = f.fieldValue;
                });
                return obj;
              });
            }
          });
          return fv;
        });
      }
      let val = _field.fieldValue || _field.fieldValues;
      val = val === '' ? undefined : val;
      if (dates.includes(_field.fieldId)) {
        const regex = /(\d{4})-(\d{2})-(\d{2})/;
        const arr = regex.exec(_field.fieldValue);
        if (arr) {
          val = arr[2] + arr[3] + arr[1];
        }
      }
      formData[_field.fieldId] = val;
    });
    _data.formData = formData;
    _data.dates = dates;
    return _data;
  } catch (e) {
    return _data;
  }
};

let appTimeoutWarn = null;
let appTimeoutKick = null;

// ------------------------------------
// Actions
// ------------------------------------
export const setHeaderHeights = (payload) => {
  return {
    type: HEADER_HEIGHTS_SET,
    payload,
  };
};

export const newNoteModalOpen = () => {
  return {
    type: NEW_NOTE_MODAL_OPEN,
  };
};

export const newNoteModalClose = () => {
  return {
    type: NEW_NOTE_MODAL_CLOSE,
  };
};

export const unsavedChangesModalSet = (show, type, path) => {
  return {
    type: UNSAVED_CHANGES_MODAL_SET,
    payload: { show, type, path },
  };
};

export const applicationTimeoutSet = (minutes) => {
  return {
    type: APPLICATION_TIMEOUT_SET,
    payload: minutes,
  };
};

export const applicationTimeoutModalSet = (open) => {
  return {
    type: APPLICATION_TIMEOUT_MODAL_SET,
    payload: open,
  };
};

export const decisionBlockSet = (data) => {
  return {
    type: DECISION_BLOCK_SET,
    payload: data,
  };
};

export const errorBoxSet = (path, data) => {
  return {
    type: ERROR_BOX_SET,
    payload: {
      path,
      data,
    },
  };
};

export const focusOnSet = (data) => {
  return {
    type: FOCUS_ON_SET,
    payload: data,
  };
};

export const errorBoxClear = (path, field) => {
  return {
    type: ERROR_BOX_CLEAR,
    payload: {
      path,
      field,
    },
  };
};

export const adverseActionModalSet = (open, selected) => {
  return {
    type: ADVERSE_ACTION_MODAL_SET,
    payload: {
      data: open,
      selected,
    },
  };
};

export const messageModalSet = (data, type, message, msgType) => {
  return {
    type: MESSAGE_MODAL_SET,
    payload: {
      data,
      type,
      message,
      msgType,
    },
  };
};

export const messageModalAlertSet = (data) => {
  return {
    type: MESSAGE_MODAL_ALERT_SET,
    payload: {
      alert: data,
    },
  };
};

export const refnumSet = (
  refnum,
  targetStatus,
  readOnlyWorkbench,
  lockIndicator,
  lockedByUser,
  lockedDateTime,
  numApplicants,
  others = false
) => {
  return {
    type: REFNUM_SET,
    payload: {
      refnum,
      targetStatus,
      readOnlyWorkbench,
      lockIndicator,
      lockedByUser,
      lockedDateTime,
      numApplicants,
      ...others,
    },
  };
};

export const scoreModalSet = (data) => {
  return {
    type: SCORE_MODAL_SET,
    payload: data,
  };
};

export const searchUrlSet = (url) => {
  return {
    type: SEARCH_URL_SET,
    payload: url,
  };
};

export const decisionDdSet = (data) => {
  return {
    type: DECISION_DD_SET,
    payload: data,
  };
};

export const setMultiAppIndicator = (payload) => {
  return {
    type: SET_MULTI_APP_INDICATOR,
    payload,
  };
};

export const vehicleTypes = ['SALE', 'TRADE'];

export const detectChanges = () => (dispatch, getState) => {
  const { formData } = getState();
  const keys = Object.keys(formData);
  let thereAreChanges = false;
  keys.forEach((path) => {
    if (
      formData?.[path]?.originalFormData &&
      !deepEqual(formData?.[path]?.formData, formData?.[path]?.originalFormData)
    ) {
      thereAreChanges = true;
    }
  });

  return thereAreChanges;
};

export const submitAllData = (code, cb) => async (dispatch) => {
  try {
    await dispatch(formDataSubmit(code));
    if (typeof cb === 'function') cb();
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    throw e;
  }
};

export const redirect = (url) => {
  setTimeout(() => {
    window.location.replace(url);
  }, 1500);
};

export const applyApplicationTimeout = (refresh = true, minutes = null) => {
  let _minutes = minutes;
  return async (dispatch, getState) => {
    if (_minutes) {
      dispatch(applicationTimeoutSet(_minutes));
    } else {
      _minutes = getState().coreLayout.applicationTimer.minutes;
    }
    clearTimeout(appTimeoutWarn);
    clearTimeout(appTimeoutKick);
    appTimeoutWarn = setTimeout(() => {
      dispatch(applicationTimeoutModalSet(true));
    }, (_minutes - 1) * 60 * 1000);
    appTimeoutKick = setTimeout(() => {
      redirect(getState().coreLayout.searchUrl);
    }, _minutes * 60 * 1000);
    if (refresh) {
      await api.put('action', { actionCode: 'KEEPLOCK' })

    }
  };
};

export const fetchActionData = () => {
  return async (dispatch, getState) => {
    const response = await api.get('action')

    if (response.status == 200) {
      let _text = response.data;
      dispatch(searchUrlSet(_text.searchUrl));
      if (_text.status !== 'ERROR') {
        // text.readOnlyWorkbench = true;
        window.refnum = _text.refnum;
        const additionalInfo = {
          multipleAppIndicator: _text.multipleAppIndicator,
          otherRefnum: _text.otherRefnum,
          workbenchUrl: _text.workbenchUrl,
        };
        dispatch(
          refnumSet(
            _text.refnum,
            _text.targetStatus,
            _text.readOnlyWorkbench,
            _text.lockIndicator,
            _text.lockedByUser,
            _text.lockedDateTime,
            _text.numApplicants || false,
            additionalInfo
          )
        );
        _text = translateActionFetch(_text);
        if (!_text.formData.DECISION_DD) {
          _text.formData.DECISION_DD = 'NO_DEC';
        }
        dispatch(decisionBlockSet(_text));
        dispatch(applyApplicationTimeout(false, _text.appLockMinutes));
      } else {
        let errorMsg = _text.errorMsg.message;
        if (_text.errorMsg.errorType === 'LOCK') {
          errorMsg = 'Application is being viewed by another user.';
        }
        dispatch(
          apiErrorSet(
            getState().location.location.pathname.split('/')[2],
            errorMsg,
            _text.errorMsg.errorType
          )
        );
      }
    }
  }
};

export const adversedecrementLoading = (data) => {
  return {
    type: ADVERSE_ACTION_RECEIVE,
    payload: data,
  };
};

export const adverseActionSet = (code, value) => {
  return {
    type: ADVERSE_ACTION_SET,
    payload: { code, value },
  };
};

export const adverseActionFetch = (actionCode, options = {}) => {
  const { initial = false } = options;
  return async (dispatch) => {
    const response = await api.get('adverseAction/${actionCode}')

    if (response.status == 200) {
      let _text = response.data;
      if (_text !== 'Server Error') {
        _text = JSON.parse(_text);
        _text = translateAdverseActionData(_text);
        dispatch(adversedecrementLoading(_text));
        if (
          Object.keys(_text?.adverseActionSchema?.properties ?? {})
            .length &&
          !initial
        ) {
          dispatch(adverseActionModalSet(true, 'CH1'));
        }
      }
    }
   
  };
};

export const ficoScoreReceive = (data) => {
  return {
    type: FICO_SCORE_RECEIVE,
    payload: data,
  };
};

export const ficoScoreFetch = () => {
  return async (dispatch) => {
    const response = await api.get('adverseAction/${actionCode}')

    if (response.status == 200) {
      let _text = response.data;
      if (_text !== 'Server Error') {
        _text = JSON.parse(_text);
        dispatch(ficoScoreReceive(_text));
      } 
    }
    
  };
};

export const exitWithoutSaving = (path) => {
  return (dispatch, getState) => {
    dispatch(
      formDataSubmit(
        getState().coreLayout.decisionBlock.actionSchema.properties.CLOSE_BTN
          .actionCode
      )
    )
      .then(() => {
        redirect(path || getState().coreLayout.searchUrl);
        return undefined;
      })
      .catch((e) => {
        throw e;
      });
  };
};

export const saveAndExit = (code, path) => {
  return (dispatch, getState) => {
    dispatch(
      submitAllData(code, () => {
        dispatch(
          formDataSubmit(
            getState().coreLayout.decisionBlock.actionSchema.properties
              .CLOSE_BTN.actionCode
          )
        )
          .then(() => {
            redirect(path || getState().coreLayout.searchUrl);
            return undefined;
          })
          .catch((e) => {
            throw e;
          });
      })
    );
  };
};

export const oldAppSet = (info) => {
  return {
    type: OLD_APP_SET,
    payload: info,
  };
};

export const actions = {
  fetchActionData,
  oldAppSet,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [NEW_NOTE_MODAL_OPEN]: (state) => {
    return Immutable({
      ...state,
      newNote: { modalOpen: true },
    });
  },
  [NEW_NOTE_MODAL_CLOSE]: (state) => {
    return Immutable({
      ...state,
      newNote: { modalOpen: false },
    });
  },
  [UNSAVED_CHANGES_MODAL_SET]: (state, action) => {
    return Immutable({
      ...state,
      unsavedChangesModal: action.payload,
    });
  },
  [APPLICATION_TIMEOUT_SET]: (state, action) => {
    return Immutable({
      ...state,
      applicationTimer: { minutes: action.payload, modal: false },
    });
  },
  [APPLICATION_TIMEOUT_MODAL_SET]: (state, action) => {
    return Immutable({
      ...state,
      applicationTimer: { ...state.applicationTimer, modal: action.payload },
    });
  },
  [DECISION_BLOCK_SET]: (state, action) => {
    return Immutable({
      ...state,
      decisionBlock: {
        actionSchema: action.payload.actionSchema,
        formData: action.payload.formData,
      },
    });
  },
  [ERROR_BOX_SET]: (state, action) => {
    return Immutable({
      ...state,
      errors: { ...state.errors, [action.payload.path]: action.payload.data },
    });
  },
  [FOCUS_ON_SET]: (state, action) => {
    return Immutable({ ...state, focusOn: action.payload });
  },
  [ERROR_BOX_CLEAR]: (state, action) => {
    if (state.errors[action.payload.path]) {
      const newErrors = Immutable.asMutable(state.errors[action.payload.path], {
        deep: true,
      });
      const index = newErrors.findIndex(
        (error) => error.fieldId === action.payload.field
      );
      if (index >= 0) {
        newErrors.splice(index, 1);
      }
      return Immutable({
        ...state,
        errors: { ...state.errors, [action.payload.path]: newErrors },
      });
    }
    return state;
  },
  [ADVERSE_ACTION_RECEIVE]: (state, action) => {
    const formData = Immutable.asMutable(action.payload.formData, {
      deep: true,
    });
    const usedVals = {
      CH1: [],
      CH2: [],
    };
    const keys = Object.keys(formData);
    keys.forEach((key) => {
      const type = key.includes('CH1') ? 'CH1' : 'CH2';
      if (usedVals[type].indexOf(formData[key]) === -1)
        usedVals[type].push(formData[key]);
    });
    return Immutable({
      ...state,
      adverseActions: { ...state.adverseActions, ...action.payload, usedVals },
    });
  },
  [ADVERSE_ACTION_MODAL_SET]: (state, action) => {
    return Immutable({
      ...state,
      adverseActions: {
        ...state.adverseActions,
        modalOpen: action.payload.data,
        selected: action.payload.selected,
      },
    });
  },
  [ADVERSE_ACTION_SET]: (state, action) => {
    const formData = Immutable.asMutable(state.adverseActions.formData, {
      deep: true,
    });
    const usedVals = {
      CH1: [],
      CH2: [],
    };
    formData[action.payload.code] = action.payload.value;
    const keys = Object.keys(formData);
    keys.forEach((key) => {
      const type = key.includes('CH1') ? 'CH1' : 'CH2';
      if (usedVals[type].indexOf(formData[key]) === -1)
        usedVals[type].push(formData[key]);
    });
    return Immutable({
      ...state,
      adverseActions: { ...state.adverseActions, formData, usedVals },
    });
  },
  [MESSAGE_MODAL_SET]: (state, action) => {
    return Immutable({
      ...state,
      messageModal: {
        ...state.messageModal,
        modalOpen: action.payload.data,
        type: action.payload.type,
        message: action.payload.message,
        msgType: action.payload.msgType,
      },
    });
  },
  [MESSAGE_MODAL_ALERT_SET]: (state, action) => {
    return Immutable({
      ...state,
      messageModal: {
        ...state.messageModal,
        alert: action.payload.alert,
      },
    });
  },
  [REFNUM_SET]: (state, action) => {
    return Immutable({ ...state, ...action.payload });
  },
  [SCORE_MODAL_SET]: (state, action) => {
    return Immutable({ ...state, scoreModal: { modalOpen: action.payload } });
  },
  [FICO_SCORE_RECEIVE]: (state, action) => {
    return Immutable({ ...state, fico: action.payload });
  },
  [SEARCH_URL_SET]: (state, action) => {
    return Immutable({ ...state, searchUrl: action.payload });
  },
  [DECISION_DD_SET]: (state, action) => {
    const newState = Immutable.asMutable(state, { deep: true });
    newState.decisionBlock.formData.DECISION_DD = action.payload;
    return Immutable(newState);
  },
  [OLD_APP_SET]: (state) => {
    return Immutable.setIn(state, ['oldApp'], true);
  },
  [HEADER_HEIGHTS_SET]: (state, action) => {
    return Immutable.setIn(state, ['headerHeights'], action.payload);
  },
  [SET_MULTI_APP_INDICATOR]: (state, action) => {
    return Immutable.setIn(state, ['multipleAppIndicator'], action.payload);
  },
  [FNI_FETCHING_SET]: (state, { payload }) => {
    const path = Array.isArray(payload.path) ? payload.path : [payload.path];
    return Immutable.setIn(state, ['fetching', ...path], payload.flag);
  },
  [LOADING_INCREMENT]: (state) =>
    Immutable.setIn(state, ['loading'], state.loading + 1),
  [LOADING_DECREMENT]: (state) =>
    Immutable.setIn(state, ['loading'], state.loading - 1),
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  fetching: {},
  loading: 0,
  closeConfirmModal: {
    modalOpen: false,
  },
  unsavedChangesModal: {
    show: false,
    type: 'exit',
    path: '',
  },
  applicationTimer: {
    modal: false,
  },
  errors: {},
  newNote: {
    modalOpen: false,
  },
  adverseActions: {
    modalOpen: false,
    selected: '',
  },
  messageModal: {
    modalOpen: false,
    type: '',
    message: {},
    msgType: '',
    alert: { show: false },
  },
  scoreModal: { modalOpen: false },
  oldApp: false,
};

export default function coreLayoutReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

import Immutable from 'seamless-immutable';
import SchemaConverter from 'fni-components/FNISchemaForm/utils/SchemaConverter';
import _get from 'lodash/get';

import { setInfo } from '../../store/coreLayout';
import apiFetch from '../../utils/apiFetch';

class FNIStandardRedux {
  constructor(path, apiPath) {
    this.prepend = path.toUpperCase();
    this.path = path;
    this.apiPath = apiPath;
    this.handlers = {};
    this.createHandler(`${this.prepend}_FETCHING`, (state, action) => {
      return Immutable.setIn(state, ['fetching'], action.payload.flag);
    });
    this.setter = this.createSet();
  }

  fetching = (path, flag) => {
    return {
      type: `${this.prepend}_FETCHING`,
      payload: { path, flag },
    };
  };

  setDefaultError = (send = false) => (dispatch) => {
    let message = 'There was an error retrieving data.';
    if (send) {
      message = 'There was an error sending data.';
    }
    window.scrollTo(0, 0);
    dispatch(
      setInfo({
        type: 'error',
        message,
      })
    );
  };

  returnSetterAction = () => (path, data) => {
    return {
      type: `${this.prepend}_SET`,
      payload: { path, data },
    };
  };

  getSet = () => this.setter;

  createSet = (type = false) => {
    const name = `${this.prepend}_${type ? `${type}_` : ''}SET`;
    this.handlers[name] = (state, action) => {
      return Immutable.setIn(state, action.payload.path, action.payload.data);
    };
    return (path, data) => ({
      type: name,
      payload: { path, data },
    });
  };

  createHandler = (name, func) => {
    this.handlers[name] = func;
  };

  returnHandlers = () => this.handlers;

  fetch = (path, savePath = [''], dataCB = false) => (dispatch) => {
    dispatch(this.fetching(savePath, true));
    apiFetch(this.apiPath + path)
      .then((response) => {
        if (response.ok) {
          return response.text();
        }
        dispatch(this.setDefaultError());
        return undefined;
      })
      .then((text) => {
        let data = JSON.parse(text);
        data = SchemaConverter.translateFromBackend({
          schema: data.adminSchema || data.schema,
          formData: data.formData,
        });
        if (dataCB) {
          data = dataCB(data);
        }
        dispatch(this.setter(savePath, data));
        dispatch(this.fetching(savePath, false));
        return undefined;
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        if (process.env.NODE_ENV === 'development') console.error(e);
      });
  };

  send = (data, path, { dataFormat, put } = {}) => (dispatch, getState) => {
    let dataCopy = data
      ? Immutable.asMutable(_get(getState()[this.path], data, {}), {
          deep: true,
        })
      : {};
    if (dataFormat) {
      dataCopy = dataFormat(dataCopy);
    } else {
      dataCopy = SchemaConverter.translateToBackend(dataCopy);
    }
    const options = {
      method: put ? 'PUT' : 'POST',
      body: JSON.stringify(dataCopy),
    };
    return new Promise((resolve, reject) => {
      apiFetch(this.apiPath + path, options)
        .then((response) => {
          if (response.ok) {
            return response.text();
          }
          dispatch(this.setDefaultError(true));
          reject();
          return undefined;
        })
        .then((text) => {
          try {
            const parsed = JSON.parse(text);
            if (parsed.status === 'ERROR') {
              window.scrollTo(0, 0);
              const message = parsed.errorMsgs[0].message
                ? parsed.errorMsgs[0].message
                : parsed.errorMsgs[0];
              dispatch(
                setInfo({
                  type: 'error',
                  message,
                })
              );
              reject();
              // throw new Error(message);
            }
            if (parsed.status === 'SUCCESS') {
              window.scrollTo(0, 0);
              dispatch(
                setInfo({
                  type: 'success',
                  message: 'Data saved successfully',
                  delay: 3000,
                })
              );
            }
            resolve();
          } catch (e) {
            resolve(true);
          }
          return undefined;
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          if (process.env.NODE_ENV === 'development') console.error(e);
        });
    });
  };
}

export default FNIStandardRedux;

import Immutable from 'seamless-immutable';
import { makeApi, translateToBackend } from 'fni-schema';

import { setInfo } from '../../../store/coreLayout';

export const api = makeApi({
  baseURL: 'fni-lenderportal-admin/admin/queue',
  schemaKey: 'adminSchema',
  withRequestInterceptor: false,
});

// ------------------------------------
// Constants
// ------------------------------------
const SET_QUEUE = 'SET_QUEUE';
const SET_QUEUE_FETCHING = 'SET_QUEUE_FETCHING';

// ------------------------------------
// Actions
// ------------------------------------
export const setQueue = (data, path) => {
  return {
    type: SET_QUEUE,
    payload: { data, path },
  };
};

export const setFetching = (payload) => {
  return {
    type: SET_QUEUE_FETCHING,
    payload,
  };
};

// ------------------------------------
// Middleware
// ------------------------------------

export const setSearch = (search) => (dispatch) => {
  dispatch(setQueue(search, ['search']));};
// GET /admin/queue/all
export const getQueues = () => async (dispatch) => {
  dispatch(setFetching(true));

  try {
    const response = await api.get('/all');
    dispatch(setQueue(response, ['queues']));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    if (e.message) dispatch(setInfo(e.message));
    else {
      dispatch(setInfo('Failed to fetch queues'));
    }
  } finally {
    dispatch(setFetching(false));
  }
};

// GET /admin/queue/group/all
export const getQueueGroups = () => async (dispatch) => {
  dispatch(setFetching(true));

  try {
    const response = await api.get('/group/all');
    dispatch(setQueue(response, ['groups']));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    if (e.message) dispatch(setInfo(e.message));
    else {
      dispatch(setInfo('Failed to fetch queue groups'));
    }
  } finally {
    dispatch(setFetching(false));
  }
};

// PUT fni-lenderportal-admin/admin/queue/update
export const updateQueue = (data) => async (dispatch) => {
  try {
    await api.put('/update', {
      fieldId: 'UPDATE_QUEUE',
      fieldValues: [translateToBackend([data])],
    });
    dispatch(
      setInfo({
        type: 'success',
        message: 'Save Successful',
        delay: 3000,
      }),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    if (e.message) dispatch(setInfo(e.message));
    else {
      dispatch(setInfo('Failed to save queue'));
    }
    throw e;
  }
};

// POST fni-lenderportal-admin/admin/queue/group/create
export const postQueueGroup = (data) => async (dispatch) => {
  try {
    await api.post('/group/create', {
      fieldId: 'CREATE_QUEUE_GROUP',
      fieldValues: [translateToBackend([data])],
    });
    dispatch(
      setInfo({
        type: 'success',
        message: 'Save Successful',
        delay: 3000,
      }),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    if (e.message) dispatch(setInfo(e.message));
    else {
      dispatch(setInfo('Failed to save queue group'));
    }
    throw e;
  }
};

// POST fni-lenderportal-admin/admin/queue/remove
export const deleteQueue = (data) => async (dispatch) => {
  try {
    await api.post('/remove', {
      fieldId: 'REMOVE_QUEUE',
      fieldValues: [translateToBackend([data])],
    });
    dispatch(
      setInfo({
        type: 'success',
        message: 'Save Successful',
        delay: 3000,
      }),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    if (e.message) dispatch(setInfo(e.message));
    else {
      dispatch(setInfo('Failed to delete queue'));
    }
    throw e;
  }
};

// POST fni-lenderportal-admin/admin/queue/create
export const createQueue = (data) => async (dispatch) => {
  try {
    await api.post('/create', {
      fieldId: 'CREATE_QUEUE',
      fieldValues: [translateToBackend([data])],
    });
    dispatch(
      setInfo({
        type: 'success',
        message: 'Save Successful',
        delay: 3000,
      }),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    if (e.message) dispatch(setInfo(e.message));
    else {
      dispatch(setInfo('Failed to create queue'));
    }
    throw e;
  }
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_QUEUE]: (state, { payload: { data, path } }) => {
    return Immutable.setIn(state, path, data);
  },
  [SET_QUEUE_FETCHING]: (state, { payload }) => {
    return Immutable.setIn(state, ['fetching'], payload);
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};
export default function queueReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

import Immutable from 'seamless-immutable';
import { makeApi } from 'fni-schema';

import { handleError, handleSuccess } from '../../../utils/infoBarHandlers';

export const api = makeApi({
  baseURL: 'fni-lenderportal-admin/admin',
});

export const blobApi = makeApi({
  baseURL: 'fni-lenderportal-admin/admin',
  responseType: 'blob',
  withResponseInterceptor: false,
  withRequestInterceptor: false,
});

export const uploadApi = makeApi({
  baseURL: 'fni-lenderportal-admin/admin',
  withRequestInterceptor: false,
  header: {
    'Content-Type': 'multipart/form-data',
  },
});

// ------------------------------------
// Constants
// ------------------------------------
const SET_FACILITY_GROUP = 'SET_FACILITY_GROUP';
const INCREMENT_FETCH_COUNT = 'INCREMENT_FETCH_COUNT';
const DECREMENT_FETCH_COUNT = 'DECREMENT_FETCH_COUNT';

// ------------------------------------
// Actions
// ------------------------------------
export const setFacilityGroup = (data, path) => {
  return {
    type: SET_FACILITY_GROUP,
    payload: { data, path },
  };
};

export const incrementFetchCount = () => {
  return {
    type: INCREMENT_FETCH_COUNT,
  };
};

export const decrementFetchCount = () => {
  return {
    type: DECREMENT_FETCH_COUNT,
  };
};

// ------------------------------------
// Middleware
// ------------------------------------

// GET fni-lenderportal-admin/admin/facilityGroups
export const getFacilityGroups = () => async (dispatch) => {
  dispatch(incrementFetchCount());

  try {
    const response = await api.get('/facilityGroups');
    dispatch(setFacilityGroup(response, ['groups']));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch facility groups'));
  } finally {
    dispatch(decrementFetchCount());
  }
};

// GET fni-lenderportal-admin/admin/facilityGroup/{id}
export const getFacilityGroupById =
  (id = '') =>
  async (dispatch) => {
    dispatch(incrementFetchCount());

    try {
      const response = await api.get(`/facilityGroup/${id}`);
      dispatch(setFacilityGroup(response, ['group']));
    } catch (e) {
      dispatch(handleError(e, 'Failed to fetch facility group'));
    } finally {
      dispatch(decrementFetchCount());
    }
  };

// PUT fni-lenderportal-admin/admin/facilityGroup/{id}
export const putFacilityGroupById = (id, data) => async (dispatch) => {
  dispatch(incrementFetchCount());

  try {
    await api.put(`/facilityGroup/${id}`, data);
    dispatch(handleSuccess('Save Successful'));
  } catch (e) {
    dispatch(handleError(e, 'Failed to save facility group'));
    throw e;
  } finally {
    dispatch(decrementFetchCount());
  }
};

// POST fni-lenderportal-admin/admin/facilityGroup
export const postFacilityGroup = (data) => async (dispatch) => {
  dispatch(incrementFetchCount());

  let groupID = false;

  try {
    const response = await api.post('/facilityGroup', data);
    groupID = response?.formData?.DEFAULT_FIELDS?.FACILITY_GROUP_ID;
    dispatch(handleSuccess('Save Successful'));
  } catch (e) {
    dispatch(handleError(e, 'Failed to save facility group'));
    throw e;
  } finally {
    dispatch(decrementFetchCount());
  }
  return groupID;
};

function getImageUrl({ data, status }) {
  // 204 = No Content
  if (status === 204) {
    return '';
  }
  return URL.createObjectURL(data);
}

// GET fni-lenderportal-admin/admin/facilityGroup/logo/{id}
export const getImageById =
  (id, refresh = false) =>
  async (dispatch, getState) => {
    const cached = getState()?.facilityGroups?.image?.[id];
    if (!cached || refresh)
      try {
        const response = await blobApi.get(`/facilityGroup/logo/${id}`);
        const url = getImageUrl(response);
        dispatch(setFacilityGroup(url, ['image', id]));
      } catch (e) {
        dispatch(handleError(e, 'Failed to fetch logo'));
      }
  };

// POST fni-lenderportal-admin/admin/facilityGroup/logo/{id}
export const uploadImageById = (id, data) => async (dispatch) => {
  const form = new FormData();
  form.append('logo', data);

  try {
    await uploadApi.post(`/facilityGroup/logo/${id}`, form);
    dispatch(handleSuccess('Successfully uploaded logo'));
  } catch (e) {
    dispatch(handleError(e, 'Failed to upload logo'));
    throw e; // necessary to prevent overwriting the existing logo on failure
  }
};

// GET fni-lenderportal-admin/admin/facilityLocations/{id}
export const getFacilityLocationsById =
  (id = '') =>
  async (dispatch) => {
    dispatch(incrementFetchCount());

    try {
      const response = await api.get(`/facilityLocations/${id}`);
      dispatch(setFacilityGroup(response, ['locations']));
    } catch (e) {
      dispatch(handleError(e, 'Failed to fetch facility locations'));
    } finally {
      dispatch(decrementFetchCount());
    }
  };

// GET fni-lenderportal-admin/admin/facilityLocation/{id}
export const getFacilityLocationById =
  (id = '') =>
  async (dispatch) => {
    dispatch(incrementFetchCount());

    try {
      const response = await api.get(`/facilityLocation/${id}`);
      dispatch(setFacilityGroup(response, ['location']));
    } catch (e) {
      dispatch(handleError(e, 'Failed to fetch facility location'));
    } finally {
      dispatch(decrementFetchCount());
    }
  };

// PUT fni-lenderportal-admin/admin/facilityLocation/{id}
export const putFacilityLocationById = (id, data) => async (dispatch) => {
  dispatch(incrementFetchCount());

  try {
    await api.put(`/facilityLocation/${id}`, data);
    dispatch(handleSuccess('Save Successful'));
  } catch (e) {
    dispatch(handleError(e, 'Failed to update facility location'));
    throw e;
  } finally {
    dispatch(decrementFetchCount());
  }
};

// POST fni-lenderportal-admin/admin/facilityLocation
export const postFacilityLocation = (data) => async (dispatch) => {
  dispatch(incrementFetchCount());
  const newData = Immutable.asMutable(data);
  delete newData.DEFAULT_FIELDS.CORP_IND;

  try {
    await api.post('/facilityLocation', newData);
    dispatch(handleSuccess('Save Successful'));
  } catch (e) {
    dispatch(handleError(e, 'Failed to create facility location'));
    throw e;
  } finally {
    dispatch(decrementFetchCount());
  }
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_FACILITY_GROUP]: (state, { payload: { data, path } }) => {
    return Immutable.setIn(state, path, data);
  },
  [INCREMENT_FETCH_COUNT]: (state) => {
    const count = state?.fetchCount;
    return Immutable.setIn(state, ['fetchCount'], count + 1);
  },
  [DECREMENT_FETCH_COUNT]: (state) => {
    const count = state?.fetchCount;
    return Immutable.setIn(state, ['fetchCount'], count - 1);
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { fetchCount: 0 };
export default function facilityGroupAdminReducer(
  state = initialState,
  action
) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

import PropTypes from 'prop-types';
import React from 'react';
import QueueItem from '../QueueItem/QueueItem';
import styles from './QueueGroup.module.scss';

const QueueGroup = ({
  groupName,
  recordsInGroup,
  queues,
  queueDataFetch,
  groupID,
}) => {
  return (
    <div>
      <h5 className={styles.groupHeader}>
        {groupName} <small>({recordsInGroup})</small>
      </h5>
      <ul className={styles.queueList}>
        {queues.map((queue) => {
          return (
            <QueueItem
              queueDataFetch={queueDataFetch}
              key={queue.queueId}
              queue={queue}
              groupID={groupID}
            />
          );
        })}
      </ul>
    </div>
  );
};

QueueGroup.propTypes = {
  groupName: PropTypes.string.isRequired,
  groupID: PropTypes.number.isRequired,
  queues: PropTypes.arrayOf(PropTypes.any).isRequired,
  recordsInGroup: PropTypes.number.isRequired,
  queueDataFetch: PropTypes.func.isRequired,
};

export default QueueGroup;

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import NumberFormat from 'react-number-format';
import { ErrorControl } from 'fni-schema';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';

import CalculatorDisplay from './CalculatorDisplay';

const required = (value) =>
  Number.isNaN(value) || value === '' || value === undefined
    ? 'Required'
    : undefined;

const greaterThanZero = (value) =>
  Number(value) > 0 ? undefined : 'Value must be greater than zero';

const lessThanPurchasePrice = (value, allValues) => {
  const { PURCHASE_PRICE = 0 } = allValues;
  return Number(value) < Number(PURCHASE_PRICE)
    ? undefined
    : 'Down Payment must be lower than Purchase Price';
};

const composeValidators = (...validators) => (value, allValues) =>
  validators.reduce(
    (error, validator) => error || validator(value, allValues),
    undefined
  );

const calculateMonthlyPayment = (
  PURCHASE_PRICE,
  DOWN_PAYMENT,
  INTEREST_RATE,
  LOAN_TERM
) => {
  const interestRate = parseFloat(INTEREST_RATE);
  const loanTerm = parseInt(LOAN_TERM);
  const principalAmount = parseFloat(
    parseFloat(PURCHASE_PRICE) - parseFloat(DOWN_PAYMENT)
  );

  const i = interestRate / 100.0 / 12.0;
  const tau = 1.0 + i;
  const tauToTheN = tau ** loanTerm;
  const magicNumber = (tauToTheN * i) / (tauToTheN - 1.0);
  return {
    monthlyPayment: parseFloat(principalAmount * magicNumber).toFixed(2),
    amtFinanced: principalAmount,
  };
};

const useStyles = makeStyles((theme) => ({
  formField: {
    marginTop: theme?.spacing(2),
  },
  buttonSide: {
    marginLeft: theme?.spacing(2),
  },
  noPadding: {
    padding: '0px',
    '&:first-child': {
      padding: '0px',
    },
  },
}));

const initialValues = {
  PURCHASE_PRICE: 0,
  DOWN_PAYMENT: 0,
  INTEREST_RATE: 0,
  LOAN_TERM: 0,
  CALCULATED: 0.0,
};

const calculateMutator = (args, state, { changeValue }) => {
  const {
    formState: {
      submitFailed,
      values: { PURCHASE_PRICE, DOWN_PAYMENT, INTEREST_RATE, LOAN_TERM },
    },
  } = state;
  if (!submitFailed) {
    changeValue(state, 'CALCULATED', () =>
      calculateMonthlyPayment(
        PURCHASE_PRICE,
        DOWN_PAYMENT,
        INTEREST_RATE,
        LOAN_TERM
      )
    );
  }
};

const Calculator = ({
  open,
  onClose,
  minRate = false,
  maxRate = false,
  disclosure,
}) => {
  const checkRate = React.useCallback(
    (value) => {
      if (maxRate && Number(value) > maxRate)
        return `Rate must be lower than ${maxRate}`;
      if (minRate && Number(value) < minRate)
        return `Rate must be greater than ${minRate}`;
      return undefined;
    },
    [minRate, maxRate]
  );

  const classes = useStyles();

  return (
    <Dialog onClose={onClose} open={open}>
      <Form
        initialValues={initialValues}
        onSubmit={() => {}}
        subscription={{}}
        mutators={{ calculate: calculateMutator }}
        validateOnBlur
      >
        {({
          form: {
            mutators: { calculate },
            submit,
          },
        }) => (
          <>
            <DialogContent className={classes?.noPadding}>
              <Box display="flex">
                <CalculatorDisplay>
                  <Box
                    display="flex"
                    bgcolor="grey.200"
                    flexDirection="column"
                    p={2}
                  >
                    <Field
                      name="PURCHASE_PRICE"
                      validate={composeValidators(required, greaterThanZero)}
                      validateFields={['DOWN_PAYMENT']}
                    >
                      {({
                        input: { value, onChange, onBlur },
                        meta: { error, touched },
                      }) => (
                        <ErrorControl
                          id="purchase-price"
                          errors={error && touched ? [error] : []}
                        >
                          <NumberFormat
                            className={classes?.formField}
                            onValueChange={({ value: rawValue }) =>
                              onChange(rawValue)
                            }
                            required
                            onBlur={onBlur}
                            value={value}
                            thousandSeparator
                            decimalScale={2}
                            customInput={TextField}
                            label="Purchase Price"
                            InputLabelProps={{
                              htmlFor: 'purchase-price-input',
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                              id: 'purchase-price-input',
                            }}
                          />
                        </ErrorControl>
                      )}
                    </Field>
                    <Field
                      name="DOWN_PAYMENT"
                      validate={composeValidators(
                        required,
                        lessThanPurchasePrice
                      )}
                      validateFields={[]}
                    >
                      {({
                        input: { value, onChange, onBlur },
                        meta: { error, touched },
                      }) => (
                        <ErrorControl
                          id="down-payment"
                          errors={error && touched ? [error] : []}
                        >
                          <NumberFormat
                            className={classes?.formField}
                            onValueChange={({ value: rawValue }) =>
                              onChange(rawValue)
                            }
                            value={value}
                            required
                            onBlur={onBlur}
                            thousandSeparator
                            decimalScale={2}
                            customInput={TextField}
                            label="Down Payment"
                            InputLabelProps={{
                              htmlFor: 'down-payment-input',
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                              id: 'down-payment-input',
                            }}
                          />
                        </ErrorControl>
                      )}
                    </Field>
                    <Field
                      name="INTEREST_RATE"
                      validate={composeValidators(
                        required,
                        greaterThanZero,
                        checkRate
                      )}
                      validateFields={[]}
                    >
                      {({
                        input: { value, onChange, onBlur },
                        meta: { error, touched },
                      }) => (
                        <ErrorControl
                          id="interest-rate"
                          errors={error && touched ? [error] : []}
                        >
                          <NumberFormat
                            className={classes?.formField}
                            onValueChange={({ value: rawValue }) =>
                              onChange(rawValue)
                            }
                            value={value}
                            required
                            onBlur={onBlur}
                            decimalScale={3}
                            customInput={TextField}
                            label="Interest Rate"
                            InputLabelProps={{
                              htmlFor: 'interest-rate-input',
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  %
                                </InputAdornment>
                              ),
                              id: 'interest-rate-input',
                            }}
                          />
                        </ErrorControl>
                      )}
                    </Field>
                    <Field
                      name="LOAN_TERM"
                      validate={composeValidators(required, greaterThanZero)}
                      validateFields={[]}
                    >
                      {({
                        input: { value, onChange, onBlur },
                        meta: { error, touched },
                      }) => (
                        <ErrorControl
                          id="loan-term"
                          errors={error && touched ? [error] : []}
                        >
                          <NumberFormat
                            className={classes?.formField}
                            onValueChange={({ value: rawValue }) =>
                              onChange(rawValue)
                            }
                            value={value}
                            required
                            onBlur={onBlur}
                            decimalScale={0}
                            customInput={TextField}
                            label="Loan Term (Months)"
                            InputLabelProps={{
                              htmlFor: 'loan-term-input',
                            }}
                            InputProps={{
                              id: 'loan-term-input',
                            }}
                          />
                        </ErrorControl>
                      )}
                    </Field>
                    <Box display="flex" mt={2}>
                      <Button variant="contained" onClick={onClose}>
                        Cancel
                      </Button>
                      <Button
                        className={classes.buttonSide}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          submit();
                          calculate();
                        }}
                      >
                        Calculate
                      </Button>
                    </Box>
                  </Box>
                  <Field name="CALCULATED">
                    {({ input: { value } }) => (
                      <>
                        {Boolean(value) && (
                          <Box
                            px={4}
                            py={2}
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                            >
                              <Typography variant="h6">
                                Monthly Payments
                              </Typography>
                              <Typography variant="h6" id="montly-payment">
                                ${value?.monthlyPayment}
                              </Typography>
                              <Box width="100%" my={2}>
                                <Divider />
                              </Box>
                              <Typography
                                variant="body1"
                                id="amount-financed"
                                data-testid="amount-financed"
                              >
                                Total Amount Financed - ${value?.amtFinanced}
                              </Typography>
                            </Box>
                            {Boolean(disclosure) && (
                              <Link href={disclosure}>Disclosure</Link>
                            )}
                          </Box>
                        )}
                      </>
                    )}
                  </Field>
                </CalculatorDisplay>
              </Box>
            </DialogContent>
          </>
        )}
      </Form>
    </Dialog>
  );
};

Calculator.propTypes = {
  maxRate: PropTypes.number,
  minRate: PropTypes.number,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  disclosure: PropTypes.string,
};

export default React.memo(Calculator);

import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import AddIcon from '@mui/icons-material/Add';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';

import InfoBar from './InfoBar';
import SendMessage from './SendMessage';
import ConvoBody from './ConvoBody';
import ApiContext from './ApiContext';

const Conversations = ({
  formData,
  permissions,
  apis,
  apis: {
    getPermissions,
    getPreDefMessages,
    getConversations,
    conversationsSet,
  },
  FEType,
  isLocked,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints?.only('xs'));
  useEffect(() => {
    Promise.all([
      getPreDefMessages(),
      getPermissions(),
      getConversations(),
      conversationsSet([], ['messages']),
    ]).catch((e) => {
      // eslint-disable-next-line no-console
      if (process.env.NODE_ENV === 'development') console.error(e);
    });
  }, [getPreDefMessages, getPermissions, getConversations, conversationsSet]);
  const staticInfo = useMemo(() => ({ apis, permissions }), [
    apis,
    permissions,
  ]);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  return (
    <>
      <InfoBar />
      <ApiContext.Provider value={staticInfo}>
        {formData ? (
          <>
            <Box
              display="flex"
              flexDirection="row-reverse"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              mb={2}
            >
              <Hidden xsDown>
                <Button
                  id="new-conversation-button"
                  onClick={() => {
                    setOpen(true);
                  }}
                  disabled={isLocked}
                  color="primary"
                  variant="contained"
                >
                  New Conversation
                </Button>
              </Hidden>
              <Hidden smUp>
                <IconButton
                  onClick={() => {
                    setOpen(true);
                  }}
                  disabled={isLocked}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Hidden>
              {FEType !== 'BORROWER' && (
                <Box flexGrow={2} mr={8}>
                  <Typography variant={isMobile ? 'h6' : 'h4'}>
                    {!isMobile ? 'Application ' : ''}Conversations
                  </Typography>
                </Box>
              )}
            </Box>
            {Boolean(formData?.DEALER_CONVOS?.length) && (
              <>
                <Typography variant={isMobile ? 'h6' : 'h4'}>
                  Dealer Conversations
                </Typography>
                {formData?.DEALER_CONVOS?.map((data) => (
                  <ConvoBody
                    show={show === data?.CONVO_ID}
                    setShow={setShow}
                    data={data}
                    key={data?.CONVO_ID}
                  />
                ))}
              </>
            )}
            {Boolean(formData?.BORROWER_CONVOS?.length) && (
              <>
                <Typography variant="h4">Borrower Conversations</Typography>
                {formData?.BORROWER_CONVOS?.map((data) => (
                  <ConvoBody
                    show={show === data?.CONVO_ID}
                    setShow={setShow}
                    data={data}
                    key={data?.CONVO_ID}
                  />
                ))}
              </>
            )}
            {!formData?.DEALER_CONVOS?.length &&
              !formData?.BORROWER_CONVOS?.length && (
                <Box>There are no current conversations.</Box>
              )}
            <SendMessage
              open={open}
              onClose={() => {
                setOpen(false);
              }}
            />
          </>
        ) : (
          <Box width="100%" height="600px" />
        )}
      </ApiContext.Provider>
    </>
  );
};

Conversations.propTypes = {
  FEType: PropTypes.string,
  apis: PropTypes.objectOf(PropTypes.any),
  formData: PropTypes.objectOf(PropTypes.any),
  permissions: PropTypes.objectOf(PropTypes.any),
  isLocked: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  schema: state?.convo?.conversations?.schema,
  formData: state?.convo?.conversations?.formData,
  permissions: state?.convo?.permissions,
  FEType: state?.convo?.type,
  isLocked:
    state?.coreLayout?.lockIndicator || state?.coreLayout?.readOnlyWorkbench,
});

export default connect(mapStateToProps)(Conversations);

import Immutable from 'seamless-immutable';
import { makeApi, translateFromBackend } from 'fni-schema';

import { informationSet } from '../../../../../components/store/shared';
import { incrementFetchCount, decrementFetchCount } from './touchpoint';

const setInfo = (error) => {
  if (typeof error === 'string') {
    return informationSet({ type: 'error', message: error });
  }
  return informationSet(error);
};

const baseURL = `lkt-custom`;
export const api = makeApi({
  baseURL,
  withRequestInterceptor: false,
  withResponseInterceptor: false,
  responseType: 'text',
});

// ------------------------------------
// Constants
// ------------------------------------
const SET_DISBURSEMENT = 'SET_DISBURSEMENT';

// ------------------------------------
// Actions
// ------------------------------------
export const setDisbursement = (data, path) => {
  return {
    type: SET_DISBURSEMENT,
    payload: { data, path },
  };
};

// ------------------------------------
// Middleware
// ------------------------------------

const handleError =
  (error, defaultMessage = '') =>
  (dispatch) => {
    /* eslint-disable no-console */
    if (process.env.NODE_ENV === 'development') {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('response: ', error.response);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error('request: ', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('message: ', error.message);
      }
    }
    /* eslint-enable no-console */

    const message =
      error?.response?.data?.errorMsgs?.[0] ??
      error?.response?.data ??
      error?.message ??
      defaultMessage;

    dispatch(setInfo(message));
  };

const handleSuccess = (message) => (dispatch) => {
  dispatch(
    setInfo({
      type: 'success',
      message,
      delay: 3000,
    })
  );
};

// test options: first, final, bad
export const getBorrowerDisbursements =
  ({ refnum, test }) =>
  async (dispatch) => {
    dispatch(incrementFetchCount());

    const params = new URLSearchParams();
    if (test) params.append('test', test);

    try {
      const response = await api.get(`borrowerDisbursements/${refnum}`, {
        params,
      });
      dispatch(setDisbursement(response?.data, ['borrower']));
      return response?.data;
    } catch (e) {
      dispatch(handleError(e, 'Failed to fetch disbursements'));
      return null;
    } finally {
      dispatch(decrementFetchCount());
    }
  };

// test options: good, bad
export const getDisbursementSchema = (test) => async (dispatch) => {
  dispatch(incrementFetchCount());

  const params = new URLSearchParams();
  if (test) params.append('test', test);

  try {
    const response = await api.get('borrowerDisbursement/schema', {
      params,
    });

    const { schema } = translateFromBackend(response?.data);
    dispatch(setDisbursement(schema, ['schema']));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch disbursement schema'));
  } finally {
    dispatch(decrementFetchCount());
  }
};

// test options: good, bad
export const updateBorrowerDisbursement =
  ({ refnum, id, data, test }) =>
  async (dispatch) => {
    dispatch(incrementFetchCount());

    const params = new URLSearchParams();
    if (test) params.append('test', test);

    try {
      await api.put(`borrowerDisbursement/${refnum}/${id}`, data, {
        params,
      });
      dispatch(handleSuccess('Successfully updated disbursement'));
    } catch (e) {
      dispatch(handleError(e, 'Failed to update disbursement'));
      throw e;
    } finally {
      dispatch(decrementFetchCount());
    }
  };

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_DISBURSEMENT]: (state, { payload: { data, path } }) => {
    return Immutable.setIn(state, path, data);
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};
export default function idologyReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

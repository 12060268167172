import Immutable from 'seamless-immutable';
import apiFetch from '../utils/apiFetch';

// ------------------------------------
// Constants
// ------------------------------------
export const UNSAVED_CHANGES_MODAL_SET = 'UNSAVED_CHANGES_MODAL_SET';
export const APPLICATION_TIMEOUT_SET = 'APPLICATION_TIMEOUT_SET';
export const APPLICATION_TIMEOUT_MODAL_SET = 'APPLICATION_TIMEOUT_MODAL_SET';
export const ERROR_BOX_SET = 'ERROR_BOX_SET';
export const FOCUS_ON_SET = 'FOCUS_ON_SET';
export const ERROR_BOX_CLEAR = 'ERROR_BOX_CLEAR';
export const PRESENCE_DATA_SET = 'PRESENCE_DATA_SET';
export const INFORMATION_SET = 'INFORMATION_SET';
export const INFORMATION_CLEAR = 'INFORMATION_CLEAR';
export const SET_HAS_ERRORS = 'SET_HAS_ERRORS';

// ------------------------------------
// Actions
// ------------------------------------

export const errorBoxSet = (path, data) => {
  return {
    type: ERROR_BOX_SET,
    payload: {
      path,
      data,
    },
  };
};

export const focusOnSet = (data) => {
  return {
    type: FOCUS_ON_SET,
    payload: data,
  };
};

export const presenceDataSet = (data) => {
  return {
    type: PRESENCE_DATA_SET,
    payload: data,
  };
};

export const setHasErrors = (data) => {
  return {
    type: SET_HAS_ERRORS,
    payload: data,
  };
};

export const presenceDataGet = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) =>
      apiFetch('presenceData')
        .then((response) => {
          if (!response.ok) {
            // Add Error
            reject();
            return false;
          }
          return response.text();
        })
        .then((text) => {
          const newText = JSON.parse(text);
          dispatch(presenceDataSet(newText));
          resolve();
          return true;
        })
    );
  };
};

export const redirect = (url) => {
  setTimeout(() => {
    window.location.replace(url);
  }, 1500);
};

export const informationSet = (data) => {
  return {
    type: INFORMATION_SET,
    payload: data,
  };
};

export const informationClear = (data) => {
  return {
    type: INFORMATION_CLEAR,
    payload: data,
  };
};

export const actions = {};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [ERROR_BOX_SET]: (state, action) => {
    return Immutable({
      ...state,
      errors: { ...state.errors, [action.payload.path]: action.payload.data },
    });
  },
  [FOCUS_ON_SET]: (state, action) => {
    return Immutable({ ...state, focusOn: action.payload });
  },
  [ERROR_BOX_CLEAR]: (state, action) => {
    if (state.errors[action.payload.path]) {
      const newErrors = Immutable.asMutable(state.errors[action.payload.path], {
        deep: true,
      });
      const index = newErrors.findIndex(
        (error) => error.fieldId === action.payload.field
      );
      if (index >= 0) {
        newErrors.splice(index, 1);
      }
      return Immutable({
        ...state,
        errors: { ...state.errors, [action.payload.path]: newErrors },
      });
    }
    return state;
  },
  [PRESENCE_DATA_SET]: (state, action) => {
    return Immutable({
      ...state,
      presence: { ...state.presence, ...action.payload },
    });
  },
  [INFORMATION_SET]: (state, action) => {
    const id = action.payload.id || 'default';
    return Immutable.setIn(state, ['informationBox', id], action.payload);
  },
  [INFORMATION_CLEAR]: (state, action) => {
    return Immutable.setIn(
      state,
      ['informationBox', action.payload],
      undefined
    );
  },
  [SET_HAS_ERRORS]: (state, action) => {
    return Immutable.setIn(state, ['hasErrors'], action.payload);
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  closeConfirmModal: {
    modalOpen: false,
  },
  unsavedChangesModal: {
    show: false,
    type: 'exit',
    path: '',
  },
  applicationTimer: {
    modal: false,
  },
  errors: {},
  presence: {},
  informationBox: {},
};

export default function coreLayoutReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

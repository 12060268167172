import { Stack, Typography } from '@mui/material';

export default function AdminHeader({ titleText }) {

    return (
        <Stack paddingBottom={1} sx={{ borderBottom: 2, borderColor: 'primary.light' }}>
            <Typography variant='h3'>{ titleText }</Typography>
        </Stack>
    );
}

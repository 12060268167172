import { Box, Container, Typography } from '@mui/material';

export default function NotFound() {

    const styles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    };


    return (
        <Container sx={styles} maxWidth='md'>
            <Box>
                <Typography>{'Oops! This page can\'t be found.'}</Typography>
            </Box>

        </Container>

    );
}

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { styled } from '@mui/styles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.light,
  },
}));

const YearsMonths = ({
  id,
  labelId,
  label,
  error,
  errors,
  value,
  onChange,
  ComponentProps,
  LabelProps,
  ErrorControlProps,
  ...props
}) => {
  const [years, setYears] = React.useState('');
  const [months, setMonths] = React.useState('');

  React.useEffect(() => {
    const _years = Math.floor(Number(value) / 12) || '';
    const _months = Number(value) % 12 || '';
    if (years !== _years) setYears(_years);
    if (months !== _months) setMonths(_months);
    
  }, []);

  React.useEffect(() => {
    onChange(Number(years) * 12 + Number(months));
  }, [months, onChange, years]);

  const filteredErrors = errors?.filter(Boolean) ?? [];

  return (
    <Grid container item xs spacing={2}>
      <Grid item xs>
        <NumberFormat
          {...props}
          id={`${id}-years`}
          customInput={TextField}
          label="Years"
          value={years}
          decimalScale={0}
          isNumericString
          allowNegative={false}
          onChange={(e) => setYears(e.target.value)}
          InputProps={ComponentProps}
          InputLabelProps={{ id: `${labelId}-years`, ...LabelProps }}
          helperText={
            error ? (
              <StyledTooltip
                id={`${id}_TOOLTIP`}
                data-testid="error-control-tooltip"
                title={filteredErrors[0]}
                placement="right"
                sx={{
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: (theme) => theme.palette.error.light,
                  },
                }}
              >
                <ErrorIcon
                  data-testid="error-icon"
                  sx={{
                    width: (theme) => theme.spacing(2),
                  }}
                />
              </StyledTooltip>
            ) : (
              label
            )
          }
          error={error}
          sx={{
            margin: (theme) => theme.spacing(0, 8, 0, 0),
          }}
          {...ErrorControlProps}
        />
      </Grid>
      <Grid item xs>
        <NumberFormat
          {...props}
          id={`${id}-months`}
          customInput={TextField}
          label="Months"
          value={months}
          decimalScale={0}
          isNumericString
          allowNegative={false}
          isAllowed={(values) => {
            return !values.floatValue || values.floatValue < 12;
          }}
          onChange={(e) => {
            setMonths(e.target.value);
          }}
          error={error}
          autoFocus={false}
          sx={{
            margin: (theme) => theme.spacing(0, 8, 0, 0),
          }}
          helperText={error && label}
          InputLabelProps={{ id: `${labelId}-months`, ...LabelProps }}
          InputProps={ComponentProps}
          {...ErrorControlProps}
        />
      </Grid>
    </Grid>
  );
};

YearsMonths.propTypes = {
  ComponentProps: PropTypes.objectOf(PropTypes.any),
  ErrorControlProps: PropTypes.objectOf(PropTypes.any),
  LabelProps: PropTypes.objectOf(PropTypes.any),
  error: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  label: PropTypes.string,
  labelId: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default YearsMonths;

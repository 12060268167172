import { Button, Container, Stack, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import PageHeader from '../../components/PageHeader';
import { authActions } from '../modules/auth';

export default function ForgotPasswordPage() {

    const styles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { 
        handleSubmit, 
        handleChange, 
        handleBlur, 
        values, 
        errors, 
        touched,
        dirty,
    } = useFormik({
        initialValues: { email: '' },
        validationSchema: yup.object({
            email: yup.string().email('Must be valid email').required('Required'),
        }),
        onSubmit: (values) => {
            dispatch(authActions.sendResetEmail(values))
                .then(response => {
                    navigate(-1);
                });
        },
    });

    const showError = Boolean(errors.email && touched.email && dirty);

    return (
        <Container sx={styles} maxWidth='md'>

            <PageHeader text='Forgot Password?' variant='h4' />

            <form onSubmit={handleSubmit} style={{ width: '100%' }}>

                <Stack spacing={2} padding={3} margin={3}>
                    <TextField
                        name='email'
                        label='Email'
                        autoFocus
                        placeholder='Enter email to update your password'
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={showError}
                        helperText={showError ? errors.email : null}
                    />

                    <Stack flexDirection={{ xs: 'column', sm: 'row' }} 
                        justifyContent={{ xs: 'center', sm: 'space-between' }} >

                        <Button onClick={() => navigate(-1)}>Cancel</Button>

                        <Button type='submit'>Send Email</Button>
                        
                    </Stack>

                </Stack>
            </form>

        </Container>
    );
}

import Immutable from 'seamless-immutable';
import SchemaConverter from 'fni-components/FNISchemaForm/utils/SchemaConverter';
import _get from 'lodash/get';
import { setInfo } from '../../../store/coreLayout';
import apiFetch from '../../../utils/apiFetch';

export const DOCUMENT_TEMPLATES_SET = 'DOCUMENT_TEMPLATES_SET';
export const DOCUMENT_COLLECTIONS_SET = 'DOCUMENT_COLLECTIONS_SET';
export const DOCUMENT_FETCHING_SET = 'DOCUMENT_FETCHING_SET';
export const DOCUMENT_FIELD_MAPPING_SOURCE_SET =
  'DOCUMENT_FIELD_MAPPING_SOURCE_SET';
export const DOCUMENT_UPLOAD_TMPLT_ID_SET = 'DOCUMENT_UPLOAD_TMPLT_ID_SET';
export const DOCUMENT_FIELD_MAPPING_SET = 'DOCUMENT_FIELD_MAPPING_SET';
export const DOCUMENT_FIELD_DETAILS_SET = 'DOCUMENT_FIELD_DETAILS_SET';
export const DOCUMENT_TEMPLATE_INFO_SET = 'DOCUMENT_TEMPLATE_INFO_SET';
export const DOCUMENT_FIELDS_MAPPING_UPDATE = 'DOCUMENT_FIELDS_MAPPING_UPDATE';
export const DOCUMENT_COLLECTION_TEMPLATES_SET =
  'DOCUMENT_COLLECTION_TEMPLATES_SET';
export const DOCUMENT_COLLECTION_SET = 'DOCUMENT_COLLECTION_SET';
export const DOCUMENT_COLLECTION_FORMDATA_UPDATE =
  'DOCUMENT_COLLECTION_FORMDATA_UPDATE';
export const SAVE_DISABLED_SET = 'SAVE_DISABLED_SET';
// ------------------------------------
// Constants
// ------------------------------------
// ------------------------------------
// Actions
// ------------------------------------

export const templatesSet = (data) => {
  return {
    type: DOCUMENT_TEMPLATES_SET,
    payload: data,
  };
};
export const collectionsSet = (data) => {
  return {
    type: DOCUMENT_COLLECTIONS_SET,
    payload: data,
  };
};
export const fieldMappingSourceSet = (data) => {
  return {
    type: DOCUMENT_FIELD_MAPPING_SOURCE_SET,
    payload: data,
  };
};
export const documentFetchingSet = (data) => {
  return {
    type: DOCUMENT_FETCHING_SET,
    payload: data,
  };
};
export const uploadTemplateIdSet = (data) => {
  return {
    type: DOCUMENT_UPLOAD_TMPLT_ID_SET,
    payload: data,
  };
};
export const fieldMappingSet = (data) => {
  return {
    type: DOCUMENT_FIELD_MAPPING_SET,
    payload: data,
  };
};
export const fieldTypeDropdownSet = (data) => {
  return {
    type: DOCUMENT_FIELD_DETAILS_SET,
    payload: data,
  };
};
export const templateInfoSet = (data) => {
  return {
    type: DOCUMENT_TEMPLATE_INFO_SET,
    payload: data,
  };
};
export const fieldsMappingUpdate = (path, data) => {
  return {
    type: DOCUMENT_FIELDS_MAPPING_UPDATE,
    payload: { path, data },
  };
};
export const collectionTemplatesSet = (data) => {
  return {
    type: DOCUMENT_COLLECTION_TEMPLATES_SET,
    payload: data,
  };
};
export const collectionSet = (data) => {
  return {
    type: DOCUMENT_COLLECTION_SET,
    payload: data,
  };
};
export const collectionFormDataUpdate = (location, data) => {
  return {
    type: DOCUMENT_COLLECTION_FORMDATA_UPDATE,
    payload: {
      location,
      data,
    },
  };
};
export const saveDisabledSet = (data) => {
  return {
    type: SAVE_DISABLED_SET,
    payload: data,
  };
};

// ------------------------------------
// Middleware
// ------------------------------------

export const templatesFetch = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(documentFetchingSet({ templates: true }));
      return apiFetch('admin/docgen/templates')
        .then((response) => {
          if (response.ok) {
            return response.text();
          }
          throw new Error();
        })
        .then((text) => {
          let parsedText = JSON.parse(text);
          parsedText = SchemaConverter.translateFromBackend({
            schema: parsedText.adminSchema,
            formData: parsedText.formData,
          });
          dispatch(templatesSet(parsedText));
          dispatch(documentFetchingSet({ templates: false }));
          resolve(true);
          return undefined;
        })
        .catch((err) => {
          dispatch(
            setInfo({
              type: 'error',
              message: 'Failed to Load Templates',
            })
          );
          dispatch(documentFetchingSet({ templates: false }));
          reject(err);
          return undefined;
        });
    });
  };
};

export const collectionsFetch = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(documentFetchingSet({ collections: true }));
      return apiFetch('admin/docgen/packages')
        .then((response) => {
          if (response.ok) {
            return response.text();
          }
          throw new Error();
        })
        .then((text) => {
          let parsedText = JSON.parse(text);
          parsedText = SchemaConverter.translateFromBackend({
            schema: parsedText.adminSchema,
            formData: parsedText.formData,
          });
          dispatch(collectionsSet(parsedText));
          dispatch(documentFetchingSet({ collections: false }));
          resolve(true);
          return undefined;
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const fieldMappingSourceFetch = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(documentFetchingSet({ mappingSource: true }));
      return apiFetch('admin/docgen/mappingSource')
        .then((response) => {
          if (response.ok) {
            return response.text();
          }
          throw new Error();
        })
        .then((text) => {
          const parsed = JSON.parse(text);
          dispatch(fieldMappingSourceSet(parsed));
          dispatch(documentFetchingSet({ mappingSource: false }));
          resolve(true);
          return undefined;
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const uploadTemplate = (formData, id) => {
  return (dispatch) => {
    dispatch(documentFetchingSet({ uploadTemplate: true }));
    const options = {
      method: 'POST',
      body: formData,
      headers: {},
    };
    const action = id ? 'replace' : 'upload';
    return apiFetch(`admin/docgen/${action}`, options)
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          return response.text();
        }
      })
      .then((text) => {
        let parsedText = JSON.parse(text);
        if (parsedText.status === 'SUCCESS') {
          let templateId = id;
          if (action === 'upload') {
            parsedText = SchemaConverter.translateFromBackend({
              schema: parsedText.formData.adminSchema,
              formData: parsedText.formData.formData,
            });
            templateId = parsedText.formData.TEMPLATE_ID;
          }
          dispatch(uploadTemplateIdSet(templateId));
          dispatch(
            setInfo({
              type: 'success',
              message: 'Template Saved Successfully!',
              delay: 3000,
            })
          );
          dispatch(documentFetchingSet({ uploadTemplate: false }));
          return templateId;
        }
        const message = parsedText?.errorMsgs
          ? parsedText.errorMsgs[0]
          : 'Error uploading template.';

        throw new Error(message);
      })
      .catch((e) => {
        if (typeof e === 'string')
          dispatch(
            setInfo({
              type: 'error',
              message: e,
            })
          );
        else
          dispatch(
            setInfo({
              type: 'error',
              message: e.message ?? 'Error uploading template.',
            })
          );
        dispatch(documentFetchingSet({ uploadTemplate: false }));
        throw e;
      });
  };
};

export const fieldMappingFetch = (id) => {
  return (dispatch) => {
    dispatch(fieldMappingSet(undefined));
    return new Promise((resolve, reject) => {
      dispatch(documentFetchingSet({ fieldMapping: true }));
      return apiFetch(`admin/docgen/fields/${id}`)
        .then((response) => {
          if (response.ok) {
            return response.text();
          }
          throw new Error();
        })
        .then((text) => {
          let parsedText = JSON.parse(text);
          parsedText = SchemaConverter.translateFromBackend({
            schema: parsedText.adminSchema,
            formData: parsedText.formData,
          });
          parsedText.schema = {
            type: 'object',
            properties: {
              FIELD_MAPPING: parsedText.schema,
            },
          };
          dispatch(fieldMappingSet(parsedText));
          dispatch(documentFetchingSet({ fieldMapping: false }));
          resolve(true);
          return undefined;
        })
        .catch(() => {
          reject();
        });
    });
  };
};

export const saveMapping = (id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(documentFetchingSet({ saveMapping: true }));
      let data = getState().document.fieldMappingSchema.formData;
      data = SchemaConverter.translateToBackend(data);
      const options = {
        method: 'POST',
        body: JSON.stringify(data[0]),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      };
      apiFetch(`admin/docgen/fields/${id}`, options)
        .then((response) => {
          if (!response.ok) {
            throw response;
          } else {
            dispatch(
              setInfo({
                type: 'success',
                message: 'Custom Fields Mapped Successfully!',
                delay: 3000,
              })
            );
            return undefined;
          }
        })
        .then(() => {
          dispatch(collectionsFetch());
          dispatch(documentFetchingSet({ saveMapping: false }));
          resolve(true);
          return undefined;
        })
        .catch(() => {
          reject();
        });
    });
  };
};

export const fieldDetailsFetch = (id) => {
  return (dispatch) => {
    dispatch(fieldTypeDropdownSet(undefined));
    return new Promise((resolve, reject) => {
      dispatch(documentFetchingSet({ fieldDetails: true }));
      return apiFetch(`admin/docgen/fields/allDetails/${id}`)
        .then((response) => {
          if (response.ok) {
            return response.text();
          }
          throw new Error();
        })
        .then((text) => {
          let parsedText = JSON.parse(text);
          parsedText = SchemaConverter.translateFromBackend({
            schema: parsedText,
            formData: [],
          });
          dispatch(fieldTypeDropdownSet(parsedText));
          dispatch(documentFetchingSet({ fieldDetails: false }));
          resolve(true);
          return undefined;
        })
        .catch(() => {
          reject();
        });
    });
  };
};

export const fieldDetailsByTypeFetch = (type) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      if (type !== undefined) {
        dispatch(documentFetchingSet({ fieldDetailsByType: true }));
        const currSchema = getState().document.fieldDetailsSchema;
        if (
          _get(currSchema, ['schema', 'items', 'properties']) &&
          currSchema.schema.items.properties[type]
        ) {
          if (
            !currSchema?.schema?.items?.properties[type]?.format &&
            currSchema?.schema?.items?.properties[type]?.enum?.length < 2
          ) {
            dispatch(saveDisabledSet(true));
            dispatch(
              setInfo({
                type: 'error',
                message: `No field details available for ${type}.`,
                delay: 3000,
              })
            );
          } else {
            dispatch(saveDisabledSet(false));
          }
          dispatch(documentFetchingSet({ fieldDetailsByType: false }));
          resolve(true);
        } else {
          let encType = encodeURI(type);
          encType = encType.split('/').join('|');
          return apiFetch(`admin/docgen/fields/details/${encType}`)
            .then((response) => {
              if (response.ok) {
                return response.text();
              }
              throw new Error();
            })
            .then((text) => {
              let parsedText = JSON.parse(text);
              if (!parsedText?.format && parsedText?.enum?.length < 2) {
                dispatch(saveDisabledSet(true));
                dispatch(
                  setInfo({
                    type: 'error',
                    message: `No field details available for ${type}.`,
                    delay: 3000,
                  })
                );
              } else {
                dispatch(saveDisabledSet(false));
              }
              parsedText = SchemaConverter.translateFromBackend({
                schema: parsedText,
                formData: [],
              });
              const data = Immutable.setIn(
                currSchema,
                ['schema', 'items', 'properties', type],
                parsedText.schema
              );
              dispatch(fieldTypeDropdownSet(data));
              dispatch(documentFetchingSet({ fieldDetailsByType: false }));
              resolve(true);
              return undefined;
            })
            .catch(() => {
              reject();
            });
        }
      }
      return undefined;
    });
  };
};

export const templateInfoFetch = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(documentFetchingSet({ templateInfo: true }));
      return apiFetch(`admin/docgen/info/${id}`)
        .then((response) => {
          if (response.ok) {
            return response.text();
          }
          throw new Error();
        })
        .then((text) => {
          let parsedText = JSON.parse(text);
          parsedText = SchemaConverter.translateFromBackend({
            schema: parsedText.adminSchema,
            formData: parsedText.formData,
          });
          dispatch(templateInfoSet(parsedText));
          dispatch(documentFetchingSet({ templateInfo: false }));
          resolve(true);
          return undefined;
        })
        .catch(() => {
          reject();
        });
    });
  };
};

export const parseTemplate = (formData, fname) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(documentFetchingSet({ parseTemplate: true }));
      const options = {
        method: 'POST',
        body: formData,
        headers: {},
        responseType: 'blob',
      };
      apiFetch('admin/docgen/scrape', options)
        .then((response) => {
          if (!response.ok) {
            throw response;
          } else {
            return response.blob();
          }
        })
        .then((blob) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fname;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);

          dispatch(
            setInfo({
              type: 'success',
              message: 'Template Parsed Successfully!',
            })
          );
          dispatch(documentFetchingSet({ parseTemplate: false }));
          resolve(true);
          return undefined;
        })
        .catch(() => {
          dispatch(
            setInfo({
              type: 'error',
              message: 'Error parsing template.',
            })
          );
          dispatch(documentFetchingSet({ parseTemplate: false }));
          reject();
        });
    });
  };
};

export const allTemplatesFetch = () => (dispatch, getState) => {
  return new Promise((resolve) => {
    return apiFetch('admin/docgen/templates')
      .then((response) => {
        if (response.ok) {
          return response.text();
        }
        throw new Error();
      })
      .then((text) => {
        let parsedText = JSON.parse(text);
        parsedText = SchemaConverter.translateFromBackend({
          schema: parsedText.adminSchema,
          formData: parsedText.formData,
        });
        const templates = parsedText.formData.TEMPLATES.map((t) => {
          return {
            TEMPLATE_ID: t.TEMPLATE_ID,
            TEMPLATE_DESC: t.TEMPLATE_DESC,
          };
        });
        if (getState().document.templatesCollectionSchema.formData) {
          const collectionTemplates =
            getState().document.templatesCollectionSchema.formData
              .PACKAGE_TEMPLATES;
          if (collectionTemplates) {
            collectionTemplates.forEach((ct) => {
              templates.forEach((t, i) => {
                if (t.TEMPLATE_ID === ct.TEMPLATE_ID) {
                  templates.splice(i, 1);
                }
              });
            });
          }
        }
        dispatch(collectionTemplatesSet(templates));
        dispatch(documentFetchingSet({ allTemplates: false }));
        resolve();
        return undefined;
      });
  });
};

export const templatesCollectionFetch = (id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(documentFetchingSet({ templateCollection: true }));
      return apiFetch(`admin/docgen/package/edit/${id || ''}`)
        .then((response) => {
          if (response.ok) {
            return response.text();
          }
          throw new Error();
        })
        .then(async (text) => {
          let parsedText = JSON.parse(text);
          parsedText = SchemaConverter.translateFromBackend({
            schema: parsedText.adminSchema,
            formData: parsedText.formData ? parsedText.formData : [],
          });
          dispatch(collectionSet(parsedText));
          await dispatch(allTemplatesFetch());
          const state = getState();
          const collection = state.document.templatesCollectionSchema;
          if (state.document.collection.availableTemplates) {
            const availableTemplates = Immutable.asMutable(
              state.document.collection.availableTemplates,
              { deep: true }
            );
            if (_get(collection, ['formData', 'PACKAGE_TEMPLATES'])) {
              collection.formData.PACKAGE_TEMPLATES.forEach((t) => {
                availableTemplates.forEach((at, i) => {
                  if (at.TEMPLATE_ID === t.TEMPLATE_ID) {
                    availableTemplates.splice(i, 1);
                  }
                });
              });
            }
            dispatch(collectionTemplatesSet(availableTemplates));
          }
          resolve(true);
          dispatch(documentFetchingSet({ templateCollection: false }));
          return undefined;
        })
        .catch(() => {
          reject();
        });
    });
  };
};

export const collectionUpdate = (id, type) => (dispatch, getState) => {
  const available = Immutable.asMutable(
    getState().document.collection.availableTemplates,
    { deep: true }
  );
  const selected = Immutable.asMutable(
    getState().document.templatesCollectionSchema.formData || {},
    { deep: true }
  );
  if (!selected.PACKAGE_TEMPLATES) {
    selected.PACKAGE_TEMPLATES = [];
  }
  if (type === 'selected') {
    const index = available.findIndex((p) => {
      return p.TEMPLATE_ID === id;
    });
    selected.PACKAGE_TEMPLATES.push(available[index]);
    available.splice(index, 1);
  } else {
    const index = selected.PACKAGE_TEMPLATES.findIndex((p) => {
      return p.TEMPLATE_ID === id;
    });
    available.push(selected.PACKAGE_TEMPLATES[index]);
    selected.PACKAGE_TEMPLATES.splice(index, 1);
  }
  // available = _sortBy(available, (p) => { return p.TEMPLATE_ORDER; });
  dispatch(collectionTemplatesSet(available));
  dispatch(collectionFormDataUpdate('templatesCollectionSchema', selected));
};

export const collDescriptionUpdate = (descr) => (dispatch, getState) => {
  const data = Immutable.asMutable(
    getState().document.templatesCollectionSchema.formData || {},
    { deep: true }
  );
  data.PACKAGE_DESCRIPTION = descr;
  dispatch(collectionFormDataUpdate('templatesCollectionSchema', data));
};

export const collPageNumberUpdate = (isNumbered) => (dispatch, getState) => {
  const data = Immutable.asMutable(
    getState().document.templatesCollectionSchema.formData || {},
    { deep: true }
  );
  data.PAGE_NUMBERING = isNumbered;
  dispatch(collectionFormDataUpdate('templatesCollectionSchema', data));
};

export const saveCollection = (id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(documentFetchingSet({ saveCollection: true }));
      const state = getState().document.templatesCollectionSchema;
      const options = {};
      options.method = 'POST';
      let nextOrder = 0;
      const templateList = state.formData.PACKAGE_TEMPLATES.map((t) => {
        nextOrder += 1;
        return {
          TEMPLATE_ID: t.TEMPLATE_ID,
          TEMPLATE_DESC: t.TEMPLATE_DESC,
          TEMPLATE_ORDER: t.TEMPLATE_ORDER ? t.TEMPLATE_ORDER : nextOrder.toString() ,
        };
      });
      const data = {
        PACKAGE_DESCRIPTION: state.formData.PACKAGE_DESCRIPTION,
        PACKAGE_TEMPLATES: templateList,
        PAGE_NUMBERING: state.formData.PAGE_NUMBERING,
      };
      options.body = JSON.stringify(SchemaConverter.translateToBackend(data));
      const apiCall = id
        ? `admin/docgen/package/save/${id}`
        : 'admin/docgen/package/new';
      apiFetch(apiCall, options)
        .then((response) => {
          if (!response.ok) {
            throw new Error();
          } else {
            dispatch(
              setInfo({
                type: 'success',
                message: 'Collection Saved Successfully!',
                delay: 3000,
              })
            );
            dispatch(documentFetchingSet({ saveCollection: false }));
            dispatch(collectionsFetch());
            resolve(true);
            return undefined;
          }
        })
        .catch((err) => {
          dispatch(
            setInfo({
              type: 'error',
              message: 'Error saving collection.',
            })
          );
          dispatch(documentFetchingSet({ saveCollection: false }));
          reject(err);
          return undefined;
        });
    });
  };
};

export const viewTemplate = (id, fname) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(documentFetchingSet({ viewTemplate: true }));
      const options = {
        method: 'GET',
        headers: {},
        responseType: 'blob',
      };
      apiFetch(`admin/docgen/template/${id}`, options)
        .then((response) => {
          if (!response.ok) {
            throw response;
          } else {
            return response.blob();
          }
        })
        .then((blob) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fname;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);

          dispatch(
            setInfo({
              type: 'success',
              message: 'Template Viewed Successfully!',
              delay: 3000,
            })
          );
          dispatch(documentFetchingSet({ viewTemplate: false }));
          resolve(true);
          return undefined;
        })
        .catch(() => {
          dispatch(
            setInfo({
              type: 'error',
              message: 'Error viewing template.',
            })
          );
          dispatch(documentFetchingSet({ viewTemplate: false }));
          reject();
        });
    });
  };
};

export const actions = {
  templatesFetch,
  collectionsFetch,
  fieldMappingSourceFetch,
  uploadTemplate,
  fieldMappingFetch,
  saveMapping,
  fieldDetailsFetch,
  fieldDetailsByTypeFetch,
  templateInfoFetch,
  fieldsMappingUpdate,
  parseTemplate,
  templatesCollectionFetch,
  allTemplatesFetch,
  collectionUpdate,
  collDescriptionUpdate,
  collPageNumberUpdate,
  saveCollection,
  collectionFormDataUpdate,
  viewTemplate,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [DOCUMENT_TEMPLATES_SET]: (state, action) => {
    return Immutable.merge(state, { templates: action.payload });
  },
  [DOCUMENT_COLLECTIONS_SET]: (state, action) => {
    return Immutable.merge(state, { collections: action.payload });
  },
  [DOCUMENT_FIELD_MAPPING_SOURCE_SET]: (state, action) => {
    return Immutable({ ...state, fieldMappingSourceSchema: action.payload });
  },
  [DOCUMENT_FETCHING_SET]: (state, action) => {
    return Immutable({
      ...state,
      fetching: { ...state.fetching, ...action.payload },
    });
  },
  [DOCUMENT_UPLOAD_TMPLT_ID_SET]: (state, action) => {
    return Immutable({ ...state, uploadTemplateId: action.payload });
  },
  [DOCUMENT_FIELD_MAPPING_SET]: (state, action) => {
    return Immutable({ ...state, fieldMappingSchema: action.payload });
  },
  [DOCUMENT_FIELD_DETAILS_SET]: (state, action) => {
    return Immutable({ ...state, fieldDetailsSchema: action.payload });
  },
  [DOCUMENT_TEMPLATE_INFO_SET]: (state, action) => {
    return Immutable({ ...state, templateInfoSchema: action.payload });
  },
  [DOCUMENT_FIELDS_MAPPING_UPDATE]: (state, action) => {
    const data = Immutable.setIn(
      state,
      action.payload.path,
      action.payload.data
    );
    return data;
  },
  [DOCUMENT_COLLECTION_TEMPLATES_SET]: (state, action) => {
    return Immutable({
      ...state,
      collection: { ...state.collection, availableTemplates: action.payload },
    });
  },
  [DOCUMENT_COLLECTION_SET]: (state, action) => {
    return Immutable.merge(state, {
      templatesCollectionSchema: action.payload,
    });
  },
  [DOCUMENT_COLLECTION_FORMDATA_UPDATE]: (state, action) => {
    return Immutable.setIn(
      state,
      [action.payload.location, 'formData'],
      action.payload.data
    );
  },
  [SAVE_DISABLED_SET]: (state, action) => {
    return Immutable({ ...state, saveDisabled: action.payload });
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};
export default function documentReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

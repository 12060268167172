const severityOptions = {
    error: 'error',
    info: 'info',
    success: 'success',
    warning: 'warning',
};

const alertInitialState = {
    open: false,
    message: '',
    severity: severityOptions.success,
};

const setAlertError = (state, error) => state.alert = {
    open: true,
    message: error.message,
    severity: severityOptions.error,
};

const setAlertSuccess = (state, message) => state.alert = {
    open: true,
    message: message,
    severity: severityOptions.success,
};

const trimActionType = (type) => type?.split(/pending|fulfilled|rejected/g)[0];


const getItemByItemId = (items, itemId) => {
    if(items?.length && itemId) {
        return items.find(item => item.id === itemId);
    } 
    return null;
};

const removeItemFromListById = (list, id) => {
    if(list?.length && id) {
        return list.filter(item => {
            return item.id !== id;
        });
    } 
    return list;
};

const getUpdatedList = (list, updatedItem) => {
    if (list?.length && updatedItem) {
        return list.map(item => {
            return item.id === updatedItem.id ? updatedItem : item;
        });
    } 
    return list;
};


export const utils = {
    getItemByItemId, 
    removeItemFromListById,
    getUpdatedList,
    setAlertError, 
    setAlertSuccess, 
    alertInitialState,
    trimActionType,
};

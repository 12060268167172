import { Button, Stack } from '@mui/material';

export default function FormHeader({ 
    navigate,
    actions = [],
}) {

    return (
        <Stack 
            flexDirection='row' 
            justifyContent='flex-end'
            paddingBottom={1}
            paddingTop={1}>

            <Button sx={{marginRight: 'auto'}}onClick={() => navigate(-1)}>Back</Button>

            {actions.map(action => (
                <Button key={action.key} onClick={action.onClick}>{action.text}</Button>
            ))}
        </Stack>
    );
}

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, color }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette[color].light,
  },
}));

function ErrorControl({
  id,
  errors,
  warning,
  children,
  required,
  FormControlProps,
  IconProps,
  TooltipProps,
}) {
  const filteredErrors = errors?.filter(Boolean) ?? [];

  const components = React.Children.toArray(children).map((component) =>
    React.cloneElement(component, {
      error: Boolean(filteredErrors.length) || Boolean(warning),
    })
  );

  return (
    <FormControl
      id={`${id}_FORM`}
      data-testid="error-form-control"
      error={Boolean(filteredErrors.length) || Boolean(warning)}
      sx={{ marginTop: 0 }}
      required={required}
      // eslint-disable-next-line
      {...FormControlProps}
    >
      {components}
      {(Boolean(filteredErrors.length) || Boolean(warning)) && (
        <FormHelperText error sx={{ marginTop: 0 }}>
          {filteredErrors.length ? (
            <StyledTooltip
              id={`${id}_TOOLTIP`}
              data-testid="error-control-tooltip"
              title={filteredErrors[0]}
              placement="right"
              color="error"
              {...TooltipProps}
            >
              <ErrorIcon
                data-testid="error-icon"
                sx={{
                  '& + svg': {
                    marginLeft: (theme) => theme.spacing(0.5),
                  },
                  width: (theme) => theme.spacing(2),
                }}
                {...IconProps}
              />
            </StyledTooltip>
          ) : null}
          {warning ? (
            <StyledTooltip
              id={`${id}_WARNING_TOOLTIP`}
              data-testid="warning-control-tooltip"
              title={warning}
              placement="right"
              color="warning"
              {...TooltipProps}
            >
              <WarningIcon
                data-testid="warning-icon"
                color="warning"
                sx={{
                  '& + svg': {
                    marginLeft: (theme) => theme.spacing(0.5),
                  },
                  width: (theme) => theme.spacing(2),
                }}
                {...IconProps}
              />
            </StyledTooltip>
          ) : null}
        </FormHelperText>
      )}
    </FormControl>
  );
}

ErrorControl.propTypes = {
  id: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  warning: PropTypes.string,
  children: PropTypes.node.isRequired,
  required: PropTypes.bool,
  FormControlProps: PropTypes.objectOf(PropTypes.any),
  IconProps: PropTypes.objectOf(PropTypes.any),
  TooltipProps: PropTypes.objectOf(PropTypes.any),
};

export default ErrorControl;

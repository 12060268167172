import { Button, Stack, TextField } from '@mui/material';

export default function ListHeader({ 
    search,
    onSearchChange,
    onActionClick, 
    actionText,
}) {

    return (
        <Stack 
            flexDirection='row' 
            justifyContent='space-between'
            paddingBottom={2}
            paddingTop={2}>

            <TextField
                label='Search'
                value={search}
                onChange={onSearchChange}
                variant='outlined'
                size='small'
            />

            { onActionClick && actionText ?  
                <Button onClick={() => onActionClick()}>{actionText}</Button> : null }
        </Stack>
    );
}

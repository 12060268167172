/* eslint-disable array-callback-return */
import Immutable from 'seamless-immutable';
import SchemaConverter from 'fni-components/FNISchemaForm/utils/SchemaConverter';
import _get from 'lodash/get';
import { makeApi } from 'fni-schema';

import { setInfo } from '../../../store/coreLayout';
import apiFetch from '../../../utils/apiFetch';
import {
  GENERAL,
  DATA_COLLECT,
  ESIGN,
  DOC_DOWNLOAD,
} from '../utils/stipPurposes';

export const api = makeApi({
  baseURL: 'fni-lenderportal-admin',
  schemaKey: 'adminSchema',
});

// ------------------------------------
// Constants
// ------------------------------------

export const STIP_FORMDATA_UPDATE = 'STIP_FORMDATA_UPDATE';
export const STIP_FIELD_SET = 'STIP_FIELD_SET';
export const STIP_CREATED_SET = 'STIP_CREATED';
export const STIP_DELETED_SET = 'STIP_DELETED_SET';
export const STIP_LIST_SET = 'STIP_LIST_SET';
export const STIP_INFO_SET = 'STIP_INFO_SET';
export const STIP_DESC_VALID_SET = 'STIP_DESC_VALID_SET';
export const STIP_INFO_INIT = 'STIP_INFO_INIT';
export const STIP_VIEW_SET = 'STIP_VIEW_SET';
export const STIP_FIELD_ERRORS_SET = 'STIP_FIELD_ERRORS_SET';
export const STIP_FETCHING_SET = 'STIP_FETCHING_SET';
export const STIP_RESPONSE_SET = 'STIP_RESPONSE_SET';
export const STIP_REQUIRED_FIELDS_SET = 'STIP_REQUIRED_FIELDS_SET';

// ------------------------------------
// Actions
// ------------------------------------

export const updateFormData = (data) => {
  return {
    type: STIP_FORMDATA_UPDATE,
    payload: {
      data,
    },
  };
};
export const setStipFields = (data) => {
  return {
    type: STIP_FIELD_SET,
    payload: data,
  };
};
export const setCreateStip = (data) => {
  return {
    type: STIP_CREATED_SET,
    payload: data,
  };
};
export const setStipDeleted = (data) => {
  return {
    type: STIP_DELETED_SET,
    payload: data,
  };
};
export const receiveList = (data) => {
  return {
    type: STIP_LIST_SET,
    payload: data,
  };
};
export const setStipInfo = (stipId, stipDesc) => {
  return {
    type: STIP_INFO_SET,
    payload: [stipId, stipDesc],
  };
};
export const stipDescValidityReceive = (data) => {
  return {
    type: STIP_DESC_VALID_SET,
    payload: data,
  };
};
export const initStipInfo = () => {
  return {
    type: STIP_INFO_INIT,
  };
};
export const setView = (data) => {
  return {
    type: STIP_VIEW_SET,
    payload: data,
  };
};
export const stipFetchingSet = (data) => {
  return {
    type: STIP_FETCHING_SET,
    payload: data,
  };
};
const setFieldErrors = (errors) => {
  return {
    type: STIP_FIELD_ERRORS_SET,
    payload: errors,
  };
};
const setRequiredFields = (data) => {
  return {
    type: STIP_REQUIRED_FIELDS_SET,
    payload: data,
  };
};

// ------------------------------------
// Middleware
// ------------------------------------

const _setView = (view) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(setView(view));
      resolve(true);
    });
  };
};

const getOptions = (method) => {
  return {
    method,
  };
};

export const fetchStipList = () => {
  return (dispatch) => {
    dispatch(stipFetchingSet(true));
    return apiFetch('admin/stips')
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error();
      })
      .then((response) => {
        const data = SchemaConverter.translateFromBackend({
          formData: response.formData,
          schema: response.adminSchema,
        });
        if (!data.schema.properties) {
          data.schema = {
            type: 'object',
            properties: { STIPS: data.schema },
          };
        }
        dispatch(receiveList(data));
        dispatch(stipFetchingSet(false));
        window.scroll(0, 0);
        return true;
      })
      .catch((response) => {
        // eslint-disable-next-line no-console
        console.error(response);
        dispatch(stipFetchingSet(false));
      });
  };
};

const newStip = () => {
  return (dispatch) => {
    let data = {};
    return apiFetch('admin/stip')
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          'todo: secure call failure alert message with operation error detail'
        );
      })
      .then((json) => {
        data = SchemaConverter.translateFromBackend({
          formData: [],
          schema: json.adminSchema,
        });
        return dispatch(_setView('load'));
      })
      .then(() => {
        dispatch(initStipInfo());
        dispatch(stipDescValidityReceive(false));
        dispatch(setStipFields(data));
        dispatch(_setView('create'));
        return true;
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };
};

const createStip = () => {
  return (dispatch, getState) => {
    const createOptions = getOptions('POST');
    const state = getState();
    const data = _get(state, ['stipulation', 'fields', 'formData']) || null;
    const transData = SchemaConverter.translateToBackend(data);
    const out = {};
    transData
      .filter((formDataElem) => {
        return formDataElem.fieldValue || formDataElem.fieldValues;
      })
      .flatMap((fd) => {
        out[fd.fieldId] = fd.fieldValue ? fd.fieldValue : fd.fieldValues;
      });
    let formData = [];
    if (data.PURPOSE === GENERAL) {
      formData = [
        { fieldId: 'INSTRUCTIONS', fieldValue: out.INSTRUCTIONS },
        { fieldId: 'CLONEABLE_IND', fieldValue: out.CLONEABLE_IND },
        {
          fieldId: 'STIP_DESC',
          fieldValue: out.STIP_DESC,
        },
        {
          fieldId: 'STIP_TYPE',
          fieldValue: out.STIP_TYPE,
        },
        {
          fieldId: 'PURPOSE',
          fieldValue: out.PURPOSE,
        },
        {
          fieldId: 'MANDATORY_IND',
          fieldValue: out.MANDATORY_IND,
        },
        {
          fieldId: 'COMMENT_REQ_IND',
          fieldValue: out.COMMENT_REQ_IND,
        },
        {
          fieldId: 'VISIBLE_IND',
          fieldValue: out.VISIBLE_IND,
        },
        {
          fieldId: 'WAIVE_IND',
          fieldValue: out.WAIVE_IND,
        },
        {
          fieldId: 'EDITABLE_IND',
          fieldValue: out.EDITABLE_IND,
        },
        {
          fieldId: 'FILE_DOC_TYPE',
          fieldValue: out.FILE_DOC_TYPE,
        },
        {
          fieldId: 'FILE_REQ_IND',
          fieldValue: '1',
        },
        {
          fieldId: 'MIN_APP_FILE_REQ',
          fieldValue: out.MIN_APP_FILE_REQ,
        },
        {
          fieldId: 'MIN_CH1_FILE_REQ',
          fieldValue: out.MIN_CH1_FILE_REQ,
        },
        {
          fieldId: 'MIN_CH2_FILE_REQ',
          fieldValue: out.MIN_CH2_FILE_REQ,
        },
        {
          fieldId: 'STIP_GROUPS',
          fieldValues: out.STIP_GROUPS || [[]],
        },
        {
          fieldId: 'STIP_STATES',
          fieldValues: out.STIP_STATES || [[]],
        },
        {
          fieldId: 'TP_STATUS',
          fieldValue: 'COMPLETE_APP',
        },
        {
          fieldId: 'STATUS',
          fieldValue: 'actv',
        },
      ];
    } else if (data.PURPOSE === DATA_COLLECT) {
      formData = [
        { fieldId: 'INSTRUCTIONS', fieldValue: out.INSTRUCTIONS },
        { fieldId: 'CLONEABLE_IND', fieldValue: out.CLONEABLE_IND },
        {
          fieldId: 'STIP_DESC',
          fieldValue: out.STIP_DESC,
        },
        {
          fieldId: 'STIP_TYPE',
          fieldValue: out.STIP_TYPE,
        },
        {
          fieldId: 'PURPOSE',
          fieldValue: out.PURPOSE,
        },
        {
          fieldId: 'MANDATORY_IND',
          fieldValue: out.MANDATORY_IND,
        },
        {
          fieldId: 'COMMENT_REQ_IND',
          fieldValue: '0',
        },
        {
          fieldId: 'VISIBLE_IND',
          fieldValue: out.VISIBLE_IND,
        },
        {
          fieldId: 'DISPLAY_COLUMNS',
          fieldValue: out.DISPLAY_COLUMNS,
        },
        {
          fieldId: 'WAIVE_IND',
          fieldValue: out.WAIVE_IND,
        },
        {
          fieldId: 'EDITABLE_IND',
          fieldValue: out.EDITABLE_IND,
        },
        {
          fieldId: 'STIP_FIELD_COLLECTION',
          fieldValues: out.STIP_FIELD_COLLECTION,
        },
        {
          fieldId: 'STIP_GROUPS',
          fieldValues: out.STIP_GROUPS || [[]],
        },
        {
          fieldId: 'STIP_STATES',
          fieldValues: out.STIP_STATES || [[]],
        },
        {
          fieldId: 'TP_STATUS',
          fieldValue: 'COMPLETE_APP',
        },
        {
          fieldId: 'STATUS',
          fieldValue: 'actv',
        },
      ];
    } else if ([ESIGN, DOC_DOWNLOAD].includes(data.PURPOSE)) {
      formData = [
        { fieldId: 'INSTRUCTIONS', fieldValue: out.INSTRUCTIONS },
        { fieldId: 'CLONEABLE_IND', fieldValue: out.CLONEABLE_IND },
        {
          fieldId: 'STIP_DESC',
          fieldValue: out.STIP_DESC,
        },
        {
          fieldId: 'STIP_TYPE',
          fieldValue: out.STIP_TYPE,
        },
        {
          fieldId: 'PURPOSE',
          fieldValue: out.PURPOSE,
        },
        {
          fieldId: 'MANDATORY_IND',
          fieldValue: data.PURPOSE === DOC_DOWNLOAD ? '0' : out.MANDATORY_IND,
        },
        {
          fieldId: 'COMMENT_REQ_IND',
          fieldValue: out.COMMENT_REQ_IND,
        },
        {
          fieldId: 'VISIBLE_IND',
          fieldValue: out.VISIBLE_IND,
        },
        {
          fieldId: 'WAIVE_IND',
          fieldValue: out.WAIVE_IND,
        },
        {
          fieldId: 'EDITABLE_IND',
          fieldValue: out.EDITABLE_IND,
        },
        {
          fieldId: 'TEMPLATE_ID',
          fieldValue: out.TEMPLATE_ID,
        },
        {
          fieldId: 'TEMPLATE_DESC',
          fieldValue: out.TEMPLATE_DESC,
        },
        {
          fieldId: 'TP_STATUS',
          fieldValue: 'COMPLETE_APP',
        },
        {
          fieldId: 'STATUS',
          fieldValue: 'actv',
        },
      ];
    } else {
      formData = [
        { fieldId: 'INSTRUCTIONS', fieldValue: out.INSTRUCTIONS },
        {
          fieldId: 'STIP_DESC',
          fieldValue: out.STIP_DESC,
        },
        {
          fieldId: 'STIP_TYPE',
          fieldValue: out.STIP_TYPE,
        },
        {
          fieldId: 'PURPOSE',
          fieldValue: out.PURPOSE,
        },
        {
          fieldId: 'TP_STATUS',
          fieldValue: 'COMPLETE_APP',
        },
        {
          fieldId: 'STATUS',
          fieldValue: 'actv',
        },
      ];
    }

    createOptions.body = JSON.stringify(formData);
    return new Promise((resolve, reject) => {
      return apiFetch('admin/stip/', createOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          const errors = {
            type: 'error',
            message: 'Failed to Save!',
          };
          dispatch(setInfo(errors));
          return reject();
        })
        .then((json) => {
          if (json.status === 'SUCCESS') {
            dispatch(setStipInfo('1', out.STIP_DESC));
            dispatch(setCreateStip(true));
            const errors = {
              type: 'success',
              message: 'Stipulation Created Successfully!',
              delay: 3000,
            };
            dispatch(setInfo(errors));
            return resolve(true);
          }
          throw json;
        })
        .catch(() => {
          const errors = {
            type: 'error',
            message: 'Failed to Save!',
          };
          dispatch(setInfo(errors));
          return reject(new Error(false));
        });
    });
  };
};

const fetchStip = (stipId) => {
  return (dispatch) => {
    dispatch(stipFetchingSet(true));
    dispatch(_setView('load'))
      .then(() => {
        return apiFetch(`admin/stip/${stipId}`);
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error();
      })
      .then((response) => {
        const data = SchemaConverter.translateFromBackend({
          formData: response.formData,
          schema: response.adminSchema,
        });
        dispatch(
          setStipInfo(
            _get(data, ['formData', 'STIP_ID']),
            _get(data, ['formData', 'STIP_DESC'])
          )
        );
        dispatch(setStipFields(data));
        dispatch(stipDescValidityReceive(true));
        dispatch(_setView('edit'));
        dispatch(stipFetchingSet(false));
        window.scroll(0, 0);
        return true;
      })
      .catch((response) => {
        // eslint-disable-next-line no-console
        console.error(response);
        dispatch(stipFetchingSet(false));
      });
  };
};

const updateRequiredFields = (purpose) => {
  return (dispatch, getState) => {
    // let putOptions = getOptions('PUT');
    const state = Immutable.asMutable(getState(), { deep: true });
    const data = state.stipulation.fields.schema;
    let required = [];
    if (purpose === GENERAL) {
      required = [
        'STIP_ID',
        'STIP_DESC',
        'MANDATORY_IND',
        'COMMENT_REQ_IND',
        'VISIBLE_IND',
        'WAIVE_IND',
        'EDITABLE_IND',
        'FILE_REQ_IND',
        'STIP_TYPE',
        'PURPOSE',
        'MIN_APP_FILE_REQ',
        'MIN_CH1_FILE_REQ',
        'MIN_CH2_FILE_REQ',
        'STATUS',
      ];
    } else if (purpose === DATA_COLLECT) {
      required = [
        'STIP_ID',
        'STIP_DESC',
        'STIP_TYPE',
        'PURPOSE',
        'MANDATORY_IND',
        'COMMENT_REQ_IND',
        'WAIVE_IND',
        'EDITABLE_IND',
        'STATUS',
        'STIP_FIELD_COLLECTION',
        'DISPLAY_COLUMNS',
        'VISIBLE_IND',
      ];
    } else if (purpose === ESIGN) {
      required = [
        'STIP_ID',
        'STIP_DESC',
        'MANDATORY_IND',
        'COMMENT_REQ_IND',
        'VISIBLE_IND',
        'WAIVE_IND',
        'EDITABLE_IND',
        'TEMPLATE_ID',
        'TEMPLATE_DESC',
        'STIP_TYPE',
        'PURPOSE',
        'STATUS',
      ];
    } else {
      required = [
        'STIP_ID',
        'STIP_DESC',
        'STIP_TYPE',
        'PURPOSE',
        'TP_STATUS',
        'STATUS',
      ];
    }
    data.required = required;
    dispatch(setRequiredFields(data));
  };
};

const sendStip = () => {
  return (dispatch, getState) => {
    const putOptions = getOptions('PUT');
    // save the primary formData
    const state = Immutable.asMutable(getState(), { deep: true });
    const data = state.stipulation.fields.formData;
    const transData = SchemaConverter.translateToBackend(data);
    const out = {};
    transData
      .filter((formDataElem) => {
        return formDataElem.fieldValue || formDataElem.fieldValues;
      })
      .flatMap((fd) => {
        out[fd.fieldId] = fd.fieldValue ? fd.fieldValue : fd.fieldValues;
      });
    const { stipId } = state.stipulation;
    if (stipId === undefined) {
      throw new Error('Could not save changes!  Please contact help desk.');
    }
    const { stipDesc } = state.stipulation;
    if (stipDesc.length === undefined) {
      throw new Error('Could not save changes!  Please contact help desk.');
    }
    let formData = [];
    if (data.PURPOSE === GENERAL) {
      formData = [
        { fieldId: 'INSTRUCTIONS', fieldValue: out.INSTRUCTIONS },
        { fieldId: 'CLONEABLE_IND', fieldValue: out.CLONEABLE_IND },
        {
          fieldId: 'STIP_ID',
          fieldValue: stipId,
        },
        {
          fieldId: 'STIP_DESC',
          fieldValue: out.STIP_DESC,
        },
        {
          fieldId: 'STIP_TYPE',
          fieldValue: out.STIP_TYPE,
        },
        {
          fieldId: 'PURPOSE',
          fieldValue: out.PURPOSE,
        },
        {
          fieldId: 'MANDATORY_IND',
          fieldValue: out.MANDATORY_IND,
        },
        {
          fieldId: 'COMMENT_REQ_IND',
          fieldValue: out.COMMENT_REQ_IND,
        },
        {
          fieldId: 'VISIBLE_IND',
          fieldValue: out.VISIBLE_IND,
        },
        {
          fieldId: 'WAIVE_IND',
          fieldValue: out.WAIVE_IND,
        },
        {
          fieldId: 'EDITABLE_IND',
          fieldValue: out.EDITABLE_IND,
        },
        {
          fieldId: 'FILE_DOC_TYPE',
          fieldValue: out.FILE_DOC_TYPE,
        },
        {
          fieldId: 'FILE_REQ_IND',
          fieldValue: '1',
        },
        {
          fieldId: 'MIN_APP_FILE_REQ',
          fieldValue: out.MIN_APP_FILE_REQ,
        },
        {
          fieldId: 'MIN_CH1_FILE_REQ',
          fieldValue: out.MIN_CH1_FILE_REQ,
        },
        {
          fieldId: 'MIN_CH2_FILE_REQ',
          fieldValue: out.MIN_CH2_FILE_REQ,
        },
        {
          fieldId: 'STIP_GROUPS',
          fieldValues: out.STIP_GROUPS || [[]],
        },
        {
          fieldId: 'STIP_STATES',
          fieldValues: out.STIP_STATES || [[]],
        },
        {
          fieldId: 'TP_STATUS',
          fieldValue: 'COMPLETE_APP',
        },
        {
          fieldId: 'STATUS',
          fieldValue: 'actv',
        },
      ];
    } else if (data.PURPOSE === DATA_COLLECT) {
      formData = [
        { fieldId: 'INSTRUCTIONS', fieldValue: out.INSTRUCTIONS },
        { fieldId: 'CLONEABLE_IND', fieldValue: out.CLONEABLE_IND },
        {
          fieldId: 'STIP_ID',
          fieldValue: stipId,
        },
        {
          fieldId: 'STIP_DESC',
          fieldValue: out.STIP_DESC,
        },
        {
          fieldId: 'STIP_TYPE',
          fieldValue: out.STIP_TYPE,
        },
        {
          fieldId: 'PURPOSE',
          fieldValue: out.PURPOSE,
        },
        {
          fieldId: 'MANDATORY_IND',
          fieldValue: out.MANDATORY_IND,
        },
        {
          fieldId: 'COMMENT_REQ_IND',
          fieldValue: '0',
        },
        {
          fieldId: 'WAIVE_IND',
          fieldValue: out.WAIVE_IND,
        },
        {
          fieldId: 'EDITABLE_IND',
          fieldValue: out.EDITABLE_IND,
        },
        {
          fieldId: 'STIP_FIELD_COLLECTION',
          fieldValues: out.STIP_FIELD_COLLECTION,
        },
        {
          fieldId: 'STIP_GROUPS',
          fieldValues: out.STIP_GROUPS || [[]],
        },
        {
          fieldId: 'STIP_STATES',
          fieldValues: out.STIP_STATES || [[]],
        },
        {
          fieldId: 'TP_STATUS',
          fieldValue: 'COMPLETE_APP',
        },
        {
          fieldId: 'STATUS',
          fieldValue: 'actv',
        },
        {
          fieldId: 'DISPLAY_COLUMNS',
          fieldValue: out.DISPLAY_COLUMNS || 3,
        },
        {
          fieldId: 'VISIBLE_IND',
          fieldValue: out.VISIBLE_IND,
        },
      ];
    } else if (data.PURPOSE === ESIGN) {
      formData = [
        { fieldId: 'INSTRUCTIONS', fieldValue: out.INSTRUCTIONS },
        { fieldId: 'CLONEABLE_IND', fieldValue: out.CLONEABLE_IND },
        {
          fieldId: 'STIP_DESC',
          fieldValue: out.STIP_DESC,
        },
        {
          fieldId: 'STIP_TYPE',
          fieldValue: out.STIP_TYPE,
        },
        {
          fieldId: 'PURPOSE',
          fieldValue: out.PURPOSE,
        },
        {
          fieldId: 'MANDATORY_IND',
          fieldValue: out.MANDATORY_IND,
        },
        {
          fieldId: 'COMMENT_REQ_IND',
          fieldValue: out.COMMENT_REQ_IND,
        },
        {
          fieldId: 'VISIBLE_IND',
          fieldValue: out.VISIBLE_IND,
        },
        {
          fieldId: 'WAIVE_IND',
          fieldValue: out.WAIVE_IND,
        },
        {
          fieldId: 'EDITABLE_IND',
          fieldValue: out.EDITABLE_IND,
        },
        {
          fieldId: 'TEMPLATE_ID',
          fieldValue: out.TEMPLATE_ID,
        },
        {
          fieldId: 'TEMPLATE_DESC',
          fieldValue: out.TEMPLATE_DESC,
        },
        {
          fieldId: 'TP_STATUS',
          fieldValue: 'COMPLETE_APP',
        },
        {
          fieldId: 'STATUS',
          fieldValue: 'actv',
        },
        {
          fieldId: 'STIP_ID',
          fieldValue: stipId,
        },
      ];
    } else {
      formData = [
        { fieldId: 'INSTRUCTIONS', fieldValue: out.INSTRUCTIONS },
        {
          fieldId: 'STIP_ID',
          fieldValue: stipId,
        },
        {
          fieldId: 'STIP_DESC',
          fieldValue: out.STIP_DESC,
        },
        {
          fieldId: 'STIP_TYPE',
          fieldValue: out.STIP_TYPE,
        },
        {
          fieldId: 'PURPOSE',
          fieldValue: out.PURPOSE,
        },
        {
          fieldId: 'TP_STATUS',
          fieldValue: 'COMPLETE_APP',
        },
        {
          fieldId: 'STATUS',
          fieldValue: 'actv',
        },
      ];
    }

    putOptions.body = JSON.stringify(formData);
    return new Promise((resolve, reject) => {
      return apiFetch('admin/stip/', putOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          const errors = {
            type: 'error',
            message: 'Failed to Save!',
          };
          dispatch(setInfo(errors));
          throw reject(new Error(false));
        })
        .then((msg) => {
          if (msg.status === 'SUCCESS') {
            dispatch(
              setInfo({
                type: 'success',
                message: 'Stipulation Saved Successfully!',
                delay: 3000,
              })
            );
            return resolve(true);
          }
          const errors = {
            type: 'error',
            message: msg.errorMsgs,
          };
          dispatch(setInfo(errors));
          throw reject(new Error(false));
        })
        .catch((err) => {
          const errors = {
            type: 'error',
            code: 'Failed to Save',
          };
          // eslint-disable-next-line no-console
          console.error(err);
          dispatch(setInfo(errors));
          return reject(new Error(false));
        });
    });
  };
};

const deleteStip = (stipId) => async (dispatch) => {
  dispatch(stipFetchingSet(true));

  try {
    await api.delete(`admin/stip/${stipId}`);
    dispatch(fetchStipList());
    dispatch(setStipDeleted(true));
    dispatch(initStipInfo());
    dispatch(
      setInfo({
        type: 'success',
        message: 'Stipulation Deleted Successfully!',
        delay: 3000,
      })
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    if (e.message) {
      dispatch(setInfo({ type: 'error', message: e.message }));
    }
  }
};

export const updateToRedux = (formData) => {
  return (dispatch) => {
    dispatch(updateFormData(formData));
  };
};

const cancel = () => {
  return (dispatch) => {
    dispatch(_setView('load'))
      .then(() => {
        dispatch(initStipInfo());
        dispatch(fetchStipList());
        dispatch(_setView('view'));
        return true;
      })
      // eslint-disable-next-line no-console
      .catch(() => console.log(''));
  };
};

const setStipDescValid = (valid) => {
  return (dispatch) => {
    dispatch(stipDescValidityReceive(valid));
  };
};

export const actions = {
  cancel,
  deleteStip,
  setStipFields,
  fetchStipList,
  fetchStip,
  initStipInfo,
  newStip,
  setFieldErrors,
  setView,
  sendStip,
  setStipDescValid,
  setStipInfo,
  createStip,
  updateFormData,
  updateToRedux,
  setRequiredFields,
  updateRequiredFields,
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [STIP_FETCHING_SET]: (state, action) => {
    return Immutable({
      ...state,
      fetching: action.payload,
    });
  },
  [STIP_VIEW_SET]: (state, action) => {
    return Immutable({ ...state, view: action.payload });
  },
  [STIP_RESPONSE_SET]: (state, action) => {
    return Immutable({ ...state, formData: action.payload });
  },
  [STIP_CREATED_SET]: (state, action) => {
    return Immutable({ ...state, createStip: action.payload });
  },
  [STIP_DELETED_SET]: (state, action) => {
    return Immutable({ ...state, stipTypeDeleted: action.payload });
  },
  [STIP_FIELD_SET]: (state, action) => {
    return Immutable({ ...state, fields: action.payload });
  },
  [STIP_FORMDATA_UPDATE]: (state, action) => {
    return Immutable.setIn(state, ['fields', 'formData'], action.payload.data);
  },
  [STIP_DESC_VALID_SET]: (state, action) => {
    return Immutable({ ...state, isStipDescValid: action.payload });
  },
  [STIP_LIST_SET]: (state, action) => {
    return Immutable({ ...state, ...action.payload });
  },
  [STIP_INFO_INIT]: (state) => {
    return Immutable({ ...state, stipId: 'new', stipDesc: '' });
  },
  [STIP_INFO_SET]: (state, action) => {
    return Immutable({
      ...state,
      stipId: action.payload[0],
      stipDesc: action.payload[1],
    });
  },
  [STIP_FIELD_ERRORS_SET]: (state, action) => {
    return Immutable({ ...state, errors: action.payload });
  },
  [STIP_REQUIRED_FIELDS_SET]: (state, action) => {
    return Immutable.setIn(state, ['fields', 'schema'], action.payload);
  },
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = { view: 'view' };
export default function stipulationReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

import _cloneDeep from 'lodash/cloneDeep';

// filters
export const getFilters = (state) => state?.search?.filters ?? {};

export const getFilterDeleteConfirmModal = (state) =>
  state?.search?.filterDeleteConfirmModal ?? {};

export const getFilterId = (state) => state?.search?.dataTable?.filterId ?? '';

// queues
export const getQueues = (state) => state?.search?.queues?.queueList ?? [];

export const getSearchColumns = (state) => {
  return _cloneDeep(state?.search?.dataTable?.columns ?? []);
};

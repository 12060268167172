import touchpoint from '../routes/Touchpoint/modules/touchpoint';
import disbursements from '../routes/Touchpoint/modules/disbursements';
import tasks from '../routes/Touchpoint/modules/tasks';
import idology from '../routes/Touchpoint/modules/idology';

const makeRootReducerTouchpoint = () => ({
  idology,
  tasks,
  disbursements,
  touchpoint
});

export default makeRootReducerTouchpoint;

/* eslint-disable no-undef */
import Cookies from 'js-cookie';

export const BASE_PATH = '/fni-lenderportal-search/';
/* eslint-enable no-undef */

export const API_PATH = BASE_PATH;

export default (url, options = {}, api = API_PATH) => {
  if (!window.XSRF) {
    window.XSRF = Cookies.get('XSRF-TOKEN');
  }
  const { XSRF } = window;

  Cookies.get('XSRF-TOKEN');

  const defaultOptions = {
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': XSRF,
    },
  };

  if (window.refnum) {
    defaultOptions.headers.REFNUM = window.refnum;
  }
  const newOptions = { ...defaultOptions, ...options };
  return fetch(api + url, newOptions);
};

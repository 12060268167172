import Immutable from 'seamless-immutable';

// ------------------------------------
// Constants
// ------------------------------------
export const API_ERROR_SET = 'API_ERROR_SET';
export const API_ERROR_CLEAR = 'API_ERROR_CLEAR';

export const DEFAULT_ERROR_MESSAGE = 'Unable to contact the server.';

// ------------------------------------
// Actions
// ------------------------------------

export const apiErrorSet = (
  path,
  message = DEFAULT_ERROR_MESSAGE,
  type = 'GENERAL'
) => {
  return {
    type: API_ERROR_SET,
    payload: {
      path,
      message,
      type,
    },
  };
};

export const apiErrorClear = (path) => {
  return {
    type: API_ERROR_CLEAR,
    payload: path,
  };
};

// ------------------------------------
// Middleware
// ------------------------------------

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [API_ERROR_SET]: (state, action) => {
    return Immutable({
      ...state,
      [action.payload.path]: {
        message: action.payload.message,
        type: action.payload.type,
      },
    });
  },
  [API_ERROR_CLEAR]: (state, action) => {
    return Immutable({ ...state, [action.payload]: null });
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};

export default function errorReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

/* eslint-disable import/no-cycle */
import timeoutReducer from 'fni-components/FNITimeout/modules/fniTimeout';
import messagingReducer from 'fni-components/FNIMessaging/modules/messaging';
import formDataReducer from './formData';
import navigationReducer from './navigation';

const makeRootReducer = () => ({
  timeout: timeoutReducer,
  navigation: navigationReducer,
  messaging: messagingReducer,
  formData: formDataReducer,
});

export default makeRootReducer;

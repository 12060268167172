import { CircularProgress, Backdrop, Box } from '@mui/material';

export default function LoadingSpinner({ open }) {

    return (
        <Box display='flex' width='100%' height='100%' alignItems='center' justifyContent='center'>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open || false}
            >
                <CircularProgress />
            </Backdrop>
        </Box>
    );
}

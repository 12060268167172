import Immutable from 'seamless-immutable';
import { batch } from 'react-redux';
import { makeApi } from 'fni-schema';

import { setInfo } from '../../../store/coreLayout';

export const api = makeApi({ baseURL: 'fni-lenderportal-admin/' });
// ------------------------------------
// Constants
// ------------------------------------
const SET_ERROR_MANAGEMENT = 'SET_ERROR_MANAGEMENT';
const SET_ERROR_MANAGEMENT_FETCHING = 'SET_ERROR_MANAGEMENT_FETCHING';

// ------------------------------------
// Actions
// ------------------------------------
export const setErrorManagement = (data, path) => {
  return {
    type: SET_ERROR_MANAGEMENT,
    payload: { data, path },
  };
};

export const setFetching = (path, data) => {
  return {
    type: SET_ERROR_MANAGEMENT_FETCHING,
    payload: { data, path },
  };
};

export const setError = (message) => setInfo({ type: 'error', message });

export const setSuccess = (message) =>
  setInfo({ type: 'success', message, delay: 2000 });

// ------------------------------------
// Middleware
// ------------------------------------

// GET /admin/spoolFiles
export const getSpoolFiles = () => async (dispatch) => {
  const fetchPath = ['fetching', 'spoolFiles'];
  try {
    dispatch(setFetching(fetchPath, true));
    const response = await api.get('admin/spoolFiles');
    batch(() => {
      dispatch(setErrorManagement(response, ['spoolFiles']));
      dispatch(setFetching(fetchPath, false));
    });
  } catch (error) {
    if (error.message) {
      dispatch(setError(error.message));
    } else {
      dispatch(setError('Failed to fetch spool files'));
    }
    dispatch(setFetching(fetchPath, false));
  }
};

// GET /admin/spoolFile/{spoolFileName}
export const getSpoolFile = (name) => async (dispatch) => {
  const fetchPath = ['fetching', 'spoolFiles'];
  try {
    dispatch(setFetching(fetchPath, true));
    const response = await api.get(`admin/spoolFile/${name}`);
    batch(() => {
      dispatch(setErrorManagement(response, ['spoolFile']));
      dispatch(setFetching(fetchPath, false));
    });
  } catch (error) {
    if (error.message) {
      dispatch(setError(error.message));
    } else {
      dispatch(setError('Failed to fetch spool file'));
    }
    dispatch(setFetching(fetchPath, false));
    throw error;
  }
};

// GET /admin/engineRequeueList
export const getEngineRequeueList = () => async (dispatch) => {
  const fetchPath = ['fetching', 'engineRequeue'];
  try {
    dispatch(setFetching(fetchPath, true));
    const response = await api.get('admin/engineRequeueList');
    batch(() => {
      dispatch(setErrorManagement(response, ['engineRequeueList']));
      dispatch(setFetching(fetchPath, false));
    });
  } catch (error) {
    if (error.message) {
      dispatch(setError(error.message));
    } else {
      dispatch(setError('Failed to fetch engine requeue list'));
    }
    dispatch(setFetching(fetchPath, false));
    throw error;
  }
};

// GET /admin/engineRequeueDetail/{refnum}/{requeueCount}
export const getEngineRequeue = (refnum, requeueCount) => async (dispatch) => {
  const fetchPath = ['fetching', 'engineRequeue'];
  try {
    dispatch(setFetching(fetchPath, true));
    const response = await api.get(
      `admin/engineRequeueDetail/${refnum}/${requeueCount}`,
    );
    batch(() => {
      dispatch(setErrorManagement(response, ['engineRequeue']));
      dispatch(setFetching(fetchPath, false));
    });
  } catch (error) {
    if (error.message) {
      dispatch(setError(error.message));
    } else {
      dispatch(setError('Failed to fetch engine requeue details'));
    }
    dispatch(setFetching(fetchPath, false));
    throw error;
  }
};

// PUT /admin/spoolFile/{spoolFileName}
export const saveSpoolFile = (name, type) => async (dispatch, getState) => {
  const fetchPath = ['saving', 'spoolFiles'];

  const spoolType = type === 'ENTITY' ? 'ENTITIES' : 'SQL_LIST';
  const data = getState().engineErrors.spoolFile.formData[spoolType];
  try {
    dispatch(setFetching(fetchPath, true));
    await api.put(`admin/spoolFile/${name}`, [
      {
        [spoolType]: data,
      },
    ]);
    dispatch(setFetching(fetchPath, false));
  } catch (error) {
    if (error.message) {
      dispatch(setError(error.message));
    } else {
      dispatch(setError('Failed to save spool file'));
    }
    dispatch(setFetching(fetchPath, false));
    throw error;
  }
  dispatch(setSuccess('Spool file saved successfully'));
};

// GET /admin/requeueSpool/{spoolFileName}
export const requeueSpoolFile = (name) => async (dispatch) => {
  const fetchPath = ['submitting', 'spoolFiles'];
  try {
    dispatch(setFetching(fetchPath, true));

    const response = await api.get(`admin/requeueSpool/${name}`);
    batch(() => {
      dispatch(setSuccess('Spool file requeued successfully'));
      dispatch(setFetching(fetchPath, false));
    });
    return response;
  } catch (error) {
    if (error.message) {
      dispatch(setError(error.message));
    } else {
      dispatch(setError('Failed to requeue spool file'));
    }
    dispatch(setFetching(fetchPath, false));
    throw error;
  }
};

// POST /admin/requeueSpools
export const requeueSpoolFiles = (spoolFileArray) => async (dispatch) => {
  const fetchPath = ['fetching', 'spoolFiles'];
  try {
    dispatch(setFetching(fetchPath, true));
    await api.post('admin/requeueSpools', [
      {
        SPOOL_FILES: spoolFileArray,
      },
    ]);
    dispatch(setFetching(fetchPath, false));
  } catch (error) {
    if (error.message) {
      dispatch(setError(error.message));
    } else {
      dispatch(setError('Failed to requeue spool files'));
    }
    dispatch(setFetching(fetchPath, false));
    throw error;
  }
  dispatch(setSuccess('Spool files requeued successfully'));
};

// GET /admin/engineRequeue/{refnum}/{requeueCount}
export const requeueToEngine = (refnum, requeueCount) => async (dispatch) => {
  const fetchPath = ['submitting', 'engineRequeue'];
  try {
    dispatch(setFetching(fetchPath, true));
    const response = await api.get(
      `admin/engineRequeue/${refnum}/${requeueCount}`,
    );
    batch(() => {
      dispatch(setFetching(fetchPath, false));
      dispatch(setSuccess('Requeued to engine successfully'));
    });

    return response;
  } catch (error) {
    if (error.message) {
      dispatch(setError(error.message));
    } else {
      dispatch(setError('Failed to requeue to engine'));
    }
    dispatch(setFetching(fetchPath, false));
    throw error;
  }
};

// DELETE /admin/spoolFile/{spoolFileName}
export const deleteSpoolFile = (spoolFileName) => async (dispatch) => {
  try {
    await api.delete(`admin/spoolFile/${spoolFileName}`);
    dispatch(setSuccess('Spool file removed successfully'));
  } catch (error) {
    if (error.message) {
      dispatch(setError(error.message));
    } else {
      dispatch(setError('Failed to remove spool file'));
    }
    throw error;
  }
};

// DELETE /admin/engineRequeue/{refnum}/{requeueCount}
export const deleteEngineRequeue =
  (refnum, requeueCount) => async (dispatch) => {
    try {
      await api.delete(`admin/engineRequeue/${refnum}/${requeueCount}`);
      dispatch(setSuccess('Requeue removed successfully'));
    } catch (error) {
      if (error.message) {
        dispatch(setError(error.message));
      } else {
        dispatch(setError('Failed to remove engine requeue'));
      }
      throw error;
    }
  };

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_ERROR_MANAGEMENT]: (state, { payload: { data, path } }) => {
    return Immutable.setIn(state, path, data);
  },
  [SET_ERROR_MANAGEMENT_FETCHING]: (state, { payload: { data, path } }) => {
    return Immutable.setIn(state, path, data);
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};
export default function errorManagementReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { useDispatch } from 'react-redux';
import { getAuth, onIdTokenChanged } from 'firebase/auth';
import CssBaseline from '@mui/material/CssBaseline';

import { authActions } from './authentication/modules/auth';
import AppRouter from './router/AppRouter';
import theme from './theme';
import { coreActions } from './redux/CoreSlice/core';
import TabIcon from './TabIcon';

function App() {

    const dispatch = useDispatch();
    const [authChecked, setAuthChecked] = useState(false);

    useEffect(() => {
        const code = localStorage.getItem('code');
        if(code) dispatch(coreActions.setCode(code));
    }, []);

    useEffect(() => {
        const unsub = onIdTokenChanged(getAuth(), user => {
            if(!user){ 
                dispatch(authActions.clearAuth());
                setAuthChecked(true);            
            } else {
                user.getIdTokenResult(true).then(response => {
                    const claims = response?.claims;
                    
                    if(!claims) {
                        dispatch(authActions.clearAuth());
                    }
                    else {
                        dispatch(authActions.setCurrentUser({ 
                            id: user.uid,
                            email: user.email,
                            tenants: claims.tenants,
                            isFNIAdmin: claims.isFNIAdmin,
                            isValid: claims.isValid,
                        })); 
                    }
                    setAuthChecked(true);
                });
            }
        });
        return unsub;
    }, []);

    if (authChecked) {
        return (
            <BrowserRouter basename="/">
                <TabIcon />
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <AppRouter />
                </ThemeProvider>
            </BrowserRouter>
        );
    }
    return null;
}

export default App;

export const getIsRequired = (isRequired, validationMessages) => (value) => {
  if (!isRequired || value || value === 0) return undefined;
  return (
    validationMessages?.find((message) => message.type === 'required')
      ?.message ?? 'Field is required'
  );
};

export const getIsValidFormat = (pattern, validationMessages) => (value) => {
  if (!value || value.toString().match(pattern)) return undefined;
  return validationMessages?.find((message) => message.type === 'pattern')
    ?.message;
};

export const getIsOverMinLength = (minLength, validationMessages) => (
  value
) => {
  if (!value || !minLength || value.toString().length >= minLength)
    return undefined;
  return validationMessages?.find((message) => message.type === 'minLength')
    ?.message;
};

export const getIsUnderMaxLength = (maxLength, validationMessages) => (
  value
) => {
  if (!value || !maxLength || value.toString().length <= maxLength)
    return undefined;
  return validationMessages?.find((message) => message.type === 'maxLength')
    ?.message;
};

export const composeValidators = (...validators) => (value, allValues) =>
  validators.reduce(
    (error, validator) => error || validator(value, allValues),
    undefined
  );

export const validateField = ({
  required,
  validationMessages,
  pattern,
  minLength,
  maxLength,
}) =>
  composeValidators(
    getIsRequired(required, validationMessages),
    getIsValidFormat(pattern, validationMessages),
    getIsOverMinLength(minLength, validationMessages),
    getIsUnderMaxLength(maxLength, validationMessages)
  );

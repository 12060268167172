import { Box } from '@mui/system';

const styles = {
    minHeight: '150px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
};

const Logo = ({ width, code }) => (
    <Box width={width} 
        marginTop={3}
        sx={styles}
    >
        <img
            width="100%"
            src={`/getLogo?code=${code}`}
            alt="logo"
            onError={event => {
                event.target.src = '/assets/Full-FNI.png';
                event.onerror = null;
            }}
        />
    </Box>
);

export default Logo;

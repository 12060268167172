import Immutable from 'seamless-immutable';
import { makeApi } from 'fni-schema';

import { setInfo } from '../../../store/coreLayout';

// ------------------------------------
// Constants
// ------------------------------------
export const api = makeApi({ baseURL: 'fni-lenderportal-admin/admin/' });

const SET_SWAGGER = 'SET_SWAGGER';
const SET_SWAGGER_FETCHING = 'SET_SWAGGER_FETCHING';

// ------------------------------------
// Actions
// ------------------------------------
export const setSwagger = (path, data) => {
  return {
    type: SET_SWAGGER,
    payload: { data, path },
  };
};

export const setFetching = (path, data) => {
  return {
    type: SET_SWAGGER_FETCHING,
    payload: { data, path },
  };
};
// ------------------------------------
// Middleware
// ------------------------------------

// GET /admin/getExternalSwaggerVersions
export const getSwaggerVersions = () => async (dispatch) => {
  const fetchPath = ['fetching', 'versions'];
  dispatch(setFetching(fetchPath, true));

  try {
    const response = await api.get('getExternalSwaggerVersions');
    dispatch(setSwagger(['versions'], response));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    dispatch(setInfo({ type: 'error', message: e.message }));
  } finally {
    dispatch(setFetching(fetchPath, false));
  }
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_SWAGGER]: (state, { payload: { data, path } }) => {
    return Immutable.setIn(state, path, data);
  },
  [SET_SWAGGER_FETCHING]: (state, { payload: { data, path } }) => {
    return Immutable.setIn(state, path, data);
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};
export default function swaggerReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

import { Alert, Snackbar } from '@mui/material';

const alertConfig = {
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
    autoHideDuration: 4000,
};

export default function LocalAlert({ alert, onHideAlert }) {
    const  { message, open, severity } = alert;

    return (  
        <Snackbar 
            anchorOrigin={alertConfig.anchorOrigin} 
            open={open} 
            autoHideDuration={alertConfig.autoHideDuration}
            onClose={onHideAlert}>

            <Alert 
                severity={severity}
                onClose={onHideAlert}>
                {message}
            </Alert>

        </Snackbar>
    );
}

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions, authSelectors } from '../authentication/modules/auth';
import { coreSelectors } from '../redux/CoreSlice/core';
import MigratedRouter from './MigratedRouter';
import UnmigratedRouter from './UnmigratedRouter';

export default function AppRouter() {

    const FNIAdminCode = 'fni';
    const dispatch = useDispatch();
    const tenantIsMigrated = useSelector(authSelectors.tenantIsMigrated);
    const availableTenants = useSelector(authSelectors.availableTenants);
    const currentAuthenticatedUser = useSelector(authSelectors.currentAuthenticatedUser);
    const tenantCode = useSelector(coreSelectors.tenantCode);
    const [tenantChecked, setTenantChecked] = useState(false);

    const loadMigratedForDev = process.env.NODE_ENV === 'development' && process.env.REACT_APP_LOAD_CONSOLIDATED === 'true';

    useEffect(() => {
        if(availableTenants?.length === 1) {
            dispatch(authActions.setCurrentTenant(availableTenants[0]));
            setTenantChecked(true);
        }
        else if(currentAuthenticatedUser && currentAuthenticatedUser?.isValid && tenantCode && tenantCode.toLowerCase() !== FNIAdminCode) {
            dispatch(authActions.getCurrentTenantByCode())
                .then(() => setTenantChecked(true));
        } else if(!availableTenants) setTenantChecked(true);

    } ,[availableTenants, currentAuthenticatedUser]);

    if(!tenantChecked) return null;
    return tenantIsMigrated || loadMigratedForDev ? <MigratedRouter /> : <UnmigratedRouter />;
}

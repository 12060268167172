import React from 'react';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import Immutable from 'seamless-immutable';
import _isEmpty from 'lodash/isEmpty';

const API = '/fni-lenderportal-navigation/navigation/';

export const bureauMapping = {
  experian: {
    name: 'Experian',
    code: 2,
    abbreviations: ['trw7', 'ex', 'X', 'EXP'],
  },
  equifax: {
    name: 'Equifax',
    code: 4,
    abbreviations: ['equ5', 'eq', 'Q', 'EQ'],
  },
  transunion: {
    name: 'Transunion',
    code: 7,
    abbreviations: ['tu40', 'tua', 'tu', 'U', 'TU'],
  },
};

export const CONTAINER_STYLES = {
  backgroundColor: 'rgb(255, 255, 255)',
  padding: '15px',
  marginTop: '0.7rem',
  marginBottom: '0.7rem',
  border: '1px solid #d6d6d6',
};

export const fmtCommas = (numString) => {
  numString = numString.replace(/,/g, '');
  let x = numString.split('.');
  let x1 = x[0];
  let x2 = x.length > 1 ? '.' + x[1] : '';
  let rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
};

export const apiFetch = (url, options = {}, api = API) => {
  let defaultOptions = {
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  options = { ...defaultOptions, ...options };
  return fetch(api + url, options);
};

export const formatPhone = (num) => {
  if (num) {
    num = num.toString();
    num = num.replace(/[^\d]/g, '');

    if (num.length === 10) {
      return num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    } else if (num.length === 7) {
      return num.replace(/(\d{3})(\d{4})/, '$1-$2');
    }
  } else {
    return null;
  }
};

export const getSelectOptions = (properties) => {
  if (!properties) {
    return [];
  }
  return properties.enum.map((value, i) => {
    return {
      value: value,
      label: properties.enumNames[i],
    };
  });
};

export const capitalizeEnum = (values) => {
  return values.map((e) => {
    return e.toUpperCase();
  });
};

function search(item) {
  for (var key in this) {
    let check = this[key] || '';
    if (
      !item[key] ||
      !item[key]
        .toString()
        .toLowerCase()
        .includes(check.toString().toLowerCase())
    ) {
      return false;
    }
  }
  return true;
}

export const sortAndFilter = (sort, filtered, items, idKey) => {
  let newItems = [];
  if (!_isEmpty(filtered)) {
    newItems = Immutable.asMutable(items.filter(search, filtered), {
      deep: true,
    });
  } else {
    newItems = Immutable.asMutable(items, { deep: true });
  }
  let orderArr = [];
  let directionArr = [];
  sort.map((s) => {
    orderArr.push(s.columnName);
    directionArr.push(s.sortDirection.toLowerCase());
  });
  return orderBy(
    newItems,
    orderArr.map((column) => {
      return (row) => {
        const value = row[column];
        return value && value !== null ? value.toString().toLowerCase() : null;
      };
    }),
    directionArr
  );
};

export const setSort = (data, state) => {
  let columns = Immutable.asMutable(state.sortedArray, { deep: true });
  let index = columns.findIndex((c) => {
    return c.columnName === data.column.info.columnName;
  });
  if (data.column.clearSort) {
    columns.splice(index, 1);
  } else if (index === -1) {
    columns.push(data.column.info);
  } else {
    columns[index] = data.column.info;
  }
  return columns;
};

export const setFilter = (data, state) => {
  let columns = Immutable.asMutable(state.filtered, { deep: true });
  if (data.column.clearFilter) {
    delete columns[data.column.info.columnName];
  } else {
    columns[data.column.info.columnName] = data.column.info.filterTerm;
  }
  return columns;
};

export const dateCellFormatter = (cell) => {
  return <div>{cell ? moment(cell).format('M/D/YYYY h:mm a') : ''}</div>;
};

export const dateFormatter = (date, format = 'M/D/YYYY h:mm a') => {
  return date ? moment(date, 'x').format(format) : null;
};

import Immutable from 'seamless-immutable';
import { makeApi } from 'fni-schema';

import { setInfo } from '../../../store/coreLayout';

export const api = makeApi({
  baseURL: 'fni-lenderportal-admin',
  schemaKey: 'adminSchema',
});

// ------------------------------------
// Constants
// ------------------------------------
export const COLLECTIONS_FETCHING_SET = 'COLLECTIONS_FETCHING_SET';
export const COLLECTIONS_SET = 'COLLECTIONS_SET';
export const COLLECTION_SET = 'COLLECTION_SET';
export const COLLECTION_PAGES_SET = 'COLLECTION_PAGES_SET';

// ------------------------------------
// Actions
// ------------------------------------

export const collectionsFetchingSet = (data) => {
  return {
    type: COLLECTIONS_FETCHING_SET,
    payload: data,
  };
};

export const collectionsSet = (data) => {
  return {
    type: COLLECTIONS_SET,
    payload: data,
  };
};

export const collectionSet = (data) => {
  return {
    type: COLLECTION_SET,
    payload: data,
  };
};

export const collectionPagesSet = (data) => {
  return {
    type: COLLECTION_PAGES_SET,
    payload: data,
  };
};

// ------------------------------------
// Middleware
// ------------------------------------

export const getCollections = () => async (dispatch) => {
  dispatch(collectionsFetchingSet(true));
  try {
    const response = await api.get('admin/books');
    dispatch(collectionsSet(response));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    if (e.message) dispatch(setInfo(e.message));
    else dispatch(setInfo('Failed to fetch collections'));
  } finally {
    dispatch(collectionsFetchingSet(false));
  }
};

export const getPages = () => async (dispatch) => {
  try {
    const response = await api.get('admin/pages');
    const pages = response.formData.PAGES.map(
      ({ PAGE_ID, PAGE_TITLE, DESCRIPTION }) => ({
        PAGE_ID,
        PAGE_TITLE,
        DESCRIPTION,
      })
    );
    dispatch(collectionPagesSet(pages));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    if (e.message) dispatch(setInfo(e.message));
    else dispatch(setInfo('Failed to fetch pages'));
  }
};

export const getCollection =
  (id = '', dupe = false) =>
  async (dispatch) => {
    dispatch(collectionsFetchingSet(true));
    try {
      const response = await api.get(`admin/book/${id}`);
      if (dupe) response.formData.BOOK_ID = '';
      dispatch(collectionSet(response));
    } catch (e) {
      // eslint-disable-next-line no-console
      if (process.env.NODE_ENV === 'development') console.error(e);
      if (e.message) dispatch(setInfo(e.message));
      else dispatch(setInfo('Failed to fetch collection'));
    } finally {
      dispatch(collectionsFetchingSet(false));
    }
  };

export const putCollection = (id, data) => async (dispatch) => {
  dispatch(collectionsFetchingSet(true));

  try {
    await api.put(`admin/book/${id}`, data);
    dispatch(
      setInfo({
        type: 'success',
        message: 'Save Successful',
        delay: 3000,
      })
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    if (e.message) dispatch(setInfo(e.message));
    else dispatch(setInfo('Failed to save collection'));
    throw e;
  } finally {
    dispatch(collectionsFetchingSet(false));
  }
};

export const postCollection = (data) => async (dispatch) => {
  dispatch(collectionsFetchingSet(true));

  try {
    await api.post('admin/book', data);
    dispatch(
      setInfo({
        type: 'success',
        message: 'Save Successful',
        delay: 3000,
      })
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    if (e.message) dispatch(setInfo(e.message));
    else dispatch(setInfo('Failed to save collection'));
    throw e;
  } finally {
    dispatch(collectionsFetchingSet(false));
  }
};

export const deleteCollection = (id) => async (dispatch) => {
  try {
    await api.delete(`admin/book/${id}`);
    dispatch(
      setInfo({
        type: 'success',
        message: 'Collection Deleted Successfully',
        delay: 3000,
      })
    );
    dispatch(getCollections());
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    if (e.message) dispatch(setInfo(e.message));
    else dispatch(setInfo('Failed to delete collection'));
  }
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [COLLECTIONS_FETCHING_SET]: (state, action) => {
    return Immutable({
      ...state,
      fetching: action.payload,
    });
  },
  [COLLECTIONS_SET]: (state, action) => {
    return Immutable({
      ...state,
      collectionList: { ...state.collectionList, ...action.payload },
    });
  },
  [COLLECTION_SET]: (state, action) => {
    return Immutable({
      ...state,
      collection: { ...state.collection, ...action.payload },
    });
  },
  [COLLECTION_PAGES_SET]: (state, action) => {
    return Immutable({
      ...state,
      collection: { ...state.collection, availablePages: action.payload },
    });
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};
export default function collectionsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

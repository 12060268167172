import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

import ApiContext from './ApiContext';
import ReplyBlock from './ReplyBlock';
import checkTrue from '../utils/checkTrue';

const formatExtraTitle = (CONVO_CLOSED, CONVO_HIDDEN, CONVO_STATUS_TEXT) => {
  if (CONVO_STATUS_TEXT) {
    return CONVO_STATUS_TEXT;
  }
  if (!checkTrue(CONVO_CLOSED) && !checkTrue(CONVO_HIDDEN)) {
    return '';
  }
  const vals = [];
  if (checkTrue(CONVO_CLOSED)) vals.push('Locked');
  if (checkTrue(CONVO_HIDDEN)) vals.push('Hidden');
  return `(${vals.join(', ')})`;
};

const useStyles = makeStyles((theme) => ({
  paperSpacing: {
    marginBottom: theme?.spacing(1.5),
    width: '90%',
  },
  paperEnd: {
    alignSelf: 'flex-end',
    backgroundColor: theme?.palette?.primary?.xxlight,
  },
  paperStart: {
    backgroundColor: theme?.palette?.tertiary?.light,
  },
  selectedHeader: {
    backgroundColor: theme?.palette?.tertiary?.light,
  },
  expansionBorder: {
    border: '1px solid black',
  },
  convoSize: {
    fontSize: '18px',
  },
  extraTitle: {
    marginLeft: theme?.spacing(2),
    fontWeight: '200',
  },
  blockWidth: {
    width: '100%',
  },
  iconMargin: {
    marginRight: theme?.spacing(2),
  },
  panelSummary: {
    minHeight: '0px',
    margin: '0px !important',
  },
  messageText: {
    whiteSpace: 'pre-wrap',
  },
}));

const ConvoBody = ({
  data: {
    CONVO_SUBJECT,
    CONVO_ID,
    CONVO_UNREAD_MSGS,
    CONVO_HIDDEN,
    CONVO_CLOSED,
    CONVO_STATUS_TEXT,
  },
  show,
  setShow,
  messages,
  FEType,
  isLocked,
}) => {
  const classes = useStyles();
  const {
    apis: { getMessages, hideConversation, closeConversation },
    permissions: { CAN_CLOSE, CAN_HIDE },
  } = useContext(ApiContext);
  const MESSAGES = messages?.formData?.MESSAGES;
  const [loading, setLoading] = useState(false);
  return (
    <Accordion
      className={classes.panelBox}
      elevation={show ? 5 : 1}
      TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
      expanded={show}
      onChange={async () => {
        if (!messages) {
          setLoading(true);
          await getMessages(CONVO_ID);
          setLoading(false);
        }
        setShow(show ? false : CONVO_ID);
      }}
    >
      <AccordionSummary
        className={classNames(classes?.panelSummary, {
          [classes?.selectedHeader]: show,
        })}
        classes={{
          content: classes?.panelSummary,
          expanded: classes?.panelSummary,
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex" alignItems="center">
            <Box width="24px" mr={1}>
              {loading ? (
                <CircularProgress size={24} color="primary" />
              ) : (
                <>
                  {checkTrue(CONVO_UNREAD_MSGS) && (
                    <AnnouncementIcon color="secondary" />
                  )}
                </>
              )}
            </Box>
            <Typography
              classes={{
                root: classNames(classes?.convoSize, {
                  [classes?.convoTitle]: show,
                }),
              }}
            >
              {CONVO_SUBJECT}
            </Typography>
            <Typography className={classes?.extraTitle}>
              <i>
                {formatExtraTitle(
                  CONVO_CLOSED,
                  CONVO_HIDDEN,
                  CONVO_STATUS_TEXT
                )}
              </i>
            </Typography>
          </Box>
          {FEType === 'LENDER' && (
            <Box>
              <IconButton
                disabled={!CAN_CLOSE || isLocked}
                onClick={(e) => {
                  e.stopPropagation();
                  if (CAN_CLOSE && !isLocked)
                    closeConversation(CONVO_ID, !checkTrue(CONVO_CLOSED));
                }}
              >
                {checkTrue(CONVO_CLOSED) ? (
                  <LockIcon
                    className={classNames({ [classes?.selectedIcon]: show })}
                  />
                ) : (
                  <LockOpenIcon
                    className={classNames({ [classes?.selectedIcon]: show })}
                  />
                )}
              </IconButton>
              <IconButton
                disabled={!CAN_HIDE || isLocked}
                onClick={(e) => {
                  e.stopPropagation();
                  if (CAN_HIDE && !isLocked)
                    hideConversation(CONVO_ID, !checkTrue(CONVO_HIDDEN));
                }}
              >
                {checkTrue(CONVO_HIDDEN) ? (
                  <VisibilityOffIcon
                    className={classNames({ [classes?.selectedIcon]: show })}
                  />
                ) : (
                  <VisibilityIcon
                    className={classNames({ [classes?.selectedIcon]: show })}
                  />
                )}
              </IconButton>
            </Box>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" width="100%">
          {MESSAGES &&
            MESSAGES.map((message) => (
              <Paper
                key={message.CONVO_MSG_ID}
                className={classNames(
                  classes.paperSpacing,
                  {
                    [classes?.paperStart]: message?.CONVO_SOURCE !== FEType,
                  },
                  {
                    [classes?.paperEnd]: message?.CONVO_SOURCE === FEType,
                  }
                )}
              >
                <Box display="flex" flexDirection="column" width="100%" m={1}>
                  <Typography variant="caption">
                    <i>
                      Created by{' '}
                      {message?.CONVO_SOURCE === FEType
                        ? message?.CONVO_USER
                        : message?.CONVO_SOURCE}
                      {' on '}
                      {moment(message?.SYS_CREATION_DATE).format(
                        'MMMM Do YYYY, h:mm:ss a'
                      )}
                    </i>
                  </Typography>
                  <Box ml={2} display="flex">
                    {!checkTrue(message?.CONVO_READ) &&
                      message?.CONVO_SOURCE !== FEType && (
                        <AnnouncementIcon
                          className={classes?.iconMargin}
                          color="secondary"
                        />
                      )}
                    <Typography className={classes.messageText} variant="body1">
                      {message?.CONVO_MSG_TEXT}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            ))}
          <Box>
            <Divider />
            <ReplyBlock
              CONVO_ID={CONVO_ID}
              closed={checkTrue(CONVO_CLOSED) || checkTrue(CONVO_HIDDEN)}
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

ConvoBody.propTypes = {
  FEType: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any),
  messages: PropTypes.objectOf(PropTypes.any),
  setShow: PropTypes.func,
  show: PropTypes.bool,
  isLocked: PropTypes.bool,
};

const mapStateToProps = (state, { data }) => ({
  messages: state?.convo?.messages?.[data?.CONVO_ID],
  FEType: state?.convo?.type,
  isLocked: state?.coreLayout?.lockIndicator,
});

export default connect(mapStateToProps)(ConvoBody);

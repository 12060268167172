import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

export const SchemaContext = createContext();

export const SchemaProvider = ({
  schema,
  children,
  optionalContext: OptionalContext = SchemaContext,
}) => (
  <OptionalContext.Provider value={schema}>{children}</OptionalContext.Provider>
);

SchemaProvider.propTypes = {
  children: PropTypes.node,
  schema: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.arrayOf(PropTypes.any),
  ]),
  optionalContext: PropTypes.objectOf(PropTypes.any),
};

const parseName = (name, schema) => {
  const levels = name.match(/[^\][.]+/g);
  const val = levels.reduce((acc, level) => {
    if (acc?.type === 'array') return acc?.items;
    if (acc?.type === 'object') return acc?.properties[level];
    if (Array.isArray(acc)) {
      const elementPos = acc
        .map((fieldSchema) => {
          if (fieldSchema) {
            return fieldSchema.key;
          }
          return null;
        })
        .indexOf(name);
      return acc[elementPos]?.properties[name];
    }
    return undefined;
  }, schema);

  if (val) {
    let options;
    if (val.enum) {
      options = val.enum.map((value, index) => ({
        value,
        label: val.enumNames[index],
      }));
    }
    return {
      key: val?.key ?? '',
      label: val?.title ?? '',
      readOnly: Boolean(val?.readOnly),
      options,
      minLength: val?.minLength,
      maxLength: val?.maxLength,
      pattern: val?.pattern,
      validationMessages: val?.validationMessages,
    };
  }
  return {};
};

export const makeName = (name, addition) => `${name}.${addition}`;

export const makeFields = (name, arr) =>
  arr.reduce((acc, value) => ({ ...acc, [value]: `${name}.${value}` }), {});

export const makeNameArray = (name, arr) =>
  arr.reduce((acc, value) => [...acc, `${name}.${value}`], []);

export const useSchema = (name = '', schemaContext = SchemaContext) => {
  const schema = useContext(schemaContext);
  return useMemo(() => {
    if (Array.isArray(name))
      return name.reduce(
        (ret, n) => ({ ...ret, [n]: parseName(n, schema) }),
        {}
      );
    return parseName(name, schema);
  }, [name, schema]);
};

export const groupEnums = (properties = []) => {

  const obj = []
  properties.forEach(d => {
    for (let i = 0; i < d.enum.length; i += 1) {
      obj.push({ title: d.enumNames[i], value: d.enum[i] })
    }

  })
  return obj;
}
import SchemaConverter from 'fni-components/FNISchemaForm/utils/SchemaConverter';
// eslint-disable-next-line import/no-named-as-default
import { makeApi } from 'fni-schema';
import { informationSet } from 'fni-components//FNIInformationBox/modules/fniInformationBox';
import FNIStandardRedux from '../../../components/deprecated/FNIStandardRedux';

const Standard = new FNIStandardRedux('apiAuth', 'admin/externalApiAuth/');
const api = makeApi({
  baseURL: 'fni-lenderportal-admin/admin/externalApiAuth',
  withResponseInterceptor: false,
});

export const apiAuthSet = Standard.createSet();
const errorsSet = Standard.createSet();

const allFetch = () => Standard.fetch('all', ['apiAuths']);
const apiAuthFetch = (id) => Standard.fetch(id, ['apiAuth']);
const newHashFetch = () => Standard.fetch('newHash', ['newHash']);

const apiAuthCreateSend = () =>
  Standard.send(['create', 'formData'], 'create', {
    dataFormat: (data) => {
      const dealerLocationId = data.DEALER_LOCATION_ID;
      const authType = data.TYPE;
      const apiConfig = SchemaConverter.translateToBackend(
        data.API_CONFIGURATION ?? {},
      );
      const formattedConfig = apiConfig.map((_api) => ({
        ..._api,
        fieldValue: _api.fieldValue === '' ? 'false' : 'true',
      }));
      let newData = {
        EXTERNAL_API_AUTH: [
          {
            DESCRIPTION: data.DESCRIPTION,
            TYPE: data.TYPE,
            KEY: data.API_KEY,
            HASH: data.HASH,
            API_CONFIGURATION: data.API_CONFIGURATION,
          },
        ],
      };
      if (authType !== 'lender' && dealerLocationId)
        newData.EXTERNAL_API_AUTH[0].DEALER_LOCATION_ID = dealerLocationId;
      newData = SchemaConverter.translateToBackend(newData);
      newData[0].fieldValues[0][4].fieldValues = [];
      newData[0].fieldValues[0][4].fieldValues[0] = formattedConfig;
      return newData[0];
    },
  });

const apiAuthUpdateSend = (data, id) =>
  Standard.send(['apiAuth', 'formData', 'EXTERNAL_API_AUTH', '0'], 'update', {
    dataFormat: (innerData) => {
      const dealerLocationId = innerData.DEALER_LOCATION_ID;
      const authType = innerData.TYPE;
      const apiConfig = SchemaConverter.translateToBackend(
        innerData.API_CONFIGURATION ?? {},
      );
      const formattedConfig = apiConfig.map((_api) => ({
        ..._api,
        fieldValue: _api.fieldValue === '' ? 'false' : 'true',
      }));
      let newData = {
        EXTERNAL_API_AUTH: [
          {
            ID: id,
            DESCRIPTION: innerData.DESCRIPTION,
            TYPE: innerData.TYPE,
            API_CONFIGURATION: innerData.API_CONFIGURATION,
            STATUS: innerData.STATUS,
          },
        ],
      };
      if (authType !== 'lender' && dealerLocationId)
        newData.EXTERNAL_API_AUTH[0].DEALER_LOCATION_ID = dealerLocationId;
      newData = SchemaConverter.translateToBackend(newData);
      newData[0].fieldValues[0][3].fieldValues = [];
      newData[0].fieldValues[0][3].fieldValues[0] = formattedConfig;
      return newData[0];
    },
  });

const apiAuthDeleteSend = (id) =>
  Standard.send(false, 'delete', {
    dataFormat: () => {
      let data = {
        EXTERNAL_API_AUTH: [
          {
            ID: id,
          },
        ],
      };
      data = SchemaConverter.translateToBackend(data);
      return data[0];
    },
  });

const apiAuthStatusSend = (id, path) =>
  Standard.send(false, path, {
    dataFormat: () => {
      let data = {
        EXTERNAL_API_AUTH: [
          {
            ID: id,
          },
        ],
      };
      data = SchemaConverter.translateToBackend(data);
      return data[0];
    },
  });

function formatOptions(data = {}) {
  return Object.entries(data).map(([value, label]) => {
    return { value, label };
  });
}

export const getDealerGroups = () => async (dispatch) => {
  try {
    const response = await api.get('/dealerGroups');
    dispatch(apiAuthSet(['types'], formatOptions(response?.data)));
  } catch (e) {
    dispatch(
      informationSet({
        id: 'API_AUTH_ERROR',
        type: 'error',
        message: e.message ?? 'Failed to load dealer groups',
      }),
    );
  }
};

export const getDealerLocations = () => async (dispatch) => {
  try {
    const response = await api.get('/dealerLocations');
    dispatch(apiAuthSet(['types'], formatOptions(response?.data)));
  } catch (e) {
    dispatch(
      informationSet({
        id: 'API_AUTH_ERROR',
        type: 'error',
        message: e.message ?? 'Failed to load dealer locations',
      }),
    );
  }
};

export const actions = {
  apiAuthSet,
  errorsSet,
  allFetch,
  apiAuthFetch,
  newHashFetch,
  apiAuthCreateSend,
  apiAuthUpdateSend,
  apiAuthDeleteSend,
  apiAuthStatusSend,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = Standard.returnHandlers();

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  create: {},
};
export default function apiAuthReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

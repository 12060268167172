import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { coreSelectors } from './redux/CoreSlice/core';

const TabIcon = () => {
    const tenantCode = useSelector(coreSelectors.tenantCode);

    if(!tenantCode) return null;
    
    return (
        <Helmet>
            <link rel="icon" href={`/getIcon?code=${tenantCode}`} onError={event => {
                event.target.href = '';
                event.onerror = null;
            }} />
            <link rel="apple-touch-icon" href={`/getIcon?code=${tenantCode}`}  onError={event => {
                event.target.href = '';
                event.onerror = null;
            }}  />
        </Helmet>
    );

};

export default TabIcon;

import { createAsyncThunk, createSelector, createSlice, miniSerializeError } from '@reduxjs/toolkit';
import axios from 'axios';
import {
    getAuth,
    sendPasswordResetEmail,
    signOut,
} from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { authUtils } from '../utils';

// SELECTORS-----------------------------------------
const currentAuthenticatedUser = createSelector(
    state => state.auth.user,
    user => user?.email ? user : null,
);

const currentSelectedTenant = createSelector(
    state => state.auth.currentSelectedTenant,
    tenant => tenant,
);

const availableTenants = createSelector(
    state => state.auth.user.tenants,
    tenants => tenants?.length ? tenants : null,
);

const authLoading = createSelector(
    state => state.auth.isLoading,
    isLoading => Object.values(isLoading).some(val => val),
);

const isFNIAdmin = createSelector(
    state => state.auth?.user?.isFNIAdmin,
    isAdmin => isAdmin,
);

const authAlert = createSelector(
    state => state.auth.alert,
    alert => alert,
);

const isUserRegistered = createSelector(
    state => state.auth.userIsRegistered,
    isRegistered => isRegistered,
);

const tenantIsMigrated = createSelector(
    state => state.auth.tenantIsMigrated,
    isMigrated => isMigrated,
);

const mfaState = createSelector(
    state => state.auth.mfa,
    mfa => mfa,
)


export const authSelectors = {
    authLoading,
    authAlert,
    availableTenants,
    isFNIAdmin,
    currentAuthenticatedUser,
    currentSelectedTenant,
    isUserRegistered,
    mfaState,
    tenantIsMigrated,
};

// ACTIONS --------------------------------------------
const forceChangePassword = createAsyncThunk(
    'auth/disableForceChangePassword',
    async (passwords) => {
        const functions = getFunctions();
        const forceChangePwdFunction = httpsCallable(functions, 'auth-forceChangePassword');
        return await forceChangePwdFunction(passwords);
    },
);

const enrollMFA = createAsyncThunk(
    'auth/enrollMFA',
    async (data, { getState }) => {
        const mfaType = getState().auth.mfa.mfaType;
        const functions = getFunctions();
        const handleEnrollMFAFunction = httpsCallable(functions, 'auth-enrollMFA');
        return await handleEnrollMFAFunction({ mfaType, data });
    },
);

const getRedirect = createAsyncThunk(
    'auth/getRedirect',
    async (tenantCode) => {
        const functions = getFunctions();
        const getRedirectFunction = httpsCallable(functions, 'auth-getRedirect');
        return await getRedirectFunction(tenantCode);
    },
);

const getCurrentTenantByCode = createAsyncThunk(
    'auth/getCurrentTenantByCode',
    async (_, { getState }) => {
        const code = getState().core.tenantCode;
        const functions = getFunctions();
        const getCurrentTenantByCodeFunction = httpsCallable(functions, 'tenants-getTenantByCode');
        return await getCurrentTenantByCodeFunction(code);
    },
);

const handleInvite = createAsyncThunk(
    'auth/handleInvite',
    async (inviteId) => {
        const functions = getFunctions();
        const handleInviteFunction = httpsCallable(functions, 'users-handleUserInvite');
        return await handleInviteFunction(inviteId);
    },
);

const signInUser = createAsyncThunk(
    'auth/signInUser',
    async (creds) => {
        const url = '/loginWithCredentials';
        axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
        return await axios.post(url, creds, { withCredentials: true });
    },
    {
        serializeError: (payload) => {

            var customError = payload.response.data;
            switch (customError.message) {
                case 'MFA_NOT_ENABLED':
                case 'FORCE_CHANGE_PASSWORD':
                    return {
                        code: customError.code,
                        message: customError.message,
                        token: customError.details.token
                    };
                case 'MFA_CODE_REQUIRED':
                    return {
                        code: customError.code,
                        message: customError.message,
                        mfaType: customError.details.mfaType,
                        recipient: customError.details.to,
                    }
                default:
                    return miniSerializeError(customError);
            }
        }
    }
);

const signOutUser = createAsyncThunk(
    'auth/signOutUser',
    async (_, { dispatch, getState }) => {
        const code = getState().core?.tenantCode;
        await signOut(getAuth());
        dispatch(authActions.logout());
        return code;
    },
);

const sendResetEmail = createAsyncThunk(
    'auth/sendResetEmail',
    async ({ email }) => await sendPasswordResetEmail(getAuth(), email),
);

const verifyMFA = createAsyncThunk(
    'auth/verifyMFA',
    async (code) => {
        const functions = getFunctions();
        const verifyMFAFunction = httpsCallable(functions, 'auth-verifyMFA');
        return await verifyMFAFunction(code);
    },
);

const setMfaType = (state, action) => {
    state.mfa.mfaType = action.payload;
}

const setMfaRecipient = (state, action) => {
    state.mfa.recipient = action.payload;
}

const resetMfaState = (state) => {
    state.mfa = mfaInitialState;
}

const {
    alertInitialState,
    setAlertError,
    setAlertSuccess,
    trimActionType,
} = authUtils;

const mfaInitialState = {
    mfaType: null,
    recipient: '',
    modal: {
        verifyOpen: false,
        successOpen: false,
    },
};

const initialState = {
    user: {
        id: null,
        email: null,
        userId: null,
        tenantRoles: [],
        isFNIAdmin: null,
    },
    currentSelectedTenant: null,
    tenantIsMigrated: false,
    userIsRegistered: false,
    unregisteredUser: null,
    isLoading: {},
    alert: alertInitialState,
    mfa: mfaInitialState,
};

export const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCurrentUser(state, action) {
            state.user = action.payload;
        },
        clearAuth() {
            return initialState;
        },
        setSuccessMessage(state, action) {
            setAlertSuccess(state, action.payload);
        },
        setCurrentTenant(state, action) {
            state.currentSelectedTenant = action.payload;
        },
        setErrorMessage(state, action) {
            setAlertError(state, action.payload);
        },
        setTenantIsMigrated(state, action) {
            state.tenantIsMigrated = action.payload;
        },
        setUserIsRegistered(state, action) {
            state.userIsRegistered = action.payload;
        },
        hideAlert(state) {
            state.alert.open = false;
        },
        openMfaSuccessModal(state, action) {
            state.mfa.modal.successOpen = action.payload;
        },
        openMfaVerifyModal(state, action) {
            state.mfa.modal.verifyOpen = action.payload;
        },
        setMfaType,
        resetMfaState,
        logout() {
            //check in store.js to see state reset for logout
        },
    },
    extraReducers: {
        [forceChangePassword.fulfilled]: (state, action) => {
            setAlertSuccess(state, 'Password changed!');
            state.isLoading[trimActionType(action.type)] = false;
        },
        [forceChangePassword.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [forceChangePassword.rejected]: (state, action) => {
            setAlertError(state, 'Unable to change password');
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getCurrentTenantByCode.fulfilled]: (state, action) => {
            state.currentSelectedTenant = action.payload.data.tenantId;
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getCurrentTenantByCode.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [getCurrentTenantByCode.rejected]: (state, action) => {
            setAlertError(state, 'Unable to get selected tenant');
            state.isLoading[trimActionType(action.type)] = false;
        },
        [enrollMFA.fulfilled]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [enrollMFA.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [enrollMFA.rejected]: (state, action) => {
            setAlertError(state, 'Unable to enroll in MFA');
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getRedirect.fulfilled]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getRedirect.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [getRedirect.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [handleInvite.fulfilled]: (state, action) => {
            state.unregisteredUser = action.payload.data;
            state.isLoading[trimActionType(action.type)] = false;
        },
        [handleInvite.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [handleInvite.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [sendResetEmail.fulfilled]: (state, action) => {
            setAlertSuccess(state, 'Email sent!');
            state.isLoading[trimActionType(action.type)] = false;
        },
        [sendResetEmail.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [sendResetEmail.rejected]: (state, action) => {
            setAlertError(state, 'Unable to send email');
            state.isLoading[trimActionType(action.type)] = false;
        },
        [signInUser.fulfilled]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [signInUser.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [signInUser.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
            if (action?.error?.message === "MFA_CODE_REQUIRED") {
                setMfaType(state, { payload: action.error.mfaType });
                setMfaRecipient(state, { payload: action.error.recipient })
            }
        },
        [signOutUser.fulfilled]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
            resetMfaState(state);
        },
        [signOutUser.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [signOutUser.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [verifyMFA.fulfilled]: (state, action) => {
            const result = action.payload?.data;
            if (!result?.success) setAlertError(state, 'Invalid MFA code, please retry');
            state.isLoading[trimActionType(action.type)] = false;
        },
        [verifyMFA.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [verifyMFA.rejected]: (state, action) => {
            setAlertError(state, 'Unable to verify MFA');
            state.isLoading[trimActionType(action.type)] = false;
        },
    },
});

export const authActions = {
    ...AuthSlice.actions,
    forceChangePassword,
    getCurrentTenantByCode,
    enrollMFA,
    getRedirect,
    handleInvite,
    signInUser,
    signOutUser,
    sendResetEmail,
    verifyMFA,
};

export default AuthSlice.reducer;

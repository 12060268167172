import { Suspense, lazy } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/styles';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LoadingSpinner } from 'fni-components-next';
import workbenchTheme from 'fni-theme/WorkbenchTheme';
import { authSelectors } from '../authentication/modules/auth.js';
import FNIContainer from '../container/fni-container';
import AuthenticationRouter from '../authentication/AuthenticationRouter.js';

const AdminEntry = {
    admin: lazy( () => import('../products/fni-admin/App.js')),
    tenantAdmin: lazy(() => import('../tenant-admin/TenantsRouter.js')),
    search: lazy(() => import('../products/fni-search/App.js')),
    workbench: lazy(() => import('fni-workbench-standard')),
    reporting: lazy(() => import('../products/fni-reporting/app/ReportGrid.js')),    
};

const configurationFromServer = [
    {
        product: 'tenantAdmin',
        authorization: ['admin', 'read-only'],
    },
    {
        product: 'admin',
        route: 'fni-lenderportal-admin',
        authorization: ['admin', 'read-only'],
    },
    {
        product: 'search',
        route: 'fni-lenderportal-search',
        authorization: ['admin', 'read-only'],
    },
    {
        product: 'workbench',
        route: 'fni-lenderportal-workbench',
        authorization: ['admin', 'read-only'],
    },
    {
        product: 'reporting',
        route: 'fni-lenderportal-reporting',
        authorization: ['admin', 'read-only'],
    },
];

export default function MigratedRouter() {

    const currentAuthenticatedUser = useSelector(authSelectors.currentAuthenticatedUser);
    const { pathname } = useLocation();

    return (
        <>
            <CssBaseline />
            <ThemeProvider theme={workbenchTheme}>
                <Suspense fallback={ <LoadingSpinner open={true} /> }>
                    <FNIContainer isSearch={pathname.includes('fni-lenderportal-search')} currentAuthenticatedUser={currentAuthenticatedUser}>

                        {currentAuthenticatedUser ? configurationFromServer.map((s, i) => {
                            const Component = AdminEntry[s.product];
                            return <Component key={i} />;
                        }) : <AuthenticationRouter /> } 
            
                    </FNIContainer>
                </Suspense>
            </ThemeProvider>
        </>
    );
}

import Immutable from 'seamless-immutable';

export const INFORMATION_SET = 'INFORMATION_SET';
export const INFORMATION_CLEAR = 'INFORMATION_CLEAR';

/** ACTIONS **/

export const informationSet = (data) => {
  return {
    type: INFORMATION_SET,
    payload: data,
  };
};

export const informationClear = (data) => {
  return {
    type: INFORMATION_CLEAR,
    payload: data,
  };
};

/** MIDDLEWARE **/

const ACTION_HANDLERS = {
  [INFORMATION_SET]: (state, action) => {
    const id = action.payload.id || 'default';
    return Immutable.setIn(state, [id], action.payload);
  },
  [INFORMATION_CLEAR]: (state, action) => {
    return Immutable.setIn(state, [action.payload], undefined);
  },
};

/** INITIAL STATE **/
const initialState = {};

export default function fniInformationBoxReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

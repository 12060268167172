import Immutable from 'seamless-immutable';
import SchemaConverter from 'fni-components/FNISchemaForm/utils/SchemaConverter';
import { batch } from 'react-redux';
import _get from 'lodash/get';

import { fetchingSet } from '../../../../../components/store/shared';
import apiFetch from '../../../utils/apiFetch';
import { setInfo } from '../../../store/coreLayout';

// ------------------------------------
// Action-Handler Constants
// ------------------------------------
export const ROLE_SET_MODE = 'ROLE_SET_MODE';
export const ROLE_LIST_FETCH = 'ROLE_LIST_FETCH';
export const ROLE_LIST_SET = 'ROLE_LIST_SET';
export const ROLE_DELETED = 'ROLE_DELETED';
export const ROLE_SAVE_PERMS_WORKING = 'ROLE_SAVE_PERMS_WORKING';
export const ROLE_INFO_SET = 'ROLE_INFO_SET';
export const ROLE_ACTION_IN_PROG_SET = 'ROLE_ACTION_IN_PROG_SET';
export const ROLE_DLR_GRP_TOGGLE = 'ROLE_DLR_GRP_TOGGLE';
export const ROLE_DLR_RESTR_CLEAR = 'ROLE_DLR_RESTR_CLEAR';
export const ROLE_DATA_UPDATE = 'ROLE_DATA_UPDATE';
export const ROLE_CREATED_SET = 'ROLE_CREATED_SET';
export const ROLE_VALIDITY_SET = 'ROLE_VALIDITY_SET';
export const ROLE_INFO_CLEAR = 'ROLE_INFO_CLEAR';
export const ROLE_ERROR_MSG_SET = 'ROLE_ERROR_MSG_SET';
export const ROLE_ERRS_CLEAR = 'ROLE_ERRS_CLEAR';
export const ROLE_DEL_MOD_SHOW = 'ROLE_DEL_MOD_SHOW';
export const ROLE_DELETING_SHOW = 'ROLE_DELETING_SHOW';
export const ROLE_LIST_LOADING_SET = 'ROLE_LIST_LOADING_SET';
export const ROLE_INIT_DATA_SET = 'ROLE_INIT_DATA_SET';
export const ROLE_SET = 'ROLE_SET';
// ------------------------------------
// Constants
// ------------------------------------
export const ROLE_DEALER_FLAG = 'DEALER_FLAG';
export const LANDING_PAGE = 'LANDING_PAGE';

const getNavGroupPermissions = (data) => {
  const { navigation } = data;
  const navKeys = Object.keys(navigation);
  const navPermissionKeys = [];

  navKeys.forEach((key) => {
    const values = Object.values(navigation[key]);
    const hasPermission = values.some((value) => value);

    if (hasPermission) {
      navPermissionKeys.push(key);
    }
  });
  return navPermissionKeys;
};

const flattenArray = (obj) => {
  if (obj[0] && typeof obj[0] === 'object') {
    const arr = obj.map((row) => {
      const rowKeys = Object.keys(row);
      return rowKeys.map((r) => {
        if (Array.isArray(row[r])) {
          const flattened = flattenArray(row[r]);
          return {
            fieldId: r,
            fieldValues: flattened,
          };
        }
        return {
          fieldId: r,
          fieldValue: row[r] || false,
        };
      });
    });
    return arr;
  }
  return obj;
};

const flattenObject = (obj, navPermissionKeys) => {
  const keys = Object.keys(obj);
  let newObj = { ...obj };
  keys.forEach((key) => {
    if (Array.isArray(newObj[key])) {
      newObj[key] = flattenArray(newObj[key]);
    } else if (typeof newObj[key] === 'object') {
      newObj = { ...newObj, ...flattenObject(newObj[key], navPermissionKeys) };
      if (navPermissionKeys.includes(key)) {
        newObj[key] = true;
      } else {
        delete newObj[key];
      }
    }
    if (key === 'assignment_wb_ro') console.log('newObj', newObj);
  });
  return newObj;
};

const translateToBackend = (data, dates = []) => {
  const newData = flattenObject(
    Immutable.asMutable(data, { deep: true }),
    getNavGroupPermissions(data),
  );
  const keys = Object.keys(newData);
  const values = [];
  keys.forEach((k) => {
    let val = newData[k] || false;
    if (dates.includes(k)) {
      const regex = /(\d{2})(\d{2})(\d{4})/;
      const arr = regex.exec(val);
      if (arr) {
        val = `${arr[3]}-${arr[1]}-${arr[2]}`;
      }
    }
    if (Array.isArray(val)) {
      values.push({
        fieldId: k,
        fieldValues: val,
      });
    } else {
      values.push({
        fieldId: k,
        fieldValue: val,
      });
    }
  });
  return values;
};

const flattenRole = (data, id = '') => {
  let newData = { ...data };
  const roleDesc = newData.ROLE_DESC;
  const landingPage = newData.role_data.general_role_properties.LANDING_PAGE;
  const dealerFlag = newData.role_data.dealer_group_properties.DEALER_FLAG;
  const facilityFlag =
    newData.role_data.facility_group_properties.FACILITY_FLAG;
  newData = Immutable.without(newData, 'ROLE_DESC');
  newData = Immutable.without(newData, 'role_data');
  newData = translateToBackend(newData);

  newData = [
    {
      fieldId: 'ROLE_ID',
      fieldValue: id,
    },
    {
      fieldId: 'ROLE_DESC',
      fieldValue: roleDesc,
    },
    {
      fieldId: 'ROLE_DATA',
      fieldValues: [
        [
          { fieldId: 'LANDING_PAGE', fieldValue: landingPage },
          { fieldId: 'DEALER_FLAG', fieldValue: dealerFlag },
          { fieldId: 'FACILITY_FLAG', fieldValue: facilityFlag },
        ],
      ],
    },
    {
      fieldId: 'ROLE_PERMS',
      fieldValues: [newData],
    },
  ];
  return newData;
};
// ------------------------------------
// Actions
// ------------------------------------
export const listReceive = (data) => {
  return {
    type: ROLE_LIST_SET,
    payload: data,
  };
};
export const updateForm = (data) => {
  return {
    type: ROLE_SAVE_PERMS_WORKING,
    payload: data,
  };
};
export const setActionInProg = (data) => {
  return {
    type: ROLE_ACTION_IN_PROG_SET,
    payload: data,
  };
};
export const toggleDlr = (data) => {
  return {
    type: ROLE_DLR_GRP_TOGGLE,
    payload: data,
  };
};
export const clearDlrRestrPerms = (dealerRestrs) => {
  return {
    type: ROLE_DLR_RESTR_CLEAR,
    payload: dealerRestrs,
  };
};
export const upDtRoleData = (path, data) => {
  return {
    type: ROLE_DATA_UPDATE,
    payload: {
      path,
      data,
    },
  };
};
export const setInitRoleState = () => {
  return {
    type: ROLE_INIT_DATA_SET,
  };
};
export const setRoleInf = (roleId, roleDesc) => {
  return {
    type: ROLE_INFO_SET,
    payload: [roleId, roleDesc],
  };
};
export const setRoleCreated = (created) => {
  return {
    type: ROLE_CREATED_SET,
    payload: created,
  };
};
export const clearRoleInfo = (data) => {
  return {
    type: ROLE_INFO_CLEAR,
    payload: data,
  };
};
export const setUserMode = (data) => {
  return {
    type: ROLE_SET_MODE,
    payload: data,
  };
};
export const loading = (data) => {
  return {
    type: ROLE_LIST_LOADING_SET,
    payload: data,
  };
};
export const roleValidityReceive = (data) => {
  return {
    type: ROLE_VALIDITY_SET,
    payload: data,
  };
};
export const roleDeleted = (data) => {
  return {
    type: ROLE_DELETED,
    payload: data,
  };
};
export const showDeleteMod = (data) => {
  return {
    type: ROLE_DEL_MOD_SHOW,
    payload: data,
  };
};
export const setRoleDeleting = (data) => {
  return {
    type: ROLE_DELETING_SHOW,
    payload: data,
  };
};
export const setErrorMsg = (data) => {
  return {
    type: ROLE_ERROR_MSG_SET,
    payload: data,
  };
};
export const clearErrors = () => {
  return {
    type: ROLE_ERRS_CLEAR,
    payload: null,
  };
};

export const roleSet = (data, path) => {
  return {
    type: ROLE_SET,
    payload: { data, path },
  };
};
// ------------------------------------
// Middleware
// ------------------------------------
const setLoading = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(loading(data));
      resolve(true);
    });
  };
};

const setMode = (mode) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (mode === 'view' || mode === 'define' || mode === 'edit') {
        dispatch(setUserMode(mode));
      } else {
        reject(mode);
      }
      resolve(mode);
    });
  };
};

const setRoleInfo = (roleId, roleDesc) => {
  return (dispatch) => {
    dispatch(setRoleInf(roleId, roleDesc));
  };
};

const getOptions = (method) => {
  return {
    method,
  };
};

function getFormattedError(error) {
  if (error.code === 500)
    return {
      type: 'error',
      message: error.message || 'Unknown Server Error. Please try again.',
    };

  switch (error.type) {
    case 'updateErr':
      return {
        type: 'error',
        message: error.message || 'Save Error',
      };
    case 'createErr':
      return { type: 'error', message: error.message || 'Role Creation Error' };
    case 'deleteErr':
      return { type: 'error', message: error.message || 'Role Deletion Error' };
    default:
      return {
        type: 'error',
        message: error.message || 'There was an unknown error',
      };
  }
}

const setErrorInfo = (error) => (dispatch) => {
  dispatch(setInfo(getFormattedError(error)));
};

export const roleStandardFetch =
  (url, options, refresh) => (dispatch, getState) => {
    console.log(url, options, refresh);
    const {
      fetchPath = ['role', 'main'],
      dataPath = ['data'],
      urlOptions = null,
      errorOptions = {},
      callBack = (data) => data,
      noText = false,
      noEnd = false,
      successMessage = false,
    } = options;
    const message =
      errorOptions.message || 'Failed to load page. Please try again later.';
    return new Promise((resolve) => {
      if (!refresh && _get(getState().role, [...dataPath, 'formData'])) {
        resolve(true);
        return undefined;
      }
      dispatch(fetchingSet(fetchPath, true));
      apiFetch(url, urlOptions ? urlOptions(dispatch, getState) : null)
        .then((response) => {
          if (response.ok) {
            return response.text();
          }
          throw new Error(response);
        })
        .then((text) => {
          let newText = text;
          if (noText) {
            newText = false;
          } else {
            const { adminSchema: schema, formData } = JSON.parse(newText);
            const data = formData?.map((o) => {
              if (o.fieldValue === 'true') {
                return { ...o, fieldValue: true };
              }
              return o;
            });
            newText = SchemaConverter.translateFromBackend({
              schema,
              formData: data,
            });
          }
          batch(() => {
            newText = callBack(newText, dispatch, getState);
            if (!noText) {
              dispatch(roleSet(newText, dataPath));
            }
            if (!noEnd) {
              dispatch(fetchingSet(fetchPath, false));
            }
          });
          if (successMessage) {
            dispatch(
              setInfo({ type: 'success', message: successMessage, delay: 3000 }),
            );
          }
          resolve(true);
          return undefined;
        })
        .catch((e) => {
          if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log(e);
          }
          dispatch(setInfo({ type: 'error', message }));
          return undefined;
        });
      return undefined;
    });
  };

export const rolesFetch = (refresh) =>
  roleStandardFetch(
    'admin/roles',
    {
      dataPath: ['list'],
      message: 'Failed to load roles list.',
    },
    refresh,
  );

export const roleFetch = (id = false) =>
  roleStandardFetch(
    `admin/role${id ? `/${id}` : ''}`,
    {
      dataPath: ['selected'],
      message: 'Failed to load role.',
      callBack: (data) => {
        const newData = { ...data };

        newData.schema.properties.role_data.properties.general_role_properties.properties.LANDING_PAGE.title =
          'Landing Page';
        newData.id = id;
        return newData;
      },
    },
    true,
  );

export const roleSave = (id) => (dispatch, getState) =>
  roleStandardFetch('admin/role', {
    noText: true,
    urlOptions: () => ({
      method: id ? 'PUT' : 'POST',
      body: JSON.stringify(flattenRole(getState().role.selected.formData, id)),
    }),
    successMessage: `${
      _get(getState().role, ['selected', 'formData', 'ROLE_DESC']) || 'Role'
    } has been successfully saved.`,
  })(dispatch, getState);

export const roleDelete = (id) => (d, g) =>
  roleStandardFetch(`admin/role${id ? `/${id}` : ''}`, {
    urlOptions: () => ({
      method: 'DELETE',
    }),
    noText: true,
    message: 'Failed to delete role.',
    noEnd: true,
    successMessage: 'Role has been successfully deleted.',
  })(d, g).then(() => {
    batch(async () => {
      await d(rolesFetch(true));
      d(fetchingSet(['role', 'main'], false));
    });
    return undefined;
  });

const fetchRows = () => {
  return (dispatch) => {
    return new Promise((resolve) => {
      return apiFetch('admin/roles')
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return undefined;
        })
        .then((response) => {
          const data = SchemaConverter.translateFromBackend({
            formData: response.formData,
            schema: response.adminSchema,
          });
          if (!data.schema.properties) {
            data.schema = {
              type: 'object',
              properties: { ROLES: data.schema },
            };
          }
          dispatch(listReceive(data));
          dispatch(setLoading(false));
          resolve();
          return undefined;
        })
        .catch((response) => {
          // eslint-disable-next-line no-console
          if (process.env.NODE_ENV === 'development') console.error(response);
        });
    });
  };
};

const newRole = () => {
  return (dispatch) => {
    return apiFetch('admin/role')
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        if (process.env.NODE_ENV === 'development')
          // eslint-disable-next-line no-console
          console.error(
            'todo: secure call failure alert message with operation error detail',
          );
        return undefined;
      })
      .then(async (json) => {
        const data = SchemaConverter.translateFromBackend({
          formData: [],
          schema: json.adminSchema,
        });
        await dispatch(setMode('define'));
        dispatch(clearRoleInfo());
        dispatch(roleValidityReceive(false));
        dispatch(setRoleInfo('', ''));
        dispatch(updateForm(data));
        return undefined;
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        if (process.env.NODE_ENV === 'development') console.error(err);
      });
  };
};

const updateRoleData = (path, data) => {
  return (dispatch) => {
    if (data) {
      dispatch(upDtRoleData(path, data));
    }
  };
};

const getRoleDesc = (roleId, rows) => {
  return rows.find((r) => {
    return r.ROLE_ID === roleId;
  }).ROLE_DESC;
};

const fetchRole = (roleId) => (dispatch) => {
  dispatch(setLoading(true));
  return apiFetch(`admin/role/${roleId}`)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return undefined;
    })
    .then(async (response) => {
      response.formData.forEach((elem) => {
        if (response.formData[elem].fieldId !== LANDING_PAGE) {
          const val = response.formData[elem].fieldValue === 'true';
          response.formData[elem] = {
            fieldId: response.formData[elem].fieldId,
            fieldValue: val,
          };
        }
      });

      const data = SchemaConverter.translateFromBackend({
        formData: response.formData,
        schema: response.adminSchema,
      });
      dispatch(updateForm(data));
      dispatch(roleValidityReceive(true));
      dispatch(setInitRoleState());
      return undefined;
    })
    .catch((response) => {
      // eslint-disable-next-line no-console
      if (process.env.NODE_ENV === 'development') console.error(response);
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

const editRole = (roleId, roleDesc) => async (dispatch, getState) => {
  if (roleDesc) {
    dispatch(setRoleInfo(roleId, roleDesc));
    dispatch(fetchRole(roleId));
    dispatch(setMode('edit'));
  } else if (!_get(getState(), ['role', 'formData', 'ROLES'])) {
    await dispatch(fetchRows());
    dispatch(
      setRoleInfo(roleId, getRoleDesc(roleId, getState().role.formData.ROLES)),
    );
    dispatch(fetchRole(roleId));
    dispatch(setMode('edit'));
  }
};

const putRole = () => (dispatch, getState) => {
  const state = Immutable.asMutable(getState(), { deep: true });
  const putOptions = getOptions('PUT');
  // save the primary formData
  const permFormData = state.role.formData;
  const roleData = {};
  const data = {};

  Object.keys(permFormData).forEach((key) => {
    if (key !== 'role_data') {
      if (state.role.isDealer === '1' || key !== 'dealer_restrictions') {
        data[key] = permFormData[key];
      }
    } else {
      roleData[key] = permFormData[key];
    }
  });

  let transData = SchemaConverter.translateToBackend(data);
  transData = transData
    .filter((formDataElem) => formDataElem.fieldValue)
    .map((formDataElem) => ({
      fieldId: formDataElem.fieldId,
      fieldValue: formDataElem.fieldValue.toString(),
    }));

  let _roleData = SchemaConverter.translateToBackend(roleData);
  _roleData = _roleData
    .filter((rd) => rd.fieldValue)
    .map((formDataElem) => ({
      fieldId: formDataElem.fieldId,
      fieldValue: formDataElem.fieldValue.toString(),
    }));

  // Add group permission by name, required to save 1 or more of it permissions
  Object.keys(data).forEach((tab) => {
    Object.keys(data[tab]).forEach((grp) => {
      Object.keys(data[tab][grp]).forEach((perm) => {
        if (data[tab][grp][perm] === true) {
          transData.push({
            fieldId: grp,
            fieldValue: true,
          });
        }
      });
    });
  });

  // merge any role_data elems with main (role_perm) set
  const { roleId } = state.role;
  const { roleDesc } = state.role;
  const formData = [
    {
      fieldId: 'ROLE_ID',
      fieldValue: roleId,
    },
    {
      fieldId: 'ROLE_DESC',
      fieldValue: roleDesc,
    },
    {
      fieldId: 'ROLE_DATA',
      fieldValues: [_roleData],
    },
    {
      fieldId: 'ROLE_PERMS',
      fieldValues: [transData],
    },
  ];
  putOptions.body = JSON.stringify(formData);
  return new Promise((resolve, reject) => {
    if (getState().actionInProg) {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('Role definition changed abruptly - please try again.');
    }
    return apiFetch('admin/role', putOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        const errors = {
          type: 'updateErr',
          code: response.status,
        };
        dispatch(setErrorInfo(errors));
        reject();
        return undefined;
      })
      .then((msg) => {
        if (msg.status === 'SUCCESS') {
          dispatch(setInitRoleState());
          resolve(true);
        } else {
          const errors = {
            type: 'updateErr',
            code: msg.status,
            message: msg.errorMsgs.join(', '),
          };
          dispatch(setErrorInfo(errors));
          reject();
        }
        return undefined;
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        if (process.env.NODE_ENV === 'development') console.error(err);
        const errors = {
          type: 'updateErr',
          code: '',
        };
        dispatch(setErrorInfo(errors));
        reject();
      });
  });
};

const deleteRole = (roleId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const deleteOptions = getOptions('DELETE');
    return apiFetch(`admin/role/${roleId}`, deleteOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        const errors = {
          type: 'deleteErr',
          code: response.status,
        };
        dispatch(setErrorInfo(errors));
        reject();
        return undefined;
      })
      .then((msg) => {
        if (msg.status === 'SUCCESS') {
          dispatch(roleDeleted(true));
          dispatch(clearRoleInfo());
          dispatch(fetchRows());
        } else {
          const errors = {
            type: 'deleteErr',
            code: msg.status,
            message: msg.errorMsgs.join(', '),
          };
          dispatch(setErrorInfo(errors));
          reject();
        }
        resolve(true);
        return undefined;
      })
      .catch((response) => {
        const errors = {
          type: 'deleteErr',
          code: response.status,
        };
        dispatch(setErrorInfo(errors));
        reject();
      });
  });
};

const showDeleteModal = (show, roleId) => (dispatch) => {
  if (show && roleId) {
    dispatch(showDeleteMod(show));
    dispatch(setRoleDeleting(roleId));
  } else if (!(show && roleId)) {
    dispatch(showDeleteMod(false));
    dispatch(setRoleDeleting(''));
  }
};

export const updateRedux = (formData) => (dispatch) => {
  dispatch(updateForm(formData));
};

const cancel = (mode) => async (dispatch) => {
  await dispatch(setLoading(true));
  batch(() => {
    dispatch(clearRoleInfo());
    dispatch(clearErrors());
    dispatch(setMode(mode));
    dispatch(fetchRows());
    dispatch(toggleDlr(null));
  });
};

const setRoleValidity = (valid) => (dispatch) => {
  dispatch(roleValidityReceive(valid));
};

const _clearDlrRestrPerms = () => (dispatch, getState) => {
  const dealerRestrictions = Immutable.asMutable(
    getState().role.formData.dealer_restrictions,
    { deep: true },
  );
  Object.keys(dealerRestrictions).forEach((key) => {
    if (dealerRestrictions[key] instanceof Object) {
      Object.keys(dealerRestrictions[key]).forEach((perm) => {
        delete dealerRestrictions[key][perm];
      });
    }
  });
  dispatch(clearDlrRestrPerms(dealerRestrictions));
};

export const actions = {
  setMode,
  newRole,
  _clearDlrRestrPerms,
  updateRoleData,
  setRoleCreated,
  fetchRows,
  fetchRole,
  editRole,
  setRoleInfo,
  setRoleValidity,
  putRole,
  deleteRole,
  cancel,
  updateRedux,
  roleDeleted,
  showDeleteModal,
  clearErrors,
  roleFetch,
  rolesFetch,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [ROLE_LIST_FETCH]: (state) => {
    return Immutable({ ...state, listFetching: true });
  },
  [ROLE_LIST_SET]: (state, action) => {
    if (state.mode === 'view' || state.mode === 'load') {
      return Immutable({ ...state, ...action.payload });
    }
    return Immutable({ ...state });
  },
  [ROLE_SAVE_PERMS_WORKING]: (state, action) => {
    if (!action.payload.schema) {
      const toUpdate = [];
      Object.keys(action.payload).forEach((tab) => {
        if (action.payload[tab][0] && action.payload[tab][0]?.name) {
          toUpdate.push(tab);
          toUpdate.push(action.payload[tab][0].name);
        }
      });

      if (toUpdate.length) {
        return Immutable({
          ...state,
          formData: {
            ...state.formData,
            [toUpdate[0]]: {
              ...state.formData[toUpdate[0]],
              [`${toUpdate[1]}`]: {
                ...action.payload[toUpdate[0]][1][toUpdate[1]],
              },
            },
          },
        });
      }
      return Immutable({ ...state, formData: { ...action.payload } });
    }
    const payloadMutable = Immutable.asMutable(action.payload, { deep: true });
    const queuesProperties = _get(payloadMutable, [
      'schema',
      'properties',
      'queues',
      'properties',
    ]);
    const queuesPropertiesReversed = {};
    Object.keys(queuesProperties)
      .sort((keyA, keyB) => keyA < keyB)
      .forEach((key) => {
        queuesPropertiesReversed[key] = queuesProperties[key];
      });
    payloadMutable.schema.properties.queues.properties =
      queuesPropertiesReversed;
    return Immutable({ ...state, ...payloadMutable });
  },
  [ROLE_INIT_DATA_SET]: (state) => {
    return Immutable({
      ...state,
      roleInitialState: {
        formData: state.formData,
        roleDesc: state.roleDesc,
        isDealer: state.isDealer,
      },
    });
  },
  [ROLE_DELETED]: (state, action) => {
    if (action.payload === false) {
      return Immutable({
        ...state,
        errors: null,
        deleteSucc: action.payload,
      });
    }
    return Immutable({ ...state, deleteSucc: action.payload });
  },
  [ROLE_DELETING_SHOW]: (state, action) => {
    return Immutable({ ...state, roleDeleting: action.payload });
  },
  [ROLE_DEL_MOD_SHOW]: (state, action) => {
    return Immutable({ ...state, showDeleteModal: action.payload });
  },
  [ROLE_INFO_CLEAR]: (state) => {
    return Immutable({
      ...state,
      roleId: '',
      roleDesc: '',
    });
  },
  [ROLE_INFO_SET]: (state, action) => {
    return Immutable({
      ...state,
      roleId: action.payload[0],
      roleDesc: action.payload[1],
    });
  },
  [ROLE_DLR_GRP_TOGGLE]: (state, action) => {
    return Immutable({
      ...state,
      formData: {
        ...state.formData,
        role_data: {
          ...state.formData.role_data,
          dealer_group_properties: {
            [ROLE_DEALER_FLAG]: action.payload === '1',
          },
        },
      },
      isDealer: action.payload,
    });
  },
  [ROLE_DLR_RESTR_CLEAR]: (state, action) => {
    return Immutable.setIn(
      state,
      ['formData', 'dealer_restrictions'],
      action.payload,
    );
  },
  [ROLE_DATA_UPDATE]: (state, action) => {
    return Immutable.setIn(
      state,
      [...action.payload.path],
      action.payload.data,
    );
  },
  [ROLE_ACTION_IN_PROG_SET]: (state, action) => {
    return Immutable({ ...state, actionInProg: action.payload });
  },
  [ROLE_CREATED_SET]: (state, action) => {
    return Immutable({ ...state, roleCreated: action.payload });
  },
  [ROLE_SET_MODE]: (state, action) => {
    return Immutable({
      ...state,
      mode: action.payload,
    });
  },
  [ROLE_LIST_LOADING_SET]: (state, action) => {
    return Immutable({ ...state, loading: action.payload });
  },
  [ROLE_VALIDITY_SET]: (state, action) => {
    return Immutable({ ...state, isRoleValid: action.payload });
  },
  [ROLE_ERROR_MSG_SET]: (state, action) => {
    return Immutable.setIn(
      state,
      ['errors'],
      [`${action.payload.msg.toString()}`],
    );
  },
  [ROLE_ERRS_CLEAR]: (state, action) => {
    return Immutable.setIn(state, ['errors'], action.payload);
  },
  [ROLE_SET]: (state, action) => {
    const { path, data } = action.payload;
    return Immutable.setIn(state, path, data);
  },
};
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {};
export default function roleReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action?.type];
  return handler ? handler(state, action) : state;
}

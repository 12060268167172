import { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { grey } from '@mui/material/colors';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';

const navButtonStyles = {
    color: grey[50],
    opacity: 0.65,
    textTransform: 'none',
    fontWeight: 300,
    '&:hover': {
        opacity: 1,
        background: 'transparent',
    },
    '.MuiButton-endIcon': {
        marginLeft: 0,
    },
};

const Dropdown = ({ link, handleLinkClick  }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                key={link.path}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={navButtonStyles}
                endIcon={<ArrowDropDownIcon />}
            >
                {link.name}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {link.items?.map((item, i) => {
                    return (
                        <MenuItem
                            key={i}
                            active={item.id}
                            onClick={() => {
                                handleLinkClick(item.path);
                                setAnchorEl(null);
                            }}
                            sx={{ 
                                '&:hover': { background: grey[200] },
                            }}
                        >
                            {item.name}
                        </MenuItem >
                    );
                })}
            </Menu>
        </>
    );
};


export default function Links({ links }) {

    const navigate = useNavigate();

    const handleLinkClick = (path) => path.includes('http') ? window.location.href = path : navigate(`/${path}`);

    return (
        <>
            { links?.map((link) => {
                if (link.type === 'link') {
                    return (
                        <Button
                            key={link.id}
                            onClick={() => handleLinkClick(link.path)}
                            sx={navButtonStyles}
                        >
                            {link.name}
                        </Button>
                    );
                } else if (link.type === 'dropdown') {
                    return (
                        <Dropdown
                            key={link.id}
                            link={link}
                            handleLinkClick={handleLinkClick}
                        />

                    );
                }
            }) }
        </>
    );
}

import SchemaConverter from 'fni-components/FNISchemaForm/utils/SchemaConverter';
import { makeApi, translateToBackend } from 'fni-schema';

import FNIStandardRedux from '../../../components/deprecated/FNIStandardRedux';
import { setInfo } from '../../../store/coreLayout';

export const api = makeApi({
  baseURL: 'fni-lenderportal-admin/admin/noteType/',
  withRequestInterceptor: false,
});

const Standard = new FNIStandardRedux('notetype', 'admin/noteType/');

const noteTypeSet = Standard.createSet();

const allFetch = () => Standard.fetch('all', ['noteTypes']);
const noteTypeFetch = (id) => Standard.fetch(id, ['noteType']);

function formatPayload(payload) {
  return translateToBackend({ NOTE_TYPE: [payload] })?.[0];
}

const noteTypeCreateSend = (data) => async (dispatch) => {
  Standard.fetching([], true);

  try {
    await api.post('create', formatPayload(data));
    dispatch(
      setInfo({
        type: 'success',
        message: 'Created Successfully!',
        delay: 3000,
      })
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    if (e.message) dispatch(setInfo({ message: e.message }));
    else {
      dispatch(setInfo({ message: 'Failed to create note type' }));
    }
    throw e;
  } finally {
    Standard.fetching([], false);
  }
};

const noteTypeUpdateSend = (data) => async (dispatch) => {
  Standard.fetching([], true);

  try {
    await api.post('update', formatPayload(data));
    dispatch(
      setInfo({ type: 'success', message: 'Edit Successful!', delay: 3000 })
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    if (e.message) dispatch(setInfo({ message: e.message }));
    else {
      dispatch(setInfo({ message: 'Failed to update note type' }));
    }
    throw e;
  } finally {
    Standard.fetching([], false);
  }
};

const noteTypeDeleteSend = (id) =>
  Standard.send(false, `remove`, {
    dataFormat: () => {
      let formattedData = {
        NOTE_TYPE: [
          {
            NOTE_TYPE_ID: id,
          },
        ],
      };
      formattedData = SchemaConverter.translateToBackend(formattedData);
      return formattedData[0];
    },
  });

export const actions = {
  noteTypeSet,
  allFetch,
  noteTypeFetch,
  noteTypeCreateSend,
  noteTypeUpdateSend,
  noteTypeDeleteSend,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = Standard.returnHandlers();

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  create: {},
  fetching: false,
};
export default function noteTypeReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}


export const TOTP = 'TOTP';
export const EMAIL = 'EMAIL';
export const SMS = 'SMS';

export default {
    TOTP,
    EMAIL,
    SMS,
}

import Immutable from 'seamless-immutable';
import { makeApi } from 'fni-schema';
export const api = makeApi({
    baseURL: 'fni-lenderportal-workbench/workbench/',
    schemaKey: 'tabSchema',
});
// ------------------------------------
// Constants
// ------------------------------------
export const TABS_SET = 'TABS_SET';
export const RIGHT_SIDEBAR_TAB_SET = 'RIGHT_SIDEBAR_TAB_SET';

export const TAB_STATUS_VALUES = {
    complete: 'COMPLETE',
    incomplete: 'INCOMPLETE',
    fetching: 'FETCHING',
    number: 'NUMBER',
    note: 'NOTE',
};

// ------------------------------------
// Actions
// ------------------------------------
export const tabsSet = (data) => {
    return {
        type: TABS_SET,
        payload: data,
    };
};

export const rightSidebarTabSet = (tabs) => {
    return {
        type: RIGHT_SIDEBAR_TAB_SET,
        payload: tabs,
    };
};

// ------------------------------------
// Middleware
// ------------------------------------

export const tabsFetch = () => async (dispatch) => {
    const response = api.get('tabs');

    if (response.status == 200) {
        const _text = response.data;
        const workbenchTabs = [];
        const sidebartabs = [];
        _text.forEach((t) => {
            if (
                t.tabType === 'INFO' ||
        t.tabType === 'ASSIGN' ||
        t.tabType === 'DEALER' ||
        t.tabType === 'DECISION' ||
        t.tabType === 'FACILITY'
            ) {
                sidebartabs.push(t);
            } else {
                workbenchTabs.push(t);
            }
            // eslint-disable-next-line no-param-reassign
            t.tabStatus = { complete: 'FETCHING' };
        });
        dispatch(tabsSet(workbenchTabs));
        dispatch(rightSidebarTabSet(sidebartabs));
    }
};

export const actions = {
    tabsSet,
    tabsFetch,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [TABS_SET]: (state, action) => {
        return Immutable({ ...state, tabs: action.payload });
    },
    [RIGHT_SIDEBAR_TAB_SET]: (state, action) => {
        return Immutable({ ...state, sidebarTabs: action.payload });
    },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
    tabs: [],
    sidebarTabs: [],
};
export default function exampleReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}

import Immutable from 'seamless-immutable';
// eslint-disable-next-line import/no-cycle
import { makeApi } from 'fni-schema';
// ------------------------------------
// Constants
// ------------------------------------
export const CONVERSATIONS_SET = 'CONVERSATIONS_SET';
export const CONVERSATIONS_ERROR_SET = 'CONVERSATIONS_ERROR_SET';
export const CONVERSATIONS_ERROR_CLEAR = 'CONVERSATIONS_ERROR_CLEAR';

// ------------------------------------
// Actions
// ------------------------------------



export const conversationsSet = (data, path) => {
  return {
    type: CONVERSATIONS_SET,
    payload: { data, path },
  };
};

export const conversationsErrorSet = (payload) => ({
  type: CONVERSATIONS_ERROR_SET,
  payload,
});

export const conversationsErrorClear = (payload) => ({
  type: CONVERSATIONS_ERROR_CLEAR,
  payload,
});



export const conversationApis = (path, iRefnum, FEType = 'LENDER') => {
  const api = makeApi({
    baseURL: 'fni-lenderportal-search/',
    headers:{
      REFNUM: iRefnum
    }
  });
  const refnum = FEType === 'LENDER' ? false : iRefnum;

  return {
    getPermissions: () => async (dispatch) => {
      if (FEType === 'BORROWER') return true;
      try {
        const data = await api.get(
          `${path}/${refnum ? `${refnum}/` : ''}conversations/permissions`
        );
        const permissions = data?.formData?.PERMISSIONS.reduce(
          (acc, { PERM_ID, PERM_VALUE }) => {
            acc[PERM_ID] = PERM_VALUE;
            return acc;
          },
          {}
        );
        dispatch(conversationsSet(permissions, ['permissions']));
        return true;
      } catch (e) {

        dispatch(conversationsErrorSet({type: 'error', message: 'Conversations encountered an error.'}));
        return false;
      }
    },
    getPreDefMessages: () => async (dispatch) => {
      if (FEType !== 'LENDER') return true;
      try {
        const data = await api.get(
          `${path}/${refnum ? `${refnum}/` : ''}predef-messages`
        );
        dispatch(conversationsSet(data, ['predef']));
        return true;
      } catch (e) {
        dispatch(conversationsErrorSet({type: 'error', message: 'Conversations encountered an error.'}));


        return false;
      }
    },
    getConversations: () => async (dispatch) => {
      dispatch(conversationsSet(FEType, ['type']));
      try {
        const data = await api.get(
          FEType === 'LENDER'
            ? `workbench/tab/convo`
            : `${path}/${refnum ? `${refnum}/` : ''}conversations`
        );
        dispatch(conversationsSet(data, ['conversations']));
        return true;
      } catch (e) {
        dispatch(conversationsErrorSet({type: 'error', message: 'Conversations encountered an error.'}));
        return false;
      }
    },
    postConversation: (data) => async (dispatch, getState) => {
      const type =
        FEType === 'LENDER' ? `${data?.CONVO_TYPE}_CONVOS` : `${FEType}_CONVOS`;
      try {
        const response = await api.post(
          `${path}/${refnum ? `${refnum}/` : ''}conversations`,
          data
        );
        const conversations =
          getState()?.convo?.conversations?.formData?.[type] ?? [];
        dispatch(
          conversationsSet(
            [...conversations, response?.formData],
            ['conversations', 'formData', type]
          )
        );
        return true;
      } catch (e) {
        dispatch(conversationsErrorSet({type: 'error', message: (e?.message || 'Conversations encountered an error.')}));
        return false;
      }
    },
    getMessages: (id) => async (dispatch, getState) => {
      let type = 'DEALER';
      const formData = getState()?.convo?.conversations?.formData;
      let messageIndex = (formData?.DEALER_CONVOS ?? []).findIndex(
        (a) => a.CONVO_ID === id
      );
      if (messageIndex === -1) {
        type = 'BORROWER';
        messageIndex = (formData?.BORROWER_CONVOS ?? []).findIndex(
          (a) => a.CONVO_ID === id
        );
      }
      try {
        const data = await api.get(
          `${path}/${refnum ? `${refnum}/` : ''}conversations/${id}/messages`
        );
        dispatch(conversationsSet(data, ['messages', id]));
        api.put(
          `${path}/${refnum ? `${refnum}/` : ''}conversations/${id}/read`
        );
        dispatch(
          conversationsSet(false, [
            'conversations',
            'formData',
            `${type}_CONVOS`,
            messageIndex,
            'CONVO_UNREAD_MSGS',
          ])
        );
        return true;
      } catch (e) {
        dispatch(conversationsErrorSet({type: 'error', message:'Conversations encountered an error.'}));
        return false;
      }
    },
    postMessage: (id, data) => async (dispatch, getState) => {
      try {
        const response = await api.post(
          `${path}/${refnum ? `${refnum}/` : ''}conversations/${id}/messages`,
          data
        );
        const messages = getState()?.convo?.messages?.[id]?.formData?.MESSAGES;
        dispatch(
          conversationsSet(
            [...messages, response?.formData],
            ['messages', id, 'formData', 'MESSAGES']
          )
        );
        return true;
      } catch (e) {
        dispatch(conversationsErrorSet({type: 'error', message:(e?.message || 'Conversations encountered an error.')}));

        return false;
      }
    },
    hideConversation: (id, flag) => async (dispatch, getState) => {
      let type = 'DEALER';
      const formData = getState()?.convo?.conversations?.formData;
      let messageIndex = formData?.DEALER_CONVOS.findIndex(
        (a) => a.CONVO_ID === id
      );
      if (messageIndex === -1) {
        type = 'BORROWER';
        messageIndex = formData?.BORROWER_CONVOS.findIndex(
          (a) => a.CONVO_ID === id
        );
      }
      try {
        await api.put(
          `${path}/${refnum ? `${refnum}/` : ''}conversations/${id}/${
            flag ? 'hide' : 'unhide'
          }`
        );
        dispatch(
          conversationsSet(flag, [
            'conversations',
            'formData',
            `${type}_CONVOS`,
            messageIndex,
            'CONVO_HIDDEN',
          ])
        );
        return true;
      } catch (e) {
        dispatch(conversationsErrorSet({type: 'error', message:'Conversations encountered an error.'}));
        return false;
      }
    },
    closeConversation: (id, flag) => async (dispatch, getState) => {
      let type = 'DEALER';
      const formData = getState()?.convo?.conversations?.formData;
      let messageIndex = formData?.DEALER_CONVOS.findIndex(
        (a) => a.CONVO_ID === id
      );
      if (messageIndex === -1) {
        type = 'BORROWER';
        messageIndex = formData?.BORROWER_CONVOS.findIndex(
          (a) => a.CONVO_ID === id
        );
      }
      try {
        await api.put(
          `${path}/${refnum ? `${refnum}/` : ''}conversations/${id}/${
            flag ? 'close' : 'open'
          }`
        );
        dispatch(
          conversationsSet(flag, [
            'conversations',
            'formData',
            `${type}_CONVOS`,
            messageIndex,
            'CONVO_CLOSED',
          ])
        );
        return true;
      } catch (e) {
        dispatch(conversationsErrorSet({type: 'error', message:'Conversations encountered an error.'}));
        return false;
      }
    },
    conversationsSet,
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CONVERSATIONS_SET]: (state, { payload: { path, data } }) =>
    Immutable.setIn(state, path, data),
  [CONVERSATIONS_ERROR_SET]: (state, { payload }) =>
    Immutable.setIn(state, ['error'], payload),
  [CONVERSATIONS_ERROR_CLEAR]: (state) => Immutable.setIn(state, ['error'], {}),
};
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { predef: {}, conversations: {}, permissions: {} };

// ------------------------------------
// Reducer
// ------------------------------------

export default function applicantReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

import aclReducer from '../routes/Acl/modules/acl';
import advActionReducer from '../routes/AdvAction/modules/advAction';
import apiAuthReducer from '../routes/APIAuth/modules/apiAuth';
import collectionsReducer from '../routes/Collections/modules/collections';
import conversationReducer from '../routes/Conversations/modules/conversations';
import dealerGroupAdminReducer from '../routes/DealerGroupAdmin/modules/dealerGroupAdmin';
import dealerMembershipAdminReducer from '../routes/DealerMembership/modules/dealerMembershipAdmin';
import documentReducer from '../routes/Document/modules/document';
import docTypeReducer from '../routes/DocumentType/modules/documentType';
import presenceListReducer from '../routes/EditPresenceList/modules/presenceList';
import emailReducer from '../routes/Email/modules/email';
import emailScheduleReducer from '../routes/EmailSchedule/modules/emailSchedule';
import errorManagementReducer from '../routes/ErrorManagement/modules/errorManagement';
import facilityGroupAdminReducer from '../routes/Facility/modules/facility';
import noteTypeReducer from '../routes/NoteType/modules/noteType';
import pagesReducer from '../routes/Pages/modules/pages';
import queueReducer from '../routes/Queue/modules/queue';
import roleReducer from '../routes/Role/modules/role';
import smsReducer from '../routes/SMS/modules/sms';
import stipulationReducer from '../routes/Stipulations/modules/stipulation';
import stipulationTypeReducer from '../routes/StipulationTypes/modules/stipulationType';
import strategyReducer from '../routes/Strategy/modules/strategy';
import swaggerReducer from '../routes/Swagger/modules/swagger';
import userReducer from '../routes/Users/modules/users';

const makeRootReducer = () => ({
    acl: aclReducer,
    advaction: advActionReducer,
    apiAuth: apiAuthReducer,
    collections: collectionsReducer,
    conversations: conversationReducer,
    dealerGroups: dealerGroupAdminReducer,
    dealerMembership: dealerMembershipAdminReducer,
    document: documentReducer,
    documentType: docTypeReducer,
    presenceList: presenceListReducer,
    email: emailReducer,
    emailSchedule: emailScheduleReducer,
    errorManagement: errorManagementReducer,
    facilityGroup: facilityGroupAdminReducer,
    noteType: noteTypeReducer,
    pages: pagesReducer,
    queue: queueReducer,
    role: roleReducer,
    sms: smsReducer,
    stipulation: stipulationReducer,
    stipulationType: stipulationTypeReducer,
    strategy: strategyReducer,
    swagger: swaggerReducer,
    users: userReducer,
});

export default makeRootReducer;

import Immutable from 'seamless-immutable';
import { makeApi, translateToBackend } from 'fni-schema';

import { informationSet } from '../../../../../components/store/shared';
import { incrementFetchCount, decrementFetchCount } from './touchpoint';

const setInfo = (error) => {
  if (typeof error === 'string') {
    return informationSet({ type: 'error', message: error });
  }
  return informationSet(error);
};

const baseURL = `fni-lenderportal-touchpoint/`;
export const api = makeApi({
  baseURL
});

export const uploadApi = makeApi({
  baseURL,
  withRequestInterceptor: false
});

// ------------------------------------
// Constants
// ------------------------------------
const SET_TASKS = 'SET_TASKS';
const SET_DATA_COLLECT = 'SET_DATA_COLLECT';
const SET_STIP = 'SET_STIP';
const UNSET_STIP = 'UNSET_STIP';

// ------------------------------------
// Actions
// ------------------------------------
export const setTasks = (data, path) => {
  return {
    type: SET_TASKS,
    payload: { data, path },
  };
};

export const setDataCollect = (payload) => {
  return {
    type: SET_DATA_COLLECT,
    payload,
  };
};

export const setStip = (data) => {
  return {
    type: SET_STIP,
    payload: data,
  };
};

export const unsetStip = () => {
  return {
    type: UNSET_STIP,
  };
};

// ------------------------------------
// Middleware
// ------------------------------------

const handleError =
  (e, defaultMessage = '') =>
  (dispatch) => {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    if (e.message) dispatch(setInfo(e.message));
    else {
      dispatch(setInfo(defaultMessage));
    }
  };

const handleSuccess = (message) => (dispatch) => {
  dispatch(
    setInfo({
      type: 'success',
      message,
      delay: 3000,
    })
  );
};

export const getTasks = (refnum) => async (dispatch) => {
  dispatch(incrementFetchCount());

  try {
    const response = await api.get(`actions/${refnum}`);
    dispatch(setTasks(response, ['tasks']));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch tasks'));
  } finally {
    dispatch(decrementFetchCount());
  }
};

export const getDataCollect =
  ({ refnum, stipId }) =>
  async (dispatch) => {
    try {
      const response = await api.get(`actions/${refnum}/${stipId}`);
      dispatch(setDataCollect({ ...response, stipId }));
    } catch (e) {
      dispatch(handleError(e, 'Failed to fetch data collection tasks'));
    }
  };

export const uploadDoc = (data) => async (dispatch) => {
  try {
    await uploadApi.post('uploadDoc', data);
    dispatch(handleSuccess('Upload Successful'));
  } catch (e) {
    dispatch(handleError(e, 'Failed to upload document'));
    throw e;
  }
};

export const putDataCollect =
  ({ data, stipId, refnum }) =>
  async (dispatch) => {
    const body = {
      stipId,
      refnum,
      formData: translateToBackend(data),
    };

    try {
      await uploadApi.put('actions', body);
      dispatch(handleSuccess('Save Successful'));
    } catch (e) {
      dispatch(handleError(e, 'Failed to save'));
      throw e;
    }
  };

export const setStipOpen = (stipId, stipStatus) => {
  return (dispatch) => {
    dispatch(setStip({ stipId, stipStatus }));
  };
};

export const unsetStipOpen = () => {
  return (dispatch) => {
    dispatch(unsetStip());
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_TASKS]: (state, { payload: { data, path } }) => {
    return Immutable.setIn(state, path, data);
  },
  [SET_DATA_COLLECT]: (state, { payload }) => {
    let index = state.dataCollect.findIndex((task) => {
      return task.stipId === payload.stipId;
    });
    if (index === -1) {
      index = state.dataCollect.length;
    }
    return Immutable.setIn(state, ['dataCollect', index], payload);
  },
  [SET_STIP]: (state, action) => {
    return Immutable.setIn(state, ['stipOpen'], action.payload);
  },
  [UNSET_STIP]: (state) => {
    return Immutable.setIn(state, ['stipOpen'], {
      stipStatus: '',
      stipId: '',
    });
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  fetchCount: 0,
  dataCollect: [],
  stipOpen: {
    stipId: '',
    stipStatus: '',
  },
};

export default function touchpointReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

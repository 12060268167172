import Immutable from 'seamless-immutable';
import SchemaConverter from 'fni-components/FNISchemaForm/utils/SchemaConverter';
import { setInfo } from '../../../store/coreLayout';
import apiFetch from '../../../utils/apiFetch';
import { handleError } from '../../../utils/infoBarHandlers';

// ------------------------------------
// Constants
// ------------------------------------
// export const POSTS_REQUEST = 'POSTS_REQUEST';
export const EMAIL_TEMPLATES_SET = 'EMAIL_TEMPLATES_SET';
export const EMAIL_FIELDS_SET = 'EMAIL_FIELDS_SET';
export const EMAIL_TEMPLATE_SET = 'EMAIL_TEMPLATE_SET';
export const EMAIL_RESPONSE_SET = 'EMAIL_RESPONSE_SET';
export const EMAIL_FORMDATA_UPDATE = 'EMAIL_FORMDATA_UPDATE';
export const EMAIL_VIEW_SET = 'EMAIL_VIEW_SET';
export const EMAIL_FETCHING_SET = 'EMAIL_FETCHING_SET';

// ------------------------------------
// Actions
// ------------------------------------

export const templatesSet = (data) => {
  return {
    type: EMAIL_TEMPLATES_SET,
    payload: data,
  };
};

export const fieldsSet = (data) => {
  return {
    type: EMAIL_FIELDS_SET,
    payload: data,
  };
};

export const templateSet = (data) => {
  return {
    type: EMAIL_TEMPLATE_SET,
    payload: data,
  };
};

export const emailResponseSet = (data) => {
  return {
    type: EMAIL_RESPONSE_SET,
    payload: data,
  };
};

export const templateFormDataUpdate = (location, data) => {
  return {
    type: EMAIL_FORMDATA_UPDATE,
    payload: {
      location,
      data,
    },
  };
};

export const emailFetchingSet = (data) => {
  return {
    type: EMAIL_FETCHING_SET,
    payload: data,
  };
};

// ------------------------------------
// Middleware
// ------------------------------------

export const templatesFetch = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(emailFetchingSet(true));
      return apiFetch('admin/email/templates')
        .then((response) => {
          if (response.ok) {
            return response.text();
          }
          return undefined;
        })
        .then((text) => {
          let parsed = JSON.parse(text);
          parsed = SchemaConverter.translateFromBackend({
            schema: parsed.adminSchema,
            formData: parsed.formData,
          });
          dispatch(templatesSet(parsed));
          resolve(true);
          return undefined;
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          if (process.env.NODE_ENV === 'development') console.error(e);
          dispatch(handleError(e, 'Failed to fetch email templates'));
          reject();
        })
        .finally(() => {
          dispatch(emailFetchingSet(false));
        });
    });
  };
};

export const templateFetch = (templateId) => (dispatch) => {
  const url = templateId
    ? `admin/email/template/${templateId}`
    : 'admin/email/template';
  return new Promise(() => {
    dispatch(emailFetchingSet(true));
    return apiFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.text();
        }
        throw response;
      })
      .then((text) => {
        let parsed = JSON.parse(text);
        parsed = SchemaConverter.translateFromBackend({
          schema: parsed.adminSchema,
          formData: parsed.formData,
        });
        dispatch(templateSet(parsed));
        return undefined;
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        if (process.env.NODE_ENV === 'development') console.error(e);
        dispatch(handleError(e, 'Failed to fetch email template'));
        dispatch(templateSet(null));
      })
      .finally(() => {
        dispatch(emailFetchingSet(false));
      });
  });
};

export const fieldsFetch = () => {
  return (dispatch) => {
    dispatch(emailFetchingSet(true));
    return apiFetch('admin/email/fields')
      .then((response) => {
        if (response.ok) {
          return response.text();
        }
        return undefined;
      })
      .then((text) => {
        let parsed = JSON.parse(text);
        parsed = SchemaConverter.translateFromBackend({
          schema: parsed.adminSchema,
          formData: parsed.formData,
        });
        dispatch(fieldsSet(parsed));
        return undefined;
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        if (process.env.NODE_ENV === 'development') console.error(e);
        dispatch(handleError(e, 'Failed to fetch fields'));
      })
      .finally(() => {
        dispatch(emailFetchingSet(false));
      });
  };
};

export const templateSend = (templateId) => {
  const url = templateId
    ? `admin/email/template/${templateId}`
    : 'admin/email/template';
  const method = templateId ? 'PUT' : 'POST';
  return (dispatch, getState) => {
    dispatch(emailFetchingSet(true));
    return new Promise((resolve, reject) => {
      let data = getState().email.template.formData;
      data = SchemaConverter.translateToBackend(data);
      const options = {
        method,
        body: JSON.stringify(data),
      };

      return apiFetch(url, options)
        .then((response) => {
          if (!response.ok) {
            dispatch(emailFetchingSet(false));
            dispatch(
              setInfo({
                type: 'error',
                message: 'Failed to Save!',
              })
            );
            reject();
            return undefined;
          }
          return response.text();
        })
        .then((text) => {
          const parsed = JSON.parse(text);
          if (parsed.status === 'SUCCESS') {
            dispatch(emailFetchingSet(false));
            dispatch(
              setInfo({
                type: 'success',
                message: 'Template Saved Successfully!',
                delay: 3000,
              })
            );
          } else {
            dispatch(emailFetchingSet(false));
            const message = parsed.errorMsgs.join('\n');
            dispatch(
              setInfo({
                type: 'error',
                message,
              })
            );
            reject();
          }
          return undefined;
        })
        .then(() => {
          dispatch(templatesFetch());
          resolve(true);
          return undefined;
        });
    });
  };
};

export const templateDelete = (templateId) => {
  const url = templateId
    ? `admin/email/template/${templateId}`
    : 'admin/email/template';
  return (dispatch) => {
    dispatch(emailFetchingSet(true));
    return new Promise((resolve, reject) => {
      const options = {
        method: 'DELETE',
      };
      return apiFetch(url, options)
        .then((response) => {
          if (!response.ok) {
            dispatch(emailFetchingSet(false));
            reject();
            return undefined;
          }
          return response.text();
        })
        .then((text) => {
          const parsed = JSON.parse(text);
          if (parsed.status === 'SUCCESS') {
            dispatch(emailFetchingSet(false));
            dispatch(
              setInfo({
                type: 'success',
                message: `Template ${templateId} Deleted Successfully!`,
                delay: 3000,
              })
            );
            dispatch(templatesFetch());
            resolve(true);
          } else {
            dispatch(emailFetchingSet(false));
            const message = parsed.errorMsgs.join('\n');
            dispatch(
              setInfo({
                type: 'error',
                message,
              })
            );
            reject();
          }
          return undefined;
        });
    });
  };
};

export const actions = {
  templatesFetch,
  templateFetch,
  fieldsFetch,
  templateFormDataUpdate,
  templateSend,
  templateDelete,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [EMAIL_TEMPLATES_SET]: (state, action) => {
    return Immutable.merge(state, { templates: action.payload });
  },
  [EMAIL_FIELDS_SET]: (state, action) => {
    return Immutable.merge(state, { fields: action.payload });
  },
  [EMAIL_TEMPLATE_SET]: (state, action) => {
    return Immutable.merge(state, { template: action.payload });
  },
  [EMAIL_RESPONSE_SET]: (state, action) => {
    return Immutable.merge(state, { response: action.payload });
  },
  [EMAIL_FORMDATA_UPDATE]: (state, action) => {
    return Immutable.setIn(
      state,
      [action.payload.location, 'formData'],
      action.payload.data
    );
  },
  [EMAIL_FETCHING_SET]: (state, action) => {
    return Immutable({ ...state, fetching: action.payload });
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { view: 'list' };
export default function emailReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import DefaultField from './DefaultField';

const DoubleEntry = (props) => {
  const {
    id,
    labelId,
    label,
    autoFocus,
    readOnly,
    disabled,
    scale,
    type,
    required,
    value,
    ComponentProps,
    ErrorControlProps,
    LabelProps,
    GridProps,
  } = props;

  const [reenter, setReenter] = React.useState('');
  const reenterLabel = `Re-Enter ${label}`;
  const reenterErrors = React.useMemo(() => {
    if (value && value !== reenter) {
      return [`${reenterLabel} must match ${label}`];
    }
    return [];
  }, [label, reenter, reenterLabel, value]);

  return (
    <>
      <Grid item xs="auto" {...GridProps}>
        <DefaultField {...props} />
      </Grid>
      <Grid item xs="auto" {...GridProps}>
        <DefaultField
          id={`reenter-${id}`}
          labelId={`reenter-${labelId}`}
          label={reenterLabel}
          autoFocus={autoFocus}
          readOnly={readOnly}
          disabled={disabled}
          scale={scale}
          type={type}
          required={required}
          value={reenter}
          errors={reenterErrors}
          onChange={(e) => setReenter(e.target.value)}
          ComponentProps={ComponentProps}
          ErrorControlProps={ErrorControlProps}
          LabelProps={LabelProps}
        />
      </Grid>
    </>
  );
};

DoubleEntry.propTypes = {
  GridProps: PropTypes.objectOf(PropTypes.any),
  ComponentProps: PropTypes.objectOf(PropTypes.any),
  ErrorControlProps: PropTypes.objectOf(PropTypes.any),
  LabelProps: PropTypes.objectOf(PropTypes.any),
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  labelId: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['number', 'text']),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]).isRequired,
  scale: PropTypes.number,
};

export default DoubleEntry;

import { lazy } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import AvatarMenu from './AvatarMenu';
import { authActions, authSelectors } from '../../../authentication/modules/auth';

const Links = lazy(() => import('./Links'));

const ResponsiveAppBar = ({
    links,
    currentGroup = '',
    currentItem,
}) => {

    const dispatch = useDispatch();
    let navigate = useNavigate ();
    const currentAuthenticatedUser = useSelector(authSelectors.currentAuthenticatedUser);


    const handleSignOut = () => dispatch(authActions.signOutUser())
        .then(() => navigate('/auth/login') );

    return (
        <AppBar position="static">
            <Toolbar disableGutters>
                <Box
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                >
                    <img
                        height="42"
                        src="/fni-lenderportal-navigation/navigation/logo"
                        alt="logo"
                        onError={event => {
                            event.target.src = '/assets/logo.png';
                            event.onerror = null;
                        }}
                    />
                </Box>
                <Box
                    sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                >
                    <img
                        height="42"
                        src="/fni-lenderportal-navigation/navigation/logo"
                        alt="logo"
                        onError={event => {
                            event.target.src = '/assets/logo.png';
                            event.onerror = null;
                        }}
                    />
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    {currentAuthenticatedUser ? 
                        <Links links={links}  /> : null }
                </Box>
                
                <Box sx={{ flexGrow: 0 }}>
                    {currentAuthenticatedUser ?
                        <AvatarMenu onLogout={handleSignOut} currentAuthenticatedUser={currentAuthenticatedUser}/> : null
                    }
                </Box>
            </Toolbar>
        </AppBar>
    );
};
export default ResponsiveAppBar;

import { createSlice } from '@reduxjs/toolkit';
import { utils } from '../utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFunctions, httpsCallable } from  'firebase/functions';
import { createSelector } from '@reduxjs/toolkit';
import { getAuth, signOut } from 'firebase/auth';

// SELECTORS ---------------------------------------------------------

const allUsers = createSelector(
    state => state.users.list,
    users => users,
);

const allInvites = createSelector(
    state => state.users.invites,
    users => users,
);

const currentSelectedTenant = createSelector(
    state => state.auth.currentSelectedTenant,
    tenant => tenant,
);

const selectedUser = createSelector(
    state => state.users.selectedUser,
    (user) => user ?? null,
);

const selectedInviteId = createSelector(
    state => state.users.selectedInviteId,
    (inviteId) => inviteId,
);

const usersLoading = createSelector(
    state => state.users.isLoading,
    isLoading => Object.values(isLoading).some(val => val),
);

const userSchema = createSelector(
    state => state.users.schema,
    schema => schema,
);

const roles = createSelector(
    state => state.users.roles,
    roles => roles,
);

const dealers = createSelector(
    state => state.users.dealers,
    dealers => dealers,
);

const facilities = createSelector(
    state => state.users.facilities,
    facilities => facilities,
);

const userAlert = createSelector(
    state => state.users.alert,
    alert => alert,
);

const isRemoveUserDialogOpen = createSelector(
    state => state.users.isRemoveUserDialogOpen,
    isOpen => isOpen,
);

export const userSelectors = {
    allUsers,
    allInvites,
    currentSelectedTenant,
    isRemoveUserDialogOpen,
    selectedUser,
    selectedInviteId,
    usersLoading,
    userSchema,
    roles, 
    dealers, 
    facilities,
    userAlert,
};



/// ASYNC ACTIONS -------------------------------------------------

const inviteUser = createAsyncThunk(
    'users/inviteUser',
    async (newUser) => {
        const functions = getFunctions();
        const inviteUserFunction = httpsCallable(functions, 'users-inviteUser');
        return await inviteUserFunction(newUser);
    },
);

const getUsers = createAsyncThunk(
    'users/getUsers',
    async (tenantId) => {
        const getUsersByTenantIdFunction = httpsCallable(getFunctions(), 'users-getUsersByTenantId');
        return await getUsersByTenantIdFunction(tenantId);
    },
);

const getInvites = createAsyncThunk(
    'users/getInvites',
    async (tenantId) => {
        const getInvitesByTenantIdFunction = httpsCallable(getFunctions(), 'users-getInvitesByTenantId');
        return await getInvitesByTenantIdFunction(tenantId);
    },
);

const updateUser = createAsyncThunk(
    'users/updateUser',
    async (updatedUser) => {
        const functions = getFunctions();
        const updateUserFunction = httpsCallable(functions, 'users-updateUserSingleTenant');
        return await updateUserFunction(updatedUser);
    },
);

const removeUserFromTenant = createAsyncThunk(
    'users/removeUserFromTenant',
    async (user) => {
        const functions = getFunctions();
        const removeUserFromTenantFunction = httpsCallable(functions, 'users-removeUserFromSingleTenant');
        return await removeUserFromTenantFunction(user);
    },
);

const removeInvite = createAsyncThunk(
    'users/removeInvite',
    async (inviteId, {getState}) => {
        const tenantId = getState()?.auth?.currentSelectedTenant;
        const functions = getFunctions();
        const removeInviteByIdFunction = httpsCallable(functions, 'users-removeInviteForTenantById');
        return await removeInviteByIdFunction({tenantId, inviteId});
    },
);

const getTenantRoleData = createAsyncThunk(
    'users/getTenantRoleData',
    async (tenantId) => {
        const getTenantRoleDataFunction = httpsCallable(getFunctions(), 'tenants-getTenantRoleData');
        return await getTenantRoleDataFunction(tenantId);
    },
);

const signOutUser = createAsyncThunk(
    'users/signOutUser',
    async (_, { dispatch, getState }) => {
        const code = getState().core?.tenantCode;
        await signOut(getAuth());
        dispatch(userActions.logout());
        localStorage.removeItem('code');
        return code;
    },
);

const getUserDetail = createAsyncThunk(
    'users/getUserDetail',
    async (userId, { getState }) => {
        const tenantId = getState().auth.currentSelectedTenant;
        const getUserDetail = httpsCallable(getFunctions(), 'users-getUserDetailByIdForTenant');
        return await getUserDetail({ userId, tenantId });
    },
);

const resetMFAForUser = createAsyncThunk(
    'users/resetMFAEnrollment',
    async (userId, { getState }) => {
        const tenantId = getState()?.auth?.currentSelectedTenant;
        const resetMFAForUserFunction = httpsCallable(getFunctions(), 'auth-resetMFAEnrollment');
        return await resetMFAForUserFunction({ userId, tenantId });
    },
);


// REDUCER ---------------------------------------------------

const { 
    getUpdatedList,
    trimActionType, 
    setAlertError,
    setAlertSuccess,
    removeItemFromListById,
    alertInitialState,
} = utils;

const initialState = {
    list: [],
    invites: [],
    schema: [],
    roles: [],
    dealers: [],
    facilities: [],
    selectedUser: null,
    selectedInviteId: null,
    isLoading: {},
    isRemoveInviteDialogOpen: false,
    alert: alertInitialState,
};

export const UserSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        hideAlert(state) {
            state.alert.open = false;
        },
        toggleRemoveUserDialogOpen(state) {
            state.isRemoveUserDialogOpen = !state.isRemoveUserDialogOpen;
        },
        selectInvite(state, action) {
            state.selectedInviteId = action.payload;
        },
        resetSlice() {
            return initialState;
        },
        logout() {
            //check in store.js to see state reset for logout
        },
    },
    extraReducers: {
        [inviteUser.fulfilled]: (state, action) => {
            state.invites = [...state.invites, action.payload.data];
            state.isLoading[trimActionType(action.type)] = false;
            setAlertSuccess(state, 'User invited!');
        },
        [inviteUser.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },        
        [inviteUser.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
            setAlertError(state, action.error);
        },
        [getTenantRoleData.fulfilled]: (state, action) => {
            const { 
                roles, 
                facilityGroups, 
                facilityLocations, 
                dealerGroups, 
                dealerLocations, 
            } = action.payload.data;

            state.roles = roles;
            state.dealers = { dealerGroups, dealerLocations };
            state.facilities = { facilityGroups, facilityLocations };
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getTenantRoleData.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },
        [getTenantRoleData.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getInvites.fulfilled]: (state, action) => {
            state.invites = action.payload.data;
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getInvites.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },        
        [getInvites.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getUsers.fulfilled]: (state, action) => {
            state.list = action.payload.data;
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getUsers.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },        
        [getUsers.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getUserDetail.fulfilled]: (state, action) => {
            state.selectedUser = action.payload.data;
            state.isLoading[trimActionType(action.type)] = false;
        },
        [getUserDetail.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },        
        [getUserDetail.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [removeUserFromTenant.fulfilled]: (state, action) => {
            state.list = removeItemFromListById(state.list, action.payload.data.removedUserId);
            state.isLoading[trimActionType(action.type)] = false;
            setAlertSuccess(state, 'The user has been removed from tenant!');
        },
        [removeUserFromTenant.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },        
        [removeUserFromTenant.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
        },
        [updateUser.fulfilled]: (state, action) => {
            state.list = getUpdatedList(state.list, action.payload);
            state.isLoading[trimActionType(action.type)] = false;
            setAlertSuccess(state, 'User updated!');
        },
        [updateUser.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },        
        [updateUser.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
            setAlertError(state, action.error);
        },
        [removeInvite.fulfilled]: (state, action) => {
            state.invites = removeItemFromListById(state.invites, action.payload.data.inviteId);
            state.isLoading[trimActionType(action.type)] = false;
            setAlertSuccess(state, 'Invite removed!');
        },
        [removeInvite.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },        
        [removeInvite.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
            setAlertError(state, action.error);
        },
        [resetMFAForUser.fulfilled]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
            setAlertSuccess(state, 'MFA reset success!');
        },
        [resetMFAForUser.pending]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = true;
        },        
        [resetMFAForUser.rejected]: (state, action) => {
            state.isLoading[trimActionType(action.type)] = false;
            setAlertError(state, action.error);
        },
    },
});

export const userActions = {
    ...UserSlice.actions,
    inviteUser,
    getUsers,
    getUserDetail,
    getInvites, 
    updateUser,
    getTenantRoleData,
    signOutUser,
    removeUserFromTenant,
    removeInvite,
    resetMFAForUser
};

export default UserSlice.reducer;

import { blue, blueGrey, green } from '@mui/material/colors';

// values are manually selected rather than set from main so they can be used in any part of the theme
export const bluePalette = {
  xxlight: blue[100],
  xlight: blue[200],
  light: blue[300],
  main: blue[700],
  dark: blue[900],
};

export const greenPalette = {
  xxlight: green[100],
  xlight: green[200],
  light: green[300],
  main: green[500],
  dark: green[700],
};

export const blueGreyPalette = {
  light: blueGrey[50],
  main: blueGrey[100],
  dark: blueGrey[500],
  xdark: blueGrey[700],
};

export const lightTextPalette = {
  primary: 'rgba(0, 0, 0, 0.87)',
  white: '#fff',
  disabled: 'rgba(0, 0, 0, 0.5)',
};

export const darkTextPalette = {
  primary: '#ffffff',
  white: '#ffffff',
  disabled: 'rgba(255, 255, 255, 0.5)',
};

// TODO create palettes for each importable MUI color for use in custom themes

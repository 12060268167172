import Immutable from 'seamless-immutable';
import { makeApi } from 'fni-schema';

import { handleError, handleSuccess } from '../../../utils/infoBarHandlers';

export const api = makeApi({
  baseURL: 'fni-lenderportal-admin/admin',
});

export const blobApi = makeApi({
  baseURL: 'fni-lenderportal-admin/admin',
  responseType: 'blob',
  withRequestInterceptor: false,
  withResponseInterceptor: false,
});

export const uploadApi = makeApi({
  baseURL: 'fni-lenderportal-admin/admin',
  withRequestInterceptor: false,
  header: {
    'Content-Type': 'multipart/form-data',
  },
});

// ------------------------------------
// Constants
// ------------------------------------
const SET_DEALER_GROUP = 'SET_DEALER_GROUP';
const INCREMENT_FETCH_COUNT = 'INCREMENT_FETCH_COUNT';
const DECREMENT_FETCH_COUNT = 'DECREMENT_FETCH_COUNT';

// ------------------------------------
// Actions
// ------------------------------------
export const setDealerGroup = (data, path) => {
  return {
    type: SET_DEALER_GROUP,
    payload: { data, path },
  };
};

export const incrementFetchCount = () => {
  return {
    type: INCREMENT_FETCH_COUNT,
  };
};

export const decrementFetchCount = () => {
  return {
    type: DECREMENT_FETCH_COUNT,
  };
};

// ------------------------------------
// Middleware
// ------------------------------------

// GET fni-lenderportal-admin/admin/dealerGroups
export const getDealerGroups = () => async (dispatch) => {
  dispatch(incrementFetchCount());

  try {
    const response = await api.get('/dealerGroups');
    dispatch(setDealerGroup(response, ['groups']));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch dealer groups'));
  } finally {
    dispatch(decrementFetchCount());
  }
};

// GET fni-lenderportal-admin/admin/dealerGroup/{id}
export const getDealerGroupById =
  (id = '') =>
  async (dispatch) => {
    dispatch(incrementFetchCount());

    try {
      const response = await api.get(`/dealerGroup/${id}`);
      dispatch(setDealerGroup(response, ['group']));
    } catch (e) {
      dispatch(handleError(e, 'Failed to fetch dealer group'));
    } finally {
      dispatch(decrementFetchCount());
    }
  };

// PUT fni-lenderportal-admin/admin/dealerGroup/{id}
export const putDealerGroupById = (id, data) => async (dispatch) => {
  dispatch(incrementFetchCount());

  try {
    await api.put(`/dealerGroup/${id}`, data);
    dispatch(handleSuccess('Save Successful'));
  } catch (e) {
    dispatch(handleError(e, 'Failed to save dealer group'));
    throw e;
  } finally {
    dispatch(decrementFetchCount());
  }
};

// POST fni-lenderportal-admin/admin/dealerGroup
export const postDealerGroup = (data) => async (dispatch) => {
  dispatch(incrementFetchCount());

  let groupID = false;

  try {
    const response = await api.post('/dealerGroup', data);
    groupID = response?.formData?.DEFAULT_FIELDS?.GROUP_ID;
    dispatch(handleSuccess('Save Successful'));
  } catch (e) {
    dispatch(handleError(e, 'Failed to save dealer group'));
    throw e;
  } finally {
    dispatch(decrementFetchCount());
  }
  return groupID;
};

function getImageUrl({ data, status }) {
  // 204 = No Content
  if (status === 204) {
    return '';
  }
  return URL.createObjectURL(data);
}

// GET fni-lenderportal-admin/admin/dealerGroup/logo/{id}
export const getImageById =
  (id, refresh = false) =>
  async (dispatch, getState) => {
    const cached = getState()?.dealerGroups?.image?.[id];
    if (!cached || refresh)
      try {
        const response = await blobApi.get(`/dealerGroup/logo/${id}`);
        const url = getImageUrl(response);
        dispatch(setDealerGroup(url, ['image', id]));
      } catch (e) {
        dispatch(handleError(e, 'Failed to fetch logo'));
      }
  };

// POST fni-lenderportal-admin/admin/dealerGroup/logo/{id}
export const uploadImageById = (id, data) => async (dispatch) => {
  const form = new FormData();
  form.append('logo', data);

  try {
    await uploadApi.post(`/dealerGroup/logo/${id}`, form);
    dispatch(handleSuccess('Successfully uploaded logo'));
  } catch (e) {
    dispatch(handleError(e, 'Failed to upload logo'));
    throw e; // necessary to prevent failed upload image from replacing existing image
  }
};

// POST fni-lenderportal-admin/admin/dealerUpload
export const uploadDealerSpreadsheet = (data) => async (dispatch) => {
  const form = new FormData();
  form.append('uploadFile', data);

  try {
    await uploadApi.post('/dealerUpload', form);
    dispatch(handleSuccess('Successfully uploaded spreadsheet'));
  } catch (e) {
    dispatch(handleError(e, 'Failed to upload spreadsheet'));
    throw e;
  }
};

// GET fni-lenderportal-admin/admin/dealerLocations/{id}
export const getDealerLocationListById = (id) => async (dispatch) => {
  dispatch(incrementFetchCount());

  try {
    const response = await api.get(`/dealerLocations/${id}`);
    dispatch(setDealerGroup(response, ['locations']));
  } catch (e) {
    dispatch(handleError(e, 'Failed to fetch dealer locations'));
  } finally {
    dispatch(decrementFetchCount());
  }
};

// GET fni-lenderportal-admin/admin/dealerLocation/{id}
export const getDealerLocationById =
  (id = '') =>
  async (dispatch) => {
    dispatch(incrementFetchCount());

    try {
      const response = await api.get(`/dealerLocation/${id}`);
      dispatch(setDealerGroup(response, ['location']));
    } catch (e) {
      dispatch(handleError(e, 'Failed to fetch dealer location'));
    } finally {
      dispatch(decrementFetchCount());
    }
  };

// GET fni-lenderportal-admin/admin/dealerDocAccess/{id}
export const getDealerDocAccessById =
  (id = '') =>
  async (dispatch) => {
    dispatch(incrementFetchCount());

    try {
      const response = await api.get(`/dealerDocAccess/${id}`);
      dispatch(setDealerGroup(response, ['docAccess']));
    } catch (e) {
      dispatch(handleError(e, 'Failed to fetch document access'));
    } finally {
      dispatch(decrementFetchCount());
    }
  };

// DELETE fni-lenderportal-admin/admin/dealerDocAccess/{id}
export const deleteDealerDocAccessById = (id) => async (dispatch) => {
  try {
    await api.delete(`/dealerDocAccess/${id}`);
    dispatch(handleSuccess('Successfully deleted document access'));
  } catch (e) {
    dispatch(handleError(e, 'Failed to delete document access'));
  }
};

// POST fni-lenderportal-admin/admin/dealerDocAccess
export const postDealerDocAccess = (data) => async (dispatch) => {
  try {
    await api.post('/dealerDocAccess', data);
    dispatch(handleSuccess('Save Successful'));
  } catch (e) {
    dispatch(handleError(e, 'Failed to create document access'));
  }
};

// PUT fni-lenderportal-admin/admin/dealerLocation/{id}
export const putDealerLocationById = (id, data) => async (dispatch) => {
  dispatch(incrementFetchCount());

  try {
    await api.put(`/dealerLocation/${id}`, data);
    dispatch(handleSuccess('Save Successful'));
  } catch (e) {
    dispatch(handleError(e, 'Failed to update dealer location'));
    throw e;
  } finally {
    dispatch(decrementFetchCount());
  }
};

// POST fni-lenderportal-admin/admin/dealerLocation
export const postDealerLocation = (data) => async (dispatch) => {
  dispatch(incrementFetchCount());

  try {
    await api.post('/dealerLocation', data);
    dispatch(handleSuccess('Save Successful'));
  } catch (e) {
    dispatch(handleError(e, 'Failed to create dealer location'));
    throw e;
  } finally {
    dispatch(decrementFetchCount());
  }
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_DEALER_GROUP]: (state, { payload: { data, path } }) => {
    return Immutable.setIn(state, path, data);
  },
  [INCREMENT_FETCH_COUNT]: (state) => {
    const count = state?.fetchCount;
    return Immutable.setIn(state, ['fetchCount'], count + 1);
  },
  [DECREMENT_FETCH_COUNT]: (state) => {
    const count = state?.fetchCount;
    return Immutable.setIn(state, ['fetchCount'], count - 1);
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { fetchCount: 0 };
export default function dealerGroupAdminReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

export default class Tenant {
    constructor (obj = {}) {

        this.id = obj.id || '';

        this.name = obj.name || '';

        this.code = obj.code || '';

        this.isMigrated = typeof obj.isMigrated === 'boolean' ? obj.isMigrated : false;

        this.baseURL = obj.baseURL || '';

        this.dbHost = obj.dbHost || '';

        this.dbPort = obj.dbPort || '';
        
        this.dbName = obj.dbName || '';

        this.hasFacilities = typeof obj.hasFacilities === 'boolean' ? obj.hasFacilities : true;

        this.isActive = typeof obj.isActive === 'boolean' ? obj.isActive : true;
    }
}

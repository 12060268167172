import Immutable from 'seamless-immutable';
import SchemaConverter from 'fni-components/FNISchemaForm/utils/SchemaConverter';
import _get from 'lodash/get';
import { makeApi } from 'fni-schema';

import { setInfo } from '../../../store/coreLayout';
import apiFetch from '../../../utils/apiFetch';

export const api = makeApi({
  baseURL: 'fni-lenderportal-admin',
  schemaKey: 'adminSchema',
});

// ------------------------------------
// Constants
// ------------------------------------
export const STIP_TYPE_LIST_FETCHING_SET = 'STIP_TYPE_LIST_FETCHING';
export const STIP_TYPE_VIEW_SET = 'STIP_TYPE_VIEW_SET';
export const STIP_TYPE_RESPONSE_SET = 'STIP_TYPE_RESPONSE_SET';
export const STIP_TYPE_CREATED_SET = 'STIP_TYPE_CREATED';
export const STIP_TYPE_DELETED_SET = 'STIP_TYPE_DELETED_SET';
export const STIP_TYPE_FIELD_SET = 'STIP_TYPE_FIELD_SET';
export const STIP_TYPE_FORMDATA_UPDATE = 'STIP_TYPE_FORMDATA_UPDATE';
export const STIP_TYPE_DESC_VALID_SET = 'STIP_TYPE_DESC_VALID_SET';
export const STIP_TYPE_LIST_SET = 'STIP_TYPE_LIST_SET';
export const STIP_TYPE_ERROR_MSG_SET = 'STIP_TYPE_ERROR_MSG_SET';
export const STIP_TYPE_INFO_SET = 'STIP_TYPE_INFO_SET';
export const STIP_TYPE_INFO_INIT = 'STIP_TYPE_INFO_INIT';
export const STIP_TYPE_FIELD_ERRORS_SET = 'STIP_TYPE_FIELD_ERRORS_SET';

// ------------------------------------
// Actions
// ------------------------------------
export const setStipFetching = (payload) => {
  return {
    type: STIP_TYPE_LIST_FETCHING_SET,
    payload,
  };
};

export const updateFormData = (location, data) => {
  return {
    type: STIP_TYPE_FORMDATA_UPDATE,
    payload: {
      location,
      data,
    },
  };
};
export const setStipTypeFields = (data) => {
  return {
    type: STIP_TYPE_FIELD_SET,
    payload: data,
  };
};
export const setcreateStipTyped = (data) => {
  return {
    type: STIP_TYPE_CREATED_SET,
    payload: data,
  };
};
export const setStipTypeDeleted = (data) => {
  return {
    type: STIP_TYPE_DELETED_SET,
    payload: data,
  };
};
export const receiveList = (data) => {
  return {
    type: STIP_TYPE_LIST_SET,
    payload: data,
  };
};
export const setStipTypeInfo = (stipTypeId, stipTypeDesc) => {
  return {
    type: STIP_TYPE_INFO_SET,
    payload: [stipTypeId, stipTypeDesc],
  };
};
export const stipTypeDescValidityReceive = (data) => {
  return {
    type: STIP_TYPE_DESC_VALID_SET,
    payload: data,
  };
};
export const initStipTypeInfo = () => {
  return {
    type: STIP_TYPE_INFO_INIT,
  };
};
export const setView = (data) => {
  return {
    type: STIP_TYPE_VIEW_SET,
    payload: data,
  };
};

const setFieldErrors = (errors) => {
  return {
    type: STIP_TYPE_FIELD_ERRORS_SET,
    payload: errors,
  };
};

// ------------------------------------
// Middleware
// ------------------------------------
const _setView = (view) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(setView(view));
      resolve(true);
    });
  };
};

const getOptions = (method) => {
  return {
    method,
  };
};

const setStipTypeDescValid = (valid) => {
  return (dispatch) => {
    dispatch(stipTypeDescValidityReceive(valid));
  };
};

function getFormattedError(error) {
  if (error.code === 500)
    return {
      type: 'error',
      message: error.message || 'Unknown Server Error. Please try again.',
    };

  switch (error.type) {
    case 'updateErr':
      return {
        type: 'error',
        message: 'Save Error',
      };
    case 'createErr':
      return { type: 'error', message: error.message || 'Role Creation Error' };
    case 'deleteErr':
      return { type: 'error', message: error.message || 'Role Deletion Error' };
    default:
      return {
        type: 'error',
        message: error.message || 'There was an unknown error',
      };
  }
}

const setErrorInfo = (error) => (dispatch) => {
  dispatch(setInfo(getFormattedError(error)));
};

export const getStipTypes = () => async (dispatch) => {
  dispatch(setStipFetching(true));

  try {
    const response = await api.get('admin/stiptypes');
    dispatch(receiveList(response));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
  } finally {
    dispatch(setStipFetching(false));
  }
};

export const fetchStipTypeList = () => {
  return (dispatch) => {
    return apiFetch('admin/stiptypes')
      .then((response) => {
        if (response.ok) {
          return response.json;
        }
        return true;
      })
      .then((response) => {
        const data = SchemaConverter.translateFromBackend({
          formData: response.formData,
          schema: response.adminSchema,
        });
        if (!data.schema.properties) {
          data.schema = {
            type: 'object',
            properties: { STIP_TYPES: data.schema },
          };
        }
        dispatch(receiveList(data));
        return true;
      })
      .catch(() => {
        // no error
      });
  };
};

export const newStipType = () => {
  return (dispatch) => {
    let data = {};
    return apiFetch('admin/stiptype')
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(
          'todo: secure call failure alert message with operation error detail'
        );
      })
      .then((json) => {
        data = SchemaConverter.translateFromBackend({
          formData: [],
          schema: json.adminSchema,
        });
        return dispatch(_setView('create'));
      })
      .then(() => {
        dispatch(initStipTypeInfo());
        dispatch(stipTypeDescValidityReceive(false));
        dispatch(setStipTypeFields(data));
        return true;
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };
};

export const createStipType = () => {
  return (dispatch, getState) => {
    const createOptions = getOptions('POST');
    const state = getState();
    const data =
      _get(state, ['stipulationType', 'fields', 'formData', 'stipType']) ||
      null;
    const transData = SchemaConverter.translateToBackend(data);
    const out = {};
    transData
      .filter((formDataElem) => {
        return formDataElem.fieldValue;
      })
      // eslint-disable-next-line array-callback-return
      .flatMap((fd) => {
        out[fd.fieldId] = fd.fieldValue;
      });
    const formData = [
      {
        fieldId: 'STIP_TYPE_DESC',
        fieldValue: out.STIP_TYPE_DESC,
      },
      {
        fieldId: 'STIP_TYPE_TAB_DESC',
        fieldValue: out.STIP_TYPE_TAB_DESC,
      },
      {
        fieldId: 'STIP_TYPE_TAB_ID',
        fieldValue: out.STIP_TYPE_TAB_ID,
      },
      {
        fieldId: 'STIP_TYPE_PERM_ID',
        fieldValue: out.STIP_TYPE_PERM_ID,
      },
      {
        fieldId: 'STIP_TYPE_RO_PERM_ID',
        fieldValue: out.STIP_TYPE_RO_PERM_ID,
      },
    ];
    createOptions.body = JSON.stringify(formData);
    return new Promise((resolve, reject) => {
      return apiFetch('admin/stiptype/', createOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          const errors = {
            type: 'createErr',
            code: response.status,
            message: response.errorMsgs.join(', '),
          };
          dispatch(setErrorInfo(errors));
          throw reject(new Error(false));
        })
        .then((json) => {
          if (json.status === 'SUCCESS') {
            dispatch(setStipTypeInfo('1', out.STIP_TYPE_DESC));
            dispatch(setcreateStipTyped(true));
            dispatch(
              setInfo({
                type: 'success',
                delay: 3000,
                message: 'Stip Created Successfully!',
              })
            );
            return resolve(true);
          }
          throw new Error(json);
        })
        .catch((errData) => {
          const errors = {
            type: 'createErr',
            code: errData.status,
            message: errData.errorMsgs.join(', '),
          };
          dispatch(setErrorInfo(errors));
          reject(new Error(false));
        });
    });
  };
};

export const fetchStipType = (stipTypeId) => {
  return (dispatch) => {
    return apiFetch(`admin/stiptype/${stipTypeId}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('No Response');
      })
      .then((response) => {
        const data = SchemaConverter.translateFromBackend({
          formData: response.formData[0].fieldValues[0],
          schema: response.adminSchema,
        });

        const stipTypeDesc = data.formData.stipType.STIP_TYPE_DESC;
        dispatch(setStipTypeInfo(stipTypeId, stipTypeDesc));
        dispatch(setStipTypeFields(data));
        dispatch(stipTypeDescValidityReceive(true));
        dispatch(_setView('edit'));
        return true;
      })
      .catch(() => {});
  };
};

export const putStipType = () => async (dispatch, getState) => {
  dispatch(setStipFetching(true));
  const data = getState()?.stipulationType?.fields?.formData?.stipType;
  try {
    await api.put('admin/stiptype', data);
    dispatch(
      setInfo({
        type: 'success',
        delay: 3000,
        message: 'Updated Successfully.',
      })
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    dispatch(setInfo({ message: 'Update Failed.' }));
  } finally {
    dispatch(setStipFetching(false));
  }
};

export const deleteStipType = (stipId) => async (dispatch) => {
  dispatch(setStipFetching(true));

  try {
    await api.delete(`admin/stiptype/${stipId}`);
    dispatch(setStipTypeDeleted(true));
    dispatch(initStipTypeInfo());
    dispatch(fetchStipTypeList());
    dispatch(getStipTypes());
    dispatch(
      setInfo({
        type: 'success',
        delay: 3000,
        message: 'Deleted Successfully.',
      })
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
    dispatch(setInfo({ message: 'Failed to delete.' }));
  } finally {
    dispatch(setStipFetching(false));
  }
};

// TODO: Determine best approach to dismissable alerts
const showDeleteModal = (show, stipId) => {
  return () => {
    if (show && stipId) {
      // dispatch(showDeleteMod(show));
      // dispatch(setStipDeleting(stipId));
    } else if (!(show && stipId)) {
      // dispatch(showDeleteMod(false));
      // dispatch(setStipDeleting(''));
    }
  };
};

const cancel = () => {
  return (dispatch) => {
    dispatch(_setView('load'))
      .then(() => {
        dispatch(initStipTypeInfo());
        // dispatch(clearErrors());
        dispatch(fetchStipTypeList());
        dispatch(_setView('view'));
        return true;
      })
      // eslint-disable-next-line no-console
      .catch(() => console.log(''));
  };
};
// TODO:  identify view-handling design already in use in other admin(s)

export const actions = {
  cancel,
  deleteStipType,
  fetchStipTypeList,
  fetchStipType,
  initStipTypeInfo,
  newStipType,
  setFieldErrors,
  setView,
  setStipTypeDescValid,
  setStipTypeInfo,
  showDeleteModal,
  updateFormData,
  getStipTypes,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [STIP_TYPE_LIST_FETCHING_SET]: (state, action) => {
    return Immutable({ ...state, listFetching: action.payload });
  },
  [STIP_TYPE_VIEW_SET]: (state, action) => {
    return Immutable({ ...state, view: action.payload });
  },
  [STIP_TYPE_RESPONSE_SET]: (state, action) => {
    return Immutable({ ...state, formData: action.payload });
  },
  [STIP_TYPE_CREATED_SET]: (state, action) => {
    return Immutable({ ...state, createStipTyped: action.payload });
  },
  [STIP_TYPE_DELETED_SET]: (state, action) => {
    return Immutable({ ...state, stipTypeDeleted: action.payload });
  },
  [STIP_TYPE_FIELD_SET]: (state, action) => {
    return Immutable({ ...state, fields: action.payload });
  },
  [STIP_TYPE_FORMDATA_UPDATE]: (state, action) => {
    return Immutable.setIn(
      state,
      ['fields', 'formData', action.payload.location],
      action.payload.data
    );
  },
  [STIP_TYPE_DESC_VALID_SET]: (state, action) => {
    return Immutable({ ...state, isStipTypeDescValid: action.payload });
  },
  [STIP_TYPE_LIST_SET]: (state, action) => {
    return Immutable({ ...state, ...action.payload });
  },
  [STIP_TYPE_ERROR_MSG_SET]: (state, action) => {
    return Immutable.setIn(
      state,
      ['apiErrs'],
      [`${action.payload.msg.toString()}`]
    );
  },
  [STIP_TYPE_INFO_INIT]: (state) => {
    return Immutable({ ...state, stipTypeId: 'new', stipTypeDesc: '' });
  },
  [STIP_TYPE_INFO_SET]: (state, action) => {
    return Immutable({
      ...state,
      stipTypeId: action.payload[0],
      stipTypeDesc: action.payload[1],
    });
  },
  [STIP_TYPE_FIELD_ERRORS_SET]: (state, action) => {
    return Immutable({ ...state, errors: action.payload });
  },
};
// ------------------------------------
// Reducer
// ------------------------------------
const initialState = { view: 'view' };
export default function stipulationTypeReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

import { setInfo } from '../store/coreLayout';

export const handleError =
  (error, defaultMessage = '') =>
  (dispatch) => {
    /* eslint-disable no-console */
    if (process.env.NODE_ENV === 'development') {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('response: ', error.response);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error('request: ', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('message: ', error.message);
      }
    }

    if (error?.response?.data) dispatch(setInfo(error.response.data));
    else if (error.message) dispatch(setInfo(error.message));
    else {
      dispatch(setInfo(defaultMessage));
    }
  };

export const handleSuccess = (message) => (dispatch) => {
  dispatch(
    setInfo({
      type: 'success',
      message,
      delay: 3000,
    })
  );
};

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import styles from './FilterList.module.scss';
import ConfirmModal from './ConfirmModal/ConfirmModal';
import FilterItem from './FilterItem/FilterItem';
import Loading from '../../../../components/Loading';
import {
  openFilterDeleteConfirmModal,
  closeFilterDeleteConfirmModal,
  filterDataFetch,
  filterDelete,
} from '../../modules/search';
import {
  getFilters,
  getFilterDeleteConfirmModal,
  getFilterId,
} from '../../utils/selectors';

const FilterList = ({
  filters,
  filterDeleteConfirmModal,
  filterId,
  listType,
  openFilterDeleteConfirmModal: dispatchOpenFilterDeleteConfirmModal,
  closeFilterDeleteConfirmModal: dispatchCloseFilterDeleteConfirmModal,
  filterDataFetch: dispatchFilterDataFetch,
  filterDelete: dispatchFilterDelete,
}) => {
  return (
    <>
      <Loading open={Boolean(filters?.fetching)} />
      <h4 className={styles.listHeader}>{listType}</h4>
      <ul className={styles.filterList}>
        {filters?.filterList
          ? filters?.filterList.map((filter) => {
              return (
                <FilterItem
                  filter={filter}
                  key={filter.filterId}
                  dataTableFilterId={filterId}
                  filterDataFetch={dispatchFilterDataFetch}
                  openFilterDeleteConfirmModal={
                    dispatchOpenFilterDeleteConfirmModal
                  }
                />
              );
            })
          : null}
      </ul>
      <ConfirmModal
        filterDeleteConfirmModal={filterDeleteConfirmModal}
        filterDelete={dispatchFilterDelete}
        closeFilterDeleteConfirmModal={dispatchCloseFilterDeleteConfirmModal}
      />
    </>
  );
};

FilterList.propTypes = {
  filterId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  listType: PropTypes.string.isRequired,
  filters: PropTypes.objectOf(PropTypes.any).isRequired,
  filterDeleteConfirmModal: PropTypes.objectOf(PropTypes.any).isRequired,
  openFilterDeleteConfirmModal: PropTypes.func.isRequired,
  closeFilterDeleteConfirmModal: PropTypes.func.isRequired,
  filterDataFetch: PropTypes.func.isRequired,
  filterDelete: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  filters: getFilters(state),
  filterDeleteConfirmModal: getFilterDeleteConfirmModal(state),
  filterId: getFilterId(state),
});

const mapDispatchToProps = {
  openFilterDeleteConfirmModal,
  closeFilterDeleteConfirmModal,
  filterDataFetch,
  filterDelete,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterList);

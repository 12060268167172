/* eslint-disable import/no-cycle */
import Immutable from 'seamless-immutable';
import { makeApi } from 'fni-schema';
import Mask from 'inputmask-core';

import { tabsFetch } from './navigation';
import { errorBoxSet, fetchActionData, ficoScoreFetch } from '../../store/shared';
import {
  apiFetch,
  ROUTE_IMPORTS,
  CUSTOM_ROUTE_IMPORTS,
  getSuccessMessage,
  getErrorMessage,
  translateSubmitFormData,
} from '../ProjectConstants';

export const api = makeApi({
  baseURL: 'fni-lenderportal-workbench/workbench/',
  schemaKey: 'tabSchema',
});

export const noInterceptorApi = makeApi({
  baseURL: 'fni-lenderportal-workbench/workbench/',
  withRequestInterceptor: false,
  withResponseInterceptor: false,
  schemaKey: 'tabSchema',
});

// ------------------------------------
// Constants
// ------------------------------------
export const FORM_DATA_SET = 'FORM_DATA_SET';
export const FORM_DATA_RECEIVE = 'FORM_DATA_RECEIVE';
export const ERROR_SCHEMA_MERGE = 'ERROR_SCHEMA_MERGE';
export const ACTION_RECEIVE = 'ACTION_RECEIVE';
export const ALERT_SET = 'ALERT_SET';
export const SCHEMA_FORM_SET = 'SCHEMA_FORM_SET';
export const SCHEMA_SET = 'SCHEMA_SET';
export const ORIGINAL_FORM_DATA_SET = 'ORIGINAL_FORM_DATA_SET';
export const UPDATE_STIP = 'UPDATE_STIP';
export const SET_UNDREAD_CONVO = 'SET_UNREAD_CONVO';
export const LOADING_INCREMENT = 'LOADING_INCREMENT';
export const LOADING_DECREMENT = 'LOADING_DECREMENT';

export const checkMask = (data) => {
  const inputMask = new Mask({ pattern: '1' });
  const maskRec = (obj, name, depth) => {
    if (obj && obj.type === 'object' && obj.properties) {
      Object.keys(obj.properties).forEach((k) => {
        const newDepth = depth.slice(0);
        if (name !== '') {
          newDepth.push(name);
        }
        maskRec(obj.properties[k], k, newDepth);
      });
    } else {
      let info = data.uiSchema;
      let value = data.formData;
      depth.forEach((l) => {
        info = info[l];
        value = value[l];
      });
      info = info[name];
      if (info && info['ui:widget'] === 'masked') {
        const { mask } = info['ui:options'];
        inputMask.setPattern(mask);
        inputMask.setValue(value[name]);
        value[name] = inputMask.getRawValue().replace(/_/g, '');
        value[name] = value[name] === '' ? undefined : value[name];
      }
    }
  };
  maskRec(data.schema, '', []);
  return data;
};

const flattenArray = (obj) => {
  return obj.map((row) => {
    return Object.entries(row).map(([key, value]) => {
      if (Array.isArray(value)) {
        return {
          fieldId: key,
          fieldValues: flattenArray(value),
        };
      }
      return {
        fieldId: key,
        fieldValue: value ?? '',
      };
    });
  });
};

export const flattenObject = (obj) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      acc[key] = flattenArray(value);
      return acc;
    }
    if (typeof value === 'object') {
      return { ...acc, ...flattenObject(value) };
    }
    acc[key] = value;
    return acc;
  }, {});
};

// ------------------------------------
// Actions
// ------------------------------------

export const alertSet = (payload) => {
  return {
    type: ALERT_SET,
    payload,
  };
};

export const formDataSet = (path, data) => {
  return {
    type: FORM_DATA_SET,
    payload: {
      data,
      path,
    },
  };
};

export const formDataReceive = (path, data) => {
  return {
    type: FORM_DATA_RECEIVE,
    payload: {
      data,
      path,
    },
  };
};

export const errorSchemaMerge = (path, data) => {
  return {
    type: ERROR_SCHEMA_MERGE,
    payload: {
      path,
      data,
    },
  };
};

export const schemaFormSet = (value, tab, path, array, index) => {
  return {
    type: SCHEMA_FORM_SET,
    payload: { value, tab, path, array, index },
  };
};

export const schemaSet = (value, tab, path) => {
  return {
    type: SCHEMA_SET,
    payload: { value, tab, path },
  };
};
export const originalFormDataSet = (value, tab, path) => {
  return {
    type: ORIGINAL_FORM_DATA_SET,
    payload: { value, tab, path },
  };
};

export const updateStip = (path, index, field, data) => ({
  type: UPDATE_STIP,
  payload: { path, index, field, data },
});

export const setUnreadConvo = (num) => ({
  type: SET_UNDREAD_CONVO,
  payload: num,
});

export const incrementLoading = () => ({
  type: LOADING_INCREMENT,
});

export const decrementLoading = () => ({
  type: LOADING_DECREMENT,
});

const handledFormats = ['date'];

const pullValidations = (
  schema,
  returns = false,
  path = [],
  arrCounter = 0
) => {
  let _returns = returns || {
    validations: {},
    formatValidations: {
      date: [],
    },
    paths: {},
    ajaxValidations: {},
    ajaxValidationMessages: {},
  };

  const { properties } = schema;

  Object.keys(properties).forEach((propKey) => {
    const retPath = path.slice();
    retPath.push(properties[propKey].key);
    const nestedSchema = properties[propKey];
    if (nestedSchema.type === 'object') {
      _returns = pullValidations(nestedSchema, _returns, retPath);
    } else if (nestedSchema.type === 'array') {
      retPath.push(`$array${arrCounter}`);
      _returns = pullValidations(
        nestedSchema.items,
        _returns,
        retPath,
        arrCounter + 1
      );
    } else if (nestedSchema.validationMessages) {
      const messages = {};
      nestedSchema.validationMessages.forEach((m) => {
        messages[m.type] = m.message;
      });
      messages.externalName = nestedSchema.title;
      if (nestedSchema.ajaxValidation) {
        nestedSchema.ajaxValidationFields.forEach((vField) => {
          _returns.ajaxValidations[vField] = {
            forField: nestedSchema.key,
            values: nestedSchema.ajaxValidationFields,
          };
        });
      }
      _returns.validations[nestedSchema.key] = messages;
      _returns.paths[nestedSchema.key] = retPath;
    }
    if (handledFormats.includes(nestedSchema.format)) {
      _returns.formatValidations[nestedSchema.format].push(nestedSchema.key);
    }
  });
  return _returns;
};

const showErrorAlert = (message) => (dispatch) => {
  dispatch(
    alertSet({
      type: 'error',
      message,
    })
  );
};

const showSuccessAlert = (message) => (dispatch) => {
  dispatch(
    alertSet({
      type: 'success',
      message,
      delay: 3000,
    })
  );
};

const clearAlert = () => (dispatch) => {
  dispatch(alertSet({}));
};

// ------------------------------------
// Middleware
// ------------------------------------

const getTabFromPath = (path) => (dispatch, getState) => {
  return getState()?.navigation?.tabs?.find(
    ({ tabRoute }) => tabRoute === path
  );
};

const handleLegacyFormData =
  (response, importTranslator, path) => (dispatch) => {
    const { formData = {}, schema = {} } = response;

    const translatedData =
      typeof importTranslator === 'function'
        ? importTranslator({ tabSchema: schema, formData })
        : response;
    translatedData.validations = pullValidations(schema);

    dispatch(formDataReceive(path, translatedData));
  };

export const formDataFetch =
  (path, options = {}) =>
  async (dispatch) => {
    const { importTranslator, legacy = false } = options;
    dispatch(incrementLoading());
    try {
      const response = await api.get(`tab/${path}`);
      if (legacy) {
        dispatch(handleLegacyFormData(response, importTranslator, path));
      } else {
        dispatch(formDataReceive(path, response));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      if (process.env.NODE_ENV === 'development') console.error(e);
      setTimeout(() => {
        dispatch(clearAlert());
      }, 5000);
      const tab = dispatch(getTabFromPath(path));

      dispatch(
        showErrorAlert(
          `There was an error retrieving the data for ${tab?.tabName}.`
        )
      );
    } finally {
      dispatch(decrementLoading());
    }
  };

export const convoMessageFetch = () => async (dispatch) => {
  try {
    const response = await noInterceptorApi.get(
      'convo/conversations/unreadMessages'
    );
    dispatch(setUnreadConvo(response.data));
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(e);
  }
};

const adverseActionCompile = (adverseActions) => {
  if (!adverseActions.adverseActionSchema) {
    return [];
  }
  const { required } = adverseActions.adverseActionSchema;
  const { formData } = adverseActions;
  const ret = [];
  let bad = false;
  if (required.length > 0) {
    bad = true;
    required.forEach((req) => {
      if (formData[req] && formData[req] !== '00') {
        bad = false;
        ret.push({
          fieldId: req,
          fieldValue: formData[req],
        });
      }
    });
  }
  return {
    bad,
    data: ret,
  };
};

const handleSubmitSuccess = (actionCode) => (dispatch) => {
  if (actionCode !== 'CLOSE') {
    dispatch(showSuccessAlert(getSuccessMessage(actionCode)));
    setTimeout(() => {
      dispatch(clearAlert());
    }, 10000);

    dispatch(tabsFetch());
    dispatch(fetchActionData());
    dispatch(ficoScoreFetch());
  }
};

const handleSubmitError = (e, actionCode) => (dispatch) => {
  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV === 'development') console.error(e);

  if (e?.length) {
    const addErrors = {};
    const sendErrors = {};
    e.forEach((msg) => {
      if (!sendErrors[msg.tabRoute]) {
        sendErrors[msg.tabRoute] = {};
        addErrors[msg.tabRoute] = [];
      }
      if (!sendErrors[msg.tabRoute][msg.fieldId]) {
        sendErrors[msg.tabRoute][msg.fieldId] = { __errors: [] };
      }
      sendErrors[msg.tabRoute][msg.fieldId].__errors.push(msg.message);
      addErrors[msg.tabRoute].push({
        fieldId: msg.fieldId,
        fieldValue: msg.message,
      });
    });

    Object.keys(sendErrors).forEach((tab) => {
      dispatch(formDataSet(tab, { errorSchema: sendErrors[tab] }));
      dispatch(errorBoxSet(tab, addErrors[tab]));
    });

    dispatch(showErrorAlert(e.map((error) => error.message).join('\n')));
  } else {
    dispatch(
      alertSet({
        type: 'error',
        message: e?.message || getErrorMessage(actionCode),
      })
    );
  }
};

const submitData =
  (data, formDataUpdates = {}, actionCode) =>
  async (dispatch) => {
    dispatch(incrementLoading());
    try {
      const response = await noInterceptorApi.put('action', data, {
        headers: { REFNUM: window.refnum },
      });

      if (response?.data?.status === 'ERROR') {
        // eslint-disable-next-line no-throw-literal
        throw response?.data?.errorMsgs;
      }
      Object.keys(formDataUpdates).forEach((key) => {
        dispatch(
          formDataSet(key, {
            originalFormData: formDataUpdates[key],
          })
        );
      });
      dispatch(handleSubmitSuccess(actionCode));
    } catch (e) {
      dispatch(handleSubmitError(e, actionCode));
    } finally {
      dispatch(decrementLoading());
    }
  };

export const closeWorkbench = () => async (dispatch) =>
  dispatch(submitData({ actionCode: 'CLOSE' }, {}, 'CLOSE'));

export const formDataSubmit = (actionCode) => async (dispatch, getState) => {
  dispatch(incrementLoading());
  dispatch(clearAlert());
  if (actionCode === 'CLOSE') {
    await dispatch(closeWorkbench());
    dispatch(decrementLoading());
    return;
  }
  const adverseActions = adverseActionCompile(
    getState().coreLayout.adverseActions
  );
  const data = getState().formData;
  const { tabs } = getState().navigation;
  const tabData = [];
  const formDataUpdates = {};

  tabs.forEach((tab) => {
    const path = tab.tabRoute;
    const route =
      ROUTE_IMPORTS.find((innerRoute) => innerRoute.path === path) ??
      CUSTOM_ROUTE_IMPORTS.find((innerRoute) => innerRoute.path === path) ??
      {};
    if (data[path] && route.submit) {
      let _info = Immutable.asMutable(data[path] || {}, { deep: true });
      if (tab.tabRoute === 'applicant' || tab.tabRoute === 'coapplicant') {
        // Potentially remove based on if any clients uses this.
        const isLast4Defined = Boolean(_info?.formData?.overflow?.SOC_LAST4);
        if (
          isLast4Defined &&
          data[path].formData.personal.SOC &&
          data[path].formData.personal.SOC.length === 9
        ) {
          _info.formData.overflow.SOC_LAST4 = data[
            path
          ].formData.personal.SOC.substr(-4, 4);
        }
        if (!data?.[path]?.formData?.personal?.LAST) {
          _info.formData = false;
        }
      }
      if (_info.formData) {
        _info = translateSubmitFormData(_info, path, tab.tabType);
        const orig = flattenObject(_info.originalFormData);
        // eslint-disable-next-line no-use-before-define
        const newData = translateFormDataSubmit(
          flattenObject(_info.formData),
          path,
          _info.dates,
          {},
          orig
        );
        tabData.push(newData);
      }

      dispatch(formDataSet(path, { errorSchema: _info.errorSchema }));
      dispatch(errorBoxSet(path, []));

      formDataUpdates[path] = data[path].formData;
    }
  });

  if (data.assignment) {
    let _info = Immutable.asMutable(data.assignment || {}, { deep: true });
    _info = translateSubmitFormData(_info, 'assignment');
    const orig = flattenObject(_info.originalFormData);
    // eslint-disable-next-line no-use-before-define
    const newData = translateFormDataSubmit(
      flattenObject(_info.formData),
      'assignment',
      _info.dates,
      {},
      orig
    );
    tabData.push(newData);
  }

  const info = {
    actionCode,
    selectedDecision:
      getState()?.coreLayout?.decisionBlock?.formData?.DECISION_DD,
    clientAppStatus:
      getState()?.formData?.key_stats?.formData?.CURR_STATUS ??
      'NO_CURR_STATUS',
    tabsFormData: tabData,
  };

  info.adverseActionFormData = adverseActions.data;

  if (adverseActions.bad) {
    dispatch(showErrorAlert('Please complete Adverse Actions'));
  } else {
    await dispatch(submitData(info, formDataUpdates, actionCode));
  }
  dispatch(decrementLoading());
};

export const actionSubmit = (actionCode, path, data = null) => {
  let _data = data;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      if (!_data) {
        _data = getState().formData[path].formData;
      }
      _data = Immutable.asMutable(_data, { deep: true });
      // eslint-disable-next-line no-use-before-define
      _data = translateActionSubmit(flattenObject(_data));
      const options = {
        method: 'PUT',
        body: JSON.stringify({
          actionCode,
          selectedDecision:
            getState().coreLayout.decisionBlock.formData.DECISION_DD,
          clientAppStatus:
            getState()?.formData?.key_stats?.formData?.CURR_STATUS ??
            'NO_CURR_STATUS',
          tabsFormData: [
            {
              tabRoute: path,
              formData: _data,
            },
          ],
        }),
      };
      apiFetch('action', options)
        .then((response) => {
          if (response.ok) {
            dispatch(showSuccessAlert('Data been successfully saved.'));
            setTimeout(() => {
              dispatch(clearAlert());
            }, 5000);
            resolve();
          } else {
            dispatch(showErrorAlert('There was an error saving your data.'));
            reject(response);
          }
          return undefined;
        })
        .catch(() => {
          dispatch(showErrorAlert('There was an error saving your data.'));
          reject();
        });
    });
  };
};

export const translateActionSubmit = (data) => {
  const keys = Object.keys(data);
  const values = [];
  keys.forEach((k) => {
    let val = data[k] || '';
    if (Array.isArray(val)) {
      val = val.map((v) => v || '');
      values.push({
        fieldId: k,
        fieldValues: val,
      });
    } else {
      values.push({
        fieldId: k,
        fieldValue: val,
      });
    }
  });
  return values;
};

export const translateFormDataSubmit = (
  data,
  path,
  dates,
  schema,
  orig = false
) => {
  const keys = Object.keys(data);
  const values = [];
  keys.forEach((dataKey) => {
    const val = data[dataKey] || '';
    if (Array.isArray(val)) {
      const vl = [];
      val.forEach((v, i) => {
        const vDataArr = [];
        if (!Array.isArray(v)) {
          const vData = [];
          Object.keys(v).forEach((key) => {
            vData.push({
              fieldId: key,
              fieldValue: v[key],
            });
          });
          vl.push(vData);
        } else if (orig) {
          if (orig[dataKey].length === data[dataKey].length) {
            Object.keys(v).forEach((arrKey) => {
              vDataArr.push({
                fieldId: v[arrKey].fieldId,
                fieldValue: v[arrKey].fieldValue,
                fieldValues: v[arrKey].fieldValues,
                origFieldValue: orig[dataKey][i][arrKey].fieldValue,
              });
            });
            vl.push(vDataArr);
          } else if (orig[dataKey].length < data[dataKey].length) {
            Object.keys(v).forEach((arrKey) => {
              let tmp = '';
              if (typeof orig[dataKey][i] !== 'undefined') {
                tmp = orig[dataKey][i][arrKey].fieldValue;
              }
              vDataArr.push({
                fieldId: v[arrKey].fieldId,
                fieldValue: v[arrKey].fieldValue,
                origFieldValue: tmp,
              });
            });
            vl.push(vDataArr);
          } else if (orig[dataKey].length > data[dataKey].length) {
            Object.keys(v).forEach((arrKey) => {
              let tmpId = '';
              let tmpVal = '';
              if (
                typeof v[arrKey].fieldId !== 'undefined' &&
                typeof v[arrKey].fieldValue !== 'undefined'
              ) {
                tmpId = v[arrKey].fieldId;
                tmpVal = v[arrKey].fieldValue;
              }
              vDataArr.push({
                fieldId: tmpId,
                fieldValue: tmpVal,
                origFieldValue: orig[dataKey][i][arrKey].fieldValue,
              });
            });
            vl.push(vDataArr);
          }
        }
      });
      values.push({
        fieldId: dataKey,
        fieldValues: vl,
      });
    } else {
      const p = {
        fieldId: dataKey,
        fieldValue: val,
      };
      if (orig[dataKey]) {
        p.origFieldValue = orig[dataKey];
      }
      values.push(p);
    }
  });
  return {
    tabRoute: path,
    formData: values,
  };
};

export const actions = {
  formDataSet,
  formDataFetch,
  formDataSubmit,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [ALERT_SET]: (state, action) => {
    return Immutable({
      ...state,
      alert: action.payload,
    });
  },
  [FORM_DATA_SET]: (state, action) => {
    return Immutable({
      ...state,
      [action.payload.path]: {
        ...state[action.payload.path],
        ...action.payload.data,
      },
    });
  },
  [FORM_DATA_RECEIVE]: (state, action) => {
    return Immutable({
      ...state,
      [action.payload.path]: {
        ...state[action.payload.path],
        ...action.payload.data,
        originalFormData: action.payload.data.formData,
      },
    });
  },
  [ERROR_SCHEMA_MERGE]: (state, action) => {
    const { errorSchema } = state[action.payload.path];
    const es = errorSchema
      ? errorSchema.merge(action.payload.data, { deep: true })
      : action.payload.data;
    return Immutable({
      ...state,
      [action.payload.path]: { ...state[action.payload.path], errorSchema: es },
    });
  },
  [SCHEMA_FORM_SET]: (state, action) => {
    const { value, array, index } = action.payload;
    let { tab, path } = action.payload;

    tab = tab.split('/').pop();
    if (path === undefined) {
      return state;
    }
    path = [...path];
    path.unshift(tab, 'formData');
    if (array) {
      const newIndex = path.indexOf(array) + 1;
      path.splice(newIndex, 0, index);
    }
    const newData = Immutable.setIn(state, path, value);
    return newData;
  },
  [SCHEMA_SET]: (state, action) => {
    const { value, tab, path } = action.payload;
    if (path === undefined) {
      return state;
    }
    const newData = Immutable.setIn(state, [tab, 'schema', ...path], value, {
      deep: true,
    });
    return newData;
  },
  [ORIGINAL_FORM_DATA_SET]: (state, action) => {
    const { value, tab, path } = action.payload;
    if (path === undefined) {
      return state;
    }
    path.unshift(tab, 'originalFormData');
    const newData = Immutable.setIn(
      state,
      [tab, 'originalFormData', ...path],
      value,
      { deep: true }
    );
    return newData;
  },
  [UPDATE_STIP]: (state, action) => {
    const { path, index, field, data } = action.payload;
    return Immutable.setIn(
      state,
      [path, 'formData', 'STIPS', index, field],
      data
    );
  },
  [SET_UNDREAD_CONVO]: (state, action) =>
    Immutable.setIn(state, ['unreadMessages'], action?.payload),
  [LOADING_INCREMENT]: (state) =>
    Immutable.setIn(state, ['loading'], state.loading + 1),
  [LOADING_DECREMENT]: (state) =>
    Immutable.setIn(state, ['loading'], state.loading - 1),
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  allRouteFetch: false,
  initialLoad: false,
  alert: {
    show: false,
  },
  loading: 0,
};
export default function formDataReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

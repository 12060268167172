
import messaging from 'fni-components/FNIMessaging/modules/messaging';
import customAbility from 'fni-components/CustomAbility/modules/customAbility';
import { reducer as conversations } from 'fni-conversations';
import coreLayoutReducer from './coreLayout';
import search from '../routes/Search/modules/search'
import loanView from '../routes/Search/modules/loanView'
import dealerLanding from '../routes/Search/modules/dealerLanding'

const makeRootReducer = () => ({
  coreLayout: coreLayoutReducer,
  messaging,
  customAbility,
  convo: conversations,
  search,
  dealerLanding,
  loanView
});

export default makeRootReducer;

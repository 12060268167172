import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { SchemaProvider } from 'fni-schema';
import {SchemaField} from 'fni-schema';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialogue from '@mui/material/Dialog';
import DialogueTitle from '@mui/material/DialogTitle';
import DialogueContent from '@mui/material/DialogContent';
import DialogueActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';

import ApiContext from './ApiContext';

const mutators = {
  postCustomMessage: (
    [postConversation, type],
    {
      formState: {
        values: { CONVO_MSG_TEXT, CONVO_SUBJECT },
      },
    }
  ) => {
    return postConversation({
      CONVO_TYPE: type,
      CONVO_MSG_TEXT,
      CONVO_SUBJECT,
    });
  },
  checkDisabled: (
    [predef, convoType, id, FEType],
    {
      formState: {
        values: { CONVO_MSG_TEXT, CONVO_SUBJECT },
      },
    }
  ) => {
    const checkConvoType = ['DEALER', 'BORROWER'].includes(convoType);
    if (FEType === 'LENDER') {
      if (predef) {
        return !checkConvoType || !id;
      }
      return !checkConvoType || !CONVO_MSG_TEXT || !CONVO_SUBJECT;
    }
    return !CONVO_MSG_TEXT || !CONVO_SUBJECT;
  },
};

const SendMessage = ({ schema, formData, open, onClose, FEType, canSend }) => {
  const [defIndex, setDefIndex] = useState(false);
  const [convoType, setConvoType] = useState(1);
  const {
    apis: { postConversation },
  } = useContext(ApiContext);
  const [predef, setPredef] = useState(FEType === 'LENDER');
  const [replyLength, setReplyLength] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  const maxLength =
    schema?.properties?.PREDEF_MESSAGES?.items?.properties?.CONVO_MSG_TEXT
      ?.maxLength ?? 250;

  return (
    <Dialogue
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      disableBackdropClick
    >
      <DialogueTitle>New Conversation</DialogueTitle>
      <Form
        initialValues={formData?.PREDEF_MESSAGES?.[defIndex]}
        subscription={{}}
        onSubmit={() => {}}
        mutators={mutators}
        render={({
          form: {
            mutators: { postCustomMessage, checkDisabled },
            reset,
          },
        }) => (
          <>
            <DialogueContent>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                mt={2}
              >
                {FEType === 'LENDER' && (
                  <>
                    <Box>
                      <TextField
                        label="Conversation Type"
                        select
                        value={convoType}
                        autoWidth
                        onChange={(e) => {
                          setConvoType(e.target.value);
                        }}
                      >
                        <MenuItem value="DEALER">Dealer</MenuItem>
                        <MenuItem value="BORROWER">Borrower</MenuItem>
                      </TextField>
                    </Box>
                    <Box display="flex" alignItems="flex-end">
                      <Box flexGrow={0} mr={2}>
                        <TextField
                          value={defIndex}
                          label="Predefined Message"
                          disabled={!predef}
                          select
                          autoWidth
                          onChange={(e) => {
                            setDefIndex(e.target.value);
                            setReplyLength(
                              formData?.PREDEF_MESSAGES?.[e.target.value]
                                ?.CONVO_MSG_TEXT?.length ?? 0
                            );
                          }}
                        >
                          {formData?.PREDEF_MESSAGES.map(
                            (
                              { CONVO_DEF_MSG_ID, CONVO_DISPLAY_TEXT },
                              index
                            ) => {
                              return (
                                <MenuItem key={CONVO_DEF_MSG_ID} value={index}>
                                  {CONVO_DISPLAY_TEXT}
                                </MenuItem>
                              );
                            }
                          )}
                        </TextField>
                      </Box>
                      <Box>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={predef}
                              disabled={!canSend}
                              onChange={() => {
                                if (!predef) reset();
                                setPredef(!predef);
                              }}
                              name="switch"
                              color="primary"
                            />
                          }
                          label="Predefined Message"
                        />
                      </Box>
                    </Box>
                  </>
                )}
                <SchemaProvider
                  schema={schema?.properties?.PREDEF_MESSAGES?.items}
                >
                  <Box>
                    <SchemaField
                      name="CONVO_SUBJECT"
                      disabled={predef}
                      label="Subject"
                    />
                  </Box>
                  <SchemaField
                    multiline
                    name="CONVO_MSG_TEXT"
                    disabled={predef}
                    label="Text"
                    onChange={(e, cb) => {
                      if (!maxLength || e.target.value?.length <= maxLength) {
                        setReplyLength(e.target.value?.length ?? 0);
                        cb(e);
                      }
                    }}
                    endAdornment={
                      Boolean(maxLength) && (
                        <InputAdornment position="end">{`${replyLength}/${maxLength}`}</InputAdornment>
                      )
                    }
                  />
                </SchemaProvider>
              </Box>
            </DialogueContent>
            <DialogueActions>
              <Button onClick={onClose}>Cancel</Button>
              <FormSpy subscription={{ values: true }}>
                {() => (
                  <Button
                    id="conversation-submit"
                    color="primary"
                    variant="contained"
                    disabled={
                      submitting ||
                      checkDisabled(
                        predef,
                        convoType,
                        formData?.PREDEF_MESSAGES?.find(
                          (a, i) => i === defIndex
                        )?.CONVO_DEF_MSG_ID,
                        FEType
                      )
                    }
                    onClick={async () => {
                      setSubmitting(true);
                      let didPost = false;
                      if (predef) {
                        didPost = await postConversation({
                          CONVO_TYPE: convoType,
                          CONVO_DEF_MSG_ID: formData?.PREDEF_MESSAGES.find(
                            (a, i) => i === defIndex
                          ).CONVO_DEF_MSG_ID,
                        });
                      } else {
                        didPost = await postCustomMessage(
                          postConversation,
                          convoType
                        );
                      }
                      if (didPost) {
                        setSubmitting(false);
                        onClose();
                      }
                    }}
                  >
                    Submit
                  </Button>
                )}
              </FormSpy>
            </DialogueActions>
          </>
        )}
      />
    </Dialogue>
  );
};

const emptyPredef = {};

SendMessage.propTypes = {
  FEType: PropTypes.string,
  formData: PropTypes.objectOf(PropTypes.any),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  schema: PropTypes.objectOf(PropTypes.any),
  canSend: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  ...(state?.convo?.predef ?? emptyPredef),
  FEType: state?.convo?.type,
  canSend: state?.convo?.permissions?.CAN_SEND,
});

export default connect(mapStateToProps)(SendMessage);

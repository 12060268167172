import PropTypes from 'prop-types';
import React from 'react';
import Calculator from 'fni-loan-calculator';
import { connect } from 'react-redux';

import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import {
  searchModalSet,
  searchCancel,
} from '../../../products/fni-search/routes/Search/modules/search';
import QueueList from '../../../products/fni-search/routes/Search/components/QueueList/QueueList';
import FilterList from '../../../products/fni-search/routes/Search/components/FilterList/FilterList';
// eslint-disable-next-line import/no-cycle

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    overflowY: 'auto',
  },
  drawer: {
    width: '320px',
    flexShrink: 0,
  },
  drawerHeader: {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    width: '320px',
    padding: '5px',
    overflowX: 'hidden',
  },
}));

const Sidebar = ({
  modalOpen,
  searchCancel: dispatchSearchCancel,
  searchModalSet: dispatchSearchModalSet,
  isSidebarOpen,
  toggleSidebar,
  hasCalc,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const classes = useStyles();

  const [openCalc, setOpenCalc] = React.useState(false);

  const toggleCalc = React.useCallback(() => setOpenCalc((c) => !c), [
    setOpenCalc,
  ]);

  return (
    <div className={classes.container}>
      <SwipeableDrawer
        className={classes.drawer}
        open={isSidebarOpen}
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor="left"
        disableBackdropTransition={false}
        disableDiscovery={false}
        onOpen={toggleSidebar}
        onClose={toggleSidebar}
      >
        <div className={classes.drawerHeader}>
          {hasCalc && (
            <>
              <Button
                id="calculator-button"
                color="primary"
                variant="contained"
                fullWidth
                onClick={toggleCalc}
              >
                Calculator
              </Button>
              <Calculator open={openCalc} onClose={toggleCalc} />
            </>
          )}
          <Box flexGrow={1} />
          <Tooltip title="Toggle Search Sidebar">
            <IconButton onClick={() => toggleSidebar(false)}>
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
        </div>
        <Divider />

        <Paper className={classes.drawerPaper}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => {
              if (modalOpen) {
                dispatchSearchCancel();
              } else {
                dispatchSearchModalSet(true);
              }
            }}
          >
            New Search
          </Button>
          <>
            <QueueList listType="Queues" />
            <FilterList listType="Filters" />
          </>
        </Paper>
      </SwipeableDrawer>
    </div>
  );
};

Sidebar.propTypes = {
  modalOpen: PropTypes.bool,
  searchModalSet: PropTypes.func.isRequired,
  searchCancel: PropTypes.func.isRequired,
  isSidebarOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  hasCalc: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  modalOpen: state?.search?.searchModal?.modalOpen,
  hasCalc: state?.search?.hasCalc,
});

const mapDispatchToProps = {
  searchModalSet,
  searchCancel,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

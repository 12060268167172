import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { incrementLoading, decrementLoading } from '../../../components/store/shared';

const Loading = ({
  open,
  incrementLoading: dispatchIncrementLoading,
  decrementLoading: dispatchDecrementLoading,
}) => {
  React.useEffect(() => {
    if (open) dispatchIncrementLoading();
    return () => {
      if (open) dispatchDecrementLoading();
    };
  }, [open, dispatchIncrementLoading, dispatchDecrementLoading]);

  return null;
};

Loading.propTypes = {
  open: PropTypes.bool.isRequired,
  incrementLoading: PropTypes.func.isRequired,
  decrementLoading: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  incrementLoading,
  decrementLoading,
};

export default connect(null, mapDispatchToProps)(Loading);

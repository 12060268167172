import { combineReducers, configureStore } from '@reduxjs/toolkit';
import coreSliceReducer from './CoreSlice/core';
import authSliceReducer from '../authentication/modules/auth';
import tenantSliceReducer from '../tenant-admin/modules/tenants';
import userSliceReducer from '../user-admin/modules/users';
import errorReducer from '../components/store/error';
import shared from '../components/store/shared';
import coreLayoutReducer from '../products/fni-admin/store/coreLayout';
import headerReducer from '../container/store/headerStore';
import timeoutReducer from 'fni-components/FNITimeout/modules/fniTimeout';
import informationBoxReducer from 'fni-components/FNIInformationBox/modules/fniInformationBox';
import makeRootReducer from '../products/fni-search/store/reducers';
import { autoCollateral } from 'fni-auto';
import reporting from '../products/fni-reporting/modules/modules';
import { default as workMakeRootReducer } from 'fni-workbench-standard/store/reducers';
import makeRootAdminReducer from '../products/fni-admin/store/reducers';
import makeRootReducerTouchpoint from '../products/fni-touchpoint/store/reducers';


const staticReducers = process.env.REACT_APP_LOAD_CONSOLIDATED === 'true' ? combineReducers({
    core: coreSliceReducer,
    auth: authSliceReducer,
    tenants: tenantSliceReducer,
    users: userSliceReducer,
    coreLayout: coreLayoutReducer,
    header: headerReducer,
    errors: errorReducer,
    timeout: timeoutReducer,
    informationBox: informationBoxReducer,
    shared,
    reporting,
    autoCollateral,
    ...makeRootReducer(),
    ...workMakeRootReducer(),
    ...makeRootAdminReducer(),
    ...makeRootReducerTouchpoint(),
})
    : 
    combineReducers({
        core: coreSliceReducer,
        auth: authSliceReducer,
        tenants: tenantSliceReducer,
        users: userSliceReducer,
    });




const rootReducer = (state, action) => {
    if(action.type === 'auth/logout') { 
        state = undefined; 
    }
    return staticReducers(state, action);
};
const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck:{
            ignoredActionPaths: ['payload', 'meta.arg'],
        },
    }),
});

export default store;

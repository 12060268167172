import Immutable from 'seamless-immutable';

export const URL_SET = 'URL_SET';

/** ACTIONS **/

export const urlSet = (data) => {
  return {
    type: URL_SET,
    payload: data
  };
};

/** MIDDLEWARE **/

const ACTION_HANDLERS = {
  [URL_SET]: (state, action) => {
    return Immutable({ ...state, url: action.payload });
  }
};

/** INITIAL STATE **/
const initialState = {};

export default function customAbilityReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
